const PUBLISHER_ACCOUNT_COM = '1100lr2iz';
const PUBLISHER_ACCOUNT_ES = '1011lrcKJ';

const CREATIVE_ID_404 = '1101l89684';
const CREATIVE_ID_AUTOPROMO = '1101l89685';
const CREATIVE_ID_CC = '1101l90584';
const CREATIVE_ID_POPUP = '1011l90437';
const CREATIVE_ID_STOCKBANNER = '1011l90232';

const CREATIVE_IDS_IDP = {
    AD: '1011l90148',
    AE: '1100l89053',
    AF: '1101l89604',
    AG: '1011l90149',
    AI: '1100l89054',
    AL: '1011l90150',
    AM: '1101l89605',
    AN: '1011l90151',
    AO: '1100l89055',
    AQ: '1101l89606',
    AR: '1011l90152',
    AS: '1011l90153',
    AT: '1101l89607',
    AU: '1101l89608',
    AW: '1101l89609',
    AX: '1101l89610',
    AZ: '1101l89611',
    BA: '1101l89612',
    BB: '1100l89056',
    BD: '1100l89057',
    BE: '1011l90154',
    BF: '1100l89058',
    BG: '1011l90155',
    BH: '1101l89613',
    BI: '1011l90156',
    BJ: '1100l89059',
    BL: '1101l89614',
    BM: '1100l89060',
    BN: '1100l89061',
    BO: '1101l89615',
    BQ: '1100l89062',
    BR: '1100l89063',
    BS: '1011l90157',
    BT: '1011l90158',
    BV: '1011l90159',
    BW: '1011l90160',
    BY: '1011l90161',
    BZ: '1100l89064',
    CA: '1100l89065',
    CC: '1100l89066',
    CD: '1101l89616',
    CF: '1100l89067',
    CG: '1101l89617',
    CH: '1011l90162',
    CI: '1011l90163',
    CK: '1100l89068',
    CL: '1101l89618',
    CM: '1011l90164',
    CN: '1100l89069',
    CO: '1011l90165',
    CR: '1101l89619',
    CS: '1011l90166',
    CU: '1100l89070',
    CV: '1011l90167',
    CW: '1100l89071',
    CX: '1100l89072',
    CY: '1100l89073',
    CZ: '1100l89074',
    DE: '1100l89075',
    DJ: '1011l90168',
    DK: '1101l89620',
    DM: '1100l89076',
    DO: '1101l89621',
    DZ: '1011l90169',
    EC: '1101l89622',
    EE: '1100l89077',
    EG: '1100l89078',
    EH: '1100l89079',
    ER: '1101l89623',
    ES: '1011l90170',
    ET: '1100l89080',
    FI: '1101l89624',
    FJ: '1011l90171',
    FK: '1011l90172',
    FM: '1101l89625',
    FO: '1011l90173',
    FR: '1101l89626',
    GA: '1100l89081',
    GB: '1011l90174',
    GD: '1100l89082',
    GE: '1101l89627',
    GF: '1101l89628',
    GG: '1011l90175',
    GH: '1101l89629',
    GI: '1100l89083',
    GL: '1100l89084',
    GM: '1011l90176',
    GN: '1100l89085',
    GP: '1011l90177',
    GQ: '1100l89086',
    GR: '1100l89087',
    GS: '1011l90178',
    GT: '1100l89088',
    GU: '1011l90179',
    GW: '1101l89630',
    GY: '1011l90180',
    HK: '1101l89631',
    HM: '1100l89089',
    HN: '1101l89632',
    HR: '1100l89090',
    HT: '1100l89091',
    HU: '1011l90181',
    ID: '1101l89633',
    IE: '1101l89634',
    IL: '1011l90182',
    IM: '1011l90183',
    IN: '1101l89635',
    IO: '1011l90184',
    IQ: '1100l89092',
    IR: '1100l89093',
    IS: '1101l89636',
    IT: '1011l90185',
    JE: '1101l89637',
    JM: '1101l89638',
    JO: '1011l90186',
    JP: '1100l89094',
    KE: '1011l90187',
    KG: '1011l90188',
    KH: '1011l90189',
    KI: '1100l89095',
    KM: '1100l89096',
    KN: '1011l90190',
    KP: '1011l90191',
    KR: '1100l89097',
    KW: '1101l89639',
    KY: '1100l89098',
    KZ: '1100l89099',
    LA: '1101l89640',
    LB: '1011l90192',
    LC: '1101l89641',
    LI: '1100l89100',
    LK: '1100l89101',
    LR: '1101l89642',
    LS: '1100l89102',
    LT: '1011l90193',
    LU: '1101l89643',
    LV: '1100l89103',
    LY: '1011l90194',
    MA: '1100l89104',
    MC: '1101l89644',
    MD: '1100l89105',
    ME: '1100l89106',
    MF: '1101l89645',
    MG: '1011l90195',
    MH: '1100l89107',
    MK: '1101l89646',
    ML: '1100l89108',
    MM: '1011l90196',
    MN: '1011l90197',
    MO: '1011l90198',
    MP: '1011l90199',
    MQ: '1100l89109',
    MR: '1011l90200',
    MS: '1011l90201',
    MT: '1101l89647',
    MU: '1101l89648',
    MV: '1100l89110',
    MW: '1011l90202',
    MX: '1101l89649',
    MY: '1011l90203',
    MZ: '1011l90204',
    NA: '1100l89111',
    NC: '1101l89650',
    NE: '1100l89112',
    NF: '1011l90205',
    NG: '1100l89113',
    NI: '1100l89114',
    NL: '1100l89115',
    NO: '1100l89116',
    NP: '1101l89651',
    NR: '1100l89117',
    NU: '1101l89652',
    NZ: '1011l90206',
    O1: '1100l89118',
    OM: '1011l90207',
    PA: '1011l90208',
    PE: '1011l90209',
    PF: '1011l90210',
    PG: '1101l89653',
    PH: '1011l90211',
    PK: '1011l90212',
    PL: '1101l89654',
    PM: '1011l90213',
    PN: '1100l89119',
    PR: '1101l89655',
    PS: '1101l89656',
    PT: '1101l89657',
    PW: '1100l89120',
    PY: '1100l89121',
    QA: '1100l89122',
    RE: '1100l89123',
    RO: '1100l89124',
    RS: '1011l90214',
    RU: '1101l89658',
    RW: '1101l89659',
    SA: '1100l89125',
    SB: '1101l89660',
    SC: '1101l89661',
    SD: '1011l90215',
    SE: '1100l89126',
    SG: '1100l89127',
    SH: '1011l90216',
    SI: '1101l89662',
    SJ: '1011l90217',
    SK: '1100l89128',
    SL: '1100l89129',
    SM: '1101l89663',
    SN: '1101l89664',
    SO: '1101l89665',
    SR: '1100l89130',
    SS: '1011l90218',
    ST: '1101l89666',
    SV: '1101l89667',
    SX: '1100l89131',
    SY: '1011l90219',
    SZ: '1101l89668',
    TC: '1011l90220',
    TD: '1100l89132',
    TF: '1011l90221',
    TG: '1101l89669',
    TH: '1100l89133',
    TJ: '1011l90222',
    TK: '1100l89134',
    TL: '1101l89670',
    TM: '1100l89135',
    TN: '1101l89671',
    TO: '1100l89136',
    TR: '1101l89672',
    TT: '1011l90223',
    TV: '1100l89137',
    TW: '1100l89138',
    TZ: '1011l90224',
    UA: '1100l89139',
    UG: '1011l90225',
    UM: '1011l90226',
    US: '1101l89673',
    UY: '1101l89674',
    UZ: '1101l89675',
    VA: '1101l89676',
    VC: '1011l90227',
    VE: '1101l89677',
    VG: '1100l89140',
    VI: '1011l90228',
    VN: '1011l90229',
    VU: '1101l89678',
    WF: '1101l89679',
    WS: '1100l89141',
    XK: '1101l89680',
    YE: '1011l90230',
    YT: '1101l89681',
    ZA: '1101l89682',
    ZM: '1101l89683',
    ZW: '1011l90231',
};

const CREATIVE_IDS_SRP = {
    AD: '1101l89523',
    AE: '1100l88968',
    AF: '1011l90061',
    AG: '1101l89524',
    AI: '1101l89525',
    AL: '1101l89526',
    AM: '1101l89527',
    AN: '1101l89528',
    AO: '1101l89529',
    AQ: '1101l89530',
    AR: '1011l90062',
    AS: '1100l88969',
    AT: '1011l90063',
    AU: '1100l88970',
    AW: '1100l88971',
    AX: '1101l89531',
    AZ: '1101l89532',
    BA: '1100l88972',
    BB: '1100l88973',
    BD: '1011l90064',
    BE: '1101l89533',
    BF: '1011l90065',
    BG: '1101l89534',
    BH: '1101l89535',
    BI: '1011l90066',
    BJ: '1101l89536',
    BL: '1011l90067',
    BM: '1101l89537',
    BN: '1011l90068',
    BO: '1101l89538',
    BQ: '1100l88974',
    BR: '1100l88975',
    BS: '1011l90069',
    BT: '1100l88976',
    BV: '1100l88977',
    BW: '1100l88978',
    BY: '1011l90070',
    BZ: '1011l90071',
    CA: '1101l89539',
    CC: '1101l89540',
    CD: '1100l88979',
    CF: '1101l89541',
    CG: '1101l89542',
    CH: '1100l88980',
    CI: '1100l88981',
    CK: '1011l90072',
    CL: '1101l89543',
    CM: '1101l89544',
    CN: '1011l90073',
    CO: '1101l89545',
    CR: '1101l89546',
    CS: '1011l90074',
    CU: '1100l88982',
    CV: '1100l88983',
    CW: '1100l88984',
    CX: '1101l89547',
    CY: '1101l89548',
    CZ: '1011l90075',
    DE: '1100l88985',
    DJ: '1100l88986',
    DK: '1101l89549',
    DM: '1101l89550',
    DO: '1011l90076',
    DZ: '1101l89551',
    EC: '1011l90077',
    EE: '1011l90078',
    EG: '1100l88987',
    EH: '1011l90079',
    ER: '1100l88988',
    ES: '1011l90080',
    ET: '1100l88989',
    FI: '1011l90081',
    FJ: '1011l90082',
    FK: '1100l88990',
    FM: '1011l90083',
    FO: '1011l90084',
    FR: '1100l88991',
    GA: '1011l90085',
    GB: '1101l89552',
    GD: '1011l90086',
    GE: '1100l88992',
    GF: '1101l89553',
    GG: '1100l88993',
    GH: '1101l89554',
    GI: '1011l90087',
    GL: '1100l88994',
    GM: '1101l89555',
    GN: '1100l88995',
    GP: '1101l89556',
    GQ: '1101l89557',
    GR: '1011l90088',
    GS: '1011l90089',
    GT: '1101l89558',
    GU: '1100l88996',
    GW: '1011l90090',
    GY: '1011l90091',
    HK: '1100l88997',
    HM: '1100l88998',
    HN: '1101l89559',
    HR: '1011l90092',
    HT: '1101l89560',
    HU: '1011l90093',
    ID: '1011l90094',
    IE: '1011l90095',
    IL: '1011l90096',
    IM: '1101l89561',
    IN: '1101l89562',
    IO: '1011l90097',
    IQ: '1100l88999',
    IR: '1011l90098',
    IS: '1100l89000',
    IT: '1101l89563',
    JE: '1011l90099',
    JM: '1100l89001',
    JO: '1101l89564',
    JP: '1100l89002',
    KE: '1011l90100',
    KG: '1101l89565',
    KH: '1100l89003',
    KI: '1101l89566',
    KM: '1011l90101',
    KN: '1011l90102',
    KP: '1101l89567',
    KR: '1100l89004',
    KW: '1100l89005',
    KY: '1101l89568',
    KZ: '1100l89006',
    LA: '1011l90103',
    LB: '1101l89569',
    LC: '1101l89570',
    LI: '1100l89007',
    LK: '1100l89008',
    LR: '1100l89009',
    LS: '1100l89010',
    LT: '1101l89571',
    LU: '1101l89572',
    LV: '1101l89573',
    LY: '1101l89574',
    MA: '1011l90104',
    MC: '1100l89011',
    MD: '1100l89012',
    ME: '1100l89013',
    MF: '1011l90105',
    MG: '1011l90106',
    MH: '1100l89014',
    MK: '1011l90107',
    ML: '1011l90108',
    MM: '1011l90109',
    MN: '1100l89015',
    MO: '1100l89016',
    MP: '1100l89017',
    MQ: '1100l89018',
    MR: '1100l89019',
    MS: '1011l90110',
    MT: '1011l90111',
    MU: '1100l89020',
    MV: '1011l90112',
    MW: '1100l89021',
    MX: '1100l89022',
    MY: '1101l89575',
    MZ: '1100l89023',
    NA: '1011l90113',
    NC: '1101l89576',
    NE: '1011l90114',
    NF: '1100l89024',
    NG: '1101l89577',
    NI: '1100l89025',
    NL: '1101l89578',
    NO: '1101l89579',
    NP: '1100l89026',
    NR: '1011l90115',
    NU: '1011l90116',
    NZ: '1101l89580',
    O1: '1011l90117',
    OM: '1100l89027',
    PA: '1101l89581',
    PE: '1011l90118',
    PF: '1011l90119',
    PG: '1011l90120',
    PH: '1011l90121',
    PK: '1100l89028',
    PL: '1011l90122',
    PM: '1101l89582',
    PN: '1011l90123',
    PR: '1101l89583',
    PS: '1101l89584',
    PT: '1100l89029',
    PW: '1011l90124',
    PY: '1101l89585',
    QA: '1011l90125',
    RE: '1101l89586',
    RO: '1011l90126',
    RS: '1011l90127',
    RU: '1011l90128',
    RW: '1101l89587',
    SA: '1011l90129',
    SB: '1101l89588',
    SC: '1100l89030',
    SD: '1011l90130',
    SE: '1011l90131',
    SG: '1101l89589',
    SH: '1011l90132',
    SI: '1101l89590',
    SJ: '1100l89031',
    SK: '1101l89591',
    SL: '1011l90133',
    SM: '1011l90134',
    SN: '1100l89032',
    SO: '1100l89033',
    SR: '1100l89034',
    SS: '1100l89035',
    ST: '1101l89592',
    SV: '1011l90135',
    SX: '1100l89036',
    SY: '1100l89037',
    SZ: '1101l89593',
    TC: '1101l89594',
    TD: '1011l90136',
    TF: '1100l89038',
    TG: '1100l89039',
    TH: '1101l89595',
    TJ: '1100l89040',
    TK: '1100l89041',
    TL: '1011l90137',
    TM: '1101l89596',
    TN: '1101l89597',
    TO: '1100l89042',
    TR: '1101l89598',
    TT: '1100l89043',
    TV: '1100l89044',
    TW: '1101l89599',
    TZ: '1011l90138',
    UA: '1100l89045',
    UG: '1101l89600',
    UM: '1011l90139',
    US: '1011l90140',
    UY: '1011l90141',
    UZ: '1101l89601',
    VA: '1100l89046',
    VC: '1011l90142',
    VE: '1101l89602',
    VG: '1100l89047',
    VI: '1100l89048',
    VN: '1011l90143',
    VU: '1011l90144',
    WF: '1011l90145',
    WS: '1100l89049',
    XK: '1100l89050',
    YE: '1100l89051',
    YT: '1101l89603',
    ZA: '1011l90146',
    ZM: '1011l90147',
    ZW: '1100l89052',
};

const ADOBE_EXPRESS_PUBLISHER_ACCOUNT_COM = '1100lr2iz';
const ADOBE_EXPRESS_PUBLISHER_ACCOUNT_ES = '1011lrcKJ';

export const PARTNERIZE_PUBLISHER_ACCOUNTS = {
    com: PUBLISHER_ACCOUNT_COM,
    es: PUBLISHER_ACCOUNT_ES,
};

export const PARTNERIZE_CREATIVE_IDS = {
    404: CREATIVE_ID_404,
    adblock: CREATIVE_ID_AUTOPROMO,
    cc: CREATIVE_ID_CC,
    idp: CREATIVE_IDS_IDP,
    popup: CREATIVE_ID_POPUP,
    srp: CREATIVE_IDS_SRP,
    stockbanner: CREATIVE_ID_STOCKBANNER,
};

export const PARTNERIZE_ADOBE_EXPRESS_ACCOUNTS = {
    com: ADOBE_EXPRESS_PUBLISHER_ACCOUNT_COM,
    es: ADOBE_EXPRESS_PUBLISHER_ACCOUNT_ES,
} as const;

export const PARTNERIZE_ADOBE_EXPRESS_CREATIVE_IDS = '1101l98805';