var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare||(depth0 && depth0.compare)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.id),"!=",(depth0 != null ? depth0.id : depth0),{"name":"compare","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":79,"column":16}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", alias5=container.lambda;

  return "        "
    + alias3((helpers.relativeHeight||(depth0 && depth0.relativeHeight)||alias2).call(alias1,(depth0 != null ? depth0.width : depth0),(depth0 != null ? depth0.height : depth0),{"name":"relativeHeight","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":39}}}))
    + "\n\n        <figure class=\"showcase__item js-detail-data\" data-type=\""
    + alias3(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":5,"column":65},"end":{"line":5,"column":73}}}) : helper)))
    + "\" data-w=\""
    + alias3(((helper = (helper = helpers.width || (depth0 != null ? depth0.width : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"width","hash":{},"data":data,"loc":{"start":{"line":5,"column":83},"end":{"line":5,"column":92}}}) : helper)))
    + "\" data-h=\""
    + alias3(((helper = (helper = helpers.height || (depth0 != null ? depth0.height : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"height","hash":{},"data":data,"loc":{"start":{"line":5,"column":102},"end":{"line":5,"column":112}}}) : helper)))
    + "\"\n            data-original-width=\""
    + alias3(((helper = (helper = helpers.original_width || (depth0 != null ? depth0.original_width : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"original_width","hash":{},"data":data,"loc":{"start":{"line":6,"column":33},"end":{"line":6,"column":51}}}) : helper)))
    + "\" data-original-height=\""
    + alias3(((helper = (helper = helpers.original_height || (depth0 != null ? depth0.original_height : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"original_height","hash":{},"data":data,"loc":{"start":{"line":6,"column":75},"end":{"line":6,"column":94}}}) : helper)))
    + "\" data-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":6,"column":105},"end":{"line":6,"column":111}}}) : helper)))
    + "\"\n            data-image=\""
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.thumbnails : depth0)) != null ? stack1.large : stack1), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.cacheArr : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":46},"end":{"line":7,"column":96}}})) != null ? stack1 : "")
    + ">\n            <div class=\"showcase__content view-details tags-links\"\n                "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.relativeHeight),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":102}}})) != null ? stack1 : "")
    + ">\n                    <a class=\"showcase__link js-detail-data-link\" data-cache-id=\""
    + alias3(((helper = (helper = helpers.key || (data && data.key)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":10,"column":81},"end":{"line":10,"column":89}}}) : helper)))
    + "\" data-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":10,"column":100},"end":{"line":10,"column":106}}}) : helper)))
    + "\"\n                        href=\""
    + alias3(((helper = (helper = helpers.url_source || (depth0 != null ? depth0.url_source : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"url_source","hash":{},"data":data,"loc":{"start":{"line":11,"column":30},"end":{"line":11,"column":44}}}) : helper)))
    + "\">\n\n                        <img class=\"lzy "
    + alias3(((helper = (helper = helpers.orientation || (depth0 != null ? depth0.orientation : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"orientation","hash":{},"data":data,"loc":{"start":{"line":13,"column":40},"end":{"line":13,"column":55}}}) : helper)))
    + "\" src=\""
    + alias3(alias5(((stack1 = (data && data.root)) && stack1.URL_STATIC_IMG), depth0))
    + "1px.png\" alt=\""
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":13,"column":100},"end":{"line":13,"column":109}}}) : helper)))
    + "\"\n                            data-src=\""
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.thumbnails : depth0)) != null ? stack1.small : stack1), depth0))
    + "\" />\n\n                        <div class=\"bg\" style=\"background-color: #"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.main_color : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":16,"column":66},"end":{"line":16,"column":119}}})) != null ? stack1 : "")
    + "\"></div>\n                        " + require("Templates/parts/tags_links_thumbnails.hbs").apply(null, Array.prototype.slice.call(arguments, arguments.length > 6)) + "\n                </a>\n\n"
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias2).call(alias1,(depth0 != null ? depth0.premium : depth0),"==","1",{"name":"compare","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":22,"column":28}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias2).call(alias1,(depth0 != null ? depth0["new"] : depth0),"==",true,{"name":"compare","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":26,"column":28}}})) != null ? stack1 : "")
    + "\n                <button\n                    class=\"button--favorite"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":29,"column":43},"end":{"line":29,"column":110}}})) != null ? stack1 : "")
    + " noscript\"\n                    data-rid=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":30,"column":30},"end":{"line":30,"column":36}}}) : helper)))
    + "\" data-rt=\""
    + alias3(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":30,"column":47},"end":{"line":30,"column":55}}}) : helper)))
    + "\" data-rl=\""
    + alias3(((helper = (helper = helpers.likes || (depth0 != null ? depth0.likes : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"likes","hash":{},"data":data,"loc":{"start":{"line":30,"column":66},"end":{"line":30,"column":75}}}) : helper)))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.likes : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":34,"column":27}}})) != null ? stack1 : "")
    + "                </button>\n                <button class=\"button--share\"></button>\n\n                <button\n                    class=\"button--favorite tooltip tooltip--auto"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":39,"column":65},"end":{"line":39,"column":132}}})) != null ? stack1 : "")
    + " noscript\"\n                    data-rid=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":40,"column":30},"end":{"line":40,"column":36}}}) : helper)))
    + "\" data-rt=\""
    + alias3(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":40,"column":47},"end":{"line":40,"column":55}}}) : helper)))
    + "\" data-rl=\""
    + alias3(((helper = (helper = helpers.likes || (depth0 != null ? depth0.likes : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"likes","hash":{},"data":data,"loc":{"start":{"line":40,"column":66},"end":{"line":40,"column":75}}}) : helper)))
    + "\">\n                    <div class=\"tooltip__container tooltip__container--left tooltip__pre alignc\">\n                        <div class=\"tooltip__content\">"
    + alias3((helpers.language||(depth0 && depth0.language)||alias2).call(alias1,"Like",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":42,"column":54},"end":{"line":42,"column":73}}}))
    + "</div>\n                    </div>\n                </button>\n\n"
    + ((stack1 = (helpers.isTrue||(depth0 && depth0.isTrue)||alias2).call(alias1,((stack1 = (data && data.root)) && stack1.isUserCollection),{"name":"isTrue","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":46,"column":16},"end":{"line":64,"column":27}}})) != null ? stack1 : "")
    + "\n                <button class=\"button--share tooltip tooltip--auto icon--pinterest\">\n                    <div class=\"tooltip__container tooltip__container--left tooltip__pre alignc\">\n                        <div class=\"tooltip__content\">\n"
    + ((stack1 = (helpers.isTrue||(depth0 && depth0.isTrue)||alias2).call(alias1,(depth0 != null ? depth0.FEATURE_NEW_COPIES_COLLECTIONS_BY_COUNTRY : depth0),{"name":"isTrue","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":69,"column":28},"end":{"line":73,"column":39}}})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                </button>\n            </div>\n        </figure>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return " data-multi=\""
    + container.escapeExpression(((helper = (helper = helpers.cacheArr || (depth0 != null ? depth0.cacheArr : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cacheArr","hash":{},"data":data,"loc":{"start":{"line":7,"column":75},"end":{"line":7,"column":87}}}) : helper)))
    + "\" ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " style=\"padding-bottom: "
    + container.escapeExpression(container.lambda(((stack1 = (data && data.root)) && stack1.relativeHeight), depth0))
    + "%\" ";
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.main_color || (depth0 != null ? depth0.main_color : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"main_color","hash":{},"data":data,"loc":{"start":{"line":16,"column":84},"end":{"line":16,"column":98}}}) : helper)));
},"9":function(container,depth0,helpers,partials,data) {
    return "e3e3e3";
},"11":function(container,depth0,helpers,partials,data) {
    return "                    <i class=\"icon--premium\"></i>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"badge badge--new\">"
    + container.escapeExpression((helpers.language||(depth0 && depth0.language)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"New",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":25,"column":51},"end":{"line":25,"column":69}}}))
    + "</span>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return " active icon--heart-filled";
},"17":function(container,depth0,helpers,partials,data) {
    return " icon--heart";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                        <i class=\"badge--favorite icon--heart-filled\" data-rid=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":32,"column":80},"end":{"line":32,"column":86}}}) : helper)))
    + "\" data-rt=\""
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":32,"column":97},"end":{"line":32,"column":105}}}) : helper)))
    + "\"\n                            data-rl=\""
    + alias4(((helper = (helper = helpers.likes || (depth0 != null ? depth0.likes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"likes","hash":{},"data":data,"loc":{"start":{"line":33,"column":37},"end":{"line":33,"column":46}}}) : helper)))
    + "\">"
    + alias4((helpers.numberFormatAbbr||(depth0 && depth0.numberFormatAbbr)||alias2).call(alias1,(depth0 != null ? depth0.likes : depth0),{"name":"numberFormatAbbr","hash":{},"data":data,"loc":{"start":{"line":33,"column":48},"end":{"line":33,"column":74}}}))
    + "</i>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "                    <button class=\"collection-remove tooltip tooltip--auto icon--remove-folder\">\n                        <div class=\"tooltip__container tooltip__container--left tooltip__pre alignc\">\n                            <div class=\"tooltip__content\">"
    + container.escapeExpression((helpers.language||(depth0 && depth0.language)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Remove",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":49,"column":58},"end":{"line":49,"column":79}}}))
    + "</div>\n                        </div>\n                    </button>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <button class=\"collection-add tooltip tooltip--auto icon--create-folder\">\n                        <div class=\"tooltip__container tooltip__container--left tooltip__pre alignc\">\n                            <div class=\"tooltip__content\">\n"
    + ((stack1 = (helpers.isTrue||(depth0 && depth0.isTrue)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.FEATURE_NEW_COPIES_COLLECTIONS_BY_COUNTRY : depth0),{"name":"isTrue","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":56,"column":32},"end":{"line":60,"column":43}}})) != null ? stack1 : "")
    + "                            </div>\n                        </div>\n                    </button>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "                                    "
    + container.escapeExpression((helpers.language||(depth0 && depth0.language)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Add to collection",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":57,"column":36},"end":{"line":57,"column":68}}}))
    + "\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "                                    "
    + container.escapeExpression((helpers.language||(depth0 && depth0.language)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Collect",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":59,"column":36},"end":{"line":59,"column":58}}}))
    + "\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "                                "
    + container.escapeExpression((helpers.language||(depth0 && depth0.language)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Save to Pinterest",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":70,"column":32},"end":{"line":70,"column":64}}}))
    + "\n";
},"30":function(container,depth0,helpers,partials,data) {
    return "                                "
    + container.escapeExpression((helpers.language||(depth0 && depth0.language)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Save",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":72,"column":32},"end":{"line":72,"column":51}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.resources : stack1)) != null ? stack1.boost : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":80,"column":9}}})) != null ? stack1 : "");
},"useData":true});