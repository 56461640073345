import 'Variables';

export class LoadScript {
    public Scripts: Scripts;
    public loaded: ScriptLoaded = {};
    public loadedPages: ScriptLoaded = {};

    public async load(name: string) {
        if (!this.Scripts[name]) return;

        const scriptAsync = this.Scripts[name].async;
        const scriptCharset = this.Scripts[name].charset;
        const scriptDefer = this.Scripts[name].defer;
        const scriptDomain = this.Scripts[name].domain;
        const scriptInsideElement = this.Scripts[name].insertInside;
        const scriptUrl = this.Scripts[name].url;
        const scriptContent = this.Scripts[name].addScript;
        const noScript = this.Scripts[name].noScript;
        const page = this.Scripts[name].page;

        const scriptsDOM = document.getElementsByTagName('script');
        const fjs = scriptInsideElement
            ? document.querySelector(scriptInsideElement)
            : scriptsDOM[scriptsDOM.length - 1];

        if (fjs) {
            if (scriptUrl) {
                const js = document.createElement('script') as HTMLScriptElement;
                js.id = 'load-script-' + this.Scripts[name].name;
                js.type = 'text/javascript';
                if (scriptAsync) js.async = scriptAsync;
                if (scriptCharset) js.charset = scriptCharset;
                if (scriptDefer) js.defer = scriptDefer;
                if (scriptDomain) js.dataset.domainScript = scriptDomain;
                js.src = scriptUrl;

                if (fjs.parentNode) fjs.parentNode.insertBefore(js, fjs);
            }

            if (scriptContent) {
                const jsContent = document.createElement('script') as HTMLScriptElement;
                jsContent.id = `load-script-${this.Scripts[name].name}-content`;
                jsContent.type = 'text/javascript';
                jsContent.innerHTML = scriptContent;

                if (scriptInsideElement) {
                    fjs.appendChild(jsContent);
                } else if (fjs.parentNode) {
                    fjs.parentNode.insertBefore(jsContent, fjs);
                }
            }

            if (noScript) {
                const jsNo = document.createElement('noscript') as HTMLElement;
                jsNo.innerHTML = noScript;
                if (fjs.parentNode) fjs.parentNode.insertBefore(jsNo, fjs);
            }
        }

        this.loaded[name] = true;
        if (page) this.loadedPages[page] = true;
    }
}

interface Scripts {
    [name: string]: Script;
}

interface Script {
    addScript?: string;
    async?: boolean;
    charset?: string;
    defer?: boolean;
    domain?: string;
    insertInside?: string;
    name: string;
    noScript?: string;
    page?: string;
    url?: string;
}

interface ScriptLoaded {
    [key: string]: boolean;
}
