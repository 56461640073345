import { ww } from 'bobjoll/ts/library/dom';
import { detectIE } from 'Library/helpers/device';

const IS_IE = detectIE();
const IS_IE_11 = detectIE();

export const globals = [
    'ACCOUNTS_BASE_URL',
    'AUTHOR_URL',
    'BASE_URL',
    'DEVICE_FAQS_URL',
    'FLATICON_AUTHOR_ID',
    'ESSENTIAL_COUNTRY',
    'FEATURE_VERTICAL_GRID_BY_LANGUAGE',
    'HOW_ATTRIBUTE_AUTHOR_URL',
    'LANGUAGE',
    'LOGIN_URL',
    'LOGOUT_URL',
    'MY_SUBSCRIPTIONS_URL',
    'NUM_RESOURCES_PREMIUM',
    'NUM_RESOURCES_SELECTION',
    'NUM_RESOURCES',
    'PRICING_URL',
    'REGISTER_URL',
    'TRACKER_URL',
    'URL_CATEGORY',
    'URL_CATEGORY',
    'URL_RESOURCE_KEYWORDS',
    'URL_STATIC_IMG',
    'USER_COUNTRY_CODE',
    'USER_CURRENCY',
    'USER_PRICE_MONTHLY',
    'USER_PRICE_YEARLY_BY_MONTH',
    'USER_PRICE_YEARLY',
].reduce((acc: IndexSignature, key) => (ww[key] ? (acc[key] = ww[key]) : acc, acc), {
    IS_IE,
    IS_IE_11,
});
