import { DetailApi } from '../detail-v2/api';
import { isTruthy } from '../detail-v2/helpers/detail.helpers.generic';
import { initializeDownloadConfirmation } from '../detail-v2/partials/detail.partials.download';

export const showDownloadConfirmation = () => {
    const detail = DetailApi.getActive();

    if (detail) {
        const { selection, premium } = detail;

        if ((isTruthy(selection) || isTruthy(premium)) && 2 != selection) {
            initializeDownloadConfirmation();
        }
    }
};
