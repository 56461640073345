var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function";

  return "<article class=\"section-spr--item "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.shutterstock)) && stack1.classes)) && stack1.item), depth0))
    + "\"\n    data-type=\""
    + alias2(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":3,"column":15},"end":{"line":3,"column":23}}}) : helper)))
    + "\"\n    data-w=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.assets : depth0)) != null ? stack1.small_thumb : stack1)) != null ? stack1.width : stack1), depth0))
    + "\"\n    data-h=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.assets : depth0)) != null ? stack1.small_thumb : stack1)) != null ? stack1.height : stack1), depth0))
    + "\"\n    data-cs-override-id=\"spn-item\">\n    <div class=\"section-spr--content "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.shutterstock)) && stack1.classes)) && stack1.content), depth0))
    + "\">\n        <a class=\"section-spr--link "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.shutterstock)) && stack1.classes)) && stack1.link), depth0))
    + "\"\n            href=\""
    + alias2(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":9,"column":18},"end":{"line":9,"column":25}}}) : helper)))
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.user)) && stack1.clientId),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":25},"end":{"line":9,"column":93}}})) != null ? stack1 : "")
    + "\"\n            target=\"_blank\"\n            rel=\"sponsored\">\n            <div class=\"section-spr--mask "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.shutterstock)) && stack1.classes)) && stack1.mask), depth0))
    + " "
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias4).call(alias3,"landscape","!=",(depth0 != null ? depth0.orientation : depth0),{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":78},"end":{"line":12,"column":133}}})) != null ? stack1 : "")
    + "\">\n                <img\n                    src=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.assets : depth0)) != null ? stack1.small_thumb : stack1)) != null ? stack1.url : stack1), depth0))
    + "\"\n                    alt=\""
    + alias2(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":15,"column":25},"end":{"line":15,"column":31}}}) : helper)))
    + "\"\n                    loading=\"lazy\" />\n            </div>\n        </a>\n    </div>\n</article>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "&client_id="
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.user)) && stack1.clientId), depth0));
},"4":function(container,depth0,helpers,partials,data) {
    return "rb";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.data : stack1)) != null ? stack1.resources : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":21,"column":9}}})) != null ? stack1 : "");
},"useData":true});