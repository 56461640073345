import { q } from 'bobjoll/ts/library/dom';
import { getView } from 'Library/helpers';
import { getDetail } from 'Partials/detail-v2/helpers/detail.helpers.generic';

import triggerManualGA4Events from './ga4.init-manual-events';

const triggerCollectionEvents = (options: collectionInfo) =>
    triggerManualGA4Events({
        description: 'collections',
        options,
    });

export const collectionClickGA4Events = (collection: HTMLElement) => {
    const name = q('.collection__title', collection)?.innerText || '';
    const url = q('.collection__link', collection)?.getAttribute('href') || '';

    const isRegisteredCollections = !!collection.closest('#collections-section');
    const isFollowedCollections = !!collection.closest('#what-is-new-section');

    const id = collection.dataset.id || '';
    let params: collectionInfo = { event_name: 'collection_click' };

    params = isRegisteredCollections
        ? {
              ...params,
              location: 'home_block_recommended_collections',
              link_text: name,
              link_url: url,
          }
        : isFollowedCollections
        ? {
              ...params,
              location: 'home_block_new_collection_authors',
              link_text: name,
              link_url: url,
          }
        : {
              ...params,
              id: collection.dataset.id || '',
              name,
              position: getView(),
              collection_name: name,
              collection_id: id,
              collection_position: getView(),
              followers: collection.dataset.followers || '0',
              type: collection.dataset.free === '1' ? 'free' : 'premium',
              url,
          };

    triggerCollectionEvents(params);
};

export const collectionCollectGA4Events = (data: {
    file_id: string;
    file_name: string;
    location: 'thumbnail' | 'resource_detail';
}) => {
    const params: collectionInfo = {
        event_name: 'collect',
        file_id: data.file_id,
        file_name: data.file_name,
        location: data.location,
    };

    triggerCollectionEvents(params);
};

export const createCollectionGA4Events = (data: { location: string }) => {
    const params: collectionInfo = {
        event_name: 'create_collection',
        location: data.location,
    };
    triggerCollectionEvents(params);
};

export const collectionQuickAccess = (data: {
    link_text: string;
    link_url: string;
    location: 'nav_bar' | 'search_bar';
}) => {
    const params: collectionInfo = {
        event_name: 'collection_quick_access',
        ...data,
    };

    triggerCollectionEvents(params);
};

export const confirmCreateCollection = (data: {
    location: 'my_collections' | 'thumbnail' | 'resource_detail';
    collection_id: string;
    collection_name: string;
}) => {
    const params: collectionInfo = {
        event_name: 'create_collection_success',
        ...data,
    };

    triggerCollectionEvents(params);
};

export const collectionFollowGA4Events = (data: any) => {
    const params: collectionInfo = {
        event_name: 'collection_follow',
        id: data.id || '',
        name: data.title || '',
        position: getView(),
        followers: data.totalFollowers || '0',
        type: data.type === 'public' ? 'free' : 'premium',
        url: data.url || '',
    };
    triggerCollectionEvents(params);
};

export const getActiveNameCollection = (form: HTMLFormElement): string => {
    if (!form && !form[1]) {
        return '';
    }

    return form[1].getAttribute('name') === 'collectionName' ? (form[1] as HTMLInputElement).value ?? '' : '';
};

export const addToCollectionGA4Events = (resourceId: string, collection: string, newCollection = false) => {
    const detailModal = q(`[data-id="${resourceId}"]`) as HTMLElement;
    const collectionId = !newCollection ? collection : '';
    const name = q(`[data-id="${collection}"]`)?.dataset.name;
    const collectionName = newCollection ? collection : name || '';

    const isDetail = getDetail();
    const isUserCollection = window.location.href.includes('user/collections');
    const resourceName = q('.title, .detail__title', detailModal)?.innerText || '';

    if (FEATURE_NEW_COPIES_COLLECTIONS_BY_COUNTRY) {
        const params: collectionInfo = {
            collection_id: FEATURE_NEW_COPIES_COLLECTIONS_BY_COUNTRY
                ? detailModal?.getAttribute('data-id') || ''
                : collectionId,
            collection_name: collectionName,
            file_id: resourceId,
            file_name: resourceName,
            event_name: 'add_to_collection',
            location: isUserCollection ? 'my_collections' : isDetail ? 'resource_detail' : 'thumbnail',
        };

        if (newCollection) {
            const paramsCollectionSuccess: collectionInfo = {
                collection_id: resourceId,
                collection_name: collectionName,
                event_name: 'create_collection_success',
                location: isUserCollection ? 'my_collections' : isDetail ? 'resource_detail' : 'thumbnail',
            };

            triggerCollectionEvents(paramsCollectionSuccess);
        }

        triggerCollectionEvents(params);
    } else {
        const params: collectionInfo = {
            id: FEATURE_NEW_COPIES_COLLECTIONS_BY_COUNTRY ? detailModal?.getAttribute('data-id') || '' : collectionId,
            name: collectionName,
            position: getView(),
            file_id: resourceId,
            file_name: resourceName,
            event_name: 'add_to_collection',
            location: isUserCollection ? 'my_collections' : isDetail ? 'resource_detail' : 'thumbnail',
        };

        triggerCollectionEvents(params);
    }
};

type CollectionEvents =
    | 'collect'
    | 'collection_quick_access'
    | 'create_collection'
    | 'add_to_collection'
    | 'collection_click'
    | 'create_collection_success'
    | 'drop_down_menu'
    | 'collection_follow';

interface collectionInfo {
    collection_id?: string;
    collection_name?: string;
    collection_position?: string;
    event_name: CollectionEvents;
    file_id?: string;
    file_name?: string;
    followers?: string;
    id?: string;
    link_text?: string;
    link_url?: string;
    location?: string;
    name?: string;
    position?: string;
    type?: string;
    url?: string;
}
