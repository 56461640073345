import { q } from 'bobjoll/ts/library/dom';

import { ga4 } from './ga4.api';

type EditButtonType = 'tooltip' | 'modal';

const wepikEvent = {
    event_name: 'tooltip_edit',
    action: '',
    location: '',
};

let triggerGa4 = true;

export const wepikEventGA4 = () => {
    const editButton =
        q('#modal-detail.active .detail .button-freepik-edit') || q('#main .detail .button-freepik-edit');
    editButton?.addEventListener(
        'click',
        () => {
            triggerGa4 &&
                ga4({
                    ...wepikEvent,
                    action: 'edit',
                    location: 'resource_detail',
                });
            triggerGa4 = false;
            const closeButton = q('.tooltipFixed--edit-button.tooltipFixed__active .notification__close');
            closeButton?.click();
            triggerGa4 = true;
        },
        false,
    );
};

export const wepikTooltipFixedEventGA4 = (type: EditButtonType = 'tooltip') => {
    const editButton =
        q('#modal-detail.active .detail .button-freepik-edit') || q('#main .detail .button-freepik-edit');
    const closeButton =
        type === 'tooltip'
            ? q('.tooltipFixed--edit-button.tooltipFixed__active .notification__close')
            : q('#modal-edit-online .modal__close');
    const tooltipFixedButton =
        type === 'tooltip'
            ? q('.tooltipFixed--edit-button.tooltipFixed__active .button--tooltip')
            : q('#modal-edit-online a[data-button="edit"]');

    closeButton?.addEventListener(
        'click',
        () =>
            triggerGa4 &&
            ga4({
                ...wepikEvent,
                action: 'close',
                location: 'pop_up',
            }),
        false,
    );

    tooltipFixedButton?.addEventListener('click', () => {
        ga4({
            ...wepikEvent,
            action: 'edit',
            location: 'pop_up',
        });
        triggerGa4 = false;
        closeButton?.click();
        editButton?.click();
        triggerGa4 = true;
    });
};
