import { q, ww } from 'bobjoll/ts/library/dom';
import { showCampaignBanner } from 'Partials/campaign-php/campaign.helpers';
import { handlerDownload } from 'Partials/download/download.component';
import { showDownloadConfirmation } from 'Partials/download/download.notifications';
import { downloadResourceWithLink } from 'Partials/download/download.recaptcha';
import { setEditButtonStyle } from 'Partials/download/download.styles';
import { DownloadResourceErrorCodes } from 'Partials/download/download.vm';
import triggerManualGA4Events from 'Partials/events/ga4.init-manual-events';
import { wepikTooltipFixedEventGA4 } from 'Partials/events/ga4.wepik';
import { __ } from 'Partials/language';
import { UserHelpers } from 'Partials/user';
import { setGrLgUri } from 'Partials/user/user.helpers';
import { ViewPrint } from 'Partials/view';
import { cookie } from 'Project/ts/library/cookie';
import { isMobile } from 'Project/ts/library/helpers/device';
import { trackerInterface } from 'Project/ts/library/helpers/tracker';
import Tracker from 'Project/ts/library/tracker';

import { getDetail } from '../helpers/detail.helpers.generic';
import { getImageLargeSize } from './detail.partials.downloadSize';

const eventStories = 'edit-stories';
const eventEssentials = 'detailessential';
const eventPremium = 'detailp';
const cookieName = 'freepik-edit';
const eventMockupEditor = 'edit-mockup-editor';

export const eventEditor = 'edit-editor';

export const initializeWepikAndStories = (viewOptions: ViewPrint) => {
    const detailElement = getDetail();
    const editButton = (q('.button-freepik-edit', detailElement || document) as HTMLLinkElement) || undefined;

    if (!editButton) return;

    const {
        freepik_editor: freepikEditor,
        freepik_stories: freepikStories,
        id: resourceId,
        is_essential: isEssential,
        premium: isPremium,
        thumbnails,
        type: resourceType,
    } = viewOptions.xhr;

    if (!OPTIMAL_BROWSER && freepikStories == '1') return;

    const URLS = {
        URL_FREEPIK_MOCKUP_EDITOR: freepikEditor == '2',
        URL_FREEPIK_EDITOR: freepikEditor == '1',
        URL_FREEPIK_STORIES: freepikStories == '1',
        URL_FREEPIK_WEPIK_ALL_PHOTOS: !!resourceType.match(/photo/),
    };

    const settingsEditButton = {
        editButton,
        isEditorAvailable: URLS.URL_FREEPIK_EDITOR,
        isPhoto: URLS.URL_FREEPIK_WEPIK_ALL_PHOTOS,
        isStoriesAvailable: URLS.URL_FREEPIK_STORIES,
        isMockupEditorAvailable: URLS.URL_FREEPIK_MOCKUP_EDITOR,
        resourceId,
        resourceIsEssential: isEssential,
        resourceIsPremium: isPremium,
        urlImage: thumbnails.large || '',
        urlRedirect: ww[Object.keys(URLS).find(key => URLS[key] === true) || ''],
    };


    if (settingsEditButton.urlImage && settingsEditButton.urlRedirect) {
        editButtonEvents(settingsEditButton);
    }
};

const userIsNotPremiumOrEssential = (resourceIsEssential: boolean) =>
    (!UserHelpers.isPremium() && !UserHelpers.isEssential()) || (UserHelpers.isEssential() && !resourceIsEssential);

const handleClickEvent = (settings: EditEvents, showModalFlag?: boolean) => ({
    redirectToPricing: (event: Event) => {
        event.preventDefault();
        event.stopPropagation();

        const resourceEvent = settings.resourceIsEssential ? eventEssentials : eventPremium;
        Tracker.trackGAEventNoCache(['premium', 'click', `${resourceEvent}-editwepik-gopremium`]);

        sendGA4Event(settings.resourceIsPremium, 'click');

        window.location.href =
            showCampaignBanner() && UserHelpers.isPremiumFreepikMonthly()
                ? GLOBAL_CAMPAIGN.config.urlUpgrade
                : `${PRICING_URL}/${settings.resourceId}?origin=freepik_web`;
    },
    isPhotoAndCountAsDownload: async (event: Event) => {
        const dataSaved: number = parseInt(cookie.getItem(`modalFixed-${cookieName}`) ?? '0');

        if (isMobile() && dataSaved < 2 && showModalFlag) {
            await showModal(settings, 'isPhotoAndCountAsDownload', dataSaved);
        } else {
            handlerDownload.call(event.currentTarget, event);
        }
    },
    usualRedirect: async (event: Event) => {
        event.preventDefault();
        event.stopPropagation();

        const dataSaved: number = parseInt(cookie.getItem(`modalFixed-${cookieName}`) ?? '0');

        if (isMobile() && dataSaved < 2 && showModalFlag) {
            await showModal(settings, 'usualRedirect', dataSaved);
        } else {
            editButtonClickEvent(settings.resourceIsPremium, settings.event);

            const wepikUrlParams = `${settings.resourceId}?locale=${LANGUAGE}`;
            const mockupUrlParams = `?sku=${settings.resourceId}&provider=fp&locale=${LANGUAGE}`;

            const urlParams = settings.isMockupEditorAvailable ? mockupUrlParams : wepikUrlParams;

            const redirect = settings.urlRedirect.indexOf('?locale') === -1 ?
                `${settings.urlRedirect}${urlParams}` :
                settings.urlRedirect;

            window.open(redirect);
        }
    },
});

const editButtonEvents = async (settings: EditEvents) => {
    let event = eventEditor;

    if (settings.isMockupEditorAvailable) {
        event = eventMockupEditor;
    }
    if (settings.isStoriesAvailable) {
        event = eventStories;
    }

    settings.event = event;

    const clickEventConditions = {
        redirectToPricing:
            settings.isPhoto && settings.resourceIsPremium && userIsNotPremiumOrEssential(settings.resourceIsEssential),
        isPhotoAndCountAsDownload: settings.isPhoto,
        usualRedirect: true,
    };

    if (clickEventConditions.redirectToPricing) {
        const resourceEvent = settings.resourceIsEssential ? eventEssentials : eventPremium;
        Tracker.trackGAEventNoCache(['premium', 'onload', `${resourceEvent}-editwepik-onload`]);
    } else {
        trackerInterface.send('ga', 'send', 'event', `${event}-onload`, 'onload');
    }

    const handleFunction = Object.keys(clickEventConditions).find(key => clickEventConditions[key] === true);
    settings.editButton.addEventListener('click', (e: Event) => {
        handleClickEvent(settings, true)[`${handleFunction}`](e);
    });

    if (!isMobile()) {
        await showTooltipFixed();
        wepikTooltipFixedEventGA4('tooltip');
    }

    sendGA4Event(settings.resourceIsPremium);
};

const showTooltipFixed = async () => {
    const elementToInsert = '.tooltipFixed--edit-button';
    const [{ default: html }, { TooltipFixed }] = await Promise.all([
        import('../views/detail.views.tooltipFixed.hbs'),
        import('bobjoll/ts/partials/tooltipFixed-v1.0'),
    ]);

    TooltipFixed.add({
        cookieId: cookieName,
        elementToInsert,
        html: html as any,
        insertContainer: false,
    });

    document.body.addEventListener('click', (e: Event) => {
        const target = e.target;
        if (target instanceof HTMLElement && target.parents(elementToInsert).length === 0) {
            q(elementToInsert)?.classList.add('hide');
        }
    });
};

const showModal = async (settings: EditEvents, handleFunction: string | undefined, dataSaved: number) => {
    cookie.setItem(`modalFixed-${cookieName}`, (dataSaved + 1).toString());

    const { default: viewTemplate } = await import('../views/detail.views.editOnlineModal.hbs');

    const modalEditOnline = ww.ModalInstance.print({
        name: 'edit-online',
        html: viewTemplate(),
    }) as HTMLElement;

    ww.ModalInstance.show('modal-edit-online');
    ww.ModalInstance.hide({ show: 'modal-edit-online' });

    if (modalEditOnline) {
        const button = q('[data-button="edit"]');
        button?.addEventListener('click', (e: Event) => {
            e.preventDefault();
            handleClickEvent(settings, false)[`${handleFunction}`](e);
            ww.ModalInstance.hide();
        });

        modalEditOnline.addEventListener('hide', function eventHandler() {
            modalEditOnline.removeEventListener('hide', eventHandler);
        });
        wepikTooltipFixedEventGA4('modal');
    }
};

const sendGA4Event = (resourceIsPremium: boolean, action = 'onload') => {
    if (!resourceIsPremium || UserHelpers.isPremium() || UserHelpers.isEssential()) return;

    triggerManualGA4Events({
        description: 'editWepikButton',
        options: {
            action,
            resource_premium: resourceIsPremium,
        },
    });
};

export const editButtonClickEvent = (resourceIsPremium = false, event: string = eventEditor) => {
    trackerInterface.send('ga', 'send', 'event', `${event}-click`, 'click');
    sendGA4Event(resourceIsPremium, 'click');

    if (FEATURE_MONTHLY_DOWNLOAD_LIMIT_BY_COUNTRY && USER_TYPE === 'anonymous') {
        setGrLgUri();
    }
};

export const countEditButtonAsDownload = (detail: HTMLElement, resourceId: string, token?: string) => {
    getImageLargeSize(resourceId, token)
        .then(imageInfo => {
            window.open(
                `${URL_FREEPIK_WEPIK_ALL_PHOTOS}?photo=${encodeURIComponent(
                    imageInfo.url,
                )}&from_view=detail&id=${resourceId}`,
            );
            showDownloadConfirmation();
        })
        .catch(({ code, url }) => {
            console.error('Error trying open a WePik url. Status: ', code);
            if (url) {
                window.location.href = url;
            } else if (DownloadResourceErrorCodes.DOWNLOAD_LIMIT_EXCEEDED === code) {
                downloadResourceWithLink(token);
            }
        })
        .finally(() => setEditButtonStyle(detail));
};

interface EditEvents {
    editButton: HTMLLinkElement;
    event?: string;
    isEditorAvailable: boolean;
    isMockupEditorAvailable?: boolean;
    isPhoto: boolean;
    isStoriesAvailable: boolean;
    resourceId: string;
    resourceIsEssential: boolean;
    resourceIsPremium: boolean;
    urlImage: string;
    urlRedirect: string;
}
