import {
    AutopromoBannerID,
    AutopromoBannerIDforVerticalGrid,
    AutopromoBannerSlot,
    AutopromoSlot,
    slotID,
} from '../../autopromo';

export const getAdOptions = function(): AdOptions {
    const modalElement = document.getElementById('modal-detail')?.classList.contains('active') || false;
    return {
        id: modalElement ? slotID[AutopromoSlot.SIDEBAR_2] : slotID[AutopromoSlot.SIDEBAR_1],
        list: modalElement ? AutopromoSlot.SIDEBAR_2 : AutopromoSlot.SIDEBAR_1,
    };
};

interface AdOptions {
    id: AutopromoBannerID | AutopromoBannerIDforVerticalGrid;
    list: AutopromoBannerSlot;
}
