var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<article class=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.shutterstock)) && stack1.classes)) && stack1.loadingElement), depth0))
    + "\"></article>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.times||(depth0 && depth0.times)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.limit),{"name":"times","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":10}}})) != null ? stack1 : "");
},"useData":true});