import { __ } from '../../language';
import { calculateJPGSizes } from '../helpers/detail.helpers.imageSizes';
import { changePopoverPosition } from './detail.partials.downloadSelector';
import { clearDownloadButton } from 'Project/ts/partials/download/download.styles';
import { copyNotSupportedMobileNotification, showCopyAlert } from 'Partials/notifications/notifications.copy';
import { copyToClipboard, isCopyToClipboardSupported } from '../helpers/detail.helpers.copyToClipboard';
import { delegate, q, qq, ww } from 'bobjoll/ts/library/dom';
import { DetailApi } from '../api';
import { DownloadResourceErrorCodes } from 'Partials/download/download.vm';
import { downloadResourceWithLink } from 'Partials/download/download.recaptcha';
import { getDetail } from '../helpers/detail.helpers.generic';
import { getImageLargeSize } from './detail.partials.downloadSize';
import { globals } from 'Library/helpers/globals';
import { isAndroidDevice, isIOSDevice, isSafariBrowser } from 'Library/helpers/device';
import { showDownloadConfirmation } from 'Partials/download/download.notifications';
import ProgressBar from 'BobjollProgressBar';
import Tracker from 'Library/tracker';

export const initializeCopyImageToClipboard = (parentElement: HTMLElement | Document) => {

    const copyToClipboardElement = q('.copy_to_clipboard', parentElement);
    const copyButton = q('.copy_size__trigger', copyToClipboardElement || parentElement);

    copyToClipboardElement?.classList.remove('hide');

    if (isCopyToClipboardSupported()) {
        if (copyButton && !copyButton?.dataset.initialized) {
            copyButton.dataset.initialized = 'true';
            copyButton.addEventListener('click', () => {
                copyButton.dataset.copyAction = 'true';
                closeSizeSelector(parentElement);
                q('.download-resource-container .download-button', parentElement)?.click();
            });
        }
    } else {
        if (isAndroidDevice()) {
            if (copyButton && !copyButton?.dataset.initialized) {
                copyButtonAndroidInitialize(copyButton, parentElement);
            }
        } else {
            copyButton?.classList.add('disabled');
            const copyButtonTooltip = q('.copy_to_clipboard .tooltip__container .tooltip__content');
            if (copyButtonTooltip) {
                copyButtonTooltip.textContent =
                    __('Copy to clipboard action is only available for latest version of Google Chrome and Safari');
            }
        }
    }
};

export const initializeJPGSizeSelector = (detailComponent: HTMLElement | Document) => {
    const originalWidth = (detailComponent as HTMLElement).dataset.originalWidth;
    const originalHeight = (detailComponent as HTMLElement).dataset.originalHeight;

    if (originalWidth && originalWidth !== '' && originalHeight && originalHeight !== '') {
        delegate('#download-wrapper-button', 'click', () => {
            enableSizeSelector(detailComponent);
        });

        enableSizeSelector(detailComponent);

        changePopoverPosition();

        qq('.size-selector p.size', detailComponent)
            .filter(sizeElement => !sizeElement.dataset.initialized)
            .forEach(sizeElement => {
                sizeElement.dataset.initialized = 'true';
                sizeElement.addEventListener('click', function () {
                    const activeSize = q('.size-selector p.size.active', detailComponent);

                    if (activeSize) {
                        activeSize.classList.remove('active');
                    }

                    this.classList.add('active');

                    closeSizeSelector(detailComponent);
                    q('.download-resource-container .download-button')?.click();
                });
            });

        const sizes = calculateJPGSizes();

        if (sizes) {
            const smallSizeElement = q('#image-size-sm', detailComponent) as HTMLSpanElement;
            const mediumSizeElement = q('#image-size-md', detailComponent) as HTMLSpanElement;
            const largeSizeElement = q('#image-size-lg', detailComponent) as HTMLSpanElement;
            const originalSizeElement = q('#image-size-org', detailComponent) as HTMLSpanElement;

            setSizeElement(smallSizeElement, sizes.sm.width, sizes.sm.height);
            setSizeElement(mediumSizeElement, sizes.md.width, sizes.md.height);
            setSizeElement(largeSizeElement, sizes.lg.width, sizes.lg.height);
            setSizeElement(originalSizeElement, sizes.org.width, sizes.org.height);
        }
    }
};

const copyButtonAndroidInitialize = (copyButton: HTMLElement, parentElement: HTMLElement | Document) => {
    copyButton.dataset.initialized = 'true';
    copyButton?.addEventListener('click', () => {
        closeSizeSelector(parentElement);
        copyNotSupportedMobileNotification();
    });
};

const setSizeElement = (sizeElement: HTMLElement, width: number, height: number) => {
    if (sizeElement) {
        sizeElement.dataset.width = width.toString();
        sizeElement.dataset.height = height.toString();
        sizeElement.innerHTML = `${width} x ${height}px`;
    }
};

const enableSizeSelector = (detailElement: HTMLElement | Document) => {
    const imageOptionsButton = q('.image-options:not([data-initialized="true"])', detailElement);

    if (imageOptionsButton) {
        imageOptionsButton.classList.remove('disabled');
        imageOptionsButton.dataset.initialized = 'true';
    }
};

const closeSizeSelector = (parentElement: HTMLElement | Document) => {
    const selectedSizeElement = q('.size-selector .size.active span', parentElement) as HTMLSpanElement;
    const downloadOptionsButton = q('.image-options.active', parentElement) as HTMLButtonElement;

    if (downloadOptionsButton && selectedSizeElement) {
        downloadOptionsButton.click();
    }
}

export const copyToClipboardActionTriggered = () => {
    const copyToClipboardAction = q('.copy_size__trigger', getDetail() || document);
    return copyToClipboardAction?.dataset.copyAction === 'true';
}

export const isCopyConfirmationModalNeeded = () => copyToClipboardActionTriggered() && (isSafariBrowser() || isIOSDevice());

export const showCopyConfirmationModal = async function () {
    const { id } = DetailApi.getActive();
    const { default: template } = await import('Project/ts/templates/detail/copyToClipboard/modal-confirm-copy.hbs');
    const settings = {
        ...globals,
        multilayer: false,
        name: 'confirm-copy',
    };
    ww.ModalInstance.print({ ...settings, html: template({ isIOS: isIOSDevice() }) }, true);
    clearDownloadButton(true);
    Tracker.trackGAEventNoCache(['modal_copy', 'view', id]);
};

export const setCopyProgressBarValue = (progress: number) => {
    if (copyToClipboardActionTriggered()) {
        const modalConfirmCopy = q('#modal-confirm-copy.active');

        if (modalConfirmCopy) {
            const completeHtml = progress === 100 ? `<i class="icon icon--check"></i>${__('Image ready!')}` : '';

            ProgressBar.setValue('#copy-image-progress', progress, completeHtml);
        }
    }
}

export const copyImageReadyForSafaryAndIOS = (blob: Blob, trackerDownloadEvents?: Function) => {
    const confirmCopyButton = q('#confirm-download-button') as HTMLButtonElement;
    const cancelCopyButton = q('#modal-confirm-copy .button__cancel') as HTMLButtonElement;

    confirmCopyButton.classList.remove('disabled');
    cancelCopyButton.classList.remove('disabled');

    confirmCopyButton.addEventListener('click', () => {
        copyToClipboard(blob).then(handleCopyToClipboardResult);
        ww.ModalInstance.hide({ show: 'confirm-copy', dispatch: true });
        trackerDownloadEvents && trackerDownloadEvents(true);
    });

    cancelCopyButton.addEventListener('click', () => {
        ww.ModalInstance.hide({ show: 'confirm-copy', dispatch: true });
    });
}

export const handleCopyToClipboardResult = (copyResult: CopyToClipboardResult): void => {
    if (copyResult.result) {
        showCopyAlert(__('Copied successfully'),
            __('Copy image to clipboard'),
            false
        );

        showDownloadConfirmation();
    } else {

        const errorName = copyResult.error && copyResult.error.name;
        let errorMessage = copyResult.error ? copyResult.error.message : 'An error has ocurred';

        if (errorName && errorName.toLocaleLowerCase().includes('notallowederror')) {
            errorMessage = __('Copy error. Please, don\'t change the window while copy is in process');
        }

        showCopyAlert(__(errorMessage),
            __('Copy image to clipboard'),
            true
        );
    }

    clearDownloadButton(true);
}

export const copyImageToClipboard = function (resourceId: string, detailElement: HTMLElement | Document, trackerDownloadEvents?: Function, token?: string) {
    setCopyProgressBarValue(50);

    getImageLargeSize(resourceId, token).then(imageInfo => {

        const canvas = q('.img-size-canvas', detailElement) as HTMLCanvasElement;
        const ctx = canvas.getContext('2d');

        if (ctx && imageInfo.url) {
            const canvasImage = new Image();
            canvasImage.src = imageInfo.url;
            canvasImage.crossOrigin = 'Anonymous';

            canvasImage.onload = async function () {
                const width = canvasImage.naturalWidth;
                const height = canvasImage.naturalHeight;

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(canvasImage, 0, 0, width, height);
                canvas.toBlob((blob) => {
                    if (!blob) {
                        return;
                    }

                    if (isSafariBrowser() || isIOSDevice()) {
                        copyImageReadyForSafaryAndIOS(blob, trackerDownloadEvents);
                    } else {
                        copyToClipboard(blob).then(handleCopyToClipboardResult);
                    }

                }, 'image/png', 1);
            }
        }
    }).catch(({ message, code, url }) => {
        if (url) {
            window.location.href = url;
        } else if (DownloadResourceErrorCodes.DOWNLOAD_LIMIT_EXCEEDED === code) {
            downloadResourceWithLink(token);
        } else {
            showCopyAlert(__(message),
                __('Copy image to clipboard'),
                true
            );
        }

        clearDownloadButton(true);
    }).finally(() => {
        setCopyProgressBarValue(100);

        const copyToClipboardButton = q('.copy_size__trigger', detailElement);

        if (copyToClipboardButton) {
            copyToClipboardButton.dataset.copyAction = "";
        }
    });
}

interface CopyToClipboardResult {
    result: boolean;
    error?: CopyToClipboardError;
}

interface CopyToClipboardError {
    name: string;
    message: string;
}
