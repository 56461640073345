import { q, qq } from 'bobjoll/ts/library/dom';
import { campaignGA4Events } from 'Partials/events/ga4.campaign';
import triggerManualGA4Events from 'Partials/events/ga4.init-manual-events';
import { trackerInterface } from 'Project/ts/library/helpers/tracker';

import { BannerLabels, checkCountdownCampaign } from './campaign.banners';
import { BannerTypes, showCampaignBanner } from './campaign.helpers';

const selector = '.banner-campaign, .campaign-premium-box';

export const trackerCampaign = (banner: BannerTypes, action = 'onload') => {
    const eventConfig = BannerLabels[banner];
    const ga4EventConfig = campaignGA4Events[banner];

    const label = eventConfig[action];
    const params: (string | boolean)[] = ['send', 'event', 'campaign', action, label];

    if (action === 'onload') {
        params.push(true);
    }

    trackerInterface.send('ga', ...params);

    if (ga4EventConfig) {
        const { location, concatUserType, type, searchTerm } = ga4EventConfig;
        const userType = concatUserType ? gr && gr.user : false;
        let extraInfo = {};

        if (searchTerm) {
            extraInfo = {
                search_term: searchTerm,
            };
        }

        triggerManualGA4Events({
            description: 'campaignBanners',
            options: {
                location: location || banner,
                action,
                type,
                concatUserType,
                registered: userType,
                extraInfo,
            },
        });
    }
};

export const initCampaignBanner = (container: HTMLElement, banner: BannerTypes) => {
    BannerLabels[banner]['click'] &&
        qq('.button-campaign', container).forEach(linkElement =>
            linkElement.addEventListener('click', () => {
                trackerCampaign(banner, 'click');
            }),
        );

    checkCountdownCampaign(container);
    BannerLabels[banner]['onload'] && trackerCampaign(banner);
};

const checkCampaignBanners = () => {
    qq(selector).forEach(container => {
        const banner = container.dataset['banner'] as BannerTypes;
        if (showCampaignBanner(banner)) {
            initCampaignBanner(container, banner);
        }
    });
};

export const initPHPCampaignBanners = () => {
    checkCampaignBanners();

    if (q('.banner-upgrade') && !showCampaignBanner('upgrade')) {
        gr && gr.addEventListener('gr:update:user', checkCampaignBanners);
    }
};

export const refreshCountdown = () => qq(selector).forEach(container => checkCountdownCampaign(container));
