import { cookie } from 'Library/cookie';

import {
    PARTNERIZE_ADOBE_EXPRESS_ACCOUNTS,
    PARTNERIZE_ADOBE_EXPRESS_CREATIVE_IDS,
    PARTNERIZE_CREATIVE_IDS,
    PARTNERIZE_PUBLISHER_ACCOUNTS,
} from './constants';
import { ParnerizeKeyParams, PartnerizeBaseParams, PartnerizeParams, Placements } from './types';

const pubref = cookie.getItem('_ga') || 'nn';

const getDomainTld = () => {
    const tld = window.location.host.split('.').pop();
    return tld?.match(/com|es/) ? tld : 'com';
};

const getPublisherAccount = () => PARTNERIZE_PUBLISHER_ACCOUNTS[getDomainTld()];

const getAdobeExpressPublisherAccount = () => PARTNERIZE_ADOBE_EXPRESS_ACCOUNTS[getDomainTld()];

const getCreativeId = (placement: Placements) => {
    const id = PARTNERIZE_CREATIVE_IDS[placement];

    if (placement === 'idp' || placement === 'srp') {
        return id[USER_COUNTRY_CODE] || id['O1'];
    }

    return id;
};

const getDestinationUrl = (url: string) => {
    const checkParams = url.split('?');
    if (checkParams && checkParams.length === 2) {
        const current = new URLSearchParams(checkParams[1]);
        if (current) {
            const params = current.toString();
            const stringParams = params !== '' ? `?${params}` : '';

            return `${checkParams[0]}${stringParams}`;
        }
    }

    return url;
};

const addParamsUrl = (url: string, params: PartnerizeParams) => {
    const order: ParnerizeKeyParams = ['camref', 'creativeref', 'ar', 'pubref', 'epi2', 'r', 'destination'];
    order.forEach(key => {
        if (params[key]) {
            const param = `${key}:${params[key]}`;
            url = `${url}/${key === 'epi2' ? `[${param}]` : param}`;
        }
    });

    return url;
};

export const getLinkUrl = (placement: Placements, destination: string) => {
    let params: PartnerizeParams = {
        camref: getPublisherAccount(),
        creativeref: getCreativeId(placement),
        pubref,
        destination: encodeURIComponent(getDestinationUrl(destination)),
    };

    return addParamsUrl('https://adobe.prf.hn/click', params);
};

export const getAdobeExpressLinkUrl = (destination: string) => {
    const params: PartnerizeParams = {
        camref: getAdobeExpressPublisherAccount(),
        pubref,
        creativeref: PARTNERIZE_ADOBE_EXPRESS_CREATIVE_IDS,
        destination: encodeURIComponent(getDestinationUrl(destination)),
    };

    return addParamsUrl('https://adobe.prf.hn/click', params);
};

export const getImpressionUrl = (placement: Placements) => {
    const params: PartnerizeBaseParams = {
        camref: getPublisherAccount(),
        creativeref: getCreativeId(placement),
        r: new Date().getTime().toString(),
    };

    return addParamsUrl('https://freepik-creative.prf.hn/source', params);
};

export const getAdobeExpressImpressionUrl = () => {
    const params: PartnerizeBaseParams = {
        camref: getAdobeExpressPublisherAccount(),
        creativeref: PARTNERIZE_ADOBE_EXPRESS_CREATIVE_IDS,
        r: new Date().getTime().toString(),
    };

    return addParamsUrl('https://freepik-creative.prf.hn/source', params);
};
