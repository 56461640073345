class Draggable {
    private element: HTMLElement;
    private isDown: boolean;
    private preventClick: boolean;
    private scrollLeft: number;
    private startX: number;
    private step: number

    constructor(element: HTMLElement) {
        this.element = element;
        this.isDown = false;
        this.preventClick = false;
        this.scrollLeft = 0;
        this.startX = 0;
        this.step = 5;

        this.init();
    }

    private init() {
        this.element.addEventListener('mousedown', this.down.bind(this));
        this.element.addEventListener('mouseleave', this.leave.bind(this));
        this.element.addEventListener('mouseup', this.leave.bind(this));
        this.element.addEventListener('mousemove', this.move.bind(this));
        this.element.addEventListener('click', this.click.bind(this));
    }

    private down(e: MouseEvent) {
        this.isDown = true;
        this.startX = e.pageX - this.element.offsetLeft;
        this.scrollLeft = this.element.scrollLeft;
    }

    private leave() {
        this.isDown = false;
    }

    private move(e: MouseEvent) {
        if (!this.isDown) return;

        e.preventDefault();
        const x = e.pageX - this.element.offsetLeft;
        const distance = this.scrollLeft - (x - this.startX);
        const diff = Math.abs(this.element.scrollLeft - distance);

        if (diff > this.step) {
            this.preventClick = true;
            this.element.scrollLeft = distance;
        }
    }

    private click(e: MouseEvent) {
        if (this.preventClick) e.preventDefault();
        this.preventClick = false;
    }
}

export default Draggable;
