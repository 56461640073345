import { q, ww } from 'bobjoll/ts/library/dom';

import { showPendingBannerTop } from './notifications.winback';

const loadPaymentFormJS = (settings: FormPayment) => {
    const fjs = document.getElementsByTagName('script')[0];
    const js = document.createElement('script') as HTMLScriptElement;

    ww.paymentMethodUpdateUrl = settings?.paymentMethodUpdateUrl;
    ww.setupIntentUrl = settings?.setupIntentUrl;
    ww.customerId = settings?.customerId;
    ww.site = settings?.site;
    ww.getPaymentSourcesUrl = settings?.getPaymentSourcesUrl;

    js.id = 'winback-form-payment-js';
    js.src = settings?.jsPaymentMethod;

    if (fjs.parentNode) fjs.parentNode.insertBefore(js, fjs);
};

const loadPaymentFormCSS = (settings: FormPayment) => {
    const fcss = document.getElementsByTagName('link')[0];
    const css = document.createElement('link') as HTMLLinkElement;

    css.id = 'winback-form-payment-css';
    css.type = 'text/css';
    css.rel = 'stylesheet';
    css.href = settings?.cssPaymentMethod;

    if (fcss.parentNode) fcss.parentNode.insertBefore(css, fcss);
};

const listenersPaymentMethod = () => {
    document.addEventListener('changePaymentMethodComplete', addPaymentMethodSuccess);
};

const addPaymentMethodSuccess = () => {
    ww.ModalInstance.hide('modal-payment-method');
    showPendingBannerTop();
};

export const addPaymentMethod = (htmlFormPaymentMethod: FormPayment) => {
    if (!q('#modal-payment-method')) {
        loadPaymentFormCSS(htmlFormPaymentMethod);

        const settings = {
            html: htmlFormPaymentMethod.htmlPaymentMethod,
            name: 'payment-method',
        };

        ww.ModalInstance.print({ ...settings }, false);

        loadPaymentFormJS(htmlFormPaymentMethod);
        listenersPaymentMethod();
    }

    ww.ModalInstance.show('modal-payment-method');
};

export interface FormPayment {
    cssPaymentMethod: string;
    customerId: number;
    getPaymentSourcesUrl: string;
    htmlPaymentMethod: string;
    jsPaymentMethod: string;
    paymentMethodUpdateUrl: string;
    setupIntentUrl: string;
    site: string;
}
