import { q } from "bobjoll/ts/library/dom";

export default class Validation {
    private static createAlert(form: HTMLFormElement, field: HTMLFormElement, alertError: HTMLElement | null) {
        if (!alertError) {
            alertError = document.createElement('div');

            alertError.classList.add(
                'form-alert',
                'form-alert--error',
                `error-${field.name}`);

            field.dataset.customValidationBackground
                && alertError.classList.add('form-alert--background');
        }

        Validation.alertTemplate(field, alertError);

        Validation.alertPosition(form, field, alertError);

        return alertError;
    }

    private static controlClassErrorOnInputAndLabel(form: HTMLFormElement, field: HTMLFormElement, classError: string) {
        field.classList[classError]('error');

        q(`label[for="${field.name}"]`, form)?.classList[classError]('error');
    }

    private static getSameErrorForInputs(form: HTMLFormElement, field: HTMLFormElement): HTMLElement | null {
        return q(`.form-alert.error-${field.name}`, form) as HTMLElement | null;
    }

    private static alertTemplate(field: HTMLFormElement, alertError: Element) {
        const errorIcon = field.dataset.customValidationIcon ? '<i class="icon icon--md icon--exclamation"></i>' : '';
        const errorMessage = field.dataset.customValidationMessage || field.validationMessage;

        alertError.innerHTML = `${errorIcon}<span>${errorMessage}</span>`;
    }

    private static alertPosition(form: HTMLFormElement, field: HTMLFormElement, alertError: Element) {
        Validation.controlClassErrorOnInputAndLabel(form, field, 'add');

        if (Validation.getSameErrorForInputs(form, field)) return;

        if (field.type.match(/checkbox|radio/)) {
            field.parentElement!.insertAdjacentElement('beforebegin', alertError);
        } else {
            field.insertAdjacentElement('afterend', alertError);
        }
    }

    public static checkValidity(form: HTMLFormElement): boolean {
        const fields: HTMLFormElement[] = Array.prototype.slice.call(form.querySelectorAll('input, select, textarea'));

        fields.forEach(function (field) {
            if (field.required && !field.validity.valid) {
                const errorListener = field.type.match(/radio|checkbox/) ? 'change' : 'keyup';

                let alertError = Validation.getSameErrorForInputs(form, field);

                const errorListenerCallback = function () {
                    if (!alertError) return;

                    if (field.validity.valid) {
                        const errorParent = alertError.parentElement;
                        errorParent && alertError && errorParent.removeChild(alertError);

                        Validation.controlClassErrorOnInputAndLabel(form, field, 'remove');

                        field.removeEventListener(errorListener, errorListenerCallback);
                    } else {
                        Validation.alertTemplate(field, alertError);
                    }
                };

                alertError = Validation.createAlert(form, field, alertError);

                alertError && field.addEventListener(errorListener, errorListenerCallback);
            }
        });

        return form.checkValidity();
    }
}