import { q, ww } from 'bobjoll/ts/library/dom';
import abbreviate from 'bobjoll/ts/library/number-abbreviate';
import { Currency } from 'Library/currency';
import { __ } from 'Project/ts/partials/language';

import { md5 } from './helpers/md5';

export const getRandomRange = function(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const visibleScroll = function(element: HTMLElement) {
    const elementStyles = getComputedStyle(element);
    const padding = { x: 0, y: 0 };

    if (
        elementStyles &&
        elementStyles.paddingTop &&
        elementStyles.paddingRight &&
        elementStyles.paddingBottom &&
        elementStyles.paddingLeft
    ) {
        padding.y = parseFloat(elementStyles.paddingLeft) + parseFloat(elementStyles.paddingRight);
        padding.x = parseFloat(elementStyles.paddingTop) + parseFloat(elementStyles.paddingBottom);
    }

    return {
        vertical: element.offsetWidth - padding.y !== element.clientWidth,
        horizontal: element.offsetHeight - padding.x !== element.clientHeight,
    };
};

export const arrayShuffle = function(array: any[]) {
    let currentIndex = array.length,
        temporaryValue,
        randomIndex;
    array = array.reverse();
    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
};

export function escape_HTML(html_str: string) {
    'use strict';

    return html_str.replace(/[&<>"]/g, function(tag) {
        const chars_to_replace: { [name: string]: string } = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
        };

        return chars_to_replace[tag] || tag;
    });
}

export function setSignNumber(n: number | string) {
    return `+${n}`;
}

export function numberFormatHundred(n: number | string, applySign = true, abbr = false): string {
    n = numberNormalizer(n);

    if (n) {
        const multiplier = n >= 1000 ? 1000 : n >= 100 ? 100 : n < 10 ? 1 : 10;
        let number = (Math.floor(n / multiplier) * multiplier).toLocaleString('es' === LANGUAGE ? 'de' : LANGUAGE);

        if (abbr === true) {
            number = abbreviate(n, 0, true);
        }

        return applySign === true ? setSignNumber(number) : number;
    }

    return '' + n;
}

export function numberFormatMillionPart(n: number | string): string {
    const n2 = numberNormalizer(n);
    return n2 < 1000000 ? '1' : Math.floor(n2 / 1000000).toFixed();
}

export function numberFormat(n: number | string): string {
    return numberNormalizer(n).toLocaleString('es' === LANGUAGE ? 'de' : LANGUAGE);
}

export function numberNormalizer(n: number | string): number {
    return 'number' === typeof n ? n : n !== '' ? parseInt(n.replace(/\D+/gi, '').trim()) : 0;
}

export function numberNormalizerDecimal(number: string): number {
    return 'number' === typeof number ? number : Number(number.replace(',', '.'));
}

export function numberFormatCurrency(str: string, decimal = 2) {
    const currency = Currency.getCurrency();

    if (currency) {
        return Currency.format(str, currency, decimal);
    }

    return str;
}

export function numberFormatCurrencyDistinct(str1: string, str2: string, decimal = 2) {
    const currency = Currency.getCurrency();

    if (currency) {
        const currencyStr = str1.indexOf(currency.CURRENCY) > -1 ? str1 : str2;
        return Currency.format(currencyStr, currency, decimal);
    }

    return str1;
}

export function getDomainName(hostName: string = window.location.origin): string | void {
    const matches = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:[^.]+\.)?([^:\/\n\?\=]+)/.exec(hostName);
    if (matches && matches[1]) {
        return matches[1];
    }
}

export function getMultiDomainName(): string {
    const matches = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:[^.]+\.)?([^:\/\n\?\=]+)/.exec(document.domain);
    const domain = matches && matches[1] && matches[1].indexOf('.') > -1 ? matches[1] : document.domain;
    return `.${domain}`;
}

export function capitalize(str: string) {
    try {
        return str.charAt(0).toUpperCase() + str.slice(1);
    } catch (err) {
        console.error(err);
    }

    return str;
}

export function checkCypress() {
    return 'undefined' !== typeof ww.Cypress ? true : false;
}

export function checkDebugEnviroment() {
    return 'undefined' !== typeof debug && debug.printEvent;
}

export function shuffleArray(arr: any[]) {
    if (Array.isArray(arr)) {
        arr = arr.reverse();
        for (let i = arr.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));

            [arr[i], arr[j]] = [arr[j], arr[i]];
        }
    }

    return arr;
}

export function randomAvatar(name?: string) {
    if ('object' === typeof name) {
        name = '';
    }

    const max = 10;
    const random = name
        ? name
              .split('')
              .map(x => x.charCodeAt(0))
              .reduce((x, y) => x + y) % 10
        : getRandomRange(1, max);

    const number = name ? getFirstNumber(`${md5(name)}`) || random : random;

    const numberNormalized = number < 10 ? `0${number === 0 ? 1 : number}` : number;
    return `${URL_STATIC_IMG}avatars/${numberNormalized}.svg`;
}

export function getFirstNumber(str: string): number | void {
    const number = (str.match(/^\d+|\d+\b|\d+(?=\w)/g) || [])
        .map(number => {
            return +number;
        })
        .shift();

    if (number) {
        return parseFloat(number.toString().charAt(0));
    }
}

export function howManyMillions(n: any): number {
    if ('number' !== typeof n) {
        n = numberNormalizer(n);
    }

    return parseInt((n / 1000000).toString());
}

export function normalizeString(str: string, lowercase = false) {
    try {
        let string = str.normalize('NFC').replace(/[\u0300-\u036f]/g, '');
        if (lowercase) string = string.toLowerCase();
        return string.trim();
    } catch (err) {
        return str.trim();
    }
}

export function escapeOutput(toOutput: string) {
    return toOutput
        .replace(/\&/g, '&amp;')
        .replace(/\</g, '&lt;')
        .replace(/\>/g, '&gt;')
        .replace(/\"/g, '&quot;')
        .replace(/\'/g, '&#x27')
        .replace(/\//g, '&#x2F');
}

export function textEllipsis(str: string, max: number) {
    return str.length > max ? str.substring(0, max) + '...' : str;
}

export function collectionToArray(collection: any): any[] {
    try {
        return Array.prototype.slice.call(collection);
    } catch (err) {
        const array = [];
        for (let i = 0; i < collection.length; i++) array.push(collection[i]);
        return array;
    }
}

export const waitToBeLoaded = (ob: string, tries = 5, timing = 1000) => {
    return new Promise((resolve, reject) => {
        const interval = setInterval(() => {
            typeof window[ob] !== 'undefined' && resolve(clearInterval(interval));
            !--tries && reject(clearInterval(interval));
        }, timing);
    })
        .then(() => window[ob])
        .catch(() => false);
};

export const isPanoramicImage = (width: number, height: number) => {
    const smallest = Math.min(width, height);
    const largest = Math.max(width, height);
    const ratio = largest / smallest;
    return ratio >= 2;
};

export const getResourceType = () =>
    VIEW_TEMPLATE === 'fontsView' ? 'font' : VIEW_TEMPLATE === '3d' ? '3d' : 'regular';

export const getView = () => {
    const viewClasses = {
        home: 'home',
        collections: 'category--landing',
        collection: 'landing--collection',
    };

    const bodyClasses = document.body.className || '';
    let viewName = document.body.dataset.hbsTemplate || '';

    viewName === 'static' &&
        Object.keys(viewClasses).forEach(view => {
            if (bodyClasses.indexOf(viewClasses[view]) >= 0) viewName = view;
        });

    return viewName;
};

export const bytesToSize = (bytes: number): string => {
    if (!bytes) return '';

    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    const key = Math.floor(Math.log(bytes) / Math.log(1024));
    const size = bytes / Math.pow(1024, key);
    const formatSize = size % 1 === 0 ? size : size.toFixed(key > 1 ? 2 : 0);

    return `${formatSize} ${units[key]}`;
};

export const initShowMore = () => {
    const text = q('.collapse__text');
    const btn = q('.collapse__button');
    const maxCharacters = 200;

    if (!btn || !text || !text.dataset.description || maxCharacters > text.dataset.description.length) return;

    let showMore = false;
    const description = text.dataset.description;
    const cutText = text.textContent;

    btn.addEventListener('click', (e: Event) => {
        e.preventDefault();
        showMore = !showMore;
        text.textContent = showMore ? description : cutText;
        btn.innerText = showMore ? __('Show less') : __('Show more');
    });
};
