/* eslint-disable @typescript-eslint/no-explicit-any */

import Metas from 'Partials/metas';
import { ViewPrint } from 'Partials/view';

import { setActiveMetas } from './detail.api.store';

export const getMetas = function(options: ViewPrint) {
    const path = `detail.${options.xhr.type}${1 == options.xhr.premium ? '_premium' : ''}`;
    const strings = Metas.get(path);
    setActiveMetas({
        path,
        strings,
    });
    return {
        path,
        strings,
    };
};
