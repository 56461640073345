import { qq } from 'bobjoll/ts/library/dom';

import { Swiper, SwiperConfig } from './swiper.config';

export const SwiperInitialize = function (options?: SwiperConfig) {
    if (IS_MOBILE && options && options.isCollection) return;

    const swiper = new Swiper();
    const swiperElements = options && options.selector ? qq(options.selector) : qq('.swiper');
    swiperElements.forEach(swiperElement => swiper.addSwiper({ ...options, swiperElement }));
};
