import Axios, { Options } from 'redaxios';
import Cache, { CacheOptions } from 'Project/ts/library/cache';
import getXHR, { getMaxAge } from 'Project/ts/partials/xhr';

export const xhr = getXHR;

const axiosRequests = {};

export default {
    get: async function(url: string, options?: Options, cache?: boolean | CacheOptions) {
        if (!axiosRequests[url]) {
            axiosRequests[url] = new Promise((resolve, reject) => {
                if (cache === true) {
                    const cacheStorage = Cache.get(url);
                    if (cacheStorage) {
                        resolve(cacheStorage);
                        return;
                    }
                }
                Axios.get(url, options)
                    .then(res => {
                        const { status, data } = res;
                        if (200 !== status) {
                            reject(res);
                        }
                        if (cache) {
                            const time = getMaxAge(res);
                            const options = {
                                key: url,
                                value: data,
                                time,
                            };

                            if (typeof cache === 'object' && cache.time) {
                                options.time = cache.time;
                            }

                            Cache.set(options);
                        }
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(`Failed XHR get in ${url}: `, error);
                        reject('Failed getting xhr');
                    });
            });
        }
        const result = await axiosRequests[url];
        delete axiosRequests[url];
        return result;
    },
};

export interface XhrResponse {
    messages?: string[];
    success: boolean;
}
