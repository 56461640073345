import { XHR } from 'Partials/xhr';
import { xhr } from 'Service/xhr';

import { PauseStatusResponse, PauseSubscriptionInfo } from './notifications.pauseSubscription.vm';
import { UserHelpers } from '../user';

import { localStorage } from 'bobjoll/ts/library/storage';

import { forceCloseNotification } from './notifications.helpers';
import notify from 'Partials/notify';
import { isHomeAnon } from 'Project/ts/pages/home-anon';
import { setPositionBannerTop, setPositionBannerTopByResize } from './notifications.helpers.position';
import { ga4EventClickResumeBanner } from '../events/ga4.pause-subscription';

const pauseStatusAPI = 'xhr/user/pause-status';
const expireTimeAPI = 12 * 60 * 60 * 1000;

export const pauseSubscriptionNotifications = async () => {
    if (UserHelpers.isPremium()) return false;

    const storage = localStorage.get('session', pauseStatusAPI);
    const now = new Date().getTime();
    const storageHasExpired = storage && now - storage > expireTimeAPI;

    if (!storage || storageHasExpired) {
        return callAPI();
    }

    return false;
};

const callAPI = async () => {
    const {
        pauseData,
        pauseData: { isPaused },
    }: PauseStatusResponse = await xhr(`${BASE_URL}${pauseStatusAPI}`, {
        withCredentials: true,
        ...XHR.settings,
    });

    if (pauseData && isPaused) {
        showPauseSubscriptionNotifications(pauseData);
        return true;
    }

    localStorage.set('session', pauseStatusAPI, new Date().getTime());

    return false;
};

export const showPauseSubscriptionNotifications = async (pausedSubscription: PauseSubscriptionInfo) => {
    let resumeAt: string;
    if (FEATURE_PAUSE_SUBSCRIPTION_V2) {
        const { format, pt, de, enUS, es, fr, ja, pl, ru, nl, it, ko } = await import('Library/date-fns');
        const locales = { pt, de, enUS, es, fr, jp: ja, pl, ru, nl, it, ko };
        resumeAt = format(new Date(pausedSubscription.resumeAt), LANGUAGE === 'en' ? 'MMM do, yyyy' : 'd MMM, yyyy', {
            locale: locales[LANGUAGE] || locales.enUS,
        });
    } else {
        resumeAt = pausedSubscription.resumeAt;
    }

    forceCloseNotification('top');

    const id = 'pause-subscription-banner-top';
    const { default: template } = await import(`Project/ts/templates/notification/pause-subscription-topBanner.hbs`);
    notify.addBanner({
        priority: 0,
        position: 'top',
        id,
        class: 'notification--hide-disable notify--banner-blue pause-subscription-banner',
        html: template({
            MY_SUBSCRIPTIONS_URL,
            date: resumeAt,
            purchaseId: pausedSubscription.purchaseId,
        }),
        callback: async (banner: HTMLElement) => {
            if (isHomeAnon && gr && gr.user) {
                setPositionBannerTop(banner);
                setPositionBannerTopByResize(id);
            }

            const linkElement = document.getElementById('btn-resume') as HTMLLinkElement;
            linkElement.addEventListener('click', () => {
                ga4EventClickResumeBanner();
            });
        },
    });

    notify.printQueue();
};
