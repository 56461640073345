import { q, qq, ww } from 'bobjoll/ts/library/dom';
import { InsertSettings } from 'bobjoll/ts/partials/alert-v2.0';
import { __ } from 'Partials/language';
import { setPositionBannerTopByResize } from 'Partials/notifications/notifications.helpers.position';
import notify from 'Partials/notify';
import Fetch, { XHR } from 'Partials/xhr';
import { globals } from 'Project/ts/library/helpers/globals';
import { isHomeAnon } from 'Project/ts/pages/home-anon';
import { Options } from 'redaxios';

const MODAL_LOADERS = {
    discard: () => import('Templates/user/devices/modal-discard.hbs'),
    email: () => import('Templates/user/devices/modal-email.hbs'),
    expired: () => import('Templates/user/devices/modal-expired.hbs'),
    help: () => import('Templates/user/devices/modal-help.hbs'),
    limit: () => import('Templates/user/devices/modal-limit.hbs'),
    'save-devices': () => import('Templates/user/devices/modal-save-devices.hbs'),
} as const;

const NOTIFICATION_LOADERS = {
    error: () => import('Templates/user/devices/notification-error.hbs'),
    limit: () => import('Templates/user/devices/notification-limit.hbs'),
} as const;

export class DeviceManager {
    private static readonly PREPARE_API = `${BASE_URL}user/device-manager/prepare`;
    public static readonly IDENTIFY_API = `${BASE_URL}user/device-manager/identify`;
    public static unsavedChanges = false;
    public static expiryStorage = DEVICE_EXPIRATION;

    private maxNumDevices: number;
    private currentNumDevices: number;
    private devicesDeleted: string[];
    public messageExceeded: HTMLElement | null;
    public messageInfo: HTMLElement;
    public btnCloseMessageExceeded: HTMLElement | null;
    public removeButtons: HTMLButtonElement[];
    public helpButtons: HTMLButtonElement[];
    public cancelButton: HTMLButtonElement;
    public saveButton: HTMLButtonElement;

    constructor() {
        this.messageExceeded = q('.message');
        this.btnCloseMessageExceeded = q('.message button');
        this.messageInfo = q('#device-manager-info') as HTMLElement;

        this.removeButtons = qq('.device-manager-remove') as HTMLButtonElement[];
        this.helpButtons = qq('.device-manager-help') as HTMLButtonElement[];
        this.cancelButton = q('#device-manager-cancel') as HTMLButtonElement;
        this.saveButton = q('#device-manager-save') as HTMLButtonElement;

        this.maxNumDevices = parseInt((q('#max-devices-allowed') as HTMLElement).innerText);
        this.currentNumDevices = parseInt((q('#enabled-devices') as HTMLElement).innerText);
        this.devicesDeleted = [];
    }

    private removeDeviceInTable(id: string) {
        const row = q(`.table-responsive--item[data-id="${id}"]`);
        row?.classList.add('pending');
        DeviceManager.unsavedChanges = true;
    }

    private onBeforeUnload() {
        window.onbeforeunload = () => (DeviceManager.unsavedChanges ? '' : null);
    }

    private activeButtons() {
        this.cancelButton.removeAttribute('disabled');
        this.cancelButton.classList.remove('button--gray');
        this.cancelButton.classList.add('button--white');
        this.saveButton.removeAttribute('disabled');
    }

    private inactiveButtons() {
        this.cancelButton.setAttribute('disabled', '');
        this.cancelButton.classList.remove('button--white');
        this.cancelButton.classList.add('button--gray');
        this.saveButton.setAttribute('disabled', '');
    }

    public removeMessageExceeded() {
        this.messageExceeded && this.messageExceeded.remove();
    }

    public deleteDevice(id: string) {
        this.currentNumDevices--;

        this.devicesDeleted.push(id);
        this.removeDeviceInTable(id);

        if (this.messageExceeded && this.currentNumDevices <= this.maxNumDevices) {
            this.removeMessageExceeded();
        }

        this.messageInfo.classList.add('active');
        this.activeButtons();
        this.onBeforeUnload();
    }

    public async saveChanges() {
        this.inactiveButtons();
        const data = new FormData();
        data.append('device_ids', this.devicesDeleted.toString());

        const response = await DeviceManager.api(DeviceManager.PREPARE_API, data);
        if (response.success) {
            DeviceManager.showModal('email');
            DeviceManager.unsavedChanges = false;
        } else {
            DeviceManager.notificationError();
            this.activeButtons();
        }
    }

    public cancelChanges() {
        DeviceManager.unsavedChanges = false;
        this.inactiveButtons();
        window.location.reload();
    }

    public static async showModal(name: keyof typeof MODAL_LOADERS) {
        try {
            const { default: template } = await MODAL_LOADERS[name]();
            ww.ModalInstance.print(
                {
                    name: `devices-${name}`,
                    html: template({ ...globals }),
                },
                true,
            );
        } catch (e) {
            throw new Error(`Modal template not found: ${name}`);
        }
    }

    public static async api(url: string, data: any) {
        const settings: Options = { ...XHR.settings, method: 'POST' };

        try {
            const request = await Fetch(url, { ...settings, data });
            return request;
        } catch {
            return false;
        }
    }

    public static async notificationSuccess() {
        const { default: alert } = await import('bobjoll/ts/partials/alert-v2.0');
        const settings: InsertSettings = {
            title: __('Changes successfully saved'),
            type: 'info',
        };

        new alert({ position: 'top-right', fixed: false, timeout: 5000 }).new(settings);
    }

    public static async notificationError(name: keyof typeof NOTIFICATION_LOADERS = 'error') {
        const id = `device-manager-${name}-top`;
        const { default: template } = await NOTIFICATION_LOADERS[name]();

        try {
            notify.addBanner({
                priority: 0,
                position: 'top',
                id,
                class: `notification--hide-disable notify--banner-danger`,
                html: template({
                    BASE_URL,
                }),
                callback: () => isHomeAnon && setPositionBannerTopByResize(id),
            });

            notify.printQueue();
        } catch (e) {
            throw new Error(`Notification template not found: ${name}`);
        }
    }
}
