import { ga4 } from "./ga4.api";


export const ga4EventClickResumeBanner = () => {
    ga4({
        event: 'ga4event',
        event_name: 'resume',
        process_name: 'standard_resume',
        location: 'header',
    });
};