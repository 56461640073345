import { ObjectToFormData } from 'Project/ts/library/helpers/data';
import { XHR } from 'Project/ts/partials/xhr';
import { xhr, XhrResponse } from 'Service/xhr';

export class Favorite {
    private static response: GetResponse;
    private static log: FavoriteLog[] = [];

    public static async get(showedResources: string, forceRequest?: boolean) {
        if (Favorite.response && !forceRequest) return Favorite.response;

        let favouriteUrl = `${BASE_URL}xhr/user/like`;

        favouriteUrl += `?ids=${showedResources}`;

        Favorite.response = await xhr(favouriteUrl, XHR.settings);
        return Favorite.response;
    }

    public static async toggle(options: FavoriteToggleOptions): Promise<ToggleResponse> {
        const data = ObjectToFormData(options);
        const request = await xhr(`${BASE_URL}xhr/user/like`, {
            ...XHR.settings,
            data,
            method: 'POST',
        });
        if (request.cerberusInvalid) {
            document.location.reload();
        }
        if (request.success) {
            if ('add' === request.data.action) {
                Favorite.response.data.resources.push(options.resource);
            } else {
                Favorite.response.data.resources.splice(Favorite.response.data.resources.indexOf(options.resource), 1);
            }
            Favorite.log.push({
                id: options.resource,
                action: request.data.action,
                timestamp: new Date().getTime(),
            });
        }
        return request;
    }

    public static toggleLog(resourceId?: string) {
        let log = Favorite.log;
        if (resourceId) {
            log = log.filter(entry => entry.id == resourceId);
        }
        return log;
    }

    public static toggleCount(resourceId: string) {
        const toggleLog = Favorite.toggleLog(resourceId).sort((a, b) =>
            a.action > b.action ? 1 : a.action < b.action ? -1 : 0
        );
        return toggleLog.reduce((a: number, b) => a + ('add' === b.action ? 1 : -1), 0);
    }
}

interface FavoriteToggleOptions {
    resource: string;
    type: string;
    action: 'like' | 'unlike';
}

interface GetResponse extends XhrResponse {
    data: {
        resources: string[];
    };
}

export interface ToggleResponse extends XhrResponse {
    data: {
        action: 'add' | 'remove';
    };
    messages: string[];
}

interface FavoriteLog {
    id: string;
    action: ToggleResponse['data']['action'];
    timestamp: number;
}
