import 'Variables';

import { Options } from 'redaxios';
import { checkCypress } from './helpers';
import Fetch from 'Project/ts/partials/xhr';

export default async function Report(form: HTMLFormElement, params?: { [name: string]: string }) {
    const data = new FormData(form);
    const user = gr.user;
    const message = form.dataset.message;

    if (user) {
        const id = user.getPathValue('id');
        const login = user.getPathValue('login');
        const premium = user.getPathValue('premium');

        if ('undefined' !== typeof id) {
            data.append('user_id', id);
        }

        if ('undefined' !== typeof login) {
            data.append('user_login', login);
        }

        if ('undefined' !== typeof premium) {
            data.append('user_premium', premium);
        }
    }

    const settings: Options = {
        method: 'POST',
        data,
        headers: {
            Accept: 'application/json',
            'X-CSRF-TOKEN': CSRF_TOKEN,
            'X-Requested-With': 'XMLHttpRequest',
        },
    };

    if (params) {
        Object.keys(params).forEach(function (key: string) {
            data.append(key, params[key]);
        });
    }

    const response = await Fetch(form.action, settings);

    if (message) {
        const messageElement = document.querySelector(message) as HTMLElement;

        if (messageElement) {
            messageElement.innerHTML = `
            <div class="mg-top-lv4 form-alert form-alert--background form-alert--${response.success ? 'success' : 'error'}">
                <i class="icon icon--md icon--${response.success ? 'check' : 'exclamation'}"></i>
                ${response.messages[0]}
            </div>`;
        }

        if (response.success) {
            setTimeout(() => {
                messageElement.innerHTML = '';
            }, 5000);
        }
    }

    if (response.success) {
        form.reset();

        if (checkCypress()) {
            (window as any).GI_DOWNLOAD_BROKEN = true;
        }
    } else {
        throw Error(response.messages);
    }

    return response;
}
