import { q, qq } from 'bobjoll/ts/library/dom';
import { DetailApi } from 'Partials/detail-v2/api';
import { getDetail } from 'Partials/detail-v2/helpers/detail.helpers.generic';
import {
    copyImageToClipboard,
    isCopyConfirmationModalNeeded,
} from 'Partials/detail-v2/partials/detail.partials.downloadCopy';
import {
    downloadJPGSpecificSize,
    DownloadType,
    getDownloadSettings,
} from 'Partials/detail-v2/partials/detail.partials.downloadSize';
import { countEditButtonAsDownload } from 'Partials/detail-v2/partials/detail.partials.wepikAndStories';
import { errorAlert, showAlertDownloadError } from 'Partials/notifications';
import { getDownloadTrackerViewData } from 'Project/ts/partials/download/download.tracker';
import { xhr } from 'Service/xhr';

import { triggerDownloadCookie } from '../detail-v2/trackers/detail.trackers.download';
import { hotjarDownloadSurvey } from '../hotjar/hotjar.download-thumbnail';
import { showDownloadConfirmation } from './download.notifications';
import { captchaChallengeAppear } from './download.recaptcha.helpers';
import {
    clearDownloadButton,
    disableOrEnableDownloads,
    isThumbnailDownloadButton,
    setDownloadButtonStyle,
    setThumbnailDownloadButtonStyle,
} from './download.styles';
import { validateDownload } from './download.validations';
import { DownloadResponse, DownloadSource, RecaptchaDownload, TrackerDownload } from './download.vm';

const downloadUrlAPI = `${BASE_URL}xhr/download-url`;
const downloadUrlsModelAPI = {
    fbx: `${BASE_URL}xhr/3dmodel/download-fbx`,
    zip: `${BASE_URL}xhr/3dmodel/download-zip`,
};
const downloadUrlFontsAPI = `${BASE_URL}xhr/download-font`;
const userDownloadPanel = q('.user--downloads');
const downloadButtonsIDUserPanel = 'captcha-download-user';

const downloadPageUrl = `${BASE_URL}download/`;
const downloadPage3dUrl = `${BASE_URL}download/3d`;
const downloadPageFontUrl = `${BASE_URL}download/font`;

let linkToDownload: HTMLAnchorElement;
export let trackerDownload: TrackerDownload;

const getDownloadPageUrlByType = (resourceType: string, id: string) =>
    `${
        resourceType === 'model' ? downloadPage3dUrl : resourceType === 'fonts' ? downloadPageFontUrl : downloadPageUrl
    }/${id}`;

export const recaptchaDownload = async (downloadParams: RecaptchaDownload) => {
    const { event, button, tracker, downloadSource } = downloadParams;
    const id = downloadSource === DownloadSource.DETAIL ? DetailApi.getActive().id : button.dataset.id;

    event.preventDefault();
    event.stopPropagation();

    trackerDownload = tracker;
    trackerDownload.button = button;
    trackerDownload.downloadSource = downloadSource;

    disableOrEnableDownloads(true, trackerDownload);
    setDownloadLink(button, downloadSource);

    try {
        if (isExternalLink(downloadSource)) {
            disableOrEnableDownloads(false, trackerDownload);
            return;
        }

        const { validate, userSuspected, cypress, activateCaptcha } = await validateDownload(
            downloadSource,
            trackerDownload,
            id,
        );

        if (validate) {
            if (cypress || !activateCaptcha) {
                initDownload();
                return;
            }
            if (activateCaptcha && id) {
                initializeCaptcha(button, id, userSuspected);
            }
        }
    } catch (err) {
        const elementThumbnailDownload = isThumbnailDownloadButton(button);
        if (elementThumbnailDownload) {
            setThumbnailDownloadButtonStyle({ button });
        } else {
            setDownloadButtonStyle(button);
        }
        console.log('recaptchaDownload catch error');

        const uniqCaptcha = button.dataset.captcha ? button.dataset.captcha : 'free';
        const captchaElement = q(`#captcha-download-${uniqCaptcha}`);

        if (id && captchaElement) {
            captchaElement.classList.add('hide');
            initializeCaptcha(button, id, false);
        }
    }
};

const setDownloadLink = (button: HTMLLinkElement | HTMLButtonElement, downloadSource: DownloadSource) => {
    linkToDownload = document.createElement('a') as HTMLAnchorElement;
    let downloadFileUrl;
    let resourceId = '';
    let isPremiumItem;
    let buttonHref = button instanceof HTMLButtonElement ? button.dataset.href : button.href || button.dataset.href;

    if (downloadSource === DownloadSource.DETAIL) {
        const { selection, premium, id, download_file_url: detailDownloadFileUrl } = DetailApi.getActive();
        const license = premium ? 'Premium' : selection.toString().match(/1|2/) ? 'Selection' : 'External';
        buttonHref = detailDownloadFileUrl;
        resourceId = id;
        trackerDownload.fileLicense = license;
        isPremiumItem = premium ? 1 : 0;
    } else {
        resourceId = button.dataset.id || '';
        isPremiumItem = button.dataset.premium === '1' ? 1 : 0;
    }

    if (buttonHref) {
        downloadFileUrl =
            buttonHref.indexOf('download-file') === -1
                ? buttonHref
                : `${buttonHref}?is_premium_item=${isPremiumItem}&is_premium_user=${gr && gr.isPremium() ? 1 : 0}`;
    }

    linkToDownload.href = downloadFileUrl || '';
    linkToDownload.dataset.id = resourceId;
};

const isExternalLink = (downloadSource: DownloadSource) => {
    const isExternal = trackerDownload.fileLicense === 'External' && downloadSource !== DownloadSource.EDIT_BUTTON;

    if (isExternal) {
        if (trackerDownload.callbackEvents) trackerDownload.callbackEvents();
        clearDownloadButton(true);
        window.open(linkToDownload.href, '_blank');
    }

    return isExternal;
};

const showCurrentCaptcha = (captchaElement: HTMLElement) => {
    if (userDownloadPanel) captchaElement.classList.remove('hide');
};

const hideAllCaptchasInUserPanel = () => {
    if (userDownloadPanel) {
        const recaptchaElements = qq(`[id^="${downloadButtonsIDUserPanel}"]`);
        if (recaptchaElements.length > 0) {
            recaptchaElements.forEach((recaptcha: HTMLElement) => recaptcha.classList.add('hide'));
        }
    }
};

const initializeCaptcha = (button: HTMLElement, id: string, show = false) => {
    let uniqCaptcha = button.dataset.captcha ? button.dataset.captcha : 'free';
    let captchaElement = q(`#captcha-download-${uniqCaptcha}`);
    let recaptchaId = trackerDownload.recaptcha?.widgets[trackerDownload.recaptcha.nameHandler];

    if (captchaElement && (recaptchaId || recaptchaId == '0')) {
        grecaptcha.reset(recaptchaId);

        const newCaptcha = captchaElement.cloneNode(false) as HTMLElement;
        uniqCaptcha = `${uniqCaptcha}-${new Date().getTime()}`;
        newCaptcha.id = `captcha-download-${uniqCaptcha}`;

        captchaElement.parentNode?.insertBefore(newCaptcha, captchaElement.nextSibling);
        captchaElement.parentNode?.removeChild(captchaElement);

        captchaElement = q(`#captcha-download-${uniqCaptcha}`);
        recaptchaId = null;
    }

    button.dataset.captcha = uniqCaptcha;
    trackerDownload.uniqCaptcha = uniqCaptcha;
    trackerDownload.captchaElement = captchaElement ? captchaElement : undefined;

    if (captchaElement && !recaptchaId && recaptchaId != '0') {
        try {
            showCurrentCaptcha(captchaElement);
            recaptchaId = trackerDownload.recaptcha?.render(captchaElement, captchaSubmitDownload, show);
        } catch (err) {
            const resourceType = getResourceType();
            window.location.href = getDownloadPageUrlByType(resourceType, id);
        }
    }

    captchaChallengeAppear();

    grecaptcha.execute(recaptchaId);
};

const getResourceType = (): string => {
    const detail = getDetail() || q('#main.download');
    return detail?.dataset.resourceType as string;
};

const captchaSubmitDownload = (token: string) => {
    try {
        initDownload(token);
    } catch (err) {
        console.error('Error submit captcha download', err);
        grecaptcha.reset();
    }
};

const initDownload = (token?: string) => {
    if (!isCopyConfirmationModalNeeded()) {
        trackerDownload.callbackEvents && trackerDownload.callbackEvents();
    }

    const detailElement = getDetail();
    const { downloadType, resourceId, width, height, selectedFileType } = getDownloadSettings(detailElement);

    switch (downloadType) {
        case DownloadType.COPY_IMAGE:
            resourceId &&
                copyImageToClipboard(resourceId, detailElement || document, trackerDownload.callbackEvents, token);
            break;
        case DownloadType.SIZE_DOWNLOAD:
            width &&
                height &&
                resourceId &&
                downloadJPGSpecificSize(resourceId, width, height, detailElement || document, token);
            break;
        case DownloadType.EDIT_BUTTON:
            detailElement && resourceId && countEditButtonAsDownload(detailElement, resourceId, token);
            break;
        case DownloadType.FILE_TYPES_DOWNLOAD:
            resourceId && selectedFileType && downloadFileResource(resourceId, selectedFileType, token);
            break;
        default:
            downloadResourceWithLink(token);
    }
};

export const downloadResourceWithLink = async (token?: string) => {
    const downloadData = await getDownloadData(token);
    downloadData && prepareDownloadResource(downloadData, token);
};

export const downloadFileResource = async (resource: string, file: string, token?: string) => {
    const downloadData = await getDownloadFilesData(resource, file, token);
    downloadData && prepareDownloadResource(downloadData, token);
};

const prepareDownloadResource = async (downloadData: DownloadResponse, token?: string) => {
    let downloadUrl = linkToDownload.href;
    let downloadFilename;
    const viewData = DetailApi.getActive() || getDownloadTrackerViewData();

    if (downloadData) {
        if (downloadData.todayDownloads && gr && gr.isOAuthLogged()) {
            gr.updateTodayDownloads(downloadData.todayDownloads);
        }

        if (downloadData.success) {
            downloadUrl = downloadData.url;
            downloadFilename = downloadData.filename;
            triggerDownloadCookie(viewData.id);
        } else if (!downloadData.success && downloadData.code === 404) {
            showAlertDownloadError();
            clearDownloadButton(true);
            return;
        } else if (typeof downloadData.url !== 'undefined') {
            window.location.href = downloadData.url;
            return;
        } else {
            errorAlert();
            clearDownloadButton(true);
            return;
        }
    }

    if (token) {
        downloadUrl += `${downloadUrl.indexOf('?') > -1 ? '&' : '?'}token=${token}`;
    }

    downloadResource(downloadUrl, downloadFilename);
};

export const downloadResource = (url: string, filename?: string) => {
    hideAllCaptchasInUserPanel();
    disableOrEnableDownloads(false, trackerDownload);
    if (linkToDownload) {
        linkToDownload.href = url;
        if (filename) {
            linkToDownload.download = filename;
        }
        document.body.appendChild(linkToDownload);
        clearDownloadButton(true);

        if (FEATURE_DOWNLOAD_FROM_THUMBNAIL_BY_COUNTRY && trackerDownload.button) {
            hotjarDownloadSurvey(trackerDownload.button as HTMLAnchorElement);
        }

        linkToDownload.click();
        linkToDownload.remove();
        downloadPageRedirect();
    }

    showDownloadConfirmation();
};

const downloadPageRedirect = () => {
    if (trackerDownload.downloadSource === DownloadSource.DOWNLOAD_PAGE) {
        const resourceUrl = trackerDownload.button?.dataset.urlSource;

        setTimeout(function() {
            if (resourceUrl) {
                window.location.href = resourceUrl;
            } else {
                window.history.back();
            }
        }, 2000);
    }
};

const xhrDownloadData = async (url = downloadUrlAPI): Promise<DownloadResponse> => {
    return await xhr(url, {
        withCredentials: true,
        headers: {
            'X-CSRF-TOKEN': CSRF_TOKEN,
            'X-Requested-With': 'XMLHttpRequest',
        },
        validateStatus: status => status >= 200 && status < 500,
    });
};

const getDownloadData = (token?: string): Promise<DownloadResponse> | void => {
    const detailElement = getDetail();
    const resourceId = detailElement ? detailElement.dataset.id : trackerDownload.button?.dataset.id;
    const resourceType = getResourceType();

    if (resourceId) {
        const downloadUrl = getDownloadUrlByResourceType(resourceType);
        const url = `${downloadUrl}/${resourceId}${token ? `?token=${token}` : ''}`;
        return xhrDownloadData(url);
    }
};

const getDownloadFilesData = (resource: string, file: string, token?: string) => {
    if (file === '') return getDownloadData(token);

    const url = `${downloadUrlAPI}/${resource}/file/${file}${token ? `?token=${token}` : ''}`;
    return xhrDownloadData(url);
};

const getDownloadUrlByResourceType = (resourceType: string) => {
    if (resourceType === 'model') {
        const resourceExtension = trackerDownload.button?.dataset.type || 'fbx';
        return downloadUrlsModelAPI[resourceExtension] || downloadUrlsModelAPI.fbx;
    }

    if (resourceType === 'fonts') return downloadUrlFontsAPI;

    return downloadUrlAPI;
};
