import { Range } from "./range";

export const resultsRange: Range[] = [
    {
        range: {
            min: 1,
            max: 10,
        },
        friendlyValue: 'de 1 a 10 resultados',
    },
    {
        range: {
            min: 11,
            max: 50,
        },
        friendlyValue: 'de 11 a 50 resultados',
    },
    {
        range: {
            min: 50,
            max: 100,
        },
        friendlyValue: 'de 50 a 100 resultados',
    },
    {
        range: {
            min: 101,
            max: 500,
        },
        friendlyValue: 'de 101 a 500 resultados',
    },
    {
        range: {
            min: 501,
            max: 1000,
        },
        friendlyValue: 'de 501 a 1000 resultados',
    },
    {
        range: {
            min: 1001,
            max: 2000,
        },
        friendlyValue: 'de 1001 a 2000 resultados',
    },
    {
        range: {
            min: 2001,
            max: 5000,
        },
        friendlyValue: 'de 2001 a 5000 resultados',
    },
    {
        range: {
            min: 5001,
            max: 10000,
        },
        friendlyValue: 'de 5001 a 10000 resultados',
    },
    {
        range: {
            min: 10001,
            max: 50000,
        },
        friendlyValue: 'de 10001 a 50000 resultados',
    },
    {
        range: {
            min: 50001,
            max: 100000,
        },
        friendlyValue: 'de 50001 a 100000 resultados',
    },
    {
        range: {
            min: 100001,
            max: 500000,
        },
        friendlyValue: 'de 100001 a 500000 resultados',
    },
    {
        range: {
            min: 500001,
            max: 1000000,
        },
        friendlyValue: 'de 500001 a 1000000 resultados',
    },
    {
        range: {
            min: 1000001,
        },
        friendlyValue: 'más de 1000001 resultados',
    },
]