import getFilterValue from 'Partials/filters-v2/getFilterValue';
import { Search } from 'Partials/search/controller';
import { ViewPrint } from 'Partials/view';
import fetch, { XHR } from 'Partials/xhr';

export const isAuthorSearch = document.body.dataset.hbsTemplate === 'author';

export class Author {
    public static async getData(options: ViewPrint) {
        const { author } = getFilterValue('author');
        const requests = [Search.getData(options), fetch(`/xhr/${author}`, XHR.settings, true)];

        try {
            const request = await Promise.all(requests);
            const [search, author] = request;

            search.meta = { ...search.meta, ...author.data };

            return search;
        } catch (err) {
            const { location = options.url } = options;
            if (location) {
                window.location.href = location;
            }
            throw err;
        }
    }

    public static getMetas(options: ViewPrint) {
        return Search.getMetas(options);
    }

    public static setFilters(options: ViewPrint, viewType: string) {
        return Search.setFilters(options, viewType);
    }

    public static async setMetas(options: ViewPrint) {
        return await Search.setMetas(options);
    }
}
