import { q, qq, ww } from 'bobjoll/ts/library/dom';
import { __ } from 'Partials/language';

import { getDetail } from '../detail-v2/helpers/detail.helpers.generic';
import {
    copyToClipboardActionTriggered,
    isCopyConfirmationModalNeeded,
} from '../detail-v2/partials/detail.partials.downloadCopy';
import { trackerDownload } from './download.recaptcha';
import { TrackerDownload } from './download.vm';

interface ThumbnailTooltip {
    actionBlocked?: boolean;
    actionDownloading?: boolean;
    button: HTMLElement;
}

const isTrackerDownloadThumbnail = (trackerDownload: TrackerDownload) =>
    FEATURE_DOWNLOAD_FROM_THUMBNAIL_BY_COUNTRY &&
    trackerDownload.button &&
    trackerDownload.button.dataset.fromView === 'thumbnail';

const setThumbnailTooltipText = (options: ThumbnailTooltip) => {
    const { button } = options;
    const textByDefault = button.dataset.license === 'External' ? 'External Download' : 'Download';

    const actionText = __(
        options.actionBlocked
            ? 'Another download in progress'
            : options.actionDownloading
            ? 'Downloading...'
            : textByDefault,
    );

    const tooltip = q('.tooltip__content', button);

    if (tooltip) {
        tooltip.textContent = actionText;
    } else if (button.closest('.showcase__thumbnail--menu')) {
        button.textContent = actionText;
    }
};

export const isEditButton = (element: HTMLElement) =>
    element.classList.contains('button-freepik-edit') || element.dataset.button === 'edit';

export const isThumbnailDownloadButton = (element: HTMLElement) =>
    element.classList.contains('download-button--thumbnail');

export const setThumbnailDownloadButtonStyle = (options: ThumbnailTooltip) => {
    const { button } = options;

    button.classList.toggle('button--loading');
    button.classList.toggle('disabled');

    setThumbnailTooltipText(options);
};

export const disableDownloadButtons = () => {
    const detail = getDetail() || document;

    q('.image-options[data-initialized="true"]', detail)?.classList.add('disabled');
    q('.copy_size__trigger[data-initialized="true"]', detail)?.classList.add('disabled');
};

export const setEditButtonStyle = (detail: HTMLElement) => {
    const button = q('.button-freepik-edit', detail) as HTMLButtonElement;
    button?.classList.toggle('active');
    button?.classList.toggle('disabled');
};

export const clearEditButton = (detail: HTMLElement) => {
    const button = q('.button-freepik-edit', detail) as HTMLButtonElement;
    button?.classList.remove('disabled');
};

export const setDownloadButtonStyle = (button: HTMLLinkElement) => {
    if (!isCopyConfirmationModalNeeded()) {
        button.classList.add('button--loading-download');

        if (!button.classList.contains('button--icon') && !button.contains(q('span.text-downloading'))) {
            const textDownloading = document.createElement('span');
            textDownloading.className = 'text-downloading';
            textDownloading.innerHTML = __(copyToClipboardActionTriggered() ? 'Copying...' : 'Downloading...');

            button.appendChild(textDownloading);
        }
    }
};

export const disableOrEnableDownloads = function(disable: boolean, trackerDownload: TrackerDownload) {
    if (trackerDownload.disableOtherDownloads) {
        const downloadButtons = qq(trackerDownload.disableOtherDownloads);
        if (downloadButtons.length > 0) {
            downloadButtons.forEach(button => {
                if (button !== trackerDownload.button) {
                    button.classList[disable ? 'add' : 'remove']('disabled');

                    if (isTrackerDownloadThumbnail(trackerDownload)) {
                        setThumbnailTooltipText({ button, actionBlocked: disable });
                    }
                }
            });
        }
    }
};

export const clearDownloadButton = (modal?: boolean) => {
    if (trackerDownload && trackerDownload.button) {
        const textDownloading = q('.text-downloading', trackerDownload.button);

        textDownloading?.parentNode?.removeChild(textDownloading);
        trackerDownload.button.classList.remove('button--loading-download');
    }

    if (trackerDownload.button && isTrackerDownloadThumbnail(trackerDownload)) {
        setThumbnailDownloadButtonStyle({ button: trackerDownload.button });
    }

    if (modal && q('#modal-download.active')) ww.ModalInstance.hide();

    q('.copy_size__trigger[data-initialized="true"]', getDetail() || document)?.classList.remove('disabled');
    q('.image-options[data-initialized="true"]', getDetail() || document)?.classList.remove('disabled');
};
