export function copyToClipboard(blob: Blob) {
    if (!isCopyToClipboardSupported()) {
        return Promise.reject({ result: false, error: new Error('clipboard not supported') });
    }

    const cb = window.navigator.clipboard as unknown as Clipboard;

    return cb
        .write([new ClipboardItem({ [blob.type]: blob })])
        .then(function() {
            return { result: true };
        })
        .catch(function(error: any) {
            return { result: false, error };
        });
}

export function isCopyToClipboardSupported() {
    return !!window.navigator.clipboard && !!(window.navigator.clipboard as unknown as Clipboard).write;
}

// This will be fixed when upgrading to TypeScript >= 4.3

interface ClipboardItem {
    readonly types: string[];
    readonly presentationStyle: "unspecified" | "inline" | "attachment";
    getType(): Promise<Blob>;
}

interface ClipboardItemData {
    [mimeType: string]: Blob | string | Promise<Blob | string>;
}

declare let ClipboardItem: {
    prototype: ClipboardItem;
    new (itemData: ClipboardItemData): ClipboardItem;
};

interface Clipboard {
    read(): Promise<Array<ClipboardItem>>;
    write(items: Array<ClipboardItem>): Promise<void>;
}
