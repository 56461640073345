import { delegate } from 'bobjoll/ts/library/dom';
import { gtm } from '../../tracker-gtm';
import { setGrLgUri } from '../user.helpers';
import { cleanUserMenuElements } from './helpers';

const eventHandlerModalLogin = function(this: HTMLLinkElement, event: Event) {
    event.preventDefault();
    event.stopPropagation();

    setGrLgUri();
    window.location.href = this.dataset.tab === 'tab-register' ? REGISTER_URL : LOGIN_URL;
};

const eventHandlerGrLogin = function() {
    if (window.location.href.match(/download-file/)) {
        window.history.back();
    } else {
        window.location.reload();
    }
};
const eventHandlerGrLogout = function() {
    window.location.reload();
};

delegate('.auth-modal[data-modal="modal-login"]', 'click', eventHandlerModalLogin);
delegate('.auth-link', 'click', () => setGrLgUri());
delegate('.tabs__link', 'click', function(this: HTMLButtonElement) {
    if (this.dataset.tab && this.dataset.tab.match(/login|register|tab-forgot-password/)) {
        const addToUrl = (this.dataset.tab || '').replace('tab-', '').replace('forgot-password', 'reset_password');
        gtm({
            event: 'modalLogin',
            addToUrl,
        });
    }
});

gr?.addEventListener('gr:logout', eventHandlerGrLogout);
gr?.addEventListener('gr:login', eventHandlerGrLogin);

if (window.location.pathname.match(/\/user\/collections/gi)) {
    cleanUserMenuElements();
}
