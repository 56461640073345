/* eslint-disable @typescript-eslint/camelcase */
import { DetailApi } from 'Partials/detail-v2/api';
import {
    isCopyConfirmationModalNeeded,
    showCopyConfirmationModal,
} from 'Partials/detail-v2/partials/detail.partials.downloadCopy';
import { editButtonClickEvent } from 'Partials/detail-v2/partials/detail.partials.wepikAndStories';
import { triggerDownloadTracker } from 'Partials/detail-v2/trackers/detail.trackers.download';
import { Modal } from 'Partials/modal';

import { isSearchByImageEnabled } from '../search-by-image/image/searchimage.get-image';
import { trackerDownload } from './download.recaptcha';
import { disableDownloadButtons } from './download.styles';
import { TrackerDownload } from './download.vm';

const common: IndexSignature = {};

export const setTrackerDownloadFromThumbnail = (downloadButton: HTMLLinkElement): TrackerDownload => ({
    callbackEvents: triggerDownloadTracker,
    disableOtherDownloads: '.download-button--thumbnail',
    fileLicense: downloadButton.dataset.license ? downloadButton.dataset.license : '',
    recaptcha: RecaptchaDownloads,
});

export const setTrackerDownloadUser = (downloadButton: HTMLLinkElement): TrackerDownload => ({
    callbackEvents: triggerDownloadTracker,
    disableOtherDownloads: '.download-button',
    fileLicense: downloadButton.dataset.license ? downloadButton.dataset.license : '',
    recaptcha: RecaptchaDownloads,
});

export const setTrackerDownloadPage = (): TrackerDownload => {
    return {
        callbackEvents: triggerDownloadTracker,
        recaptcha: RecaptchaDownloads,
    };
};

export const setTrackerEditButton = (): TrackerDownload => {
    const viewData = DetailApi.getActive() || getDownloadTrackerViewData();
    const { premium } = viewData;

    editButtonClickEvent(premium);

    return {
        callbackEvents: () => triggerDownloadTracker(false, true),
        recaptcha: RecaptchaDownloads,
    };
};

export const setTrackerDownloadDetail = (): TrackerDownload => {
    disableDownloadButtons();

    if (isCopyConfirmationModalNeeded()) {
        showCopyConfirmationModal();
    }

    const { modalDownloadOptions } = common;

    if (modalDownloadOptions) {
        Modal.hide();
    }

    return {
        callbackEvents: triggerDownloadTracker,
        recaptcha: RecaptchaDownloads,
    };
};

export const getDownloadTrackerViewData = () => {
    let viewData = {};

    if (trackerDownload.button) {
        const searchByImage = isSearchByImageEnabled() ? { referrerArgs: { img: '1' } } : {};

        viewData = {
            id: trackerDownload.button.dataset.id,
            type: trackerDownload.button.dataset.type,
            selection: trackerDownload.button.dataset.selection,
            premium: trackerDownload.button.dataset.premium,
            author: {
                name: trackerDownload.button.dataset.authorName,
            },
            dependency: searchByImage,
            fileUrl: (trackerDownload.button as HTMLLinkElement)?.href,
            fromView: trackerDownload.button.dataset.fromView || '',
        };
    }

    return viewData;
};
