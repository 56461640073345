import { q } from 'bobjoll/ts/library/dom';
import { Settings } from 'bobjoll/ts/library/settings';
import { adobeAutopromoProbability, initializeAdobeBannerAutopromo } from 'common/adobe/adobe.autopromo';
import { getAdobeBannerUrl } from 'common/adobe/adobe.helpers';
import { getImpressionUrl } from 'common/adobe/partnerize';
import { globals } from 'Project/ts/library/helpers/globals';

import { getRandomRange } from '../library/helpers';
import { initCampaignBanner } from './campaign-php/campaign.events';
import { BannerTypes, showCampaignBanner } from './campaign-php/campaign.helpers';
import { sendAutopromoGA4events } from './events/ga4.autopromo';
import { __ } from './language';

const slidesGoVersion = getRandomRange(0, 2);
const slidesGoFriendlyValue = ['yellow', 'purple', 'white'];
const WEPIK_BANNER_URL =
    'https://wepik.com/?utm_source=wepik&utm_medium=adblocker&utm_campaign=autopromo&utm_term=2021_12&utm_content=';
const showMobileADS = IS_MOBILE && FEATURE_MOBILE_ADS_BY_LANGUAGE;

const createCampaignBanner = function(options: BannerOptions): any {
    try {
        return function() {
            return new Promise(resolve => {
                options.template().then(async ({ default: template }: any) => {
                    resolve({
                        default: function(data: IndexSignature) {
                            return template({
                                ...data,
                                ...globals,
                                ...options.data,
                            });
                        },
                    });

                    if (typeof GLOBAL_CAMPAIGN !== 'undefined') {
                        const { globalCampaign } = await import('Partials/campaign-php');
                        globalCampaign.refreshCountdown();
                    }
                });
            });
        };
    } catch (err) {}
};

const eventsCampaignBanner = function(type: AutopromoCampaignType) {
    if (showCampaignBanner()) {
        const banner = type === 'adblock' ? 'adblock' : 'detail';
        const parent = {
            adblock: '.spr--fixed-height',
            detailjs: '.modal .detail',
            detailphp: '#main > .detail',
        };

        const campaignElement = q(`${parent[type]} .banner-campaign[data-banner="${banner}"]`);
        campaignElement && initCampaignBanner(campaignElement, banner);
    }
};

export const slotID: AutopromoSlotID = {
    LIST_1: 'ssm-1455007701531-0',
    LIST_2: 'ssm-1455007701531-1',
    LIST_3: 'ssm-1550064356826-0',
    SIDEBAR_1: 'ssm-1461149264689-0',
    SIDEBAR_2: 'ssm-1509449714283-0',
};

export const slotIDforVerticalGrid: AutopromoSlotIDforVerticalGrid = {
    LIST_1: showMobileADS ? 'ssm-1618376698793-0' : 'ssm-1618376698790-0',
    LIST_2: 'ssm-1618376887991-0',
    LIST_3: showMobileADS ? 'ssm-1618376698794-0' : 'ssm-1618376964992-0',
    SIDEBAR_1: 'ssm-1461149264689-0',
    SIDEBAR_2: 'ssm-1509449714283-0',
};

export enum AutopromoSlot {
    LIST_1 = 'LIST_1',
    LIST_2 = 'LIST_2',
    LIST_3 = 'LIST_3',
    SIDEBAR_1 = 'SIDEBAR_1',
    SIDEBAR_2 = 'SIDEBAR_2',
}

export const slotMappedIdForVerticalGrid = {
    [slotIDforVerticalGrid[AutopromoSlot.LIST_1]]: slotID[AutopromoSlot.LIST_1],
    [slotIDforVerticalGrid[AutopromoSlot.LIST_2]]: slotID[AutopromoSlot.LIST_2],
    [slotIDforVerticalGrid[AutopromoSlot.LIST_3]]: slotID[AutopromoSlot.LIST_3],
};

const autopromoView = {
    adblock: 'adblock',
    detailphp: 'detailphp',
    detailjs: 'detailjs',
};

export default class Autopromo {
    private static showAdobeBanner =
        !FEATURE_DISABLE_AFFILIATE_BY_COUNTRY &&
        !FEATURE_DISABLE_ADOBE_AUTOPROMO_BY_COUNTRY &&
        !FEATURE_SPONSOR_SHUTTERSTOCK &&
        !FEATURE_SPONSOR_ISTOCK;

    private static bannerAdblock = (banner: BannerTypes = 'adblock') => {
        if (showCampaignBanner(banner)) {
            return createCampaignBanner({
                data: { banner, globalCampaign: GLOBAL_CAMPAIGN },
                template: () => import(`Partials/campaign-php/views/banner.hbs`),
            });
        }

        return createCampaignBanner({
            data: {
                banner: {
                    class: banner,
                },
            },
            template: () => import(`Partials/microfunnels/banner.hbs`),
        });
    };

    private static bannersCampaign: AutopromoBanners[] = [
        {
            callback: () => eventsCampaignBanner('adblock'),
            enabled: true,
            probability: 1,
            slot: AutopromoSlot.LIST_1,
            id: FEATURE_VERTICAL_GRID_BY_LANGUAGE
                ? slotIDforVerticalGrid[AutopromoSlot.LIST_1]
                : slotID[AutopromoSlot.LIST_1],
            name: 'autopromo-fullwidth-adblock',
            template: Autopromo.bannerAdblock(),
            ga4Name: 'freepik',
        },
        {
            callback: () => eventsCampaignBanner('detailphp'),
            enabled: true,
            slot: AutopromoSlot.SIDEBAR_1,
            id: slotID[AutopromoSlot.SIDEBAR_1],
            name: 'autopromo-microfunnel',
            template: Autopromo.bannerAdblock('detail'),
            ga4Name: 'freepik',
        },
        {
            callback: () => eventsCampaignBanner('detailjs'),
            enabled: true,
            slot: AutopromoSlot.SIDEBAR_2,
            id: slotID[AutopromoSlot.SIDEBAR_2],
            name: 'autopromo-microfunnel',
            template: Autopromo.bannerAdblock('detail'),
            ga4Name: 'freepik',
        },
    ];

    private static banners: AutopromoBanners[] = [
        ...Autopromo.bannersCampaign,
        // list
        {
            enabled: FEATURE_VERTICAL_GRID_BY_LANGUAGE,
            slot: AutopromoSlot.LIST_2,
            id: slotIDforVerticalGrid[AutopromoSlot.LIST_2],
            template: () => import(`Project/ts/templates/banner/pinterest/autopromo-pinterest.hbs`),
            name: 'pinterest-fullx250',
            ga4Name: 'pinterest',
        },
        {
            enabled: !FEATURE_VERTICAL_GRID_BY_LANGUAGE,
            slot: AutopromoSlot.LIST_2,
            id: slotID[AutopromoSlot.LIST_2],
            template: () => import(`Project/ts/templates/banner/autopromo-slidesgo.hbs`),
            name: 'slidego',
            globalVariables: {
                version: slidesGoVersion,
                friendlyValue: slidesGoFriendlyValue[slidesGoVersion],
            },
            event: {
                impression: [
                    'send',
                    'event',
                    'banner-web',
                    'onload',
                    `autopromo-slidego-${slidesGoFriendlyValue[slidesGoVersion]}`,
                ],
            },
            show: () => window.innerWidth > Settings.breakpoints['sm'],
            ga4Name: 'slidesgo',
        },
        {
            enabled: !FEATURE_VERTICAL_GRID_BY_LANGUAGE,
            slot: AutopromoSlot.LIST_2,
            id: slotID[AutopromoSlot.LIST_2],
            template: () => import(`Project/ts/templates/banner/autopromo-contributor.hbs`),
            name: 'contributor',
            globalVariables: {
                LANGUAGE,
            },
            event: {
                impression: ['send', 'event', 'banner-web', 'onload', `autopromo-contributor`],
            },
            show: () => window.innerWidth > Settings.breakpoints['sm'],
            ga4Name: 'contributor',
        },
        {
            enabled: FEATURE_VERTICAL_GRID_BY_LANGUAGE && OPTIMAL_BROWSER,
            slot: AutopromoSlot.LIST_1,
            id: slotIDforVerticalGrid[AutopromoSlot.LIST_1],
            template: () => import(`Project/ts/templates/banner/autopromo-stories.hbs`),
            name: 'stories',
            globalVariables: {
                eventLabel: 'adblock1',
                logo: 'stories',
            },
            event: {
                impression: ['send', 'event', 'campaign', 'onload', 'adblock1-stories-onload'],
            },
            ga4Name: 'storyset',
        },
        {
            enabled: FEATURE_VERTICAL_GRID_BY_LANGUAGE,
            slot: AutopromoSlot.LIST_3,
            id: slotID[AutopromoSlot.LIST_3],
            template: () => import(`Project/ts/templates/banner/wepik/autopromo-wepik.hbs`),
            name: 'wepik',
            globalVariables: {
                detail: false,
                url: `${WEPIK_BANNER_URL}list-2`,
            },
            ga4Name: 'wepik',
        },
        {
            enabled: Autopromo.showAdobeBanner,
            slot: AutopromoSlot.LIST_2,
            id: slotID[AutopromoSlot.LIST_2],
            template: () => import(`Project/ts/templates/banner/adobe/autopromo-adobe.hbs`),
            name: 'adobe',
            globalVariables: {
                view: autopromoView.adblock,
                url: getAdobeBannerUrl(),
                pixelOnloadUrl: getImpressionUrl('adblock'),
                detail: true,
            },
            callback: () => initializeAdobeBannerAutopromo(autopromoView.adblock),
            probability: adobeAutopromoProbability,
            ga4Name: 'adobe',
        },
        // detail php
        {
            enabled: OPTIMAL_BROWSER,
            slot: AutopromoSlot.SIDEBAR_1,
            id: slotID[AutopromoSlot.SIDEBAR_1],
            name: 'stories-300x250',
            template: () => import(`Project/ts/templates/banner/autopromo-stories.hbs`),
            globalVariables: {
                detail: 'stories-detail',
                eventLabel: 'adblock2',
                logo: 'stories',
            },
            event: {
                impression: ['send', 'event', 'campaign', 'onload', 'adblock2-stories-onload'],
            },
            ga4Name: 'storyset',
        },
        {
            enabled: true,
            slot: AutopromoSlot.SIDEBAR_1,
            id: slotID[AutopromoSlot.SIDEBAR_1],
            name: 'pinterest-300x250',
            globalVariables: {
                detail: 'sidebar',
            },
            template: () => import(`Project/ts/templates/banner/pinterest/autopromo-pinterest.hbs`),
            ga4Name: 'pinterest',
        },
        {
            enabled: true,
            slot: AutopromoSlot.SIDEBAR_1,
            id: slotID[AutopromoSlot.SIDEBAR_1],
            name: 'wepik',
            globalVariables: {
                detail: true,
                url: `${WEPIK_BANNER_URL}detail`,
            },
            template: () => import(`Project/ts/templates/banner/wepik/autopromo-wepik.hbs`),
            ga4Name: 'wepik',
        },
        {
            enabled: Autopromo.showAdobeBanner,
            slot: AutopromoSlot.SIDEBAR_1,
            id: slotID[AutopromoSlot.SIDEBAR_1],
            name: 'adobe-300x250',
            template: () => import(`Project/ts/templates/banner/adobe/autopromo-adobe.hbs`),
            globalVariables: {
                view: autopromoView.detailphp,
                detail: 'adobe-detail',
                url: getAdobeBannerUrl(),
                pixelOnloadUrl: getImpressionUrl('adblock'),
            },
            callback: () => initializeAdobeBannerAutopromo(autopromoView.detailphp),
            probability: adobeAutopromoProbability,
            ga4Name: 'adobe',
        },
        // detail js
        {
            enabled: OPTIMAL_BROWSER,
            slot: AutopromoSlot.SIDEBAR_2,
            id: slotID[AutopromoSlot.SIDEBAR_2],
            name: 'stories-300x250',
            template: () => import(`Project/ts/templates/banner/autopromo-stories.hbs`),
            globalVariables: {
                detail: 'stories-detail',
                eventLabel: 'adblock2',
                logo: 'stories',
            },
            event: {
                impression: ['send', 'event', 'campaign', 'onload', 'adblock2-stories-onload'],
            },
            ga4Name: 'storyset',
        },
        {
            enabled: true,
            slot: AutopromoSlot.SIDEBAR_2,
            id: slotID[AutopromoSlot.SIDEBAR_2],
            name: 'pinterest-300x250',
            globalVariables: {
                detail: 'sidebar',
            },
            template: () => import(`Project/ts/templates/banner/pinterest/autopromo-pinterest.hbs`),
            ga4Name: 'pinterest',
        },
        {
            enabled: true,
            slot: AutopromoSlot.SIDEBAR_2,
            id: slotID[AutopromoSlot.SIDEBAR_2],
            name: 'wepik',
            globalVariables: {
                detail: true,
                url: `${WEPIK_BANNER_URL}detail`,
            },
            template: () => import(`Project/ts/templates/banner/wepik/autopromo-wepik.hbs`),
            ga4Name: 'wepik',
        },
        {
            enabled: Autopromo.showAdobeBanner,
            slot: AutopromoSlot.SIDEBAR_2,
            id: slotID[AutopromoSlot.SIDEBAR_2],
            name: 'adobe-300x250',
            template: () => import(`Project/ts/templates/banner/adobe/autopromo-adobe.hbs`),
            globalVariables: {
                view: autopromoView.detailjs,
                detail: 'adobe-detail',
                url: getAdobeBannerUrl(),
                pixelOnloadUrl: getImpressionUrl('adblock'),
            },
            callback: () => initializeAdobeBannerAutopromo(autopromoView.detailjs),
            probability: adobeAutopromoProbability,
            ga4Name: 'adobe',
        },
    ];

    private static getBanners(
        slot: AutopromoBannerSlot,
        id: AutopromoBannerID | AutopromoBannerIDforVerticalGrid,
        forced = false,
    ) {
        const banners =
            showCampaignBanner() && IS_MOBILE && !FEATURE_MOBILE_ADS_BY_LANGUAGE
                ? Autopromo.bannersCampaign
                : Autopromo.banners;
        return banners.filter(
            banner =>
                banner.enabled &&
                banner.slot === slot &&
                (banner.id === id || slotMappedIdForVerticalGrid[id] === banner.id) &&
                (forced ? banner.force : true) &&
                (banner.show ? banner.show() : true) &&
                (banner.languages
                    ? (Array.isArray(banner.languages) ? banner.languages.join(',') : banner.languages).match(
                          new RegExp(LANGUAGE, 'gi'),
                      )
                    : true),
        );
    }

    private static getOriginal(
        slot: AutopromoBannerSlot,
        id: AutopromoBannerID | AutopromoBannerIDforVerticalGrid,
        forced = false,
    ): AutopromoBanners | void {
        const banners = Autopromo.getBanners(slot, id, forced);
        let banner;

        if (Autopromo.showAdobeBanner) {
            const bannersWithProbability = banners.filter(banner => banner.probability);
            const bannersWithoutProbability = banners.filter(banner => !banner.probability);

            if (bannersWithProbability) {
                banner = bannersWithProbability.find(({ probability }) => probability && probability > Math.random());
            }

            if (!banner) {
                banner = Autopromo.getRandomBanner(bannersWithoutProbability);
            }
        } else {
            banner = Autopromo.getRandomBanner(banners);
        }

        if (banner) {
            const { ga4Name, slot } = banner;

            if (Array.isArray(banner.template)) {
                return banner.template[Math.floor(Math.random() * banner.template.length)];
            }

            ga4Name && sendAutopromoGA4events(slot, id, ga4Name);

            return banner;
        }
    }

    private static getRandomBanner(banners: AutopromoBanners[]) {
        return banners[Math.floor(Math.random() * banners.length)];
    }

    public static get(
        slot: AutopromoBannerSlot,
        id: AutopromoBannerID | AutopromoBannerIDforVerticalGrid,
        forced = false,
    ): AutopromoBanners | void {
        return Autopromo.getOriginal(slot, id, forced);
    }

    public static add(options: AutopromoBanners) {
        Autopromo.banners.push(options);
    }
}

interface BannerOptions {
    data: any;
    template: Function;
}

export interface AutopromoBanners {
    callback?: Function;
    enabled: boolean;
    event?: {
        impression: string[];
    };
    force?: boolean;
    ga4Name?: string;
    globalVariables?: {
        [name: string]: any;
    };
    id: AutopromoBannerID | AutopromoBannerIDforVerticalGrid;
    languages?: AutopromoLanguageSupport[];
    name: string;
    probability?: number;
    show?: () => boolean;
    slot: AutopromoBannerSlot;
    template: Function;
}

type AutopromoCampaignType = 'adblock' | 'detailphp' | 'detailjs';

export type AutopromoBannerSlot = 'LIST_1' | 'LIST_2' | 'LIST_3' | 'SIDEBAR_1' | 'SIDEBAR_2';

export type AutopromoBannerID =
    | 'ssm-1455007701531-0'
    | 'ssm-1455007701531-1'
    | 'ssm-1550064356826-0'
    | 'ssm-1461149264689-0'
    | 'ssm-1509449714283-0';

export type AutopromoBannerIDforVerticalGrid =
    | 'ssm-1618376698790-0'
    | 'ssm-1618376887991-0' // desktop | mobile
    | 'ssm-1618376964992-0'
    | 'ssm-1618376698793-0' // mobile
    | 'ssm-1618376698794-0' // mobile
    | 'ssm-1461149264689-0'
    | 'ssm-1509449714283-0';

export type AutopromoSlotID = {
    [key in AutopromoBannerSlot]: AutopromoBannerID;
};

export type AutopromoSlotIDforVerticalGrid = {
    [key in AutopromoBannerSlot]: AutopromoBannerIDforVerticalGrid;
};

export type AutopromoLanguageSupport = 'es' | 'en' | 'nl' | 'fr' | 'de' | 'it' | 'jp' | 'pl' | 'pt' | 'br' | 'ru';
