var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing;

  return "    <div"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.globalCampaign : depth0)) != null ? stack1.config : stack1)) != null ? stack1.campaignId : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":101}}})) != null ? stack1 : "")
    + "\n        class=\"banner-campaign banner-"
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias2).call(alias1,(depth0 != null ? depth0.banner : depth0),"==","adblock",{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":3,"column":38},"end":{"line":3,"column":109}}})) != null ? stack1 : "")
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias2).call(alias1,(depth0 != null ? depth0.banner : depth0),"!=","adblock",{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":109},"end":{"line":3,"column":172}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.globalCampaign : depth0)) != null ? stack1.config : stack1)) != null ? stack1.template : stack1), depth0))
    + "\"\n        data-banner=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.banner : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":4,"column":21},"end":{"line":4,"column":81}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.globalCampaign : depth0)) != null ? stack1.config : stack1)) != null ? stack1.template : stack1),"==","price_before",{"name":"compare","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":9,"column":20}}})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " id=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.globalCampaign : depth0)) != null ? stack1.config : stack1)) != null ? stack1.campaignId : stack1), depth0))
    + "\" ";
},"4":function(container,depth0,helpers,partials,data) {
    return "upper";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.banner || (depth0 != null ? depth0.banner : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"banner","hash":{},"data":data,"loc":{"start":{"line":3,"column":85},"end":{"line":3,"column":97}}}) : helper)));
},"8":function(container,depth0,helpers,partials,data) {
    return " banner-container";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (data && data.root)) && stack1.format), depth0));
},"12":function(container,depth0,helpers,partials,data) {
    return "            " + require("Partials/campaign-php/views/templates/price_before.hbs").apply(null, Array.prototype.slice.call(arguments, arguments.length > 6)) + "\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "            " + require("Partials/campaign-php/views/templates/percent_off.hbs").apply(null, Array.prototype.slice.call(arguments, arguments.length > 6)) + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.globalCampaign : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":11,"column":7}}})) != null ? stack1 : "");
},"useData":true});