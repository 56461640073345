import { __ } from 'Project/ts/partials/language';

import { AdobeOptions, AdobeResponse } from './adobe.api';
import {
    getAdobeUrl,
    getLanguagePath,
    getNextUrl,
    getNumImages,
    getPromoUrl,
    getRedirectUrl,
    getScaleFactor,
    getTypeFilterValue,
} from './adobe.helpers';
import { getImpressionUrl } from './partnerize';
import { Placements } from './partnerize/types';

export const mapResponse = function(
    placement: Placements,
    options: AdobeOptions,
    response: AdobeResponse,
    _status?: AdobeResponseStatus,
) {
    const { nb_results: total_count, files } = response;
    const { page = 1 } = options;

    const resources = shuffleFiles(files, 'image/jpeg');
    const couponTitle = __(`Get %s free %s`, getNumImages(), getTypeFilterValue());
    const sponsorTitle = __('Sponsored results by %s');
    return {
        data: {
            coupon: {
                title: couponTitle,
                url: getRedirectUrl(placement, `https://stock.adobe.com/${getLanguagePath()}promo/${getPromoUrl()}`),
            },
            resources: mapFiles(placement, resources, options),
            show_more_url: getNextUrl(placement, options),
        },
        meta: {
            title: sponsorTitle,
            sponsor: 'Adobe Stock',
            pixel_url: getImpressionUrl(placement),
            sponsorUrl: getRedirectUrl(placement, `https://stock.adobe.com/${getLanguagePath()}promo/${getPromoUrl()}`),
        },
        paging: {
            page,
            per_page: resources.length,
            total_count,
        },
    };
};

const mapFiles = function(placement: Placements, files: AdobeResponse['files'], options: AdobeOptions) {
    return files.map(({ id, thumbnail_240_height, thumbnail_240_url, thumbnail_240_width }) => ({
        id,
        ratio: (thumbnail_240_height / thumbnail_240_width) * 100,
        scale_ratio: getScaleFactor(thumbnail_240_width),
        url: getAdobeUrl({
            placement,
            options,
            url: `https://stock.adobe.com/${getLanguagePath()}images/id/${id}`,
        }),
        assets: {
            small_thumb: {
                height: thumbnail_240_height,
                width: thumbnail_240_width,
                url: thumbnail_240_url,
            },
        },
    }));
};

const shuffleFiles = (files: AdobeResponse['files'], type: string): AdobeResponse['files'] => {
    const list = files.filter(file => file.content_type !== type);
    let index = 0;

    files.forEach(file => {
        if (file.content_type === type) {
            list.splice(index, 0, file);
            index = index + 2;
        }
    });

    return list;
};

export type AdobeResponseStatus = 'empty' | 'success' | 'failed';
