import { qq } from 'bobjoll/ts/library/dom';
import { Spinner } from 'Partials/spinner';

export const loadAuthModal = function() {
    const spinner = new Spinner(document.body);
    return import('User/auth')
        .then(({ Auth }) => Auth.initialize())
        .finally(() => spinner.done());
};

export const cleanUserMenuElements = function() {
    if (gr.user && gr.user.login) {
        qq(`[data-user="${gr.user.login}"]`).forEach(menuElement => menuElement.classList.remove('hide'));
    }
};
