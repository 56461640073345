var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing;

  return "<div class=\"attribution\">\n    <div class=\"container-text\">\n        <span class=\"bold\"><i class=\"inline icon icon--check-round font-sm\"></i>"
    + container.escapeExpression((helpers.language||(depth0 && depth0.language)||alias2).call(alias1,"Download completed",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":3,"column":80},"end":{"line":3,"column":115}}}))
    + "</span>\n\n        <div class=\"flex-break\"></div>\n\n        <label class=\"font-sm block\">\n            "
    + ((stack1 = (helpers.language||(depth0 && depth0.language)||alias2).call(alias1,"%sSave the license%s or download it anytime from %sProfile > Downloads.%s",(helpers.concat||(depth0 && depth0.concat)||alias2).call(alias1,"<a class=\"underline link--inverted regular gtm-license-download-link\" href=\"",(depth0 != null ? depth0.downloadLicenseUrl : depth0),"\">",{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":10,"column":127}}}),"</a>","<a href=\"/user/downloads\" class=\"underline link--inverted regular gtm-license-profile-link\" target=\"_blank\" rel=\"nofollow noopener noreferrer\">","</a>",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "\n        </label>\n    </div>\n\n    <div class=\"container-button-download-broken\">\n        <button class=\"modal__trigger button button--sm button--link-inverted gtm-problems-downloading-button\" data-modal=\"modal-report\">\n            "
    + ((stack1 = (helpers.language||(depth0 && depth0.language)||alias2).call(alias1,"Problems with the download? %sLet us know%s","<span class=\"underline\">","</span>",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":20,"column":111}}})) != null ? stack1 : "")
    + "\n        </button>\n    </div>\n</div>";
},"useData":true});