import { ww } from 'bobjoll/ts/library/dom';

import { FilterKeys, FilterProperties } from '../FilterInputs';

import { FilterValue } from './types';

const getFilterValue = (filterKey: string | string[], filterKeysStatus: FilterKeys = ww.Filters): FilterValue => {
    const keyValues: FilterValue = {};
    if (Array.isArray(filterKey)) {
        filterKey.forEach(keyName => {
            mapKeyNames(keyName, filterKeysStatus, keyValues);
        });
    } else {
        mapKeyNames(filterKey, filterKeysStatus, keyValues);
    }

    return keyValues;
};

const mapKeyNames = (keyName: string, filterKeysStatus: FilterKeys, keyValues: FilterValue) => {
    const filterByName = filterKeysStatus && filterKeysStatus[keyName];

    filterByName &&
        Object.keys(filterByName).forEach((name: string) => {
            const optionItemProperties = filterByName[name] as FilterProperties;

            if (!keyValues[keyName]) keyValues[keyName] = [];

            if (optionItemProperties.isTextType || optionItemProperties.element?.checked) {
                keyValues[keyName].push(optionItemProperties.value);
            }
        });

    if (keyValues[keyName]) keyValues[keyName] = keyValues[keyName].join(',');
};

export { FilterValue };

export default getFilterValue;
