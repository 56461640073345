import navigationHistory from 'Partials/navigation-history';
import { UserHelpers } from 'Partials/user';

import { Notify } from './notify.component.legacy';

const notify = new Notify({
    User: {
        isLogged: () => (gr && gr.user ? true : false),
        isPremiumFlaticon: UserHelpers && UserHelpers.isPremiumFlaticon,
        isPremiumFlaticonAnnual: UserHelpers && UserHelpers.isPremiumFlaticonAnnual,
        isPremiumFlaticonMonthly: UserHelpers && UserHelpers.isPremiumFlaticonMonthly,
        isPremiumFreepik: UserHelpers && UserHelpers.isPremiumFreepik,
        isPremiumFreepikAnnual: UserHelpers && UserHelpers.isPremiumFreepikAnnual,
        isPremiumFreepikMonthly: UserHelpers && UserHelpers.isPremiumFreepikMonthly,
        isRenewalCanceledFlaticon: UserHelpers && UserHelpers.isRenewalCanceledFlaticon,
        isRenewalCanceledFreepik: UserHelpers && UserHelpers.isRenewalCanceledFreepik,
        isRenewalCanceledPlus: UserHelpers && UserHelpers.isRenewalCanceledPlus,
        isInterestedInSponsor: UserHelpers && UserHelpers.isInterestedInSponsor,
        type: UserHelpers && UserHelpers.type,
    },
    History: {
        getHistoryLength: navigationHistory.getHistoryLength,
    },
});

export default notify;
