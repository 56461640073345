import { isThumbnailDownloadButton } from 'Partials/download/download.styles';
import { waitToBeLoaded } from 'Project/ts/library/helpers';

export const hotjarDownloadSurvey = async (linkToDownload: HTMLAnchorElement) => {
    const isThumbnail = isThumbnailDownloadButton(linkToDownload);

    if (isThumbnail) {
        await waitToBeLoaded('hj').then(hj => {
            if (typeof hj === 'function') {
                hj(
                    'trigger',
                    USER_COUNTRY_CODE === 'ES' ? `thumbnailDownload${USER_COUNTRY_CODE}` : `thumbnailDownloadCA`,
                );
            }
        });
    }
};
