import { isContentCampaign } from 'Partials/campaign-php/campaign.helpers';

import * as GA4Model from './ga4.model';

const defaultCampaignParams = {
    campaign_name: 'no_campaign',
    product_price: 'full_price',
    discount_type: 0,
};

export const getCampaignConfig = () => {
    if (typeof GLOBAL_CAMPAIGN === 'undefined') return;

    const {
        config: { id, coupon, variable, variableMonthly, prices, url },
    } = GLOBAL_CAMPAIGN;
    const userIsPremium = gr && gr.user && gr.user.premium;
    const getVariable = userIsPremium ? variableMonthly : variable;
    const variableSplit = getVariable ? getVariable.split('%') : [];
    let pricesString;

    if (prices) {
        pricesString = Object.values(prices)
            .filter((pricesArray: string[]) => pricesArray.length > 1)
            .map((pricesArray: string[]) => pricesArray[1])
            .join('|');
    }

    return {
        name: id,
        coupon,
        url,
        discount: pricesString || variableSplit[0] || '',
        isContent: isContentCampaign(),
    };
};

export const getCampaignParams = (isContent: boolean, setCampaignParams?: Record<string, any>) => {
    const campaignConfig = getCampaignConfig();

    if (!campaignConfig) {
        return setCampaignParams || defaultCampaignParams;
    }

    const { name, discount } = campaignConfig;

    if (isContent) {
        return { campaign_name: name };
    }

    return {
        campaign_name: name,
        product_price: campaignConfig.isContent ? defaultCampaignParams.product_price : 'campaign_discount',
        discount_type: campaignConfig.isContent ? defaultCampaignParams.discount_type : discount,
    };
};

export const replaceCampaignParams = (event: GA4Model.EventConfig) => {
    if (event.replaceCampaignParams) {
        const { campaign_name, discount_type, product_price } = event.ga4Options as GA4Model.GA4EventParams;
        const setCampaignParams =
            campaign_name && discount_type && product_price
                ? {
                      campaign_name,
                      discount_type,
                      product_price,
                  }
                : undefined;

        event = {
            ...event,
            ga4Options: {
                ...event.ga4Options,
                ...getCampaignParams(event.ga4Options?.event_name === 'content_banner', setCampaignParams),
            },
        };
    }

    if (event.microfunnel) {
        const { location, campaign_name } = event.ga4Options as GA4Model.GA4EventParams;

        if (location === 'search_results_list_1' && campaign_name !== 'no_campaign') {
            delete event.microfunnel;
        }

        if (
            isContentCampaign() &&
            event.ga4Options?.event_name !== 'content_banner' &&
            event.ga4Options?.campaign_name
        ) {
            event.ga4Options.campaign_name = defaultCampaignParams.campaign_name;
        }
    }

    return event;
};
