/* eslint-disable @typescript-eslint/camelcase */
import 'Variables';

import { delegate, q, ww } from 'bobjoll/ts/library/dom';
import { Settings } from 'bobjoll/ts/library/settings';
import Alert from 'BobjollAlert';
import { cookie } from 'Library/cookie';
import { chance } from 'Library/helpers/change';
import { isTablet } from 'Library/helpers/device';
import { globals } from 'Library/helpers/globals';
import { openWindow } from 'Library/helpers/openWindow';
import Tracker from 'Library/tracker';
import { isLandingPaid } from 'Partials/landing-paid/landing-paid-constants';
import navigationHistory from 'Partials/navigation-history';
import Shutterstock from 'Partials/sponsor-ads/shutterstock';
import IStock from 'Partials/sponsor-ads/istock';
import { UserHelpers } from 'Partials/user';
import { Adobe } from 'Project/ts/common/adobe';
import { adobePopup, AdobePopupOrigin } from 'Project/ts/library/trackerSend/trackerSend.adobestock';

import Countdown from './countdown';
import { adobeCoupon } from './sponsor/index.adobestock';
import { PopupOrigin } from './sponsor-ads/shutterstock/shutterstock.vm';
import { isLandingPricing } from './landing-pricing-constants/landing-pricing-constants';

export const cookieAdobeModalTestAB = cookie.getItem('gr_fABy3v_test_adobe_modal');

export default class Sponsor {
    private static pageNumber = 12;
    public static randomSponsorPopup = 0.2;
    public static adobeModalTestABenabled = cookieAdobeModalTestAB && cookieAdobeModalTestAB === 'b';

    public static alert = new Alert({
        fixed: true,
        position: 'bottom-right',
    });

    static async popup(query: string, origin: AdobePopupOrigin) {
        if (
            UserHelpers.isPremium() ||
            isTablet() ||
            FEATURE_DISABLE_AFFILIATE_BY_COUNTRY ||
            FEATURE_DISABLE_ADOBE_POPUP_BY_COUNTRY
        )
            return;

        if (FEATURE_SPONSOR_ISTOCK) {
            IStock.renderPopup(query, origin as PopupOrigin);
            return;
        }

        if (FEATURE_SPONSOR_SHUTTERSTOCK) {
            let originParam: string = origin;
            if (origin === 'page-button-next' || origin === 'page-button-prev') {
                originParam = 'page-button';
            }
            Shutterstock.renderPopup(query, originParam as PopupOrigin);
        } else {
            Sponsor.popupAdobe(query, origin);
        }
    }

    static getPopularSearchTerms(query: string) {
        if (query == '') {
            return TOP_SEARCH_TERMS[Math.floor(Math.random() * (TOP_SEARCH_TERMS.length - 1))];
        }
        return query;
    }

    static async popupAdobe(query: string, origin: AdobePopupOrigin) {
        query = Sponsor.getPopularSearchTerms(query);

        const name = 'sponsor-popup';
        let adobeLibraryUrl = Adobe.helpers.getPopupUrl({
            words: query,
        });

        const expDate = cookie.getItem(name);
        if (!expDate) {
            const expires = new Date();
            expires.setHours(expires.getHours() + 12);

            cookie.setItem(name, expires.toString(), {
                path: '/',
                domain: document.domain,
                expires: new Date(expires),
            });

            openWindow(adobeLibraryUrl);

            adobePopup(
                {
                    origin,
                    term: query,
                },
                origin,
            );
        }
    }

    static async coupon() {
        const isLanding = isLandingPaid || isLandingPricing || VIEW_TEMPLATE === 'keyDates';

        if (
            isLanding ||
            UserHelpers.isPremium() ||
            UserHelpers.isEssential() ||
            window.innerWidth < parseFloat(Settings.breakpoints.sm) ||
            FEATURE_DISABLE_AFFILIATE_BY_COUNTRY ||
            FEATURE_SPONSOR_SHUTTERSTOCK ||
            FEATURE_SPONSOR_ISTOCK
        )
            return;

        const history = navigationHistory.getHistoryLength();
        const chanceSponsor = chance(1, Sponsor.pageNumber);
        if (history > 3 && chanceSponsor) {
            try {
                const couponSettings = Adobe.helpers.getCoupon();

                const coupon = {
                    code: '',
                    content: '',
                    date_to: '',
                    title: '',
                    ...couponSettings,
                };

                setTimeout(() => adobeCoupon(coupon), 2000);
            } catch (err) {
                throw Error(`Error rendering coupon, ${err}`);
            }
        }
    }

    public static couponCountdown(element: HTMLElement, dateEnd: Date) {
        const container = q('.countdown', element);
        const dateStart = new Date();
        const dateHoursLeft = Math.abs(dateStart.getTime() - dateEnd.getTime()) / 3600000;
        if (container && 48 >= dateHoursLeft) {
            new Countdown({ container, dateStart, dateEnd });
        }
    }

    public static async showAdobeModal(query: string, origin: AdobePopupOrigin, page?: number) {
        const pagesToShowModal = 5;
        sessionStorage.removeItem('show-adobe-modal');

        const random = Math.random();

        if (
            UserHelpers.isPremium() ||
            UserHelpers.isEssential() ||
            isTablet() ||
            (page && page > pagesToShowModal) ||
            (page && page > 1 && random > ADOBE_MODAL_PROBABILITIES[page - 1])
        )
            return;

        const lastPage = page ? page - 1 : undefined;
        const lastPageString = lastPage || '';
        const cookieName = `sponsor-modal${lastPageString}`;
        const expDate = cookie.getItem(cookieName);

        if (!expDate) {
            const eventLabelSufix = !page || page === 1 ? 'random' : `NextPage${lastPageString}`;
            const expires = new Date();
            expires.setHours(expires.getHours() + 12);

            cookie.setItem(cookieName, expires.toString(), {
                path: '/',
                domain: document.domain,
                expires: new Date(expires),
            });

            const template = () => import('Project/ts/templates/adobe/adobe-modal.hbs');
            const { default: Template } = await template();
            const settings = {
                ...globals,
                multilayer: false,
                name: 'adobe-next-page',
            };
            ww.ModalInstance.print({ ...settings, html: Template({ URL_STATIC_IMG }) }, true);

            Tracker.trackGAEventNoCache(['sponsor', 'onload', `modal-${eventLabelSufix}`]);

            Sponsor.initializeAdobeModal(origin, query, eventLabelSufix, lastPage);
        }
    }

    private static initializeAdobeModal(
        origin: AdobePopupOrigin,
        query: string,
        eventLabelSufix: string,
        page?: number,
    ) {
        delegate('#modal-adobe-next-page .button--blue', 'click', () => {
            ww.ModalInstance.hide();
            Tracker.trackGAEventNoCache(['sponsor', 'click', `modal-${eventLabelSufix}-continue-freepik`]);
        });
        delegate('#modal-adobe-next-page .go-adobe, #modal-adobe-next-page #adobe-logo', 'click', () => {
            Sponsor.tab(
                Adobe.helpers.getPopupUrl({
                    words: query,
                }),
            );
            adobePopup(
                {
                    origin,
                    term: query,
                },
                origin,
                page,
            );
            ww.ModalInstance.hide();
        });

        const modalClose = q('#modal-adobe-next-page .modal__close');
        const modalOut = q('#modal-adobe-next-page.modal:not(.modal__close)');

        modalClose &&
            modalClose.addEventListener('click', () =>
                Tracker.trackGAEventNoCache(['sponsor', 'click', `modal-${eventLabelSufix}-close`]),
            );
        modalOut &&
            modalOut.addEventListener('click', function(ev) {
                const target = ev.target as HTMLElement;
                target &&
                    target.id === 'modal-adobe-next-page' &&
                    Tracker.trackGAEventNoCache(['sponsor', 'click', `modal-${eventLabelSufix}-out`]);
            });
    }

    private static tab(url: string) {
        Object.assign(document.createElement('a'), {
            target: '_blank',
            href: url,
        }).click();
    }
}
