import { Istock as getIstock, IstockVM } from '@fc/sponsor_front';

import { q } from 'bobjoll/ts/library/dom';
import { Arguments, ArgumentsDictionary } from 'Library/arguments';
import { cookie } from 'Library/cookie';
import { globals } from 'Library/helpers/globals';
import { openWindow } from 'Library/helpers/openWindow';
import getFilterValue from 'Partials/filters-v2/getFilterValue';
import { __ } from 'Partials/language';
import { UserHelpers } from 'Partials/user';
import { triggerWindowResize } from 'Project/ts/library/helpers/resize';

import { SponsorAdsInsertSettings, SponsorAdsOptions } from '../sponsor-ads.types';
import { REDIRECT_URL } from './istock.constants';
import { getParamsToAPI } from './istock.params';
import { add404ClickPixelAndRedirection } from './istock.tracker-404';
import { addImpressionAndClickPixels } from './istock.tracker-banner';
import { addPopupClickPixel } from './istock.tracker-popup';
import { Placements, PopupOrigin } from './istock.vm';

class IStock {
    public static renderBanner = async (
        options: SponsorAdsInsertSettings,
        banner: HTMLElement,
        settings: { id: string; url: string },
        globalVariables: ArgumentsDictionary,
        setLayout: Function,
    ) => {
        const { default: template } = await import('Project/ts/templates/parts/sponsor_container.hbs');
        const placement = IStock.getPlacement(settings);
        const sponsorResponse = <IstockVM.iStockResponseBanner>await IStock.getData(placement);
        const layoutOptions = IStock.getLayout(globalVariables);
        const coupon = IStock.getCoupon(sponsorResponse);
        const templateData = {
            sponsor: {
                ...sponsorResponse,
                coupon,
            },
            options: {
                ...options,
                ...globalVariables,
            },
            user: {
                id: UserHelpers.id(),
                clientId: UserHelpers.getClientId(),
            },
            ...globals,
            FEATURE_HIDE_SPONSOR_VISIBILITY,
        };

        banner.innerHTML = template(templateData);

        setLayout(layoutOptions);

        window.addEventListener('resize', function() {
            setLayout(layoutOptions);
        });

        setTimeout(() => {
            Lazyload.run(banner);

            const bannerContainer = q('.showcase-spr', banner);
            if (bannerContainer) bannerContainer.style.visibility = 'visible';

            triggerWindowResize();
        }, 100);

        globalVariables && addImpressionAndClickPixels(globalVariables, sponsorResponse);

        placement === 404 && add404ClickPixelAndRedirection(sponsorResponse);
    };

    private static getPlacement(settings: { id: string; url: string }) {
        const { url } = settings;
        const { referer_page = 'search' } = Arguments.stringToObject(url);
        return IStock.mapReferrerFromVmToApi(referer_page);
    }

    private static async getData(placement: Placements) {
        const { type } = getFilterValue('type');
        const params = getParamsToAPI();

        return await getIstock({
            placement,
            country: USER_COUNTRY_CODE || 'us',
            projectId: DOMAIN_FP,
            userId: cookie.getItem('_ga') || '',
            components: 'banner',
            redirectAndBase64: REDIRECT_URL,
            categoryType: type || '',
            type: type || '',
            params,
            locale: LANGUAGE || 'en',
        });
    }

    private static getLayout(globalVariables: ArgumentsDictionary): SponsorAdsOptions {
        return {
            classes: {
                row: `.${globalVariables.classes.row}`,
                item: `.${globalVariables.classes.item}`,
            },
            hideRowClasses: globalVariables.hideRowClasses,
            numberRowsDisplay: 1,
        };
    }

    private static mapReferrerFromVmToApi(referrer = 'search'): Placements {
        if (referrer.match(/detail|detailjs/gi)) {
            return 'idp';
        }
        if (referrer.match(/search_premium|empty/)) {
            return 404;
        }
        return 'srp';
    }

    private static getCoupon(sponsorResponse: IstockVM.iStockResponseBanner) {
        if (!sponsorResponse || !sponsorResponse.coupon) return {};

        return {
            ...sponsorResponse.coupon,
            title: __('Get %1$s off with code %2$s', '20%', 'FREEPIK20'),
        };
    }

    public static async renderPopup(query: string, origin: PopupOrigin) {
        const { type } = getFilterValue('type');
        const params = getParamsToAPI(query);

        const sponsorResponse = <IstockVM.iStockResponsePopup>await getIstock({
            placement: 'popup',
            country: USER_COUNTRY_CODE || 'us',
            projectId: DOMAIN_FP,
            userId: cookie.getItem('_ga') || '',
            components: 'popup',
            redirectAndBase64: REDIRECT_URL,
            categoryType: type || '',
            type: type || '',
            popupOrigin: origin,
            params,
            locale: LANGUAGE || 'en',
        });

        sponsorResponse && IStock.conditionsPopup(sponsorResponse);
    }

    private static conditionsPopup(sponsorResponse: IstockVM.iStockResponsePopup) {
        const cookieName = 'sponsor-popup';
        const cookieHoursExpire = 12;
        const expDate = cookie.getItem(cookieName);

        if (!expDate) {
            const expires = new Date();
            expires.setHours(expires.getHours() + cookieHoursExpire);

            cookie.setItem(cookieName, expires.toString(), {
                path: '/',
                domain: document.domain,
                expires: new Date(expires),
            });

            openWindow(sponsorResponse.url);

            addPopupClickPixel(sponsorResponse);
        }
    }
}

export default IStock;
