import { q, qq, ww } from 'bobjoll/ts/library/dom';
import accordion from 'bobjoll/ts/partials/accordion-v1.0';
import triggerManualGA4Events from 'Partials/events/ga4.init-manual-events';
import { __ } from 'Partials/language';
import { globals } from 'Project/ts/library/helpers/globals';

import { DetailApi } from '../api';
import { blurDetailWhileIsOpened } from '../helpers/detail.helpers.event';
import { getDetail, getDetailModalContainer } from '../helpers/detail.helpers.generic';

let closeFn: Function;

const handlerAttributeClick = async function(this: HTMLButtonElement, event: Event) {
    event.preventDefault();
    event.stopPropagation();

    const viewData = DetailApi.getActive();
    const { default: viewTemplate } = await import('Templates/parts/detail-attribute.hbs');

    const textFlaticon = __('%sNow with Flaticon exclusive content.%s', '<p class="text">', '</p>');
    const textBanner = __('Join our Premium family, outsmart deadlines, and live a hassle-free life');

    const title = __('Forget about attribution');
    const text = `${textBanner} ${textFlaticon}`;
    const button = __('Go Premium');

    const banner = {
        microfunnel: 'how-attribute',
        class: 'attribution',
        small: true,
        title,
        text,
        button,
    };

    const modalAttribution = ww.ModalInstance.print({
        name: 'attribute',
        html: viewTemplate({ ...globals, ...viewData, banner }),
    });

    ww.ModalInstance.show('modal-attribute');
    ww.ModalInstance.hide({ show: 'modal-attribute' });

    if (modalAttribution && closeFn) {
        const fn = function() {
            closeFn();
            modalAttribution.removeEventListener('hide', fn);
        };
        modalAttribution.addEventListener('hide', fn);
    }

    accordion.refresh();

    if (getDetailModalContainer()) {
        q('#modal-attribute .modal__close')?.addEventListener('click', blurDetailWhileIsOpened);
    }

    triggerManualGA4Events({
        description: 'modalAttributionBannerOnload',
        options: {},
    });
};

export const initializeAttribute = function(parentElement?: HTMLElement, myCloseFn?: Function) {
    const detailElement = getDetail() || document;

    if (myCloseFn) {
        closeFn = myCloseFn;
    }

    qq('[data-modal="modal-attribute"]', parentElement || detailElement).forEach(buttonElement => {
        buttonElement.classList.remove('modal__trigger');
        buttonElement.addEventListener('click', handlerAttributeClick);
    });
};
