import { q, qq } from 'bobjoll/ts/library/dom';
import triggerManualGA4Events from 'Partials/events/ga4.init-manual-events';

export const sectionEvents: HomeEvents[] = [
    {
        name: 'content_space',
        location: 'home_categories',
        element: '.section--categories .bubble-list--item',
        multiple: true,
        elementTitle: '.bubble-list--title',
        extraInfo: {
            type: 'version-squares',
        },
        insertLabel: true,
    },
    {
        name: 'content_space',
        element: '.section--trendings .caption-list--item',
        location: 'home_trends',
        multiple: true,
        elementTitle: '.caption-list--title',
        insertLabel: true,
    },
    {
        name: 'content_space',
        element: '.section--collections .caption-list--item',
        location: 'home_collections',
        multiple: true,
        elementTitle: '.caption-list--title',
        extraInfo: {
            link_id: 'id',
        },
        insertLabel: true,
    },
    {
        name: 'content_banner',
        element: '.section--join a',
        location: 'home',
        extraInfo: {
            action: 'contributor',
        },
        insertLabel: true,
    },
    {
        name: 'microfunnel',
        element: '.section--features__registered .button--yellow',
        location: 'home_block',
        replaceCampaignParams: true,
    },
];

const sendHomeSectionsEventGA4 = (homeEvent: HomeEvents, item: HTMLAnchorElement) => {
    const title =
        homeEvent.multiple && homeEvent.elementTitle ? q(homeEvent.elementTitle, item)?.innerText : item.innerText;
    const url = item.href || '';
    const isLogged = gr && gr.user;
    const isPremium = isLogged && gr.user?.premium;

    const isVideo = q('[data-type="video"]', item) ? true : false;
    homeEvent.category = isVideo ? 'video' : 'photo';
    homeEvent.from_view = url.replace(/^.*\/\/[^\/]+/, '');

    if (homeEvent.insertLabel) {
        if (!homeEvent.extraInfo) {
            homeEvent.extraInfo = {};
        }
        homeEvent.extraInfo.label = `new_home_${isLogged ? (isPremium ? 'payment' : 'registered') : 'anonymous'}`;
    }

    const getCollectionId = homeEvent.extraInfo?.link_id;
    if (getCollectionId) {
        homeEvent.extraInfo = {
            ...homeEvent.extraInfo,
            link_id: item.dataset.id || '',
        };
    }

    triggerManualGA4Events({
        description: 'homeSections',
        options: {
            event_name: homeEvent.name,
            location: homeEvent.location,
            link_text: title?.toLowerCase(),
            link_url: url,
            category: homeEvent.category,
            from_view: homeEvent.from_view,
            extraInfo: homeEvent.extraInfo || {},
            replaceCampaignParams: homeEvent.replaceCampaignParams ?? false,
        },
    });
};

const sendBannerHomeEventGA4 = (description: string, link: HTMLAnchorElement, action = 'click') => {
    triggerManualGA4Events({
        description,
        options: {
            link_url: link.href,
            link_text: link.innerText,
            action,
        },
    });
};

export const homeEventsGA4 = () => {
    const autopromo = LANGUAGE === 'en' ? 'calendar' : 'wepik';
    if (!FEATURE_AD_MANAGER) {
        homeMiddleBannerEventsGA4();
        homeFpcBannerEventsGA4(`.section--${autopromo} a`, autopromo);
    }

    qq('.section--tags a')?.forEach((tag: HTMLAnchorElement) =>
        tag.addEventListener('click', () =>
            triggerManualGA4Events({
                description: 'popularSearchesTags',
                options: {
                    search_term: tag.innerText,
                    location: 'home',
                },
            }),
        ),
    );

    sectionEvents.forEach(homeEvent => {
        if (homeEvent.multiple) {
            qq(homeEvent.element)?.forEach((item: HTMLAnchorElement) =>
                item.addEventListener('click', () => sendHomeSectionsEventGA4(homeEvent, item)),
            );
        } else {
            const element = q(homeEvent.element) as HTMLAnchorElement | undefined;
            element?.addEventListener('click', () => sendHomeSectionsEventGA4(homeEvent, element));
        }
    });
};

export const homeMiddleBannerEventsGA4 = () => {
    const bannerSelector = '#main.home .banner-header-home[data-user-type="{USER_TYPE}"] a';
    const linkBannerAnonymous = q(bannerSelector.replace('{USER_TYPE}', 'anonymous')) as HTMLAnchorElement | undefined;
    const linkBannerLogged = q(bannerSelector.replace('{USER_TYPE}', 'free')) as HTMLAnchorElement | undefined;

    linkBannerAnonymous?.addEventListener('click', () =>
        sendBannerHomeEventGA4('homeAnonymousBanner', linkBannerAnonymous),
    );

    if (linkBannerLogged) {
        sendBannerHomeEventGA4('homeLoggedBannerMicrofunnel', linkBannerLogged, 'onload');
        linkBannerLogged.addEventListener('click', () =>
            sendBannerHomeEventGA4('homeLoggedBannerMicrofunnel', linkBannerLogged),
        );
    }
};

export const homeFpcBannerEventsGA4 = (selector: string, action: string): void => {
    const linkBanner = q(selector) as HTMLAnchorElement | undefined;

    linkBanner?.addEventListener('click', () =>
        sendHomeSectionsEventGA4(
            {
                name: 'content_banner',
                element: selector,
                location: 'home',
                extraInfo: {
                    action,
                },
            },
            linkBanner,
        ),
    );
};

interface HomeEvents {
    element: string;
    elementTitle?: string;
    extraInfo?: HomeExtraInfo;
    insertLabel?: boolean;
    location: string;
    multiple?: boolean;
    name: string;
    replaceCampaignParams?: boolean;
    category?: string;
    from_view?: string;
}

interface HomeExtraInfo {
    action?: string;
    label?: string;
    link_id?: string;
    type?: string;
    category?: string;
    from_view?: string;
}
