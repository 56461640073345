import { delegate,delegateRemove } from 'bobjoll/ts/library/dom';
import { localStorage } from 'bobjoll/ts/library/storage';

import { isLogged } from '../user.helpers';

export const FavoriteJobs = {
    get: function() {
        let jobs = localStorage.getItem('user', 'favorites');
        if (jobs) {
            jobs = JSON.parse(jobs);
        }
        return (jobs || []) as FavoriteJobOptions[];
    },
    set: function(job: FavoriteJobOptions) {
        const jobs = FavoriteJobs.get();
        jobs.push(job);
        localStorage.setItem('user', 'favorites', JSON.stringify(jobs));
    },
    clear: function() {
        localStorage.removeItem('user', 'favorites');
    },
};

const eventHandler = {
    toggle: async function(this: HTMLButtonElement) {
        this.classList.add('button--loading');
        const action = 'like';
        const type = this.dataset.rt;
        const resource = this.dataset.rid;
        if (resource && action && type && (await isLogged(true))) {
            FavoriteJobs.set({
                action,
                resource,
                type,
            });
        }
        this.classList.remove('button--loading');
    },
    ready: function() {
        gr.removeEventListener('gr:ready', eventHandler.ready);
        delegateRemove('.button--favorite', 'click', eventHandler.toggle);
    },
};

delegate('.button--favorite', 'click', eventHandler.toggle);
gr.addEventListener('gr:ready', eventHandler.ready);

interface FavoriteJobOptions {
    action: 'like' | 'unlike';
    type: string;
    resource: string;
}
