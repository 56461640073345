/* eslint-disable @typescript-eslint/camelcase */
import { q, qq } from 'bobjoll/ts/library/dom';
import { numberNormalizer } from 'Library/helpers';
import { view } from 'Partials/view';

import { TrackerOptions } from '.';

export const getActiveDetailId = (): number | void => {
    const detailElement = q('#modal-detail.active .detail') || q('#main .detail');
    if (detailElement && detailElement.dataset.id) {
        return numberNormalizer(detailElement.dataset.id);
    }
};

export const getViewType = (): 'srp' | 'idp' | '404' | 'nn' => {
    const viewType = view.getView();
    if ('search' === viewType && !qq('#main .showcase .showcase__item').length) {
        return '404';
    }
    if (viewType.match(/search|category|keyword|collections|popular/gi)) {
        return 'srp';
    }
    if ('detail' === viewType) {
        return 'idp';
    }
    return 'nn';
};

export const getTrackerData = (data: TrackerOptions, view: string) => {
    const resource_id = getActiveDetailId();
    const trackerData: TrackerOptions = { ...data };
    if (resource_id) trackerData['resource_id'] = resource_id;
    if (view) {
        const testName = getTestForGA(view);
        if (testName) trackerData['test'] = testName;
    }
    return trackerData;
};

export const getTestForGA = (view: string | number) => {
    if (view.toString().match(/idp/g)) {
        if (optimizeTests['idp']) return `test-idp-${optimizeTests['idp']}`;
    }

    return '';
};
