import { qq, ww } from 'bobjoll/ts/library/dom';

export class Currency {
    public static getCurrency(): currencyDictionary {
        const CURRENCY_SYMBOL = {
            EUR: '€',
            INR: '₹',
            USD: '$',
        };

        const currencyDictionary = {
            CURRENCY_NAME: USER_CURRENCY,
            CURRENCY: CURRENCY_SYMBOL[USER_CURRENCY],
            PRICE: {
                annual: USER_PRICE_YEARLY,
                annual_monthly: USER_PRICE_YEARLY_BY_MONTH,
                monthly: USER_PRICE_MONTHLY,
            },
        };
        Object.keys(currencyDictionary.PRICE).forEach((key: keyof typeof currencyDictionary['PRICE']) => {
            currencyDictionary.PRICE[key] = Currency.format(currencyDictionary.PRICE[key], currencyDictionary);
        });
        return currencyDictionary;
    }
    public static getCountry(): string {
        return ww.USER_COUNTRY_CODE;
    }
    public static updateDomPrices() {
        const currency = Currency.getCurrency();
        if (currency) {
            document.body.classList.add(`currency-${'EURO' === currency.CURRENCY_NAME ? 'euro' : 'dollar'}`);
            qq('.price').forEach(price => {
                const original = parseFloat(price.innerText.replace(',', '.').replace(/[^\d|\.\-]/gi, ''));
                const type = 20 < original ? 'annual' : 'monthly';
                price.innerText = currency.PRICE[type];
            });
        }
    }
    public static format(str: string, currency: currencyDictionary, decimal = 2) {
        if ('number' !== typeof decimal) {
            decimal = 2;
        }

        if ('number' === typeof str) {
            str = (str as any).toString();
        }

        const numberRegExp = new RegExp(/([0-9]+,?.?([0-9]+)?)/g);
        const numberString = ((str.match(numberRegExp) || []).shift() || '').replace(',', '.').trim();
        const numberContainsName = str.match(/USD/gi) ? true : false;
        if (numberString) {
            let numberStringFormated;

            if ('EURO' === currency.CURRENCY_NAME) {
                numberStringFormated = numberString + ' ' + currency.CURRENCY;
            } else {
                numberStringFormated = currency.CURRENCY + numberString;
                if (numberContainsName) {
                    numberStringFormated += ` ${currency.CURRENCY_NAME}`;
                }
            }

            if (LANGUAGE != 'en') {
                numberStringFormated = numberStringFormated.replace(/\./gi, ',');
            }

            return numberStringFormated;
        }

        return str;
    }
}

export interface currencyDictionary {
    CURRENCY_NAME: string;
    CURRENCY: string;
    PRICE: {
        annual: string;
        annual_monthly: string;
        monthly: string;
        [key: string]: string;
    };
}
