import { trackerInterface } from 'Project/ts/library/helpers/tracker';

declare let MutationObserver: any;
let recaptchaObserver: typeof MutationObserver;

if (typeof MutationObserver !== 'undefined') {
    recaptchaObserver = new MutationObserver(challengeCallbackFunction);
}

export const captchaChallengeAppear = () => {
    const targetElement = document.body;
    const observerConfig = {
        childList: true,
        attributes: false,
        attributeOldValue: false,
        characterData: false,
        characterDataOldValue: false,
        subtree: false,
    };
    recaptchaObserver.observe(targetElement, observerConfig);
};

function challengeCallbackFunction(mutationRecords: MutationRecord[]) {
    mutationRecords.forEach((mutationRecord: MutationRecord) => {
        if (mutationRecord.addedNodes.length) {
            const recaptchaParentContainer = mutationRecord.addedNodes[0];
            const recaptchaIframe =
                (recaptchaParentContainer as HTMLElement).querySelectorAll &&
                (recaptchaParentContainer as HTMLElement).querySelectorAll('iframe[title*="recaptcha"]');

            if (recaptchaIframe && recaptchaIframe.length) {
                (recaptchaParentContainer as HTMLElement).classList?.add('captcha-download-container');
                recaptchaObserver.disconnect();

                recaptchaIframe.forEach((iframes: HTMLIFrameElement) => {
                    setTimeout(function() {
                        const containerIframe = iframes.parentNode?.parentNode as HTMLDivElement;
                        let captchaType = 'invisible';
                        if (containerIframe?.style.visibility == 'visible') {
                            captchaType = 'manual';
                        }
                        trackerInterface.send('ga', 'send', 'event', captchaType);
                    }, 1500);
                });
            }
        }
    });
}
