import { q, qq } from 'bobjoll/ts/library/dom';
import { initContentBanner } from 'Partials/adManager';
import { homeEventsGA4 } from 'Partials/events/ga4.home';
import { topBannerEvents } from 'Partials/notifications';
import Draggable from 'Project/ts/ui/draggable';

const checkTopBannerPosition = () => {
    const topBanner = q('.notify-wrapper--top .notification, .top-banner');
    if (topBanner) {
        document.body.style.marginTop = `${topBanner.offsetHeight}px`;
        window.addEventListener('resize', () => (document.body.style.marginTop = `${topBanner.offsetHeight}px`));
        q('.notification__close', topBanner)?.addEventListener('click', () => (document.body.style.marginTop = '0px'));
    }
};

export const isHomeAnon = document.body.className.indexOf('home-anon') >= 0;

export const initializeHomeAnonPage = (fromMain = false) => {
    if (window.location.pathname === '/') {
        FEATURE_TOP_BANNER_BY_COUNTRY && !IS_PREMIUM_USER && topBannerEvents();
        fromMain && FEATURE_AD_MANAGER && initContentBanner(['HOME_HEADER', 'HOME_BODY']);
    }

    if (isHomeAnon) {
        qq('.flex-section--carousel').forEach((element: HTMLElement) => {
            new Draggable(element);
        });

        if (q('.home-anon--new')) checkTopBannerPosition();

        homeEventsGA4();
    }
};
