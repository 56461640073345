/* eslint-disable @typescript-eslint/camelcase */
import { q, qq } from 'bobjoll/ts/library/dom';

import triggerManualGA4Events from './ga4.init-manual-events';

export const tagGA4RemoveAds = () => {
    qq('.ads-block')?.forEach((item: HTMLElement) => {
        const anchor = (q('a', item) as HTMLAnchorElement) || undefined;
        const parentElement = anchor?.parentElement?.parentElement;

        if (!parentElement) return;

        const iframe = q('iframe', parentElement);
        const location = q('.detail__test-ads-tag')
            ? 'resource_detail'
            : `search_results_list_${iframe?.dataset.googleContainerId}`;

        triggerManualGA4Events({ description: 'tagRemoveAds', options: { location } });

        anchor?.addEventListener('click', () => {
            triggerManualGA4Events({
                description: 'tagRemoveAds',
                options: {
                    location,
                    action: 'click',
                },
            });
        });
    });
};
