import { q, qq } from 'bobjoll/ts/library/dom';
import { cookie } from 'Project/ts/library/cookie';
import { isAdBlockEnabled } from 'Project/ts/library/helpers/adblock';
import { tagGA4RemoveAds } from 'Project/ts/partials/events/ga4.tag-remove-ads';
import { isPremium } from 'Project/ts/partials/user/user.helpers';

const configAds = {
    isDisableAds: FEATURE_DISABLE_ADS || isPremium(),
    showAdsTag: cookie.getItem('ads-tag') === 'b',
    showRemoveTag: FEATURE_ADD_TAG_REMOVE_ADS_BY_COUNTRY && cookie.getItem('tag-remove-ads') === 'b',
};

export const getConfigAds = Object.keys(configAds).find(key => configAds[key] === true);

export const getRatio = (height: number, width: number) => {
    return (height / width) * 100;
};

export const getScaleFactor = function(width: number): number {
    const screenWidth = 100;
    const screenHeight = 100;
    const screenAspectRatio = screenHeight / screenWidth;
    return screenAspectRatio - (screenWidth / width - screenHeight / screenHeight) * 100;
};

export const setTestSubscribeToRemoveAds = (selector: string, template: string, isDetailView: boolean) => {
    const observer = new MutationObserver((mutations: MutationRecord[], obs) => {
        let counter = 0;

        mutations.forEach(ad => {
            Array.from(ad.addedNodes).find((element: HTMLElement) => {
                if (element.matches && element.matches('iframe')) {
                    if (
                        element.parentElement?.offsetWidth &&
                        element.parentElement.offsetWidth > 1 &&
                        !element.parentElement?.parentElement?.classList.contains('ssm_adunit_container--ads')
                    ) {
                        element.parentElement.parentElement?.classList.add('ssm_adunit_container--ads');
                        element.insertAdjacentHTML('beforebegin', template);
                        if (isDetailView && element.offsetWidth < 320)
                            q('.ads-block', element.parentElement)?.classList.add('mg-left-lv2');
                        counter++;
                    }

                    if (counter === ads?.length) {
                        tagGA4RemoveAds();
                        obs.disconnect();
                    }
                }
            });
        });
    });

    const observerOptions = {
        childList: true,
        subtree: true,
    };

    const ads = qq(selector);
    ads.forEach(ad => {
        observer.observe(ad, observerOptions);
    });
};

export const setAdsTag = async (selector: string, isDetailView = false) => {
    if (isAdBlockEnabled() || getConfigAds === 'isDisableAds') return;

    if (getConfigAds === 'showRemoveTag') {
        const { default: templateGoPremium } = await import('Project/ts/templates/views/tag-ad-go-premium.hbs');
        setTestSubscribeToRemoveAds(selector, templateGoPremium({ PRICING_URL }), isDetailView);
    }
};
