/* eslint-disable @typescript-eslint/camelcase */
import { q, ww } from 'bobjoll/ts/library/dom';
import FrTracker from 'Library/tracker';
import navigationHistory from 'Partials/navigation-history';
import { gtm } from 'Partials/tracker-gtm';
import { UserHelpers } from 'Partials/user';
import { setCbPurchaseUrl, setCbUrl } from 'Partials/user/user.helpers';
import { view } from 'Partials/view';
import { cookie } from 'Project/ts/library/cookie';
import { getDomainName } from 'Project/ts/library/helpers';
import { pixelScriptPinterest } from 'Project/ts/library/pixels/pixel.pinterest';
import Tracker from 'Project/ts/library/tracker';
import { trackerDownload } from 'Project/ts/partials/download/download.recaptcha';
import { getDownloadTrackerViewData } from 'Project/ts/partials/download/download.tracker';

import { DetailApi } from '../api';
import { getDetail, isTruthy } from '../helpers/detail.helpers.generic';
import { mapFreepikTracker } from '../mappers/detail.mappers.tracker';
import { copyToClipboardActionTriggered } from '../partials/detail.partials.downloadCopy';

const resetFileTypeTrackerAfterDownload = (is3DModel: boolean) => {
    if (!is3DModel) {
        trackerDownload.button && trackerDownload.button.removeAttribute('data-type');
    }
};

const isDetailApiActive = (obj: any): obj is ReturnType<typeof DetailApi.getActive> => obj && typeof obj === 'object';

export const triggerDownloadTracker = function(modalCopyConfirm?: boolean, isEditButton?: boolean) {
    const viewData = getDetail() ? DetailApi.getActive() : getDownloadTrackerViewData();
    const userType = UserHelpers.type();
    const eventPinterest: IndexSignature = {};
    const fileUrl = viewData['download_file_url'] || viewData['fileUrl'];
    const is3DModel = viewData['type'] === 'model' || false;
    const isExternalIcon = viewData['type'] === 'icon' || false;
    const isFont = viewData['type'] === 'fonts' || false;
    const location = viewData['fromView'] || 'resource_detail';
    const fileTypeExtension = (trackerDownload.button && trackerDownload.button.dataset.type) || 'default';

    setCbUrl();
    setCbPurchaseUrl();

    gtm({ event: 'download', location }, false);

    if (isTruthy(viewData['premium'])) {
        gtm({ event: 'clic-download-premium' }, false);
    }

    if (isTruthy(viewData['premium']) && userType.match(/anonymous/gi)) {
        UserHelpers.isLogged().then(function() {
            if (UserHelpers.isPremium() || UserHelpers.isEssential()) {
                view.newPage({
                    history: false,
                    loader: false,
                    push: false,
                    views: ['detail'],
                    xhr: viewData as any,
                });
            }
        });
    }

    if (viewData['type']) eventPinterest.id = viewData['type'];
    pixelScriptPinterest.addEvent('custom', eventPinterest);

    if (isDetailApiActive(viewData)) {
        triggerDownloadGoogleEvents(viewData, modalCopyConfirm);

        if (userType === 'Premium' || is3DModel || isEditButton || isExternalIcon) {
            triggerDownloadLog(viewData, fileTypeExtension);
        }
    }

    if (fileUrl) {
        navigationHistory.addHistoryEntry('download', fileUrl);
    }

    isFont && ww.fontsTrackerDownload();

    resetFileTypeTrackerAfterDownload(is3DModel);
};

export const triggerDownloadIntentionTracker = function() {
    const viewData = DetailApi.getActive();

    triggerDownloadLog(viewData);
};

export const triggerDownloadCookie = function(id: string) {
    const cookieName = `ck_items_${UserHelpers.id() || 0}`;
    const cookieExpires = new Date().getTime() + 8 * 60 * 1000;
    const cookieValue = cookie.getItem(cookieName);

    try {
        const cookieData = cookieValue ? JSON.parse(cookieValue) : [];

        if (id && cookieData.indexOf(id) == -1) {
            cookieData.push(id);
        }

        cookie.setItem(cookieName, JSON.stringify(cookieData), {
            path: '/',
            domain: getDomainName(document.domain) || document.domain,
            expires: new Date(cookieExpires),
        });
    } catch (error) {
        console.log('Error setting cookie downloads: ', error);
    }
};

const triggerDownloadLog = function(viewData: ReturnType<typeof DetailApi.getActive>, fileTypeExtension?: string) {
    const FreepikTrackerArgs = mapFreepikTracker(viewData, fileTypeExtension ?? '');

    FrTracker.download2(FreepikTrackerArgs);
};

const triggerDownloadGoogleEvents = function(
    viewData: ReturnType<typeof DetailApi.getActive>,
    modalCopyConfirm?: boolean,
) {
    const {
        author: { name: authorName },
        id,
        premium = 0,
        selection = 0,
    } = viewData;
    const userIsPremium = UserHelpers.isPremium();
    const userIsEssential = UserHelpers.isEssential();
    const gaArgs = [];
    const hubspotArgs = [];
    const detail = getDetail();
    let selectedSizeParam = '';
    let copyParam = '';

    if (detail) {
        const selectedSizeElement = q('.size-selector .size.active span', detail) as HTMLSpanElement;
        copyParam = copyToClipboardActionTriggered() || modalCopyConfirm ? 'copy' : '';

        if (selectedSizeElement) {
            selectedSizeParam = `size=${selectedSizeElement.dataset.size}`;
        }
    }

    if (isTruthy(premium)) {
        if (userIsPremium || userIsEssential) {
            const downloadTypeLabel = userIsPremium ? 'premium' : 'essential';
            const downloadGaArguments = ['download', `download-${downloadTypeLabel}`, id];

            if (selectedSizeParam !== '') {
                downloadGaArguments.push(selectedSizeParam);
            } else if (copyParam !== '') {
                downloadGaArguments.push(copyParam);
            }

            gaArgs.push(downloadGaArguments);
            gaArgs.push(['download-attribution', 'direct-download', id]);
            hubspotArgs.push([{ category: 'detail-modal', action: `${downloadTypeLabel}-download`, label: id }]);
        } else {
            gaArgs.push(['download', 'go-premium', id]);
            hubspotArgs.push([
                {
                    category: 'detail-modal',
                    action: 'registered-click-premium-resource',
                    label: id,
                },
            ]);
        }
    } else {
        if (userIsPremium && !isTruthy(selection) && !isTruthy(premium)) {
            gaArgs.push(['download-attribution', 'direct-download', id]);
        }

        const downloadGaArguments = ['download', authorName, id];

        if (selectedSizeParam !== '') {
            downloadGaArguments.push(selectedSizeParam);
        } else if (copyParam !== '') {
            downloadGaArguments.push(copyParam);
        }

        gaArgs.push(downloadGaArguments);

        hubspotArgs.push([{ category: 'detail-modal', action: 'free-download', label: id }]);
    }

    gaArgs.forEach(args => Tracker.trackGAEventNoCache([...args]));
};
