import { q } from 'bobjoll/ts/library/dom';
import { localStorage } from 'bobjoll/ts/library/storage';
import { cookie } from 'Library/cookie';
import notify from 'Partials/notify';
import { UserHelpers } from 'Partials/user';
import { XHR } from 'Partials/xhr';
import { trackerInterface } from 'Project/ts/library/helpers/tracker';
import { isHomeAnon } from 'Project/ts/pages/home-anon';
import { xhr } from 'Service/xhr';

import { __ } from '../language';
import { addPaymentMethod } from './notifications.addPaymentMethod';
import { forceCloseNotification, overlayNotifications } from './notifications.helpers';
import { setPositionBannerTop, setPositionBannerTopByResize } from './notifications.helpers.position';
import { WinbackStatusResponse } from './notifications.winback.vm';

let isWinback: WinbackStatusResponse;

const winbackAPI = 'xhr/user/last-failed-payment';
const expireTimeAPI = 12 * 60 * 60 * 1000;

const MY_SUBSCRIPTION_URL_PARAMS = new URLSearchParams();
MY_SUBSCRIPTION_URL_PARAMS.append('wbor', '');

export const winbackNotifications = async (fromDownload = false): Promise<Boolean> => {
    if (cookie.getItem('debug_notification_winback')) {
        showWinbackNotifications(fromDownload);
        return true;
    }

    if (!UserHelpers.isPremium()) return false;

    const storage = localStorage.get('session', winbackAPI);
    const now = new Date().getTime();
    const storageHasExpired = storage && now - storage > expireTimeAPI;

    if (!storage || storageHasExpired) {
        return callAPI(fromDownload);
    }

    return false;
};

const callAPI = async (fromDownload: boolean) => {
    isWinback = await xhr(`${BASE_URL}${winbackAPI}`, {
        withCredentials: true,
        ...XHR.settings,
    });

    if (
        isWinback &&
        (isWinback.status === 'payment_failed_found' || isWinback.statusAffiliate === 'payment_failed_found')
    ) {
        showWinbackNotifications(fromDownload, isWinback.isFromIndia);
        return true;
    }

    localStorage.set('session', winbackAPI, new Date().getTime());

    return false;
};

export const showWinbackNotifications = (fromDownload: boolean, isFromIndia = false) => {
    showModal(fromDownload, isFromIndia);
    cookie.getItem('notify--winback-popup') && !fromDownload && showBannerTop(isFromIndia);
};

const showModal = async (fromDownload: boolean, isFromIndia: boolean) => {
    const id = 'winback-popup';
    const { default: template } = await import(`Project/ts/templates/notification/winback-modal.hbs`);

    MY_SUBSCRIPTION_URL_PARAMS.set('wbor', 'pop_up');

    if (fromDownload) {
        cookie.removeItem(`notify--${id}`);
        forceCloseNotification('top');

        q('.button--loading-download .text-downloading')?.remove();
        q('.button--loading-download')?.classList.remove('button--loading-download');

        MY_SUBSCRIPTION_URL_PARAMS.set('wbor', 'resource_detail');
    }

    const MY_SUBSCRIPTIONS_URL_WITH_PARAMS = `${MY_SUBSCRIPTIONS_URL}?${MY_SUBSCRIPTION_URL_PARAMS.toString()}`;

    forceCloseNotification('bottom-right');
    forceCloseNotification('bottom');

    notify.addNotification({
        html: template({
            MY_SUBSCRIPTIONS_URL_WITH_PARAMS,
            isFromIndia,
            isAffiliate: isWinback.lastFailedPaymentAffiliate,
        }),
        id,
        priority: 1,
        position: 'center',
        expires: () => new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        callback: (popup: HTMLElement) => {
            overlayNotifications(true);
            document.body.classList.add('overflow-hidden');

            const isFromDownload = fromDownload ? '-from-download' : '-first-time';
            trackerInterface.send('ga', 'send', 'event', 'unpaid', 'onload', `popup${isFromDownload}`);

            if (popup) {
                q('.notification__close', popup)?.addEventListener('click', () => {
                    trackerInterface.send('ga', 'send', 'event', 'unpaid', 'close', 'popup');

                    overlayNotifications(false);
                    document.body.classList.remove('overflow-hidden');
                    showBannerTop(isFromIndia);
                });

                if (
                    FEATURE_SHOW_PAYMENT_UPDATE &&
                    !isFromIndia &&
                    isWinback?.htmlFormPaymentMethod?.htmlPaymentMethod
                ) {
                    q('a', popup)?.addEventListener('click', (e: Event) => {
                        e.preventDefault();
                        q('.notification__close', popup)?.click();

                        addPaymentMethod(isWinback.htmlFormPaymentMethod);
                    });
                }
            }
        },
    });

    notify.printQueue();
};

const showBannerTop = async (isFromIndia: boolean) => {
    const id = 'winback-banner-top';
    if (q(`#${id}`)) return;

    const { default: template } = await import('Project/ts/templates/notification/winback-topBanner.hbs');

    cookie.removeItem(`notify--${id}`);
    forceCloseNotification('bottom-right');
    forceCloseNotification('bottom');

    MY_SUBSCRIPTION_URL_PARAMS.set('wbor', 'header');

    const MY_SUBSCRIPTIONS_URL_WITH_PARAMS = `${MY_SUBSCRIPTIONS_URL}?${MY_SUBSCRIPTION_URL_PARAMS.toString()}`;

    notify.addBanner({
        priority: 0,
        position: 'top',
        id,
        class: 'notification--hide-disable notify--banner-danger',
        html: template({
            MY_SUBSCRIPTIONS_URL_WITH_PARAMS,
            isFromIndia,
            isAffiliate: isWinback.lastFailedPaymentAffiliate,
        }),
        callback: async (banner: HTMLElement) => {
            trackerInterface.send('ga', 'send', 'event', 'unpaid', 'onload', 'banner-top');

            if (FEATURE_SHOW_PAYMENT_UPDATE && !isFromIndia && isWinback?.htmlFormPaymentMethod?.htmlPaymentMethod) {
                q('a', banner)?.addEventListener('click', (e: Event) => {
                    e.preventDefault();
                    addPaymentMethod(isWinback.htmlFormPaymentMethod);
                });
            }

            if (isHomeAnon && gr && gr.user) {
                setPositionBannerTop(banner);
                setPositionBannerTopByResize(id);
            }
        },
    });

    notify.printQueue();
};

export const showPendingBannerTop = async () => {
    forceCloseNotification('top');

    const id = 'winback-pending-banner-top';
    const { default: template } = await import(`Project/ts/templates/notification/winback-pending-topBanner.hbs`);

    MY_SUBSCRIPTION_URL_PARAMS.set('wbor', 'header');

    const MY_SUBSCRIPTIONS_URL_WITH_PARAMS = `${MY_SUBSCRIPTIONS_URL}?${MY_SUBSCRIPTION_URL_PARAMS.toString()}`;

    notify.addBanner({
        priority: 0,
        position: 'top',
        id,
        class: 'notification--hide-disable notify--banner-danger notify--banner-blue',
        html: template({
            MY_SUBSCRIPTIONS_URL_WITH_PARAMS,
        }),
        callback: async (banner: HTMLElement) => {
            if (isHomeAnon && gr && gr.user) {
                setPositionBannerTop(banner);
                setPositionBannerTopByResize(id);
            }
        },
    });

    notify.printQueue();
};
