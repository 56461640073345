import { q } from 'bobjoll/ts/library/dom';
import { DetailApi } from 'Partials/detail-v2/api';

import triggerManualGA4Events from './ga4.init-manual-events';
import { ManualEvent } from './ga4.model';

export const ga4EventsMonthlyDownloads = (settings: resourceSettings) => {
    if (USER_TYPE === 'premium') return;

    const selector = USER_TYPE === 'anonymous' ? '.download-login-action' : '.download-button';
    const config: ManualEvent = {
        description: 'downloadButtonsMonthly',
        options: {
            location: 'resource_detail_step1',
            file_id: settings.id,
            type: settings.type,
            license: settings.premium,
        }
    };

    if (USER_TYPE === 'anonymous') config.options.event_name = 'button_login_to_download';

    triggerManualGA4Events(config);

    q(selector, settings.container)?.addEventListener('click', () => {
        config.options.location = 'resource_detail_step2';
        triggerManualGA4Events(config);
    });
}

export const ga4EventsMonthlyLimitReached = () => {
    if (q('main.download')) return;

    q('.limit-reached')?.addEventListener('click', () => {
        const data = DetailApi.getActive();

        triggerManualGA4Events({
            description: 'downloadButtonsMonthly',
            options: {
                event_name: 'button_download_limit_reached',
                file_id: data.id,
                type: data.type,
                license: data.premium,
            }
        });
    });
}

export const ga4EventsMonthlyDownloadPage = (limit = false) => {
    if (!q('main.download')) return;

    const resource = q('.download-button, .download-login-action');
    const container = resource && resource.parentElement;

    if (resource && container) {
        const label = limit
            ? 'download_limit_reached'
            : `${resource.classList.contains('download-login-action') ? 'login_to_' : ''}download`;

        const config: ManualEvent = {
            description: 'downloadButtonsMonthly',
            options: {
                event_name: `button_${label}`,
                location: 'landing_download_error',
                file_id: resource.dataset.id || '',
                type: resource.dataset.type || '',
                license: resource.dataset.premium === '1',
            }
        };

        const element = limit ? container : resource;
        element.addEventListener('click', () => triggerManualGA4Events(config));
    }
}

interface resourceSettings {
    container: HTMLElement;
    id: string;
    type: string;
    premium: boolean;
}
