import { sessionStorage } from 'bobjoll/ts/library/storage';

class NavigationHistory {
    private history: string[];
    private historyNS = 'navigation-history';
    private historyKey = 'history';
    private historyMax = 10;
    public readonly abandonedCartHistoryLimit: number = 3;
    public readonly campaignHistoryLimit: number = 8;

    constructor() {
        this.history = sessionStorage.get(this.historyNS, this.historyKey) || [];
    }

    public getHistoryLength() {
        return this.history ? this.history.length : 0;
    }

    public addHistoryEntry(view: string, url?: string | undefined) {
        this.history.unshift(
            btoa(
                JSON.stringify({
                    view,
                    url: url || window.location.href,
                }),
            ),
        );

        this.history.length > this.historyMax && this.history.pop();
        sessionStorage.set(this.historyNS, this.historyKey, this.history);
    }
}

export default new NavigationHistory();
