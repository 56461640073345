import { InsertSettings } from 'bobjoll/ts/partials/alert-v2.0';

import notify from '../notify';
import { forceCloseNotification } from './notifications.helpers';

const alertV2 = () => import('bobjoll/ts/partials/alert-v2.0');

export const copyNotSupportedMobileNotification = async () => {
    const id = 'copy-not-allowed-browser';

    forceCloseNotification('top');

    const template = await import('Project/ts/templates/notification/copy-image-not-allowed.hbs').then(
        mod => mod.default,
    );

    notify.addBanner({
        priority: 0,
        position: 'top',
        id,
        class: 'copy-not-allowed-notification',
        html: template(),
        ignoreClose: true,
    });

    notify.printQueue();
    window.scrollTo(0, 0);
};

export const showCopyAlert = async function(content: string, title: string, error: boolean): Promise<void> {
    forceCloseNotification(IS_MOBILE ? 'bottom-right' : 'top-right');
    const alert = await alertV2();

    const insertAlert: InsertSettings = {
        content,
        title,
        fixed: true,
        type: error ? 'error' : 'info',
    };

    new alert.default({ position: IS_MOBILE ? 'bottom-right' : 'top-right' }).new(insertAlert);
};
