import { q, qq } from 'bobjoll/ts/library/dom';
import { capitalize, numberFormat, numberFormatHundred, numberNormalizer, shuffleArray } from 'Library/helpers';
import { SwiperInitialize } from 'Library/swiper';
import Metas from 'Partials/metas';
import Sponsor from 'Partials/sponsor';
import ga from 'Partials/tracker';
import { view, ViewPrint } from 'Partials/view';
import fetch, { XHR } from 'Partials/xhr';
import { Optimize } from 'Project/ts/common/optimize';
import { Arguments, ArgumentsDictionary } from 'Project/ts/library/arguments';
import Cache from 'Project/ts/library/cache';
import { pixelScriptPinterest } from 'Project/ts/library/pixels/pixel.pinterest';
import Tracker from 'Project/ts/library/tracker';
import { sprintf } from 'sprintf-js';

import { isAuthorSearch } from './author/controller';

export class Search {
    private static randomSponsorPopup = 0.2;
    private static cacheExpirationDefault = 1800;
    public static readonly api = BASE_URL + 'xhr/search';
    public static readonly action = BASE_URL + 'search';
    public static active: ViewPrint;
    public static author = q('#author') as HTMLFormElement | undefined;
    public static authorSlug = q('#author-name') as HTMLFormElement | undefined;
    public static form = q('#search') as HTMLFormElement | undefined;
    public static input = q('#search-value') as HTMLInputElement | undefined;
    public static inputFake = q('#search-value-fake') as HTMLInputElement | undefined;
    public static nextSearch = q('#next-search') as HTMLInputElement | undefined;
    public static queryAuthor = q('#query-author') as HTMLInputElement | undefined;
    public static lastFiltersCheckedStorage = {
        key: 'last-view',
        namespace: 'filters',
    };
    public static defaultFilters = {
        dates: 'any',
        demographic: 'any-people',
        sort: 'popular',
    };

    public static focus() {
        if (!isAuthorSearch) {
            setTimeout(() => {
                [q('#search-value'), q('#search-value-fake')].forEach(input => {
                    if (input) {
                        input.focus();
                        input.blur();
                        input.focus();
                    }
                });
            }, 250);
        }
    }

    public static new(location: string, params: string) {
        const filter = Arguments.stringToObject(params);

        if (!location.match(/http|https/gi)) {
            location = window.location.protocol + location;
        }

        const initializeFilter = async () => {
            const url = Search.api + '?' + params;

            Search.active = {
                views: ['search'],
                key: url,
                url: url,
                location: location,
                push: true,
                setPage: true,
            };

            if (filter.format) {
                Search.active.views = filter.format.split(',');

                Search.setFormat(filter.format as any);

                if (filter.author) {
                    Search.active.views = ['author'];

                    if ('collections' === filter.format) {
                        const { default: template } = await import('Project/ts/templates/views/author-collections.hbs');
                        Search.active.template = template;
                    }
                }
            }

            view.newPage(Search.active);
        };

        if (filter) {
            initializeFilter();
        }
    }

    public static async getData(options: ViewPrint) {
        if (!options.url) return;

        try {
            const response = await fetch(options.url, XHR.settings, true, response => Search.parseData(response));
            if (response && options) Search.nextSearchUrl(response, options);
            const parsed = {
                ...response,
                ...Search.parseShutterstock(options),
                ...Search.parseRelatedKeywords(response),
            };
            Search.active = { ...options, xhr: parsed };
            return parsed;
        } catch (err) {
            throw err;
        }
    }

    private static nextSearchUrl(response: any, options: ViewPrint) {
        if (response.meta && response.meta.from_query && response.meta.search_href) {
            // fixme: No he encontrado ninguna forma mejor de solucionar el problema de la tarea FP-7166,
            // resulta ser un problema de caché, y es por la separación de las palabras en PHP, que la url se convierte en %20
            const linkSearch = response.meta.search_href.split('+').join('%20');
            options.key = `${Search.api}?${linkSearch}`;
            options.url = `${Search.api}?${linkSearch}`;
        }
    }

    public static getMetas(options: ViewPrint) {
        const query = options.getPathValue('xhr.meta.query');
        const filter = Arguments.stringToObject(options.url || '');
        const format = filter.getPathValue('format') || options.views.shift();
        const type = filter.getPathValue('type');
        const sort = filter.getPathValue('sort');

        let path = `search.search`;

        if ((format || '').match(/category|popular/) && type) {
            path = `category.${query ? `subcategory_${type}` : `category_${type}`}`;
        }

        if ((format || '').match(/category/) && !type && 'popular' === sort) {
            path = `category.popular`;
        }

        if ('keyword' === format) {
            path = `keyword.keywords`;
        }

        if ('keyword' === format && type) {
            path = `keyword.keywords_by_${type}`;
        }

        if ('author' === format) {
            path = `author.author`;
        }

        return {
            path: path,
            strings: Metas.get(path),
        };
    }

    public static getPage(url: string) {
        const settings: SearchPageResult = {
            set: false,
            url: null,
            location: null,
        };

        if (Search && Search.active && Search.active.url && url !== Search.active.url) {
            const current = Arguments.stringToObject(url);
            const currentKeys = Object.keys(current);
            const previous = Arguments.stringToObject(Search.active.url);
            const previousKeys = Object.keys(previous);

            if (currentKeys && previousKeys) {
                const keys = (currentKeys.concat(previousKeys) as any).unique();
                const changes: string[] = [];

                keys.forEach((key: string) => {
                    const p = previous[key];
                    const c = current[key];
                    if ((p && c && p !== c) || (!p && c) || (!c && p)) changes.push(key);
                });

                if (current && current.page && changes.length > 0) {
                    if (changes.indexOf('page') === -1 || current.format !== previous.format) current.page = '1';

                    settings.set = true;
                    settings.url = Search.api + '?' + Arguments.objectToString(current);
                    settings.location = BASE_URL + 'search?' + Arguments.objectToString(current);
                }
            }
        } else settings.set = true;

        return settings;
    }

    public static getFormat(): FilterFormat {
        const checked = (qq('#search input[name="format"]') as HTMLInputElement[]).filter(input => input.checked).pop();

        return checked ? (checked.value as any) : 'search';
    }

    public static setFormat(format: FilterFormat, dispatchEvent = false) {
        const inputFormatElements = qq('#search input[name="format"]') as HTMLInputElement[];
        inputFormatElements.forEach(input => {
            if (format === input.value) {
                input.checked = true;
                if (dispatchEvent) {
                    input.dispatchEvent(new Event('change'));
                }
            }
        });

        return format;
    }

    public static cleanAdvancedSearchParams(searchTerms: string) {
        const terms = searchTerms.split(' -');

        return terms[0].trim();
    }

    public static setFilters(options: ViewPrint, viewType: string) {
        const query = options.getPathValue('xhr.meta.query');
        const fromQuery = options.getPathValue('xhr.meta.from_query');
        const author = options.getPathValue('xhr.meta.author');
        const search = q('#search-value') as HTMLInputElement;
        const filters = Arguments.stringToObject(options.url || options.key || '');
        const filtersDictionary: { [name: string]: string[] } = {};

        if (search) {
            const searchValue = search.value ? search.value : '';
            if (FEATURE_ADVANCED_SEARCH_BY_LANGUAGE) {
                const value = Search.cleanAdvancedSearchParams(
                    fromQuery ? fromQuery : query && query !== false ? query : searchValue,
                );
                search.value = value;
                search.setAttribute('value', value);
            } else {
                search.value = fromQuery ? fromQuery : query && query !== false ? query : searchValue;
                search.setAttribute('value', search.value);
            }
        }
        Object.keys(filters).forEach(name => (filtersDictionary[name] = filters[name].split(',')));
        if (Search.active && Search.active.setPage) {
            filtersDictionary.page = [options.getPathValue('xhr.paging.page')];
        }
        if (author) {
            filtersDictionary.format = [viewType];
            filtersDictionary.author = [author.user_id || ''];
            filtersDictionary.authorSlug = [author.name || ''];
        } else if ('collections' !== viewType) {
            filtersDictionary.format = ['search'];
        } else if ('collections' === viewType) {
            filtersDictionary.format = ['collections'];
        }

        if (Search.nextSearch && fromQuery && options.url) {
            if (query && Math.random() <= Search.randomSponsorPopup) {
                Sponsor.popup(query, 'search');
            }

            const urlPreviousNextSearch = options.url;
            let cache = Cache.get(urlPreviousNextSearch);
            if (cache) {
                Cache.Remove(urlPreviousNextSearch);
            } else {
                const initialFiltersDictionary = {
                    ...filtersDictionary,
                    query: filtersDictionary.from_query,
                };

                delete initialFiltersDictionary['from_query'];

                const initialFilterString = Arguments.objectToString(initialFiltersDictionary);
                const cacheKey = `${Search.api}?${initialFilterString}`;
                cache = Cache.get(cacheKey);
            }

            Search.nextSearch.value = fromQuery;
            filtersDictionary.from_query = fromQuery;
            filtersDictionary.query = query;

            let newUrl = Object.keys(filtersDictionary)
                .sort()
                .map(key => `${key}=${filtersDictionary[key]}`)
                .join('&');

            newUrl = `?${newUrl.includes('page=false') ? newUrl.replace('page=false', 'page=1') : newUrl}`;
            options.key = `${Search.api}${newUrl}`;
            options.url = `${Search.api}${newUrl}`;
            options.location = `${Search.action}${newUrl}`;

            const showcaseElement = q('#main .showcase');
            const cacheExpiration = showcaseElement
                ? numberNormalizer(showcaseElement.dataset.cacheControl || Search.cacheExpirationDefault.toString())
                : Search.cacheExpirationDefault;

            if (cache) {
                Cache.set({ key: options.url, value: cache, time: cacheExpiration });
            }
        }

        SwiperInitialize({
            selector: '.swiper-search',
            onChange: () => setTimeout(() => Lazyload.run(), 150),
            isCollection: true,
        });
    }

    public static async setMetas(options: ViewPrint) {
        const main = q('#main');

        if (!main) return;

        const metas = Search.getMetas(options);
        const title = q('title', document.head || document.body);
        const author = capitalize(options.getPathValue('xhr.meta.author.name') || '');
        const authorAvatar = options.getPathValue('xhr.meta.author.avatar') || '';
        const queryMeta = options.getPathValue('xhr.meta.query') || '';
        const paging = options.getPathValue('xhr.paging');
        const pagingTotal = options.getPathValue('xhr.meta.summary.total') || 0;
        const authorTotalResources = options.getPathValue('xhr.meta.author.total_resources') || 0;
        const thumbnail = options.getPathValue('xhr.data.resources.0.thumbnails.large');
        const imageTitle = options.getPathValue('xhr.data.resources.0.title');
        const query = capitalize(queryMeta.toString());

        const metasVariables: MetaGlobalVariables = {
            'category_(.[a-zA-Z]*)': {
                title: [numberFormatHundred(pagingTotal, true)],
                'og:title': [numberFormatHundred(pagingTotal, true)],
                'og:image': [thumbnail],
                'og:image:alt': [imageTitle],
                'og:url': [window.location.href],
                'twitter:url': [window.location.href],
                'twitter:title': [numberFormatHundred(pagingTotal, true)],
                'twitter:image:src': [thumbnail],
            },
            'subcategory_(.[a-zA-Z]*)': {
                'og:image': [thumbnail],
                'og:image:alt': [imageTitle],
                'og:url': [window.location.href],
                'twitter:url': [window.location.href],
                'twitter:image:src': [thumbnail],
            },
            search: {
                'og:image': [thumbnail],
                'og:image:alt': [imageTitle],
                'og:url': [window.location.href],
                'og:description': [numberFormat(pagingTotal)],
                'twitter:url': [window.location.href],
                'twitter:image:src': [thumbnail],
                'twitter:description': [numberFormat(pagingTotal)],
            },
            author: {
                title: [author],
                description: [author],
                keywords: [author.toLowerCase()],
                'og:title': [author],
                'og:image': [authorAvatar || thumbnail],
                'og:image:alt': [imageTitle],
                'og:url': [window.location.href],
                'og:description': [numberFormat(authorTotalResources)],
                'twitter:url': [window.location.href],
                'twitter:title': [author],
                'twitter:image:src': [authorAvatar || thumbnail],
                'twitter:description': [numberFormat(authorTotalResources)],
            },
            keywords: {
                'og:image': [thumbnail],
                'og:image:alt': [imageTitle],
                'og:url': [window.location.href],
                'twitter:url': [window.location.href],
                'twitter:image:src': [thumbnail],
            },
            'keywords_by_(.[a-zA-Z]*)': {
                'og:image': [thumbnail],
                'og:image:alt': [imageTitle],
                'og:url': [window.location.href],
                'twitter:url': [window.location.href],
                'twitter:image:src': [thumbnail],
            },
        };
        const metaVariables = Object.keys(metasVariables).reduce((acc: MetaGlobalVariables['key'], key: string) => {
            const re = new RegExp(key);

            if (re.exec(metas.path)) {
                acc = metasVariables[key];
            }

            return acc;
        }, <MetaGlobalVariables['key']>{});
        const globalVariables = {
            default: [query, numberFormatHundred(NUM_RESOURCES)],
        };

        try {
            if (title && (query || author || metaVariables['title']) && metas.strings.getPathValue('title')) {
                title.innerText = sprintf.apply(undefined, [
                    metas.strings.title,
                    ...(metaVariables['title'] || globalVariables.default),
                ]);
            }

            if (title && metas.strings.raw) {
                title.innerText = metas.strings.title;
            }

            if (metas) {
                Metas.clear();
                metas.strings.metas.forEach((meta: { name?: string; property?: string; content: string }) => {
                    const settings: {
                        name: string;
                        content: string;
                        property?: string;
                    } = {
                        name: meta.name || '',
                        content: '' !== meta.content ? meta.content : '',
                    };
                    const args = metaVariables[meta.name || meta.property || ''] || globalVariables.default;

                    if (typeof settings.content === 'string' && settings.content.match(/\%/gi)) {
                        args.unshift(settings.content);
                        settings.content = sprintf.apply(null, args);
                    } else if (metaVariables[meta.name || meta.property || ''] && 0 == settings.content.length) {
                        settings.content = args.join(', ');
                    }

                    if (meta.property) {
                        settings.property = meta.property;
                    }

                    Metas.add(settings);
                });
            }
        } catch (err) {
            console.error(err);
        }

        if (paging) {
            if (paging.previous) {
                Metas.add({
                    rel: 'prev',
                    href: `${window.location.host}${paging.previous}`,
                });
            }

            if (paging.next) {
                Metas.add({
                    rel: 'next',
                    href: `${window.location.host}${paging.next}`,
                });
            }
        }
    }

    public static track(query: string) {
        ga('send', 'event', 'busquedas', `_${LANGUAGE_NAME}`, query);
    }

    public static clearDefaultFilters(filterDictionary: ArgumentsDictionary) {
        Object.keys(Search.defaultFilters).forEach(key => {
            if (filterDictionary[key] === Search.defaultFilters[key]) {
                delete filterDictionary[key];
            }
        });
    }

    public static tracker(format: FilterFormat, filters: ArgumentsDictionary) {
        pixelScriptPinterest.addEvent('search', {
            search_query: filters.query,
        });

        if (format === 'collections') {
            Tracker.trackGAEventNoCache(['collections', 'search', filters.query]);
        }
    }

    public static parseData(response: JSON) {
        const data = {
            ...response,
            ...Search.parseBoostedResources(response),
        };

        return data;
    }

    public static parseBoostedResources(response: JSON) {
        const boostedSearch = response.getPathValue('meta.multi');

        if (boostedSearch) {
            try {
                const resources = response.getPathValue('data.resources');

                if (resources) {
                    const boost = resources['boost'] || [];
                    const latestBoost = shuffleArray(resources['latest_boost']);
                    const premium = resources['premium'] || [];
                    const essential = resources['essential'] || [];

                    if (boost) {
                        const ignorePositions = FIXED_RESOURCES_IN_RESULTS;

                        if (boost.length > 0) {
                            for (let i = 0; i < boost.length; i++) {
                                const item = boost[i];

                                item.cacheId = i;
                                item.cacheArr = 'boost';
                            }
                        }

                        if (latestBoost && latestBoost.length > 0) {
                            for (let i = 0; i < latestBoost.length; i++) {
                                const item = latestBoost[i];

                                if (item) {
                                    const pos = Math.floor(
                                        Math.random() * (boost.length - 1 - ignorePositions) + ignorePositions,
                                    );

                                    item.cacheId = i;
                                    item.cacheArr = 'latest_boost';

                                    boost.splice(pos, 0, item);
                                }
                            }
                        }

                        if (premium && premium.length) {
                            premium.forEach((item: { [name: string]: any }, index: number) => {
                                const pos = Math.floor(
                                    Math.random() * (boost.length - 1 - ignorePositions) + ignorePositions,
                                );

                                item.cacheId = index;
                                item.cacheArr = 'premium';

                                boost.splice(pos, 0, item);
                            });
                        }

                        if (essential && essential.length) {
                            essential.forEach((item: { [name: string]: any }, index: number) => {
                                const pos = Math.floor(Math.random() * (boost.length - 1 - 0) + 0);

                                item.cacheId = index;
                                item.cacheArr = 'essential';

                                boost.splice(pos, 0, item);
                            });
                        }
                    }

                    (response as any).data.resources = boost;
                }
            } catch (e) {
                if ('undefined' !== typeof debug && debug.printEvent) {
                    console.error(e);
                } else {
                    window.location.reload();
                }
            }
        }

        return response;
    }

    public static async parseShutterstock(options: ViewPrint) {
        const experiment = Optimize.get('Cl7DTNHBRF6WrUVO__B5wQ') || 0;
        if (experiment != 0 || !options.url) return;
        const filterDictionary: IndexSignature = Arguments.stringToObject(options.url);
        if (filterDictionary.getPathValue('premium')) return;
        const { SponsorAds } = await import(
            /* webpackChunkName: "sponsor-invent" */ 'Project/ts/partials/sponsor-ads/sponsor-ads'
        );
        const shutterstock = SponsorAds.preload({
            override: { referer_page: 'search' },
            url: options.url,
            sendTracker: true,
        });
        return { shutterstock };
    }

    public static parseRelatedKeywords(response: any) {
        const relatedKeywords: SearchMetaRelatedKeyword[] = response.getPathValue('meta.related_keywords') || [];
        const relatedQueries: SearchMetaRelatedKeyword[] = response.getPathValue('meta.related_queries') || [];
        const viewType = view.getView();
        const filterType = ((response.getPathValue('meta.filters') || []) as SearchMetaFiltersResponse[])
            .filter(filter => filter.key === 'type')
            .map(filter => filter.value);
        if (response.meta && response.meta.related_keywords) {
            response.meta.related_keywords = relatedKeywords.reduce((keywords, data: SearchMetaRelatedKeyword) => {
                (data.name = `${data.name}`), (data.url = `${BASE_URL + data.path}/${data.slug}`);
                data.queryUrl = `query=${data.slug}&format=${viewType || 'search'}&sort=popular`;
                if ('all_resources' !== data.type) {
                    data.queryUrl += `&type=${data.type}`;
                }
                keywords.push(data);
                return keywords;
            }, [] as SearchMetaRelatedKeyword[]);
        }
        if (response.meta && response.meta.related_queries) {
            response.meta.related_queries = relatedQueries.reduce((queries, query) => {
                query.queryUrl = `query=${query.slug}&format=keyword&sort=popular`;
                try {
                    if (filterType.length && response.meta.related_keywords.length) {
                        query.queryUrl += `&type=${filterType.join(',')}`;
                    }
                } catch (err) {
                    console.warn(err);
                }
                queries.push(query);
                return queries;
            }, [] as SearchMetaRelatedKeyword[]);
        }
        return response;
    }

    public static getValue() {
        return Search.input ? Search.input.value.trim() : '';
    }
}

export type FilterFormat = 'search' | 'collections' | 'author';

interface MetaGlobalVariables {
    [name: string]: { [name: string]: any[] };
}

interface SearchPageResult {
    set: boolean;
    url: string | null;
    location: string | null;
}

interface SearchMetaRelatedKeyword {
    name: string;
    name_prefix: string;
    slug: string;
    url?: string;
    path: string;
    queryUrl?: string;
    type?: 'all_resources' | 'photo' | 'psd';
    bg_image: string;
    bg_color: string;
}

interface SearchMetaFiltersResponse {
    name: string;
    key: string;
    value: string;
}
