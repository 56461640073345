/* eslint-disable @typescript-eslint/camelcase */
import { q, qq } from 'bobjoll/ts/library/dom';
import { isPanoramicImage } from 'Library/helpers';
import { isAdBlockEnabled } from 'Library/helpers/adblock';
import Tracker from 'Library/tracker';
import { showCampaignBanner } from 'Partials/campaign-php/campaign.helpers';
import triggerManualGA4Events from 'Partials/events/ga4.init-manual-events';
import { ga4EventsMonthlyDownloads, ga4EventsMonthlyLimitReached } from 'Partials/events/ga4.monthlyLimit';
import { __ } from 'Partials/language';
import { UserHelpers } from 'Partials/user';
import { isFreepikAuthor } from 'Partials/user/user.helpers';
import { ViewPrint } from 'Partials/view';
import { Optimize } from 'Project/ts/common/optimize';
import { trackCollectionResourceDownload } from 'Project/ts/partials/collections/collections-pixel-events';

import { initContentBanner } from '../../adManager';
import { initCampaignBanner } from '../../campaign-php/campaign.events';
import { handlerDownload } from '../../download/download.component';
import { gtm } from '../../tracker-gtm';
import { DetailApi } from '../api';
import { setDetailCountDownloads } from '../helpers/detail.helpers.downloadButton';
import { getDetail, isTruthy } from '../helpers/detail.helpers.generic';
import { triggerDownloadIntentionTracker } from '../trackers/detail.trackers.download';
import { initializeAttribute } from './detail.partials.attribute';
import { initializeCopyImageToClipboard, initializeJPGSizeSelector } from './detail.partials.downloadCopy';
import { initializeDownloadFileTypes } from './detail.partials.downloadFileTypes';
import { enableDownloadSelector, initializeDownloadSelector } from './detail.partials.downloadSelector';
import { enableDownloadSizes } from './detail.partials.downloadSize';
import { initializeReport } from './detail.partials.report';

const common: IndexSignature = {};
const globalCampaignAttribution = showCampaignBanner('attribution') && GLOBAL_CAMPAIGN;

const initializeFreeDownload = async function() {
    const detailElement = getDetail();
    const { id, value } = Optimize.getDetailExperiment();
    const { default: template } = await import('Templates/parts/detail-download-variation.hbs');
    const is3DModel = detailElement?.dataset.resourceType && detailElement?.dataset.resourceType === 'model';

    if (detailElement && !is3DModel) {
        const rowDownload = '.selection-download-wrapper';

        const detailDownloadElement = q(rowDownload, detailElement);
        const detailDownloadButtonElement = q('.selection-download-wrapper .button', detailElement);

        if (detailDownloadElement && detailDownloadButtonElement) {
            ['click', 'auxclick'].forEach(eventType => {
                detailDownloadButtonElement.addEventListener(eventType, function() {
                    const globalCampaign = showCampaignBanner('download') && GLOBAL_CAMPAIGN;
                    const data = DetailApi.getActive();
                    const { id: resourceId, type: resourceType } = data;

                    let downloadOptions = q('.download-options', detailDownloadElement);

                    if (!downloadOptions) {
                        const disableForAnonymous = DISABLE_ANONYMOUS_DOWNLOADS && USER_TYPE === 'anonymous';
                        const limitReached =
                            FEATURE_MONTHLY_DOWNLOAD_LIMIT_BY_COUNTRY &&
                            USER_TYPE === 'free' &&
                            UserHelpers.isUserLimitReached();

                        detailDownloadElement.insertAdjacentHTML(
                            'beforeend',
                            template({
                                ...data,
                                ...DetailApi.getGlobals(data),
                                downloadSelector: enableDownloadSelector(data),
                                enableDownloadSizes: enableDownloadSizes(data),
                                globalCampaign,
                                disableForAnonymous,
                                limitReached,
                            }),
                        );

                        downloadOptions = q('.download-options', detailDownloadElement);
                    }

                    if (downloadOptions) {
                        const downloadOptionsClose = q('.download-options__close', downloadOptions);

                        downloadOptions.classList.add('show');

                        downloadOptionsClose?.addEventListener('click', () =>
                            downloadOptions?.classList.remove('show'),
                        );
                    }

                    globalCampaign && initCampaignBanner(detailDownloadElement, 'download');

                    triggerManualGA4Events({
                        description: 'detailGoPremiumDownloadOptions',
                        options: {
                            category: 'premium',
                            resource_premium: data.premium,
                            resource_id: resourceId,
                            resource_type: resourceType,
                        },
                    });

                    gtm({
                        event: 'modalItemOptions',
                        addToUrl: 'downloadOptions',
                    });

                    Tracker.log('send', 'event', 'modal_download', 'view', `${id}/${value}`);

                    qq('.download-button', common.modalDownloadOptions || document).forEach(buttonElement =>
                        buttonElement.addEventListener('click', () =>
                            Tracker.log('send', 'event', 'modal_download', 'click', `${id}/${value}`),
                        ),
                    );
                    qq('.download-button', common.modalDownloadOptions || document).forEach(buttonElement =>
                        buttonElement.addEventListener('click', handlerDownload),
                    );

                    q('.download-go-premium', common.modalDownloadOptions || document)?.addEventListener(
                        'click',
                        event => {
                            if (
                                event.currentTarget instanceof HTMLButtonElement &&
                                event.currentTarget?.dataset.resourceId
                            ) {
                                window.location.href = `${PRICING_URL}/${event.currentTarget.dataset.resourceId}?origin=freepik_web`;
                            }

                            triggerManualGA4Events({
                                description: 'detailGoPremiumDownloadOptions',
                                options: {
                                    action: 'click',
                                    resource_premium: data.premium,
                                    resource_id: resourceId,
                                    resource_type: resourceType,
                                },
                            });
                        },
                    );

                    triggerDownloadIntentionTracker();

                    initializeAttribute(common.modalDownloadOptions);
                    initializeJPGSizeSelector(detailElement);
                    initializeCopyImageToClipboard(detailDownloadElement);

                    if (FEATURE_DOWNLOAD_FILE_TYPES) {
                        initializeDownloadSelector('types', () => initializeDownloadFileTypes(data.id, detailElement));
                    }

                    if (FEATURE_MONTHLY_DOWNLOAD_LIMIT_BY_COUNTRY) {
                        setDetailCountDownloads();
                        ga4EventsMonthlyDownloads({
                            container: common.modalDownloadOptions || document,
                            id: resourceId,
                            type: resourceType,
                            premium: data.premium,
                        });
                    }
                });
            });
        }

        if (FEATURE_MONTHLY_DOWNLOAD_LIMIT_BY_COUNTRY) {
            ga4EventsMonthlyLimitReached();
        }
    }
};

export const initializeDownloadConfirmation = () => {
    const detailElement = getDetail();
    const detailConfElement = q('.detail__download-confirmation', detailElement || document);

    if (detailElement && detailConfElement) {
        const viewData = DetailApi.getActive();
        const globals = DetailApi.getGlobals(viewData);
        const imageHeight = detailElement.dataset.h ? parseInt(detailElement.dataset.h) : 0;
        const imageWidth = detailElement.dataset.w ? parseInt(detailElement.dataset.w) : 0;
        const isPanoramic = isPanoramicImage(imageWidth, imageHeight);
        const textFlaticon = __('%sNow with Flaticon exclusive content.%s', '<p class="text">', '</p>');
        const textBanner = __('Join our Premium family, outsmart deadlines, and live a hassle-free life');
        const downloadLicenseUrl =
            detailElement.dataset.selection === '2'
                ? 'https://creativecommons.org/licenses/'
                : detailElement.dataset.downloadLicenseUrl;
        const copyButtonText = isFreepikAuthor(detailElement.dataset.authorId ?? '')
            ? __('Image by %s', `<a href="${window.location.href}">Freepik</a>`)
            : __(
                  '%sImage by %s%s on %s',
                  `<a href="${window.location.href}">`,
                  detailElement.dataset.authorName ?? '',
                  '</a>',
                  'Freepik',
              );

        if (!viewData['user'] && gr && gr.user) {
            viewData['user'] = gr.user;
        }

        const title = __('Forget about attribution');
        const text = `${textBanner} ${textFlaticon}`;
        const button = __('Go Premium');

        const banner = {
            class: 'attribution',
            small: true,
            title,
            text,
            button,
        };

        q('.download-options')?.classList.remove('show');

        const viewTemplate = require('../views/detail.views.modalDownloadConfirmation.hbs');
        const dataTemplate: any = {
            ...viewData,
            ...globals,
            banner,
            downloadLicenseUrl,
            copyButtonText,
            showDownloadBoxBanner: FEATURE_AD_MANAGER_DOWNLOADBOX_BY_COUNTRY && !IS_MOBILE && !isAdBlockEnabled(),
        };

        if (globalCampaignAttribution) {
            dataTemplate.banner = 'attribution';
            dataTemplate.globalCampaign = globalCampaignAttribution;
        }

        detailElement.scrollTop = 0;
        detailConfElement.classList.add('show');
        detailConfElement.innerHTML = viewTemplate(dataTemplate);

        window.dispatchEvent(new Event('download:attribution'));

        const detailPreview = q('.detail__preview', detailElement);
        detailPreview?.classList.add(`show-confirmation${isPanoramic ? '-panoramic' : ''}`);

        q('.detail__download-confirmation__close', detailElement || document)?.addEventListener('click', () => {
            detailConfElement.classList.remove('show');
            detailPreview?.classList.remove('show-confirmation', 'show-confirmation-panoramic');
        });

        initializeReport();
        trackCollectionResourceDownload();

        FEATURE_AD_MANAGER_ATTRIBUTE && initContentBanner(['BANNER_ATTRIBUTE']);

        if (!IS_MOBILE) {
            if (globalCampaignAttribution) {
                initCampaignBanner(detailElement, dataTemplate.banner);
            } else if (q('.detail__download-confirmation .attribution')) {
                triggerManualGA4Events({
                    description: 'modalAttributionBannerAfterDownloadOnload',
                    options: {},
                });
            }
        }
    }
};

export const initializeDownload = async function(options: ViewPrint) {
    const { premium } = options.xhr || { premium: false };
    const detailElement = getDetail();
    const isUserPremium = UserHelpers.isPremiumFreepik();

    if (!isTruthy(premium) && !isUserPremium) {
        initializeFreeDownload();
    }

    ['click', 'auxclick'].forEach(eventType => {
        qq('.download-button', detailElement || document).forEach(buttonElement => {
            if (!buttonElement.classList.contains('redirect-url')) {
                buttonElement.addEventListener(eventType, handlerDownload);
            }
        });
    });
};
