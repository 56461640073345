import { q } from 'bobjoll/ts/library/dom';

import { SwiperConfig } from './swiper.config';
import { eventResize } from './swiper.helpers';

export class SwiperElement {
    public options: SwiperConfig;
    approxMargin = 50;
    buttonPrev: HTMLElement;
    buttonNext: HTMLElement;
    currentSlide = 0;
    drag: {
        startX: number;
        endX: number;
        startY: number;
        letItGo: null | boolean;
        preventClick: boolean;
    };
    innerElements: HTMLElement[];
    parentContainer: HTMLElement;
    perPage: number;
    pointerDown: boolean;
    selectorWidth: number;
    screenWidthInitial = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    screenHeightInitial = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    sliderFrame: HTMLDivElement;
    swiperElement: HTMLElement;

    constructor(options: SwiperConfig) {
        this.options = options;
        this.setup();
    }

    private setup() {
        const swiperElement = this.options.swiperElement
            ? this.options.swiperElement
            : this.options.selector
            ? q(this.options.selector)
            : undefined;
        if (!swiperElement) throw new Error('Swiper selector wrong!');

        this.swiperElement = swiperElement;
        this.pointerDown = false;
        this.drag = {
            startX: 0,
            endX: 0,
            startY: 0,
            letItGo: null,
            preventClick: false,
        };
        const parent = swiperElement.parent('.container') as HTMLElement;
        if (parent) this.parentContainer = parent;

        this.getChildren();
        this.getNavigation();
        this.initFirstTime();
    }

    private getChildren() {
        const children = this.swiperElement.children;
        if (!children) throw new Error('Swiper children not found');
        if (this.options.ignoreElements) {
            const onlyThisChildren = [];
            for (let i = 0; i < children.length; i++) {
                const classListChild = [].slice.call(children[i].classList).join(' ');
                if (classListChild) {
                    if (classListChild.match(this.options.ignoreElements)) {
                        continue;
                    }
                    onlyThisChildren.push(children[i]);
                }
            }
            this.innerElements = [].slice.call(onlyThisChildren);
        } else {
            this.innerElements = [].slice.call(children);
        }
    }

    private getNavigation() {
        if (this.options.navigation && this.options.navigation.elementNext && this.options.navigation.elementPrev) {
            const footerNavigation = this.swiperElement.nextElementSibling as HTMLElement;
            if (footerNavigation && footerNavigation.classList.contains('swiper__navigation')) {
                const prev = q(this.options.navigation.elementPrev, footerNavigation);
                const next = q(this.options.navigation.elementNext, footerNavigation);
                if (prev) this.buttonPrev = prev;
                if (next) this.buttonNext = next;
                if (this.currentSlide <= 0) {
                    this.disableNavigation(this.buttonPrev);
                }
            }
        }
    }

    private enableNavigation() {
        if (this.options.navigation && this.options.navigation.disabledClass && this.buttonPrev && this.buttonNext) {
            this.buttonPrev.classList.remove(this.options.navigation.disabledClass);
            this.buttonNext.classList.remove(this.options.navigation.disabledClass);
        }
    }

    private disableNavigation(button: HTMLElement) {
        if (button && this.options.navigation && this.options.navigation.disabledClass) {
            button.classList.add(this.options.navigation.disabledClass);
        }
    }

    public prev(howManySlides = 1, callback?: () => {}) {
        this.enableNavigation();
        if (this.innerElements.length <= this.perPage) {
            return;
        }
        const beforeChange = this.currentSlide;
        if (this.options.loop) {
            const isNewIndexClone = this.currentSlide - howManySlides < 0;
            if (isNewIndexClone) {
                this.disableTransition();
                const mirrorSlideIndex = this.currentSlide + this.innerElements.length;
                const mirrorSlideIndexOffset = this.perPage;
                const moveTo = mirrorSlideIndex + mirrorSlideIndexOffset;
                const offset = -1 * moveTo * (this.selectorWidth / this.perPage);
                const dragDistance = this.options.draggable ? this.drag.endX - this.drag.startX : 0;
                this.swiperElement.style.transform = `translate3d(${offset + dragDistance}px, 0, 0)`;

                this.currentSlide = mirrorSlideIndex - howManySlides;
            } else {
                this.currentSlide = this.currentSlide - howManySlides;
            }
        } else {
            this.currentSlide = Math.max(this.currentSlide - howManySlides, 0);
        }
        if (beforeChange !== this.currentSlide) {
            this.slideToCurrent(this.options.loop);
            if (this.options.onChange) this.options.onChange.call(this);
            if (callback) {
                callback.call(this);
            }
        }
        if (this.currentSlide <= 0) {
            this.disableNavigation(this.buttonPrev);
        }
    }

    public next(howManySlides = 1, callback?: () => {}) {
        this.enableNavigation();
        if (this.innerElements.length <= this.perPage) {
            return;
        }
        const beforeChange = this.currentSlide;
        if (this.options.loop) {
            const isNewIndexClone = this.currentSlide + howManySlides > this.innerElements.length - this.perPage;
            if (isNewIndexClone) {
                this.disableTransition();
                const mirrorSlideIndex = this.currentSlide - this.innerElements.length;
                const mirrorSlideIndexOffset = this.perPage;
                const moveTo = mirrorSlideIndex + mirrorSlideIndexOffset;
                const offset = -1 * moveTo * (this.selectorWidth / this.perPage);
                const dragDistance = this.options.draggable ? this.drag.endX - this.drag.startX : 0;
                this.swiperElement.style.transform = `translate3d(${offset + dragDistance}px, 0, 0)`;
                this.currentSlide = mirrorSlideIndex + howManySlides;
            } else {
                this.currentSlide = this.currentSlide + howManySlides;
            }
        } else {
            this.currentSlide = Math.min(this.currentSlide + howManySlides, this.innerElements.length - this.perPage);
        }

        if (beforeChange !== this.currentSlide) {
            this.slideToCurrent(this.options.loop);
            if (this.options.onChange) this.options.onChange.call(this);
            if (callback) {
                callback.call(this);
            }
        }
        if (this.currentSlide >= this.innerElements.length - this.perPage) {
            this.disableNavigation(this.buttonNext);
        }
    }

    public checkNavigationAfterResize() {
        if (this.currentSlide > 0 && this.currentSlide >= this.innerElements.length - this.perPage) {
            this.disableNavigation(this.buttonNext);
        } else if (this.currentSlide <= 0) {
            this.disableNavigation(this.buttonPrev);
        } else {
            this.enableNavigation();
        }
    }

    private calculatePerPage() {
        this.swiperElement.style.width = '';
        this.selectorWidth = this.swiperElement.offsetWidth;
        this.perPage = 0;
        let totalWidthElements = 0;
        for (let i = 0; i < this.innerElements.length; i++) {
            const element = this.innerElements[i] as HTMLElement | null;
            if (element) {
                const widthElement = element.clientWidth;
                totalWidthElements += widthElement;
                if (this.selectorWidth > totalWidthElements) {
                    this.perPage++;
                } else {
                    const elementHidePx = totalWidthElements - this.selectorWidth;
                    const elementShowPx = widthElement - elementHidePx;
                    const percVisible = (elementShowPx * 100) / widthElement;
                    this.perPage += percVisible / 100;
                    break;
                }
            }
        }
    }

    private initFirstTime() {
        if (this.options.autoBreakPoint && this.parentContainer) {
            this.autoInitWhenItFits();
        } else if (this.options.breakPoint && this.options.breakPoint > 0) {
            const swiper = this;
            window.addEventListener('resize', function createByBreakPoint() {
                const headerFilter = q('#header-filter.active');
                const filterWidth = headerFilter ? headerFilter.clientWidth : 0;
                if (
                    swiper.options.breakPoint &&
                    swiper.options.breakPoint >= window.innerWidth - filterWidth - swiper.approxMargin
                ) {
                    swiper.init();
                    window.removeEventListener('resize', createByBreakPoint);
                    swiper.enableNavigation();
                    swiper.checkNavigationAfterResize();
                    eventResize();
                }
            });
        } else {
            this.init();
        }
    }

    private getFilterActiveWidth() {
        const headerFilter = q('#header-filter.active');
        return headerFilter ? headerFilter.clientWidth : 0;
    }

    private autoInitWhenItFits() {
        const swiper = this;

        window.addEventListener('resize', function createWhenItFits() {
            if (swiper.parentContainer.clientWidth - swiper.approxMargin < swiper.getTotalWidthItems(swiper)) {
                swiper.init();
                window.removeEventListener('resize', createWhenItFits);
                swiper.enableNavigation();
                swiper.checkNavigationAfterResize();
                eventResize();
            }
        });
    }

    private getTotalWidthItems(swiper: SwiperElement) {
        let totalWidth = 0;
        for (let i = 0; i < swiper.innerElements.length; i++) {
            const element = swiper.innerElements[i] as HTMLElement | null;
            if (element) {
                const widthElement = element.clientWidth;
                totalWidth += widthElement;
            }
        }
        return totalWidth;
    }

    private init() {
        this.addEventListener();
        this.swiperElement.classList.add('active');
        if (this.options.autoBreakPoint) this.swiperElement.classList.add('auto-fit');
        this.build();
        if (this.options.onInit) this.options.onInit.call(this);
    }

    private build() {
        this.buildContainer();

        if (this.options.draggable) this.swiperElement.style.cursor = '-webkit-grab';

        if (this.options.loop && this.perPage) {
            for (let i = this.innerElements.length - this.perPage; i < this.innerElements.length; i++) {
                const element = this.innerElements[i] as HTMLElement | null;
                if (element) {
                    this.buildItem(element);
                }
            }
        }
        for (let i = 0; i < this.innerElements.length; i++) {
            const element = this.innerElements[i] as HTMLElement | null;
            if (element) {
                if (this.options.ignoreElements) {
                    const classListElement = [].slice.call(element.classList).join(' ');
                    if (classListElement.match(this.options.ignoreElements)) {
                        continue;
                    }
                }
                this.buildItem(element);
            }
        }
        if (this.options.loop && this.perPage) {
            for (let i = 0; i < this.perPage; i++) {
                const element = this.innerElements[i] as HTMLElement | null;
                if (element) {
                    this.buildItem(element);
                }
            }
        }

        this.swiperElement.classList.add('swiper__row');
        this.slideToCurrent();
    }

    public buildContainer() {
        if (this.options.perPage) this.perPage = this.options.perPage;
        if (this.options.autoPerPage) this.calculatePerPage();
        this.enableTransition();
        this.setTotalWidth();
    }

    private setTotalWidth() {
        const widthItem = this.selectorWidth / this.perPage;
        const itemsToBuild = this.options.loop
            ? this.innerElements.length + 2 * this.perPage
            : this.innerElements.length;
        const paddingChildren = this.getPaddingItem(this.innerElements[0]);
        const totalPadding = paddingChildren * this.innerElements.length;
        const newWidthSwiper = widthItem * itemsToBuild + totalPadding + this.approxMargin;
        this.swiperElement.style.width = `${newWidthSwiper}px`;
    }

    private getPaddingItem(element: HTMLElement) {
        let paddingItem: any;
        let totalPaddingX = 0;

        try {
            paddingItem = window.getComputedStyle(element as Element, null).getPropertyValue('padding');
        } catch (e) {
            paddingItem = element.style.padding;
        }

        if (paddingItem) {
            const pixels = paddingItem.match(/\d+/g).map(Number);
            if (pixels && pixels.length <= 2 && pixels[1]) {
                totalPaddingX = pixels[1] * 2;
            }
        }

        return totalPaddingX;
    }

    private buildItem(element: HTMLElement) {
        element.classList.add('swiper__item__done');
        element.style.width = 'auto';
        element.style.minWidth = `${this.options.minWidth}px`;
        element.style.maxWidth = `${this.options.maxWidth}px`;
    }

    public enableTransition() {
        this.swiperElement.style.webkitTransition = `all ${this.options.duration}ms ${this.options.easing}`;
        this.swiperElement.style.transition = `all ${this.options.duration}ms ${this.options.easing}`;
    }

    private disableTransition() {
        this.swiperElement.style.webkitTransition = `all 0ms ${this.options.easing}`;
        this.swiperElement.style.transition = `all 0ms ${this.options.easing}`;
    }

    public slideToCurrent(enableTransition = false) {
        const currentSlide = this.options.loop && this.perPage ? this.currentSlide + this.perPage : this.currentSlide;
        const offset = this.perPage ? -1 * currentSlide * (this.selectorWidth / this.perPage) : 0;
        if (enableTransition) {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    this.enableTransition();
                    this.swiperElement.style.transform = `translate3d(${offset}px, 0, 0)`;
                });
            });
        } else {
            this.swiperElement.style.transform = `translate3d(${offset}px, 0, 0)`;
        }
    }

    public destroy(callback?: () => {}) {
        this.disableTransition();

        this.removeEventListener();
        this.swiperElement.removeAttribute('style');
        this.swiperElement.classList.remove('active');
        this.swiperElement.classList.remove('swiper__row');
        eventResize();

        for (let i = 0; i < this.innerElements.length; i++) {
            const swiperItems = this.innerElements[i] as HTMLElement;
            if (swiperItems) {
                swiperItems.removeAttribute('style');
                swiperItems.classList.remove('swiper__item__done');
            }
        }

        if (callback) {
            callback.call(this);
        }
    }

    private addEventListener() {
        [
            'autoResizeHandler',
            'resizeHandler',
            'touchstart',
            'touchend',
            'touchmove',
            'mousedown',
            'mouseup',
            'mouseleave',
            'mousemove',
            'click',
        ].forEach(method => {
            this[method] = this[method].bind(this);
        });

        if (this.options.autoBreakPoint) {
            window.addEventListener('resize', this.autoResizeHandler);
        } else {
            window.addEventListener('resize', this.resizeHandler);
        }

        this.swiperElement.addEventListener('touchstart', this.touchstart, {
            passive: true,
        });
        this.swiperElement.addEventListener('touchend', this.touchend, {
            passive: false,
        });
        this.swiperElement.addEventListener('touchmove', this.touchmove, {
            passive: false,
        });
        this.swiperElement.addEventListener('mousedown', this.mousedown);
        this.swiperElement.addEventListener('mouseup', this.mouseup);
        this.swiperElement.addEventListener('mouseleave', this.mouseleave);
        this.swiperElement.addEventListener('mousemove', this.mousemove);
        this.swiperElement.addEventListener('click', this.click);

        if (this.buttonPrev && this.buttonNext) {
            this.buttonPrev.addEventListener('click', () => this.prev());
            this.buttonNext.addEventListener('click', () => this.next());
        }
    }

    private removeEventListener() {
        if (this.options.autoBreakPoint) {
            window.removeEventListener('resize', this.autoResizeHandler);
        } else {
            window.removeEventListener('resize', this.resizeHandler);
        }

        this.swiperElement.removeEventListener('touchstart', this.touchstart);
        this.swiperElement.removeEventListener('touchend', this.touchend);
        this.swiperElement.removeEventListener('touchmove', this.touchmove);
        this.swiperElement.removeEventListener('mousedown', this.mousedown);
        this.swiperElement.removeEventListener('mouseup', this.mouseup);
        this.swiperElement.removeEventListener('mouseleave', this.mouseleave);
        this.swiperElement.removeEventListener('mousemove', this.mousemove);
        this.swiperElement.removeEventListener('click', this.click);

        if (this.buttonPrev && this.buttonNext) {
            this.buttonPrev.removeEventListener('click', () => this.prev());
            this.buttonNext.removeEventListener('click', () => this.next());
        }
    }

    private ignoreElementsOnDragByClass(event: Event) {
        const target = event.target as HTMLElement;
        if (target && this.options.classesIgnoreSwiper) {
            const classListTarget = [].slice.call(target.classList).join(' ');
            if (classListTarget.match(this.options.classesIgnoreSwiper)) {
                return true;
            }
        }
        return false;
    }

    private updateAfterDrag() {
        if (!this.perPage) return;

        const movement = this.drag.endX - this.drag.startX;
        const movementDistance = Math.abs(movement);
        const howManySliderToSlide =
            this.options.multipleDrag && this.perPage
                ? Math.ceil(movementDistance / (this.selectorWidth / this.perPage))
                : 1;
        const slideToNegativeClone = movement > 0 && this.currentSlide - howManySliderToSlide < 0;
        const slideToPositiveClone =
            movement < 0 && this.currentSlide + howManySliderToSlide > this.innerElements.length - this.perPage;
        if (this.options.threshold) {
            if (movement > 0 && movementDistance > this.options.threshold && this.innerElements.length > this.perPage) {
                this.prev(howManySliderToSlide);
            } else if (
                movement < 0 &&
                movementDistance > this.options.threshold &&
                this.innerElements.length > this.perPage
            ) {
                this.next(howManySliderToSlide);
            }
        }

        this.slideToCurrent(slideToNegativeClone || slideToPositiveClone);
    }

    private clearDrag() {
        this.drag = {
            startX: 0,
            endX: 0,
            startY: 0,
            letItGo: null,
            preventClick: this.drag.preventClick,
        };
    }

    private touchstart(event: TouchEvent) {
        const target = event.target as HTMLElement;
        if (target) {
            const ignoreSwiper = this.options.elementsIgnoreSwiper
                ? this.options.elementsIgnoreSwiper.indexOf(target.nodeName) !== -1
                : false;
            if (ignoreSwiper || this.ignoreElementsOnDragByClass(event)) {
                return;
            }
            event.stopPropagation();
            this.pointerDown = true;
            this.drag.startX = event.touches[0].pageX;
            this.drag.startY = event.touches[0].pageY;
        }
    }

    private touchend(event: TouchEvent) {
        event.stopPropagation();
        this.pointerDown = false;
        this.enableTransition();
        if (this.drag.endX > 0 || event.changedTouches[0].clientX <= 0) {
            this.updateAfterDrag();
        }
        this.clearDrag();
    }

    private touchmove(event: TouchEvent) {
        event.stopPropagation();
        if (this.drag.letItGo === null) {
            this.drag.letItGo =
                Math.abs(this.drag.startY - event.touches[0].pageY) <
                Math.abs(this.drag.startX - event.touches[0].pageX);
        }
        if (this.pointerDown && this.drag.letItGo) {
            event.preventDefault();
            this.drag.endX = event.touches[0].pageX;
            this.swiperElement.style.webkitTransition = `all 0ms ${this.options.easing}`;
            this.swiperElement.style.transition = `all 0ms ${this.options.easing}`;
            const currentSlide =
                this.options.loop && this.perPage ? this.currentSlide + this.perPage : this.currentSlide;
            const currentOffset = this.perPage ? currentSlide * (this.selectorWidth / this.perPage) : 0;
            const dragOffset = this.drag.endX - this.drag.startX;
            const offset = currentOffset - dragOffset;
            this.swiperElement.style.transform = `translate3d(${-1 * offset}px, 0, 0)`;
        }
    }

    private mousedown(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (target) {
            const ignoreSwiper = this.options.elementsIgnoreSwiper
                ? this.options.elementsIgnoreSwiper.indexOf(target.nodeName) !== -1
                : false;
            if (ignoreSwiper || this.ignoreElementsOnDragByClass(event)) {
                return;
            }
            event.preventDefault();
            event.stopPropagation();
            this.pointerDown = true;
            this.drag.startX = event.pageX;
        }
    }

    private mouseup(event: MouseEvent) {
        event.stopPropagation();
        this.pointerDown = false;
        this.swiperElement.style.cursor = '-webkit-grab';
        this.enableTransition();
        if (this.drag.endX > 0 || event.clientX == 0) {
            this.updateAfterDrag();
        }
        this.clearDrag();
    }

    private mouseleave(event: MouseEvent) {
        if (this.pointerDown) {
            this.pointerDown = false;
            this.swiperElement.style.cursor = '-webkit-grab';
            this.drag.endX = event.pageX;
            this.drag.preventClick = false;
            this.enableTransition();
            this.updateAfterDrag();
            this.clearDrag();
        }
    }

    private mousemove(event: MouseEvent) {
        event.preventDefault();
        if (this.pointerDown && this.perPage) {
            const target = event.target as HTMLElement;
            if (target && target.nodeName === 'A') {
                this.drag.preventClick = true;
            }
            this.drag.endX = event.pageX;
            this.swiperElement.style.cursor = '-webkit-grabbing';
            this.swiperElement.style.webkitTransition = `all 0ms ${this.options.easing}`;
            this.swiperElement.style.transition = `all 0ms ${this.options.easing}`;
            const currentSlide = this.options.loop ? this.currentSlide + this.perPage : this.currentSlide;
            const currentOffset = currentSlide * (this.selectorWidth / this.perPage);
            const dragOffset = this.drag.endX - this.drag.startX;
            const offset = currentOffset - dragOffset;
            this.swiperElement.style.transform = `translate3d(${-1 * offset}px, 0, 0)`;
        }
    }

    private click(event: Event) {
        if (this.drag.preventClick) {
            event.preventDefault();
        }
        this.drag.preventClick = false;
    }

    private resizeHandler() {
        if (this.options.breakPoint && this.options.breakPoint <= window.innerWidth - this.getFilterActiveWidth()) {
            const oldConfig = this.options;
            this.destroy();
            new SwiperElement(oldConfig);
            return;
        }
        this.buildContainer();
        this.slideToCurrent();
        if (this.buttonPrev && this.buttonNext) this.checkNavigationAfterResize();
    }

    private autoResizeHandler() {
        if (this.parentContainer.clientWidth + this.approxMargin > this.getTotalWidthItems(this)) {
            this.destroy();
        }
    }
}
