import { delegate, ww } from 'bobjoll/ts/library/dom';
import { setAdsTag } from 'common/sponsor-common/sponsor.helpers';

const handlerWindowMouseUp = (event: Event) => {
    const { target } = event;

    if (target instanceof HTMLElement) {
        const wrapper = target.parents('.modal');
        const container = target.parents('.modal__container');
        const modalElement = (wrapper.length ? wrapper[0] : target) as HTMLElement;
        const disabled = '' === modalElement.dataset.disableMouseUp ? true : false;

        if ((wrapper.length || target.classList.contains('modal')) && 0 === container.length && !disabled) {
            ww.ModalInstance.hide();
        }
    }
};

const handlerWindowKeyUp = function (event: KeyboardEvent) {
    if (event.keyCode === 27) ww.ModalInstance.hide();
};

const handlerModalHide = function () {
    ww.ModalInstance.hide();
    setAdsTag('.spr.spr__test-ad');
};

const handleModalTrigger = function (this: HTMLElement, event: Event) {
    if (!this.dataset.allowDefault) {
        event.preventDefault();
        event.stopPropagation();
    }

    const id = this.dataset['modal'];

    if (id) {
        ww.ModalInstance.show(id);
        ww.ModalInstance.hide({ show: id });
    }
};

const setup = () => {
    if (ww.ModalInitialize) return;
    window.addEventListener('keyup', handlerWindowKeyUp);
    window.addEventListener('mouseup', handlerWindowMouseUp);
    delegate('.modal__close', 'click', handlerModalHide);
    delegate('.modal__trigger', 'click', handleModalTrigger);
    ww.ModalInitialize = true;
};

setup();
