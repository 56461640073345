var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    " + require("./detail.views.modalDownloadConfirmationPremium.hbs").apply(null, Array.prototype.slice.call(arguments, arguments.length > 6)) + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    " + require("./detail.views.modalDownloadConfirmationFree.hbs").apply(null, Array.prototype.slice.call(arguments, arguments.length > 6)) + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<button class=\"detail__download-confirmation__close button button--link-inverted button--icon\">\n    <i class=\"icon icon--md icon--mg-sm icon--cross\"></i>\n</button>\n"
    + ((stack1 = (helpers.isTrue||(depth0 && depth0.isTrue)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.premium : stack1),{"name":"isTrue","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":8,"column":11}}})) != null ? stack1 : "");
},"useData":true});