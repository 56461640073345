import { IstockVM } from '@fc/sponsor_front';
import { q, qq } from 'bobjoll/ts/library/dom';
import { ArgumentsDictionary } from 'Library/arguments';

import { createPixel, createSponsorPixel } from './istock.pixel';

const getListIdForVisibleItems = (globalVariables: ArgumentsDictionary): string => {
    const items = qq(`.${globalVariables.classes.item}`);
    const ids: string[] = [];

    for (let i = 0; i < items.length; i++) {
        const element = items[i];
        if (element.classList.contains('last-visible')) {
            break;
        }
        element.dataset.id && ids.push(element.dataset.id);
    }

    return ids.join(',');
};

export const addImpressionAndClickPixels = (
    globalVariables: ArgumentsDictionary,
    sponsorResponse: IstockVM.iStockResponseBanner,
) => {
    createPixel({
        listItemIds: getListIdForVisibleItems(globalVariables),
        pixelParams: sponsorResponse.trackerImpCustom,
    });

    sponsorResponse.trackerImpSponsor && createSponsorPixel(sponsorResponse.trackerImpSponsor);

    const logo = q(`#${globalVariables.ids.logo}`);
    const couponTitle = q(`#${globalVariables.ids.couponTitle}`);
    const showMore = q(`#${globalVariables.ids.more}`);
    const itemsLink = qq(`.${globalVariables.classes.link}`);

    ['click', 'auxclick'].forEach(eventType => {
        logo &&
            logo.addEventListener(eventType, () =>
                createPixel({
                    element: logo,
                    eventType,
                    pixelParams: sponsorResponse.logo.trackerClickCustom,
                }),
            );

        couponTitle &&
            couponTitle.addEventListener(eventType, () =>
                createPixel({
                    element: couponTitle,
                    eventType,
                    pixelParams: (sponsorResponse.coupon && sponsorResponse.coupon.trackerClickCustom) || '',
                }),
            );

        showMore &&
            showMore.addEventListener(eventType, () =>
                createPixel({
                    element: showMore,
                    eventType,
                    pixelParams: (sponsorResponse.showMore && sponsorResponse.showMore.trackerClickCustom) || '',
                }),
            );

        itemsLink &&
            itemsLink.forEach((item: HTMLElement, index: number) =>
                item.addEventListener(eventType, () =>
                    createPixel({
                        element: item,
                        eventType,
                        pixelParams:
                            (sponsorResponse.results &&
                                sponsorResponse.results[index] &&
                                sponsorResponse.results[index].trackerClickCustom) ||
                            '',
                    }),
                ),
            );
    });
};
