import { Arguments } from '../arguments';

export const urlJoin = (base: string, ...str: string[]) =>
    str.reduce((url, str) => ((url += url.indexOf('?') >= 0 ? `&${str}` : `?${str}`), url), base);

export const urlLocation = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    return link;
};

export const getQueryStringFromURL = (queryParameter: string, url?: string) => {
    const urlParams = new URLSearchParams(url || window.location.search);
    return urlParams.get(queryParameter) || '';
};

export const getAllParamsFromURL = (url: string): string[] => {
    if (!url.includes('?')) return [];

    const urlWithParams = new URL(url);
    const allParams: string[] = [];

    for (const param of urlWithParams.search.slice(1).split('&')) {
        const { 0: key, 1: value } = param.split('=');
        allParams[key.replace(/[^a-zA-Z_-]/g, '')] = value;
    }

    return allParams;
};

export const updateParamValue = (url: string, paramToReplace: string, paramNewValue: string) => {
    const regex = new RegExp(`(${paramToReplace}=)[^&]+`);
    return url.replace(regex, '$1' + paramNewValue);
};

export const getQueryFromHashParameters = () => {
    const url = document.location.href.split('#');
    if (url.length <= 1) return '';
    const hashParameters = Arguments.stringToObject(url[1]);
    return hashParameters['query'] || '';
}