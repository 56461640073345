import { sessionStorage } from 'bobjoll/ts/library/storage';

/* eslint-disable @typescript-eslint/camelcase */
declare const google_optimize: Optimize;

export const get = function(experimentId: string): string | void {
    try {
        const experimentValue =
            'undefined' !== typeof google_optimize
                ? google_optimize.get(experimentId) || sessionStorage.get('session', experimentId)
                : sessionStorage.get('session', experimentId);
        if (experimentValue) {
            sessionStorage.set('session', experimentId, experimentValue);
            return experimentValue;
        } else {
            getActiveExperiment().then(experiment => {
                if (experiment && experiment.id === experimentId) {
                    sessionStorage.set('session', experimentId, experiment.variation);
                }
            });
        }
    } catch (err) {
        console.error(experimentId, err);
    }
};

let getActiveExperimentPromise: any;
let getActiveExperimentResolved: any;
export const getActiveExperiment = function(): Promise<OptimizeResponse | void> {
    if (!getActiveExperimentPromise) {
        getActiveExperimentPromise = getActiveExperimentRequest();
    }
    return getActiveExperimentPromise;
};

export const getActiveExperimentRequest = function(): Promise<OptimizeResponse | void> {
    return new Promise(resolve => {
        if (getActiveExperimentResolved) {
            resolve(getActiveExperimentResolved);
        } else {
            const fn = (variation: string, id: string) => {
                const experiment = {
                    id,
                    variation,
                };
                getActiveExperimentResolved;
                resolve(experiment);
            };
            try {
                gtag('event', 'optimize.callback', {
                    callback: fn,
                });
                setTimeout(resolve, 200);
            } catch (err) {
                resolve();
            }
        }
    });
};

let getInitializedStatePromise: any;
let getInitializedStateResolved = false;
export const isInitialized = async function() {
    if (!getInitializedStatePromise) {
        getInitializedStatePromise = await isInitializedRequest();
    }
    return getInitializedStatePromise;
};

const isInitializedRequest = function() {
    return new Promise(resolve => {
        if (getInitializedStateResolved) {
            resolve(getInitializedStateResolved);
        }
        try {
            gtag('event', 'optimize.callback', {
                callback: () => {
                    getInitializedStateResolved = true;
                    resolve('loaded');
                },
                remove: true,
            });
            setTimeout(() => resolve('timeout'), 300);
        } catch (err) {
            resolve('error');
        }
    });
};

interface OptimizeResponse {
    id: string;
    variation: string;
}

interface Optimize {
    get: (experimentId: string) => string | void;
}
