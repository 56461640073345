var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return ((stack1 = (helpers.compare||(depth0 && depth0.compare)||container.hooks.helperMissing).call(alias1,"Adobe Stock","===",(depth0 != null ? depth0.SPONSOR : depth0),{"name":"compare","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":15,"column":16}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.SPONSOR_URL_IMPRESS : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":19,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.meta : stack1)) != null ? stack1.pixel_url : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class=\"push-right\">\n        <div class=\"section-spr--row "
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.classes : stack1)) != null ? stack1.row : stack1), depth0))
    + " "
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.classes : stack1)) != null ? stack1.promo : stack1), depth0))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.data : stack1)) != null ? stack1.coupon : stack1)) != null ? stack1.title : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":35,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.data : stack1)) != null ? stack1.coupon : stack1)) != null ? stack1.body : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":12},"end":{"line":45,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing;

  return "        <p class=\"mg-none-i font-xs "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.classes : stack1)) != null ? stack1.logo : stack1), depth0))
    + "\">\n            "
    + ((stack1 = (helpers.language||(depth0 && depth0.language)||alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.meta : stack1)) != null ? stack1.title : stack1),(helpers.concat||(depth0 && depth0.concat)||alias2).call(alias1,"<a id=\"",((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.ids : stack1)) != null ? stack1.logo : stack1),"\" class=\"section-srp--logo\" href=\"",((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.meta : stack1)) != null ? stack1.sponsorUrl : stack1),"&client_id=",((stack1 = ((stack1 = (data && data.root)) && stack1.user)) && stack1.clientId),"\" target=\"_blank\" rel=\"nofollow noopener noreferrer\" data-cs-override-id=\"spn-logo\"><img width=\"97\" style=\"vertical-align: text-bottom; max-height: inherit;\" src=\"",(depth0 != null ? depth0.URL_STATIC_IMG : depth0),"logos/adobestock.svg","\" alt=\"\"/></a>",{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":4,"column":49},"end":{"line":4,"column":410}}}),{"name":"language","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":414}}})) != null ? stack1 : "")
    + "\n        </p>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare||(depth0 && depth0.compare)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"iStockPhoto","===",(depth0 != null ? depth0.SPONSOR : depth0),{"name":"compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":14,"column":24}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing;

  return "                <p class=\"mg-none-i font-xs "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.classes : stack1)) != null ? stack1.logo : stack1), depth0))
    + "\">\n                    "
    + ((stack1 = (helpers.language||(depth0 && depth0.language)||alias2).call(alias1,"Sponsored results by %s",(helpers.concat||(depth0 && depth0.concat)||alias2).call(alias1,"<a id=\"",((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.ids : stack1)) != null ? stack1.logo : stack1),"\" href=\"",((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.meta : stack1)) != null ? stack1.sponsorUrl : stack1),"&client_id=",((stack1 = ((stack1 = (data && data.root)) && stack1.user)) && stack1.clientId),"\" target=\"_blank\" rel=\"nofollow noopener noreferrer sponsored\" data-cs-override-id=\"spn-logo\"><img width=\"40\" style=\"vertical-align: text-bottom; max-height: inherit;\" src=\"",(depth0 != null ? depth0.URL_STATIC_IMG : depth0),"logos/iStock.svg","\" alt=\"\"/></a>",{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":9,"column":59},"end":{"line":9,"column":400}}}),{"name":"language","hash":{},"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":9,"column":404}}})) != null ? stack1 : "")
    + "\n                </p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <p class=\"mg-none-i font-xs\">"
    + ((stack1 = (helpers.language||(depth0 && depth0.language)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Sponsored results by %s",((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.meta : stack1)) != null ? stack1.sponsor : stack1),{"name":"language","hash":{},"data":data,"loc":{"start":{"line":12,"column":49},"end":{"line":12,"column":117}}})) != null ? stack1 : "")
    + "\n                    </p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <img src=\""
    + container.escapeExpression(((helper = (helper = helpers.SPONSOR_URL_IMPRESS || (depth0 != null ? depth0.SPONSOR_URL_IMPRESS : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"SPONSOR_URL_IMPRESS","hash":{},"data":data,"loc":{"start":{"line":18,"column":18},"end":{"line":18,"column":41}}}) : helper)))
    + "\" style=\"visibility: hidden;\" />\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <img src=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.meta : stack1)) != null ? stack1.pixel_url : stack1), depth0))
    + "\" alt=\"\" style=\"visibility: hidden;\" />\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <a id=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.ids : stack1)) != null ? stack1.couponTitle : stack1), depth0))
    + "\"\n                    href=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.data : stack1)) != null ? stack1.coupon : stack1)) != null ? stack1.url : stack1), depth0))
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.user)) && stack1.clientId),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":58},"end":{"line":29,"column":126}}})) != null ? stack1 : "")
    + "\"\n                    target=\"_blank\" class=\"floatl mg-x-none mg-right-lv1-i font-xs\"\n                    rel=\"nofollow noopener noreferrer"
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||container.hooks.helperMissing).call(alias3,"iStockPhoto","===",(depth0 != null ? depth0.SPONSOR : depth0),{"name":"compare","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":53},"end":{"line":31,"column":115}}})) != null ? stack1 : "")
    + "\"\n                    data-cs-override-id=\"spn-promo\">\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.data : stack1)) != null ? stack1.coupon : stack1)) != null ? stack1.title : stack1), depth0))
    + "\n                </a>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "&client_id="
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.user)) && stack1.clientId), depth0));
},"16":function(container,depth0,helpers,partials,data) {
    return " sponsored";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <a id=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.ids : stack1)) != null ? stack1.couponContent : stack1), depth0))
    + "\"\n                    href=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.data : stack1)) != null ? stack1.coupon : stack1)) != null ? stack1.url : stack1), depth0))
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.user)) && stack1.clientId),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":58},"end":{"line":38,"column":126}}})) != null ? stack1 : "")
    + "\"\n                    target=\"_blank\" class=\"floatl mg-x-none font-xs coupon capitalize\"\n                    rel=\"nofollow noopener noreferrer"
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||container.hooks.helperMissing).call(alias3,"iStockPhoto","===",(depth0 != null ? depth0.SPONSOR : depth0),{"name":"compare","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":53},"end":{"line":40,"column":115}}})) != null ? stack1 : "")
    + "\"\n                    data-cs-override-id=\"spn-promo\">\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.data : stack1)) != null ? stack1.coupon : stack1)) != null ? stack1.body : stack1), depth0))
    + "\n                    "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.data : stack1)) != null ? stack1.coupon : stack1)) != null ? stack1.code : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":20},"end":{"line":43,"column":105}}})) != null ? stack1 : "")
    + "\n                </a>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " <b>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.data : stack1)) != null ? stack1.coupon : stack1)) != null ? stack1.code : stack1), depth0))
    + "</b>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare||(depth0 && depth0.compare)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),true,"==",((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.hideHeaderElement : stack1),{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":48,"column":12}}})) != null ? stack1 : "");
},"useData":true});