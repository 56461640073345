var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"image-wrapper\">\n    <div class=\"image image--single\">\n        <figure>\n            <img\n                src=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.preview_images : depth0)) != null ? stack1.image0 : stack1)) != null ? stack1.url : stack1), depth0))
    + "\"\n                alt=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.preview_images : depth0)) != null ? stack1.image0 : stack1)) != null ? stack1.title : stack1), depth0))
    + "\"\n                width=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.preview_images : depth0)) != null ? stack1.image0 : stack1)) != null ? stack1.width : stack1), depth0))
    + "\"\n                height=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.preview_images : depth0)) != null ? stack1.image0 : stack1)) != null ? stack1.height : stack1), depth0))
    + "\"\n                loading=\"lazy\" />\n        </figure>\n    </div>\n</div>\n";
},"useData":true});