import { q } from 'bobjoll/ts/library/dom';

import Countdown from '../countdown';
import { BannerTypes } from './campaign.helpers';

export const checkCountdownCampaign = (container: HTMLElement) => {
    const countdown = q('.countdown', container);

    if (countdown) {
        new Countdown({
            container: countdown,
            dateEnd: new Date(GLOBAL_CAMPAIGN.config.end),
        });
    }
};

export const BannerLabels: Banners = {
    home: { onload: 'banner-anonimo', click: 'banner-anonimo-gopremium' },
    logged: { onload: 'banner-registrado', click: 'banner-registrado-gopremium' },
    category: { onload: 'banner-categorias', click: 'banner-categorias-gopremium' },
    popular: { onload: 'banner-categorias', click: 'banner-categorias-gopremium' },
    keyword: { onload: 'banner-tags', click: 'banner-tags-gopremium' },
    collections: { onload: 'banner-collections', click: 'banner-collections-gopremium' },
    search: { onload: 'banner-paginacion', click: 'banner-paginacion-gopremium' },
    attribution: { onload: 'banner-atribution', click: 'atribution-gopremium' },
    limit: { onload: 'banner-downlimit', click: 'downlimit-gopremium' },
    adblock: { onload: 'banner-autopromo1', click: 'banner-autopromo1-gopremium' },
    detail: { onload: 'banner-autopromo2', click: 'banner-autopromo2-gopremium' },
    premium: { onload: 'banner-detailp', click: 'banner-detallep-gopremium' },
    download: { onload: 'downloadoption', click: 'downloadoption-gopremium' },
    upgrade: { onload: 'banner-upgrade', click: 'banner-upgrade-go' },
    pricing: { onload: false, click: false },
};

type Banners = {
    [key in BannerTypes]: {
        onload: string | false;
        click: string | false;
    };
};
