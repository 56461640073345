import { __ } from 'Partials/language';

import { DownloadFileTypesResponse } from './detail.partials.downloadFileTypesModel';

const FILENAME_PSD_PHOTOSHOP_V23_EXCEPTION = 'psd_v23';
const ITEM_TYPE_MENU_CUSTOM_CLASS = 'item__description';
const PSD_V23_DESCRIPTION = __('PS v23 (2021) or later');
const PSD_PREVIOUS_VERSION_DESCRIPTION = __('previous versions');
const keyLocalStorage = 'notification-mockup-baker-displayed';

const getPSDWithExceptionalVersions = (
    enableTypes: DownloadFileTypesResponse[],
    endsWithV23 = true,
): DownloadFileTypesResponse | undefined => {
    return enableTypes.find(
        (item: DownloadFileTypesResponse) =>
            item.filetype === 'psd' &&
            (endsWithV23
                ? item.filename.endsWith(FILENAME_PSD_PHOTOSHOP_V23_EXCEPTION)
                : !item.filename.endsWith(FILENAME_PSD_PHOTOSHOP_V23_EXCEPTION)),
    );
};

export const sortList = (enableTypes: DownloadFileTypesResponse[]) => {
    return enableTypes.sort((a, b) => {
        if (a.filename.includes('_v23')) return -1;
        if (a.filetype === 'psd') return -1;
        if (a.filename < b.filename) return 1;
        if (a.filename > b.filename) return -1;
        return 0;
    });
};

export const psdMockupBakerException = (enableTypes: DownloadFileTypesResponse[]): number => {
    const hasNewPSD = getPSDWithExceptionalVersions(enableTypes);

    if (hasNewPSD) {
        hasNewPSD.customClass = ITEM_TYPE_MENU_CUSTOM_CLASS;
        hasNewPSD.description = PSD_V23_DESCRIPTION;

        const hasPreviousPSD = getPSDWithExceptionalVersions(enableTypes, false);
        if (hasPreviousPSD) {
            hasPreviousPSD.customClass = ITEM_TYPE_MENU_CUSTOM_CLASS;
            hasPreviousPSD.description = PSD_PREVIOUS_VERSION_DESCRIPTION;
        }

        return hasNewPSD.id;
    }

    return 0;
};

export const setLocalValue = (val: string) => localStorage.setItem(keyLocalStorage, val);
export const getLocalValue = () => localStorage.getItem(keyLocalStorage) || '1';
