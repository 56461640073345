import Follow from 'Partials/user/follow';

export const getFollowingStatus = function(authorId: string): Promise<boolean> {
    return new Promise(resolve => {
        Follow.get(authorId).then(({ data: { following } }: FollowResponse) => {
            resolve(following);
        });
    });
};

export const toggleFollow = function(
    authorId: string,
): Promise<FollowToggleResponse['data']['action']> {
    return new Promise(resolve => {
        Follow.toggle(authorId).then(({ data: { action } }: FollowToggleResponse) => {
            resolve(action);
        });
    });
};

interface FollowResponse {
    data: {
        following: boolean;
        label: 'Follow' | 'Unfollow';
    };
    success: boolean;
}

interface FollowToggleResponse {
    success: boolean;
    data: {
        action: 'follow' | 'unfollow';
    };
    messages: string[];
}
