import getFilterValue from 'Partials/filters-v2/getFilterValue';
import { __ } from 'Project/ts/partials/language';

import { AdobeOptions, AdobeURLOptions } from './adobe.api';
import { getAdobeExpressRedirectUrl } from './adobe-express.helpers';
import { getAdobeExpressImpressionUrl, getImpressionUrl, getLinkUrl } from './partnerize';
import { Placements } from './partnerize/types';

const languagePath = {
    'uk/': USER_COUNTRY_CODE === 'UK' || USER_COUNTRY_CODE === 'GB',
    'ar/': USER_COUNTRY_CODE === 'AR',
    'at/': USER_COUNTRY_CODE === 'AT',
    'au/': USER_COUNTRY_CODE === 'AU',
    'be_en/': USER_COUNTRY_CODE === 'BE' && LANGUAGE === 'en',
    'be_fr/': USER_COUNTRY_CODE === 'BE' && LANGUAGE === 'fr',
    'be_nl/': USER_COUNTRY_CODE === 'BE' && LANGUAGE === 'nl',
    'bg/': USER_COUNTRY_CODE === 'BG',
    'br/': USER_COUNTRY_CODE === 'BR',
    'ca_fr/': USER_COUNTRY_CODE === 'CA' && LANGUAGE === 'fr',
    'ca/': USER_COUNTRY_CODE === 'CA',
    'ch_de/': USER_COUNTRY_CODE === 'CH' && LANGUAGE === 'de',
    'ch_fr/': USER_COUNTRY_CODE === 'CH' && LANGUAGE === 'fr',
    'ch_it/': USER_COUNTRY_CODE === 'CH' && LANGUAGE === 'it',
    'cl/': USER_COUNTRY_CODE === 'CL',
    'co/': USER_COUNTRY_CODE === 'CO',
    'cr/': USER_COUNTRY_CODE === 'CR',
    'cy_en/': USER_COUNTRY_CODE === 'CY',
    'cz/': USER_COUNTRY_CODE === 'CZ',
    'de/': USER_COUNTRY_CODE === 'DE',
    'dk/': USER_COUNTRY_CODE === 'DK',
    'ec/': USER_COUNTRY_CODE === 'EC',
    'ee/': USER_COUNTRY_CODE === 'EE',
    'eg/': USER_COUNTRY_CODE === 'EG',
    'es/': USER_COUNTRY_CODE === 'ES',
    'fi/': USER_COUNTRY_CODE === 'FI',
    'fr/': USER_COUNTRY_CODE === 'FR',
    'gt/': USER_COUNTRY_CODE === 'GT',
    'gr_en/': USER_COUNTRY_CODE === 'GR',
    'hk/': USER_COUNTRY_CODE === 'HK',
    'hu/': USER_COUNTRY_CODE === 'HU',
    'id/': USER_COUNTRY_CODE === 'ID',
    'ie/': USER_COUNTRY_CODE === 'IE',
    'il/': USER_COUNTRY_CODE === 'IL',
    'in/': USER_COUNTRY_CODE === 'IN',
    'it/': USER_COUNTRY_CODE === 'IT',
    'jp/': USER_COUNTRY_CODE === 'JP',
    'kr/': USER_COUNTRY_CODE === 'KR' || LANGUAGE === 'ko',
    'lt/': USER_COUNTRY_CODE === 'LT',
    'lu_de/': USER_COUNTRY_CODE === 'LU' && LANGUAGE === 'de',
    'lu_en/': USER_COUNTRY_CODE === 'LU' && LANGUAGE === 'en',
    'lu_fr/': USER_COUNTRY_CODE === 'LU' && LANGUAGE === 'fr',
    'lv/': USER_COUNTRY_CODE === 'LV',
    'mt/': USER_COUNTRY_CODE === 'MT',
    'mx/': USER_COUNTRY_CODE === 'MX',
    'my/': USER_COUNTRY_CODE === 'MY',
    'nl/': USER_COUNTRY_CODE === 'NL' || LANGUAGE === 'nl',
    'no/': USER_COUNTRY_CODE === 'NO',
    'nz/': USER_COUNTRY_CODE === 'NZ',
    'pe/': USER_COUNTRY_CODE === 'PE',
    'ph/': USER_COUNTRY_CODE === 'PH',
    'pl/': USER_COUNTRY_CODE === 'PL',
    'pt/': USER_COUNTRY_CODE === 'PT',
    'ro/': USER_COUNTRY_CODE === 'RO',
    'ru/': USER_COUNTRY_CODE === 'RU',
    'sa/': USER_COUNTRY_CODE === 'SA',
    'se/': USER_COUNTRY_CODE === 'SE',
    'sg/': USER_COUNTRY_CODE === 'SG',
    'si/': USER_COUNTRY_CODE === 'SI',
    'sk/': USER_COUNTRY_CODE === 'SK',
    'th/': USER_COUNTRY_CODE === 'TH',
    'tr/': USER_COUNTRY_CODE === 'TR',
    'tw/': USER_COUNTRY_CODE === 'TW',
    'ua/': USER_COUNTRY_CODE === 'UA',
    've/': USER_COUNTRY_CODE === 'VE',
    'en/': LANGUAGE === 'en',
};

export const getPromoUrl = () => 'firstmonthfree';

export const getNumImages = () => 10;

export const getTypeFilterValue = () => {
    const { type } = getFilterValue('type');
    const map = {
        images: __('images'),
        vector: __('vectors'),
        photo: __('photos'),
        icon: __('icons'),
    };
    return map[type] || map['images'];
};

export const objectToArguments = function(options: IndexSignature) {
    return Object.keys(options)
        .map(key => `${key}=${options[key]}`)
        .join('&');
};

export const getRedirectUrl = function(referrer: Placements, destination: string, freepikRedirect = true) {
    const urlTradeDoubler = getLinkUrl(referrer, destination);
    return freepikRedirect ? `//www.freepik.com/redirect?url=` + btoa(urlTradeDoubler) : urlTradeDoubler;
};

export const getScaleFactor = function(width: number): number {
    const screenWidth = 100;
    const screenHeight = 100;
    const screenAspectRatio = screenHeight / screenWidth;
    return screenAspectRatio - (screenWidth / width - screenHeight / screenHeight) * 100;
};

export const getAdobeUrl = (urlConfig: AdobeURLOptions) => {
    const { args, options, placement, url = '', freepikRedirect = true } = urlConfig;
    const { words, orientation, type } = options;
    const urlArs = {
        get_facets: 1,
        order: 'relevance',
        safe_search: 1,
        ...args,
    };
    if (words) {
        urlArs['k'] = encodeURI(words);
    }
    if (type) {
        type.forEach(t => (urlArs[`filters[content_type:${'vector' === t ? 'zip_' + t : t}]`] = 1));
    }
    if (orientation) {
        urlArs['filters[orientation]'] = orientation;
    }
    return getRedirectUrl(
        placement,
        `${url || `https://stock.adobe.com/${getLanguagePath()}search`}?${objectToArguments(urlArs)}`,
        freepikRedirect,
    );
};

export const getNextUrl = (placement: Placements, options: AdobeOptions) => {
    const { page = 0 } = options;
    const freepikRedirect = true;
    if (options.orientation) {
        delete options.orientation;
    }
    return getAdobeUrl({
        placement,
        options,
        args: {
            search_page: page,
        },
        freepikRedirect,
    });
};

export const getPopupUrl = (options: AdobeOptions) =>
    getAdobeUrl({
        placement: 'popup',
        options,
        freepikRedirect: false,
    });

export const get404Url = () => {
    return (
        `//www.freepik.com/redirect?url=` +
        btoa(getLinkUrl(404, `https://stock.adobe.com/${getLanguagePath()}promo/firstmonthfree`))
    );
};

export const getCoupon = () => {
    let link: string;
    let pixel_url: string;

    if (FEATURE_SHOW_ADOBE_EXPRESS_STOCKBANNER_BY_COUNTRY) {
        link = getAdobeExpressRedirectUrl('https://adobesparkpost.app.link/hMVKToYk7gb?sdid=S6XGMMPV');
        pixel_url = getAdobeExpressImpressionUrl();
    } else {
        link = getRedirectUrl('stockbanner', `https://stock.adobe.com/${getLanguagePath()}promo/firstmonthfree`);
        pixel_url = getImpressionUrl('stockbanner');
    }

    return {
        link,
        pixel_url,
    };
};

export const getMktCopy = () => {
    return getRedirectUrl('srp', `https://stock.adobe.com/${getLanguagePath()}promo/${getPromoUrl()}`);
};

export const getCCBannerUrl = () => getRedirectUrl('cc', 'https://www.adobe.com/creativecloud/plans.html');

export const getAdobeBannerUrl = () => getRedirectUrl('adblock', 'https://stock.adobe.com/promo/firstmonthfree');

export const getLanguagePath = (ignoreLanguage = false) => {
    let path = Object.keys(languagePath).find(key => languagePath[key]);
    if (path === 'en/' || (path === undefined && ignoreLanguage)) {
        path = '';
    }
    return path === undefined ? LANGUAGE + '/' : path;
};

const helpers = {
    get404Url,
    getAdobeUrl,
    getLinkUrl,
    getCoupon,
    getImpressionUrl,
    getMktCopy,
    getPopupUrl,
    getRedirectUrl,
    getScaleFactor,
    objectToArguments,
};

export default helpers;
