import { RecaptchaV2 } from 'Project/ts/library/recaptcha-v2';

export interface TrackerDownload {
    button?: HTMLElement;
    callbackEvents?: Function;
    captchaElement?: HTMLElement;
    detail?: any;
    disableOtherDownloads?: string;
    downloadData?: any;
    downloadSource?: DownloadSource;
    fileLicense?: string;
    fileSelectionType?: any;
    recaptcha?: RecaptchaV2;
    uniqCaptcha?: string;
    userType?: string;
}

export interface RecaptchaDownload {
    button: HTMLLinkElement;
    downloadSource: DownloadSource;
    event: Event;
    tracker: TrackerDownload;
}

export interface DownloadValidate {
    activateCaptcha?: boolean;
    cypress?: boolean;
    userSuspected?: boolean;
    validate: boolean;
}

export interface DownloadResponse {
    code: number;
    csrfToken: string;
    filename: string;
    success: boolean;
    todayDownloads: number;
    url: string;
}

export enum DownloadSource {
    DETAIL,
    DOWNLOAD_PAGE,
    EDIT_BUTTON,
    USER_DOWNLOADS,
    THUMBNAIL,
}

export enum DownloadResourceErrorCodes {
    DOWNLOAD_LIMIT_EXCEEDED = 429,
}
