Array.prototype.unique = function() {
    var a = this.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
};

Object.defineProperty(Object.prototype, 'getPathValue', {
	value: function(path) {
		return path.split('.').reduce(function(obj, key) {
			if (obj) {
				return obj[key];
			}

			return false;
		}, this);
	},
	writable: true,
	configurable: true,
	enumerable: false
});

Date.prototype.getWeekNumber = function () {
	var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
	var dayNum = d.getUTCDay() || 7;

	d.setUTCDate(d.getUTCDate() + 4 - dayNum);

	var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
	
	return Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
};