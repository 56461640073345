import { qq } from 'bobjoll/ts/library/dom';

interface SizeSelection {
    width: number;
    height: number;
}

interface ResourceSizes {
    sm: SizeSelection;
    md: SizeSelection;
    lg: SizeSelection;
    org: SizeSelection;
}

export const calculateJPGSizes = function (): ResourceSizes | null {
    const largeBaseSize = 2000;
    const smallImageRatioPercentage = 0.5;
    const largeImageRatioPercentage = 0.75;
    const detailSection = qq('.detail');

    if (detailSection && detailSection.length > 0) {
        const originalWidth = detailSection[detailSection.length - 1].dataset.originalWidth;
        const originalHeight = detailSection[detailSection.length - 1].dataset.originalHeight;

        if (originalWidth && originalHeight) {
            const originalWidthNumber = +originalWidth;
            const originalHeightNumber = +originalHeight;
            const isLandscape = originalWidth > originalHeight;

            const ratio = isLandscape
                ? originalWidthNumber / originalHeightNumber
                : originalHeightNumber / originalWidthNumber;
            const largeWidth = isLandscape ? largeBaseSize : largeBaseSize / ratio;
            const largeHeight = isLandscape ? largeBaseSize / ratio : largeBaseSize;

            return {
                sm: {
                    width: Math.round(largeWidth * smallImageRatioPercentage),
                    height: Math.round(largeHeight * smallImageRatioPercentage),
                },
                md: {
                    width: Math.round(largeWidth * largeImageRatioPercentage),
                    height: Math.round(largeHeight * largeImageRatioPercentage),
                },
                lg: {
                    width: Math.round(largeWidth),
                    height: Math.round(largeHeight),
                },
                org: {
                    width: originalWidthNumber,
                    height: originalHeightNumber,
                },
            };
        }
    }

    return null;
};
