import { checkCypress, checkDebugEnviroment } from 'Library/helpers';
import { gtm, GTMArguments } from 'Project/ts/partials/tracker-gtm';

import { FilterValue } from '../partials/filters-v2/getFilterValue/types';
import { Arguments } from './arguments';
import { cookie } from './cookie';
import { getUUIDSearchTracker } from './getUUIDSearchTracker';
import { SEARCH_BY_IMAGE_STORAGE_KEY } from '../partials/search-similar/searchsimilar.constants';

const getFiltersAsSerializedJSON = (filters: FilterValue) => {
    const jsonFilters = {};

    for (const key in filters) {
        if (filters.hasOwnProperty(key)) {
            const value = filters[key];

            if (typeof value === 'string' && value.includes(',')) {
                const arrayValue = value.split(',').map(item => item.trim());
                jsonFilters[key] = arrayValue;
            } else {
                jsonFilters[key] = value;
            }
        }
    }

    return JSON.stringify(jsonFilters);
};

export default class Tracker {
    // TODO...
    // Pasar las funcionalidades de `https://trk.freepik.com/media/js/fr_search_pos_tracker.js` a TypeScript

    static search(pixel: PixelSearch) {
        const keyRand = Math.random();
        const { filters, isUserPremium, method, itemsIdBoostedResources, term, userId } = pixel;
        const searchFilter = {};

        if (TRACKER_URL && LANGUAGE_NAME) {
            const clientId = cookie.getItem('_ga') || 'nn';
            const hbsTemplate = document.body.dataset.hbsTemplate;
            const trackingSearch = TRACKING_SEARCH ? `&track=${TRACKING_SEARCH}` : '';

            const uuid = getUUIDSearchTracker();
            const uuidParam = uuid ? `&uuid=${uuid}` : '';

            const searchResults = `&searchResults=${pixel.searchResults ?? 0}`;
            const resourceType = pixel.resourceType ? `&resourceType=${pixel.resourceType}` : '';

            const formatDictionary = {
                search: 'search',
                popular: 'category',
                category: 'category',
                keyword: 'keyword',
            };

            let format = hbsTemplate && formatDictionary[hbsTemplate] ? formatDictionary[hbsTemplate] : filters.format;

            if (format === 'keyword') {
                const { type } = Arguments.stringToObject(window.location.href);
                format = type || format;
            }

            if (filters.img && filters.img === '1') {
                const searchByImageDataString = localStorage.getItem(SEARCH_BY_IMAGE_STORAGE_KEY);
                const searchByImageData = searchByImageDataString ? JSON.parse(searchByImageDataString) : null;

                format =
                    searchByImageData && searchByImageData.searchType === 'similar'
                        ? 'image_search_similar'
                        : 'image_search';
            }

            searchFilter['format'] = format;
            searchFilter['ga_client'] = clientId;

            if (searchFilter['query']) {
                delete searchFilter['query'];
            }

            if (method) {
                searchFilter['method'] = method;
            }

            if (filters) {
                searchFilter['filters'] = getFiltersAsSerializedJSON(filters);
            }

            const url =
                `search.gif?search-${LANGUAGE_NAME}&term=${term}&userid=${userId}&userPremium=${isUserPremium}&language=${LANGUAGE_NAME}&r=${keyRand}${trackingSearch}${uuidParam}${searchResults}${resourceType}&` +
                Arguments.objectToString(searchFilter) +
                itemsIdBoostedResources;
            Tracker.send(`${TRACKER_URL}${url}`);
        }
    }

    static detail() {
        if (TRACKER_URL && LANGUAGE_NAME) {
            const keyRand = Math.random();
            const url = `search.gif?detalle-${LANGUAGE_NAME}&r=${keyRand}`;
            Tracker.send(`${TRACKER_URL}${url}`);
        }
    }

    static download(resourceId: string, selection: string, userId: string) {
        if (TRACKER_URL && LANGUAGE_NAME) {
            const hash = location.href.split('#');
            const searchHash = typeof hash[1] !== 'undefined' ? hash[1] + '&' : '';
            const keyRand = Math.random();
            const queryString = `${searchHash}pg=detalle-${LANGUAGE_NAME}&id=${resourceId}&userid=${userId}&exclusive=${selection}&r=${keyRand}`;
            const url = `download.gif?${queryString}`;
            Tracker.send(`${TRACKER_URL}${url}`);
        }
    }

    static async download2(args: string[]) {
        const clientId = cookie.getItem('_ga') || 'nn';
        args.push(`ga_client_id=${clientId}`);
        const trackerStatus: {}[] = [];
        const url = `download.gif?${args.join('&')}&r=${Math.random()}`;
        const trackersURL = [`${TRACKER_URL}${url}`];

        await trackersURL.forEach(async url => {
            let tracker: any = new Image();

            tracker.src = url;

            const status: {
                status?: 'success' | 'error';
                url?: string;
            } = await new Promise(function(resolve, reject) {
                tracker.onload = () =>
                    resolve({
                        status: 'success',
                        url: url,
                    });
                tracker.onerror = () =>
                    reject({
                        status: 'error',
                        url: url,
                    });
            });

            trackerStatus.push(status);

            tracker = null;

            if ('undefined' !== typeof debug && debug.printEvent) {
                debug.printEvent({
                    driver: 'fr',
                    settings: [url],
                    url: window.location.href,
                    status: status.status,
                });
            }

            if (checkCypress()) {
                const trackerArgsEvent = new CustomEvent('tracker', {
                    detail: trackerStatus.pop(),
                });

                window.dispatchEvent(trackerArgsEvent);
            }
        });
    }

    public static async send(url: string) {
        if (url) {
            let tracker: any = new Image();

            tracker.src = url;

            const status = await new Promise(function(resolve, reject) {
                tracker.onload = () => resolve('success');
                tracker.onerror = () => reject('error');
            }).catch(() => {});

            tracker = null;

            if ('undefined' !== typeof debug && debug.printEvent) {
                debug.printEvent({
                    driver: 'fr',
                    settings: [url],
                    url: window.location.href,
                    status: status,
                });
            }
        }
    }

    public static async image(trackerUrl: string) {
        let tracker: HTMLImageElement | null = new Image();

        tracker.src = trackerUrl;

        try {
            const status = await new Promise(function(resolve, reject) {
                if (tracker) {
                    tracker.onload = () => resolve('success');
                    tracker.onerror = () => reject('error');
                } else {
                    reject('error');
                }
            });

            tracker = null;

            if ('undefined' !== typeof debug && debug.printEvent) {
                debug.printEvent({
                    driver: 'statscounter',
                    settings: [trackerUrl],
                    url: window.location.href,
                    status: status,
                });
            }
        } catch (err) {
            if (checkDebugEnviroment()) {
                console.error(`Error in Tracker.image: ${trackerUrl}`, err);
            }
        }
    }

    public static async autocomplete(method: string, position: number, query: string, history: boolean = false) {
        const userID = gr && gr.user ? gr.user.id || 0 : 0;
        if (TRACKER_URL && method && position) {
            const clientId = cookie.getItem('_ga') || 'nn';
            const keyRand = Math.random();
            let historyQuery = '';
            if (history) {
                historyQuery = `&history=${query}`;
                query = '';
            }
            const url = `search.gif?autocomplete=1&method=${method}&position=${position}&term=${query}&user_id=${userID}&ga_client_id=${clientId}&r=${keyRand}${historyQuery}`;
            Tracker.send(`${TRACKER_URL}${url}`);
        }
    }

    public static async layout(type: 'pixabay' | 'fixed' | 'full' | 'columns') {
        Tracker.send(`${TRACKER_URL}_ga?send&event&display_${type}`);
    }

    public static async sponsor(partner: string, term: string, ids: string) {
        if (TRACKER_URL && partner && term && ids) {
            const keyRand = Math.random();
            const userId: number = gr && gr.user ? gr.user.id : 0;
            const url = `_ga?sponsor-track&partner=${partner}&term=${term}&id_list=${ids}&user_freepik_id=${userId}&r=${keyRand}`;
            Tracker.send(`${TRACKER_URL}${url}`);
        }
    }

    public static async stockbannerAffiliate(url: string) {
        if (url) {
            Tracker.send(`${url}`);
        }
    }

    public static log(...argUri: string[]) {
        new Image().src = '/_ga?' + argUri.join('&');
    }

    public static trackMicroFunnelEvent = function(options: Microfunnel) {
        const { category, action, label, noninteraction } = options;

        if (TRACKER_URL && category && action && label) {
            const args = [category, action, label];
            Tracker.trackGAEventNoCache(args);
        }

        let gtmEvent: GTMArguments<string> = { event: 'old-event', category, action, label };

        if (noninteraction) {
            gtmEvent = { ...gtmEvent, noninteraction: 'true' };
        }

        gtm(gtmEvent, false);
    };

    public static trackCerberusAlerts(action: string, type: string) {
        if (TRACKER_URL && type && action) {
            const keyRand = Math.random();
            const url = `_ga?send&event&download-devices-limited&${action}&${type}&r=${keyRand}`;
            Tracker.send(`${TRACKER_URL}${url}`);
        }
    }

    public static trackGAEventNoCache(args: any[]) {
        const keyRand = Math.random();
        const url = `_ga?send&event&${args.join('&')}&r=${keyRand}`;
        Tracker.send(`${TRACKER_URL}${url}`);
    }
}

interface PixelSearch {
    filters: IndexSignature;
    isUserPremium: number;
    itemsIdBoostedResources: string;
    method: 'click' | 'enter' | false;
    term: string;
    userId: string;
    searchResults?: boolean;
    resourceType?: string;
}

interface Microfunnel {
    action: string;
    category: string;
    label: string;
    noninteraction?: boolean;
}
