import { q } from 'bobjoll/ts/library/dom';
import { getCountryLanguage } from 'Project/ts/common/adobe';
import { CouponResponse } from 'Partials/sponsor/index';
import { getRandomRange } from 'Project/ts/library/helpers';
import { globals } from 'Project/ts/library/helpers/globals';
import { adobeClickCoupon, adobeRenderCoupon } from 'Project/ts/library/trackerSend/trackerSend.adobestock';

import notify from '../notify';
import Sponsor from '../sponsor';

export const adobeCoupon = async (response: CouponResponse) => {
    if ((response.link && '' === response.link) || !response.pixel_url) return;
    let stockBannerOptimize = false;
    if (stockBannerImage && stockBannerUrl && stockBannerPixelVariation) {
        stockBannerOptimize = true;
        response.link = `${BASE_URL}redirect?url=` + btoa(stockBannerUrl);
    }
    const isAdobeExpress = FEATURE_SHOW_ADOBE_EXPRESS_STOCKBANNER_BY_COUNTRY;
    const { default: template } = await import('Templates/coupon/adobestock.hbs');
    const imageVariation = ['1', '2'];
    const imageVariationIndex = getRandomRange(0, imageVariation.length - 1);
    const image = new Image();

    image.src = isAdobeExpress 
                    ? `${URL_STATIC_IMG}coupon/adobe-express/${getCountryLanguage().toLocaleUpperCase()}.png`
                    : stockBannerOptimize 
                        ? stockBannerImage
                        : `${URL_STATIC_IMG}coupon/adobe-stockbanner/${LANGUAGE.toLocaleUpperCase()}_${imageVariation[imageVariationIndex]}.jpg`;
    image.onerror = () => {
        image.src = isAdobeExpress 
            ? `${URL_STATIC_IMG}coupon/adobe-express/${getCountryLanguage().toLocaleUpperCase()}.png`
            : `${URL_STATIC_IMG}coupon/adobe-stockbanner/EN_${imageVariation[imageVariationIndex]}.jpg`;
    };
    image.onload = () => {
        notify.addNotification({
            class: 'bg__background--secondary',
            expires:
                'undefined' !== typeof stockBannerExpires
                    ? stockBannerExpires
                    : () => new Date(new Date().getTime() + 48 * 60 * 60 * 1000),
            html: template({
                ...globals,
                ...response,
                IMAGE: image.src,
                randomIdentifier: new Date().getTime(),
            }),
            id: `adobe-coupon`,
            position: 'bottom-right',
            priority: 5,
            callback: (couponElement: HTMLElement) => {
                const closeElement = q('.notification__close', couponElement);
                adobeRenderCoupon({
                    pixelAffiliate: stockBannerPixelAffiliate,
                    variation: stockBannerOptimize
                        ? stockBannerPixelVariation
                        : (imageVariation[imageVariationIndex] as AdobeVariation),
                });
                if (couponElement) {
                    if (response.date_to) {
                        Sponsor.couponCountdown(couponElement, new Date(response.date_to));
                    }
                    couponElement.parentElement && couponElement.parentElement.classList.add(`adobe-coupon-container`);
                    ['click', 'auxclick'].forEach(eventType => {
                        const couponButton = document.getElementById('adobe-coupon-button');
                        couponButton &&
                            couponButton.addEventListener(eventType, () =>
                                adobeClickCoupon({
                                    variation: stockBannerOptimize
                                        ? stockBannerPixelVariation
                                        : (imageVariation[imageVariationIndex] as AdobeVariation),
                                })
                            );
                    });
                    if ('function' == typeof stockBannerCallback) {
                        stockBannerCallback(couponElement, stockBannerOptimize);
                    }
                    if ('function' == typeof stockBannerCloseFn) {
                        closeElement &&
                            closeElement.addEventListener('click', () => stockBannerCloseFn(stockBannerOptimize));
                    }
                }
            },
        });
        notify.printQueue();
    };
};

type AdobeVariation =
    | 'berry-images'
    | 'berry-month'
    | 'leaf-images'
    | 'leaf-month'
    | 'nature-images'
    | 'nature-month'
    | 'pencil-images'
    | 'pencil-month';
