import { q, qq, ww } from 'bobjoll/ts/library/dom';
import { localStorage } from 'bobjoll/ts/library/storage';
import Cache from 'Library/cache';
import { cookie } from 'Library/cookie';
import { numberNormalizer } from 'Library/helpers';
import { getResourceAge } from 'Library/helpers/age';
import { camelToSnake } from 'Library/helpers/case';
import { getPathValue } from 'Library/helpers/path';
import { getRangeValue } from 'Library/helpers/range';
import { resultsRange } from 'Library/helpers/results';
import { normalizeStringGTM } from 'Library/helpers/text';
import getFilterValue from 'Partials/filters-v2/getFilterValue';
import { isLandingPaid } from 'Partials/landing-paid/landing-paid-constants';
import { isFlaticonAuthor } from 'Partials/user/user.helpers';

import { getDetail } from './detail-v2/helpers/detail.helpers.generic';

const AVOID_GTM_EVENT_URLS = ['/authentic-photos'];

const getGTMPageData = function() {
    return {
        pageCode: document.body.classList.contains('p404')
            ? '404-not-found'
            : document.body.classList.contains('p500')
            ? '500-server-error'
            : document.body.classList.contains('download-limit')
            ? '429-too-many-requests'
            : '200-ok',
        pageLang: LANGUAGE,
        pageOptimizationVersion: 'nn',
    };
};

const getGTMUserData = function() {
    const user = gr && gr.user ? gr.user : undefined;
    const data: GTMArguments<string> = {
        userType: 'anonymous',
        userTypeFi: 'anonymous',
        userAge: 'nn',
        userLoginStatus: 'not-logged',
        userFirstBuy: 'nn/nn/nn',
    };
    if (user) {
        const userFirstBuy =
            user.first_buy_fr && user.first_buy_fr.source && user.first_buy_fr.medium && user.first_buy_fr.campaign;
        data.userType = user.user_type_fr || 'nn';
        data.userTypeFi = user.user_type_fi || 'nn';
        data.userAge = user.user_age || 'nn';
        data.userLoginStatus = 'logged';
        data.userFirstBuy = userFirstBuy
            ? `${userFirstBuy.source}/${userFirstBuy.medium}/${userFirstBuy.campaign}`
            : 'nn/nn/nn';
        data.userId = user.id;
    }
    return data;
};

const getGTMCategoryData = function() {
    let data: GTMArguments<string> = {};
    if (qq('.terms').length) {
        data = getGTMLegalCategoryData();
    }
    if (gtmData && gtmData.category) {
        for (const idx in gtmData.category) {
            data[idx] = gtmData.category[idx];
        }
    }
    return data;
};

const getGTMLegalCategoryData = function() {
    return {
        catZone: 'information',
        catN1: 'legal-information',
        catTag: 'nn',
        catType: 'content-page',
    };
};

const getSearchCache = () => {
    const showcaseElement = q('#main .showcase[data-cache-key]') || document.getElementById('main') || null;
    const key = showcaseElement ? showcaseElement.dataset.cacheKey || showcaseElement.dataset.key || '' : '';
    const getAllFilterValues =
        (ww.FiltersEnabled && getFilterValue(Object.keys(ww.FiltersEnabled))) ||
        (window.location.search && new URLSearchParams(window.location.search));
    return {
        filters: getAllFilterValues || {},
        xhr: Cache.get(key),
    };
};

const getGTMCategoryLandingData = function() {
    const data = getSearchCache();
    const searchResult = numberNormalizer(data.getPathValue('xhr.meta.summary.total') || '0');
    return {
        searchNumResults: searchResult ? getRangeValue(searchResult, resultsRange) : 'nn',
        searchOrder: data.filters.sort ? data.filters.sort : 'popular',
        searchFilters: data.filters.type ? data.filters.type : {},
        ...getGTMCategoryLandingCategoryData(),
    };
};

const getGTMKeywordLandingData = function() {
    return {
        ...getGTMCategoryLandingData(),
        ...getGTMKeywordLandingKeywordData(),
    };
};

const getGTMSearchData = function() {
    const data = getSearchCache() || {};
    const searchResult = numberNormalizer(data.getPathValue('xhr.meta.summary.total') || '0');
    const searchFilters: { [name: string]: string[] } = {};

    if (data.filters.type) {
        searchFilters.category = data.filters.type.split(',');
    }

    if (data.filters.premium) {
        if (!searchFilters.license) searchFilters.license = [];
        searchFilters.license.push('premium');
    }

    if (data.filters.selection) {
        if (!searchFilters.license) searchFilters.license = [];
        searchFilters.license.push('selection');
    }

    if (data.filters.essential) {
        if (!searchFilters.license) searchFilters.license = [];
        searchFilters.license.push('essential');
    }

    return {
        searchNumResults: searchResult ? getRangeValue(searchResult, resultsRange) : 'nn',
        searchOrder: data.filters.sort ? data.filters.sort : 'popular',
        searchFilters,
        query: data.filters.query ? data.filters.query : 'nn',
        ...getGTMSearchCategoryData(),
    };
};

const getGTMTagsData = function() {
    const data = getSearchCache();
    const searchFilters: { [name: string]: string[] } = {};

    if (data.filters.type) {
        searchFilters.category = data.filters.type.split(',');
    }

    if (data.filters.premium) {
        if (!searchFilters.license) searchFilters.license = [];
        searchFilters.license.push('premium');
    }

    if (data.filters.selection) {
        if (!searchFilters.license) searchFilters.license = [];
        searchFilters.license.push('selection');
    }

    if (data.filters.essential) {
        if (!searchFilters.license) searchFilters.license = [];
        searchFilters.license.push('essential');
    }

    return {
        searchNumResults: data.getPathValue('xhr.meta.summary.total') ? data.xhr.meta.summary.total : 'nn',
        searchOrder: data.filters.sort ? data.filters.sort : 'popular',
        searchFilters,
        query: data.filters.query ? data.filters.query : 'nn',
        ...getGTMTagsCategoryData(),
    };
};

const getGTMCategoryLandingCategoryData = function() {
    const { query, type, license, sort } = getFilterValue(['query', 'type', 'license', 'sort']);
    return {
        catZone: 'section-images',
        catN1: type != '' ? type : 'recent' === sort ? 'new' : license === 'premium' ? license : 'popular',
        catTag: normalizeStringGTM(query) || 'nn',
        catType: 'item-list',
    };
};

const getGTMKeywordLandingKeywordData = function() {
    const { query, type } = getFilterValue(['query', 'type']);
    return {
        catZone: 'section-images',
        catN1: type,
        catTag: normalizeStringGTM(query),
        catType: 'item-list',
    };
};

const getGTMSearchCategoryData = function() {
    const data = getSearchCache();
    return {
        catZone: 'search' === data.filters.format ? 'search' : 'section-images',
        catN1: normalizeStringGTM(data.filters.type ? data.filters.type : 'nn'),
        catTag: 'nn',
        catType: 'search' === data.filters.format ? 'search' : 'item-list',
    };
};

const getGTMTagsCategoryData = function() {
    const path = window.location.pathname.split('/').filter(str => '' !== str);
    return {
        catZone: 'section-tags',
        catN1:
            1 === path.length ? 'top' : path[1].match(/p[0-9]+/) ? (path[0].match(/tags/) ? 'top' : path[1]) : path[1],
        catTag: 'nn',
        catType: 'tags-list',
    };
};

const getDetailCache = function() {
    const detailElement = (q('#modal-detail.active .detail') || q('.detail')) as HTMLElement | undefined;
    let data: GTMData = {};
    if (detailElement) {
        const detailId = detailElement.dataset.id || '';
        const detailCacheKey = detailElement.dataset.cacheKey || '';
        const {
            data: { resources = [] },
        } = Cache.get(detailCacheKey) || { data: {} };
        const resource = resources.filter(({ id }: any) => id == detailId).pop();
        data = resource;
        if (!data) {
            data = {};
            Object.keys(detailElement.dataset).forEach(key => {
                const isSnake = key.indexOf('_') >= 0;
                data[isSnake ? key : camelToSnake(key)] = detailElement.dataset[key];
            });
        }
        const authorId = data.author ? data.author.id : data.author_id;
        if (isFlaticonAuthor(authorId) && data.selection === '1') {
            data.type = 'icon-pack';
            data.fileType = 'zip';
        }
    }
    return data;
};

const getUserDownloadsResource = () => {
    const downloadButton = q('.button--loading-download') as HTMLElement | undefined;
    const data: GTMData = {};
    const fileTypes = {
        photo: 'jpg',
        model: null,
    };
    if (downloadButton) {
        data.creation_date = downloadButton.dataset.creationDate;
        data.author = { id: downloadButton.dataset.author };
        data.typology = downloadButton.dataset.type;
        data.fileType = downloadButton.dataset.type ? fileTypes[downloadButton.dataset.type] || 'zip' : 'zip';
    }
    return data;
};

const getDownloadFileType = () => {
    const detailElement = getDetail();
    if (detailElement) {
        const resourceType = detailElement.dataset.resourceType || detailElement.dataset.type || '';
        if (resourceType === 'photo') return 'jpg';
        if (resourceType.match(/vector|psd/i)) {
            const downloadTypeSelector = q('.popover__download[data-selector="types"]', detailElement);
            if (downloadTypeSelector) {
                const fileType = q('.download-selector .item.active strong');
                return fileType?.innerText || 'zip';
            }
            return 'zip';
        }
        if (resourceType === 'model') {
            const fileDownloaded: HTMLAnchorElement | null = detailElement.querySelector(
                '.download-button.button--loading-download',
            );

            if (fileDownloaded !== null) return fileDownloaded.dataset.type || 'nn';
        }
    }
    return 'nn';
};

const getGTMDetailData = function() {
    const detail = getDetail();
    const data = getDetailCache();
    const date = new Date(data.creation_date);

    const getQueryByDefault = 'nn';
    const query = detail ? q('.detail__keywords a', detail)?.innerText.toLowerCase() : getQueryByDefault;
    const itemContributor: string = data.author ? data.author.id : data.author_id || 'nn';

    return {
        itemType: data.typology || 'nn',
        itemContributor,
        itemAge: getResourceAge(date) || 'nn',
        query,
        resourceId: data.id,
        ...getGTMDetailCategoryData(),
    };
};

const getGTMDownloadData = function(location: string) {
    const data = q('.user--downloads') ? getUserDownloadsResource() : getDetailCache();
    const fileType = getDownloadFileType();
    const date = new Date(data.creation_date);
    const itemContributor: string = data.author ? data.author.id : data.author_id || 'nn';
    return {
        itemAge: getResourceAge(date) || 'nn',
        itemContributor,
        itemQuantity: 1,
        itemType: data.typology || 'nn',
        file_extension: data.fileType || fileType,
        location,
    };
};

const getGTMDetailCategoryData = function() {
    const data = getDetailCache();
    return {
        catZone: 'section-images',
        catN1: normalizeStringGTM(data.type || 'nn'),
        catTag: normalizeStringGTM(data.main_keywords || 'nn'),
        catType: 'item',
    };
};

const getGTMStaticData = function() {
    const checkStaticPageType: GTMPageTypeTesters = {
        home: {
            callbacks: [getGTMHomeData],
            selectors: ['body.home, body.home-anon'],
        },
        collections: {
            callbacks: [getGTMCollectionsCategoryDate],
            selectors: ['section.collections'],
        },
        legal: {
            callbacks: [getGTMLegalData],
            selectors: ['.terms'],
        },
        pricing: {
            callbacks: [getGTMPricingData],
            selectors: ['body.page-pricing, main.page-started'],
        },
        author: {
            callbacks: [getGTMAuthorData],
            selectors: ['body[data-hbs-template="author"]'],
        },
    };
    let data = {
        catZone: 'information',
        catN1: 'nn',
        catTag: 'nn',
        catType: 'content-page',
    };
    const staticPageType = Object.keys(checkStaticPageType)
        .filter(key => {
            if (checkStaticPageType[key]) {
                return checkStaticPageType[key].selectors.filter(selector => qq(selector).length).length;
            }
            return false;
        })
        .pop();

    if (staticPageType && checkStaticPageType[staticPageType]) {
        data = {
            ...data,
            ...checkStaticPageType[staticPageType].callbacks.reduce((data, callback) => {
                data = {
                    ...data,
                    ...callback(),
                };
                return data;
            }, {}),
        };
    }
    if (gtmData) {
        data = {
            ...data,
            ...getGTMCategoryData(),
        };
    }
    return data;
};

const getGTMHomeData = function() {
    const userType = gr && gr.user ? 'registered' : 'anonymous';
    return {
        catZone: 'home',
        catN1: 'nn',
        catTag: 'nn',
        catType: 'home',
        label: `new_home_${userType}`,
        pageType: `home_${userType}`,
    };
};

const getGTMCollectionsCategoryDate = function() {
    return {
        catZone: 'section-images',
        catN1: 'collections',
        catTag: 'nn',
        catType: 'item-list',
    };
};

const getGTMLegalData = function() {
    return {
        catN1: 'legal-information',
    };
};

const getGTMPricingData = function() {
    return {
        catZone: 'profile',
        catN1: 'subscription',
        catTag: 'nn',
        catType: 'product-page',
    };
};

const getGTMAuthorData = function() {
    const authorElement = q('.author-info .link--text');
    const authorName = authorElement ? authorElement.textContent : '';
    return {
        pageType: 'author_page',
        authorName: authorName ? authorName.replace('@', '').trim() : 'nn',
    };
};

const getSpecificGTMData = function(type: GTMProjectTypes) {
    const specificGTMDataMethods: GTMSpecificMethods = {
        category: [getGTMCategoryLandingData],
        detail: [getGTMDetailData],
        keyword: [getGTMKeywordLandingData],
        popular: [getGTMCategoryLandingData],
        search: [getGTMSearchData],
        static: [getGTMStaticData],
        tags: [getGTMTagsData],
    };
    return {
        ...specificGTMDataMethods[type].reduce((acc, method) => {
            acc = {
                ...acc,
                ...method(),
            };
            return acc;
        }, {}),
    };
};

const getSpecificType = function() {
    const view = (getPathValue('body.dataset.hbsTemplate', document) || 'static').trim();
    return (view.match(/search|category|keyword|popular|detail|static|tags/gi) ? view : 'static') as GTMProjectTypes;
};

const getOptimizeData = function() {
    try {
        for (const gtm in google_tag_manager) {
            if (gtm.match(/^GTM/)) {
                if (google_tag_manager[gtm].experiment) {
                    return {
                        // 'pageOptimizationContainer': gtm,
                        // 'pageOptimizationExperiment': google_tag_manager[gtm].experiment.split("$")[0],
                        pageOptimizationVersion: 'Variation: ' + google_tag_manager[gtm].experiment.split('$')[1],
                    };
                }
            }
        }
    } catch (err) {}

    return {
        pageOptimizationVersion: 'nn',
    };
};

function getGTMEcommerceDefault(currency: GTMCurrencyTypes) {
    return {
        list: isLandingPaid ? 'landing_sem' : 'pricing',
        currencyCode: currency,
    };
}

function getGTMProductCategory() {
    const lastPush = getCookieLastPush();
    const catZone = lastPush['catZone'] || 'direct';
    const catType = lastPush['catType'] || 'direct';
    return catZone + '/' + catType;
}

function getCookieLastPush() {
    const lastPushCookie = JSON.parse(cookie.getItem('gtm_last_push') || '{}');
    const lastPushStorage = JSON.parse(localStorage.getItem('gtm', 'gtm_last_push') || '{}');
    cookie.removeItem('gtm_last_push');
    return {
        ...lastPushCookie,
        ...lastPushStorage,
    };
}

export function getGTMClick(plansProduct: GTMProduct[], currency: GTMCurrencyTypes) {
    const options = getGTMEcommerceDefault(currency);

    const ecommerce: GTMEcommerce = {
        ecommerce: {
            currencyCode: options.currencyCode,
            click: {
                actionField: {
                    list: options.list,
                },
                products: plansProduct,
            },
        },
    };

    gtm({ event: 'ecommerce-click' }, true, ecommerce);
}

export function getGTMAddToCart(plansProduct: GTMProduct[], currency: GTMCurrencyTypes) {
    const options = getGTMEcommerceDefault(currency);

    const ecommerce: GTMEcommerce = {
        ecommerce: {
            currencyCode: options.currencyCode,
            add: {
                actionField: {
                    list: options.list,
                },
                products: plansProduct,
            },
        },
    };

    gtm({ event: 'ecommerce-add-to-cart' }, true, ecommerce);
}

export function getGTMImpressions(plansProduct: GTMProduct[], currency: GTMCurrencyTypes) {
    if (!plansProduct.length) return;

    const options = getGTMEcommerceDefault(currency);

    let positionButtons = 1;
    plansProduct.forEach(plan => {
        plan.list = options.list;
        plan.position = positionButtons;
        positionButtons++;
    });

    const ecommerce: GTMEcommerce = {
        ecommerce: {
            currencyCode: options.currencyCode,
            impressions: plansProduct,
        },
    };

    gtm({ event: 'ecommerce-impressions' }, true, ecommerce);
}

export function getGTMProductDefault(plan: HTMLElement | HTMLLinkElement) {
    const frequency = plan.dataset.frequency as string;
    const price = plan.dataset.priceNumber as string;
    const code = plan.dataset.code as string;
    const coupon = plan.dataset.couponApplied ? plan.dataset.couponApplied : 'nn';
    const planName = 'month' === frequency ? 'monthly' : 'annual';
    const productId = `${code}-${planName.toUpperCase()}`;
    const user = gr && gr.user ? gr.user : undefined;
    const userType = user ? user.user_type_fr : 'anonymous';

    const product: GTMProduct = {
        name: planName,
        id: productId,
        price,
        variant: 'new',
        category: `${userType}/${getGTMProductCategory()}` || 'nn',
        brand: 'nn',
        coupon,
        quantity: 1,
    };

    return product;
}

export const gtm = function(options?: GTMArguments<string | Function>, addDefaults = true, ecommerce?: GTMEcommerce) {
    if (AVOID_GTM_EVENT_URLS.includes(window.location.pathname)) {
        return;
    }

    if ('undefined' === typeof ww.dataLayer) {
        ww.dataLayer = ww.dataLayer || [];
    }

    let data = {
        event: 'gtm.datalayer',
        ...options,
    };

    if (addDefaults) {
        const type = getSpecificType();

        data = {
            ...data,
            // col.page
            ...getGTMPageData(),
            // col.user
            ...getGTMUserData(),
            // col.cat
            ...getGTMCategoryData(),
            // Optimize
            ...getOptimizeData(),
        };

        if (type) {
            data = {
                ...data,
                ...getSpecificGTMData(type),
            };
        }
    }

    if (ecommerce) {
        data = {
            ...data,
            ...ecommerce,
        };
    }

    if ('download' === data.event) {
        const location = options && typeof options.location === 'string' ? options.location : undefined;
        data = {
            ...data,
            ...getGTMDownloadData(location || ''),
        };
    }

    localStorage.setItem('gtm', 'gtm_last_push', JSON.stringify(data));

    ww.dataLayer.push(data);
};

type GTMCurrencyTypes = 'EUR' | 'USD';
type GTMProductTypes = 'monthly' | 'annual';
type GTMProjectTypes = 'search' | 'category' | 'keyword' | 'detail' | 'static' | 'popular' | 'tags';
type GTMMethodType = () => { [name: string]: any };
type GTMSpecificMethods = { [Name in GTMProjectTypes]: GTMMethodType[] };

export interface GTMArguments<T> {
    [name: string]: T;
}

interface GTMData {
    [name: string]: any;
}

interface GTMPageTypeTesters {
    [name: string]: {
        callbacks: GTMMethodType[];
        selectors: string[];
    };
}

export interface GTMEcommerce {
    ecommerce: {
        currencyCode: GTMCurrencyTypes;
        impressions?: GTMProduct[];
        click?: {
            actionField: {
                list: string;
            };
            products: GTMProduct[];
        };
        add?: {
            actionField: {
                list: string;
            };
            products: GTMProduct[];
        };
    };
}

export interface GTMProduct {
    name: GTMProductTypes;
    id: string;
    price: string;
    variant: string;
    category: string;
    brand: string;
    coupon: string;
    quantity: number;
    list?: string;
    position?: number;
}
