import { delegate, q, ww } from 'bobjoll/ts/library/dom';
import { localStorage } from 'bobjoll/ts/library/storage';
import { getQueryStringFromURL } from 'Library/helpers/url';

import { DeviceManager } from './device.api';

const isDeviceManager = q('.device-manager');
const IDENTIFY_API = 'xhr/device-manager/identify';
const deviceManagerEvents = () => {
    const manager = new DeviceManager();

    manager.btnCloseMessageExceeded &&
        manager.btnCloseMessageExceeded.addEventListener('click', () => manager.removeMessageExceeded());

    manager.removeButtons.forEach(button => {
        const device = (button.closest('.table-responsive--item') as HTMLElement).dataset.id;
        device && button.addEventListener('click', () => manager.deleteDevice(device));
    });

    manager.helpButtons.forEach(button => {
        button.addEventListener('click', () => DeviceManager.showModal('help'));
    });

    manager.cancelButton.addEventListener('click', () => DeviceManager.showModal('discard'));
    manager.saveButton.addEventListener('click', () => manager.saveChanges());

    delegate('#device-manager-modal-discard', 'click', () => manager.cancelChanges());
    delegate('#device-manager-modal-save', 'click', () => {
        ww.ModalInstance.hide();
        manager.saveChanges();
    });
};

const deviceLimitEvents = () => {
    !isDeviceManager && DeviceManager.notificationError('limit');

    delegate('.check-device-limit', 'click', (event: Event) => {
        event && event.preventDefault();
        showLimitDeviceModal();
    });
};

const getStorageDevice = () => {
    const storage = localStorage.getItem('session', IDENTIFY_API);
    let deviceData;

    if (storage) {
        try {
            deviceData = JSON.parse(storage) as StorageDevice;
        } catch (err) {
            console.warn('device data parse failed', err);
        }
    }

    return deviceData;
};

export const showModal429 = () => DeviceManager.showModal('limit');

const showLimitDeviceModal = () => {
    const modal = DeviceManager.unsavedChanges ? 'discard' : 'limit';
    DeviceManager.showModal(modal);
};

export const checkUserDevice = async (force = false) => {
    const current = new Date().getTime();
    const storageDeviceStatus: StorageDevice = {
        disabled: false,
        expiry: current + DeviceManager.expiryStorage,
    };
    const storage = getStorageDevice();

    if (storage && storage.expiry > current && !force) {
        if (storage.disabled) {
            deviceLimitEvents();
        }
    } else {
        const response = await DeviceManager.api(DeviceManager.IDENTIFY_API, {});
        if (response.success && response.disabled) {
            storageDeviceStatus.disabled = true;
            deviceLimitEvents();
        }
        localStorage.setItem('session', IDENTIFY_API, JSON.stringify(storageDeviceStatus));
    }
};

export const isUserDeviceBlocked = () => {
    const current = new Date().getTime();
    const storage = getStorageDevice();
    const result = storage && storage.expiry > current && storage.disabled;

    if (result) {
        showLimitDeviceModal();
    }

    return result;
};

export const addEvents = () => {
    if (isDeviceManager) {
        deviceManagerEvents();
        checkUserDevice(true);
        return;
    }

    gr && gr.user && checkUserDevice();
    gr.addEventListener('gr:update:user', checkUserDevice);
};

export const clearLocalStorage = () => {
    const status = getQueryStringFromURL('devicenotify');
    if (status == 'success') {
        localStorage.removeItem('session', IDENTIFY_API);
    }
};

export const addNotifications = () => {
    const status = getQueryStringFromURL('devicenotify');

    status == 'success' && DeviceManager.notificationSuccess();
    status == 'error' && DeviceManager.notificationError();
    status == 'expired' && DeviceManager.showModal('expired');
};

interface StorageDevice {
    disabled: boolean;
    expiry: number;
}
