import { __ } from "Project/ts/partials/language";

import { numberNormalizer } from "../helpers";

export function ngettext(str1: string, str2: string, n: string | number) {
    return __(numberNormalizer(n) > 1 ? str2 : str1, n);
}

export function normalizeStringGTM(str: string) {
    try {
        return str
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .trim()
            .replace(/[\s|\/]+/gi, '-')
            .replace(/[`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]+/gi, '');
    } catch {
        return str;
    }
}

export const maxLength = (str: string, length: number) =>
    str.length > length ? `${str.substr(0, length)}...` : str;

export const escapeQuotes = (str: string) =>
    str
        .replace(/\\/g, '\\\\')
        .replace(/\u0008/g, '\\b')
        .replace(/\t/g, '\\t')
        .replace(/\n/g, '\\n')
        .replace(/\f/g, '\\f')
        .replace(/\r/g, '\\r')
        .replace(/'/g, "\\'")
        .replace(/"/g, '\\"');

export const unescapeQuotes = (str: string) =>
    str
        .replace('\\\\', '\\')
        .replace('\\b', '/\u0008/g')
        .replace('\\t', '\t')
        .replace('\\n', '\n')
        .replace('\\f', '\f')
        .replace('\\r', '\r')
        .replace("\\'", "'")
        .replace('\\"', '"');
