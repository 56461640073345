export const getRangeValue = function(value: number, dictionary: Range[]) {
    return dictionary.reduce(
        (acc: string | null, settings) => (
            (acc =
                value >= settings.range.min && (settings.range.max ? value <= settings.range.max : true)
                    ? settings.range.max
                        ? `${settings.range.min}-${settings.range.max}`
                        : `+${settings.range.min}`
                    : acc),
            acc
        ),
        null,
    );
};

export const getRangeFriendlyValue = function(value: number, dictionary: Range[]) {
    const range = dictionary
        .filter(settings => {
            return value >= settings.range.min && (settings.range.max ? value <= settings.range.max : true);
        })
        .shift();
    return range ? range.friendlyValue : null;
};

export interface Range {
    range: {
        min: number;
        max?: number;
    };
    friendlyValue: string;
}
