import { delegate, delegateRemove, q, qq } from 'bobjoll/ts/library/dom';
import { InsertSettings } from 'bobjoll/ts/partials/alert-v2.0';
import { __ } from 'Partials/language';
import { setGrLgUri } from 'Partials/user/user.helpers';
import { view } from 'Partials/view';
import { UserService } from 'Project/ts/service/user';
import { Favorite } from 'User/favorite';

const ANIMATION_DURATION = 2000;
const alertV2 = () => import('bobjoll/ts/partials/alert-v2.0');

const insertAlert: InsertSettings = {
    content: __('%sAssets you like are added to Favorites.%s', '<div data-notification="like">', '</div>'),
    title: __('Liked item'),
    type: 'info',
    fixed: false,
    link: {
        text: __('View'),
        arrow: true,
        clickAndClose: true,
        target: '_self',
        url: '/user/bookmarks',
    },
};

const eventHandler = {
    toggle: function(this: HTMLButtonElement) {
        const notificationExists = q('[data-notification="like"]') ? true : false;
        const type = this.dataset.rt;
        const action = this.classList.contains('active') ? 'unlike' : 'like';
        const resource = this.dataset.rid;

        this.classList.toggle('active');
        this.classList.add('init-animation');
        Favorite.setButtonFavouriteState(this, this.classList.contains('active'));

        if (resource && type && action) {
            UserService.Favorite.toggle({ resource, type, action }).then(async response => {
                if (!response.success) {
                    setGrLgUri();
                    window.location.href = LOGIN_URL;
                    return;
                }

                if (response.success) {
                    if (
                        'add' === response.data.action &&
                        FEATURE_NEW_COPIES_COLLECTIONS_BY_COUNTRY &&
                        !notificationExists
                    ) {
                        const alert = await alertV2();
                        new alert.default().new(insertAlert);
                    }

                    Favorite.updateToggle(resource);
                    Favorite.updateCounter(resource);
                }
                eventHandler.remove.call(this);
            });
        }
        setTimeout(() => this.classList.remove('init-animation'), ANIMATION_DURATION);
    },
    remove: function(this: HTMLButtonElement) {
        const resourceItem = this.parent('.showcase__item');
        if (view.getView().match(/user/i) && resourceItem) {
            if (qq('.pagination__next, .pagination__prev').length) window.location.reload();
            if (resourceItem.parentElement) resourceItem.parentElement.removeChild(resourceItem);
        }

        if (q('.user--bookmarks')) {
            if (qq('.user--bookmarks .showcase__item').length === 0) window.location.reload();
        }
    },
    update: function() {
        Favorite.updateState();
    },
};

const addEventListener = function() {
    delegate('.button--favorite', 'click', eventHandler.toggle);
    window.addEventListener(`printgroup-static`, eventHandler.update);
    window.addEventListener(`printgroup-favorites`, eventHandler.update);
};

const removeEventListener = function() {
    delegateRemove('.button--favorite', 'click', eventHandler.toggle);
    window.removeEventListener(`printgroup-static`, eventHandler.update);
    window.removeEventListener(`printgroup-favorites`, eventHandler.update);
};

export const FavoriteEvents = {
    addEventListener,
    removeEventListener,
};
