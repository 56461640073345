export class Loader {
    counter: number;
    current: number;
    images: HTMLElement[];
    instance: any;
    loader: HTMLElement;
    percent: number;
    total: number;
    view: string;

    initialize(view: string) {
        this.reset();

        let loader: HTMLElement | null = document.getElementById('page-loader');

        if (!loader) {
            loader = document.createElement('div');
            loader.id = 'page-loader';
            loader.classList.add('page-loader');

            document.body.insertAdjacentElement('afterbegin', loader);
        }

        this.view = view;
        this.counter = 0;
        this.current = 0;
        this.loader = loader;
    }

    setListener(images: HTMLElement[]) {
        this.images = images;
        this.percent = (1 / images.length) * (100 - this.current);
        this.total = images.length - 1;

        this.instance = () => {
            this.percentage(this.percent);
            this.counter++;
        };

        if (images && images.length > 0) {
            [].forEach.call(images, (image: HTMLImageElement) => {
                if (image.complete) {
                    this.instance();
                } else {
                    image.onload = image.onabort = image.onerror = this.instance;
                }
            });
        }

        if (images.length === 0) {
            this.instance();
        }
    }

    percentage(percent: number) {
        this.current += percent;
        this.loader.style.width = this.current + '%';

        if (this.counter == this.total || this.current >= 100) {
            const event = new Event(this.view + '-loaded');

            this.loader.className = 'page-loader hide';

            window.dispatchEvent(event);
        }
    }

    reset() {
        if (this.loader) {
            this.loader.style.width = '0%';
            this.loader.classList.remove('hide');
        }
    }
}

export var loader = new Loader();
