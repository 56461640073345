import 'Library/variables';

import { Resource } from 'Project/ts/partials/showcase';

import { shuffleArray } from '../helpers';

export const mapBoosterResources = (response: Response, fromQuery?: string) => {
    if (response.data.detail?.type !== 'model') {
        response['data']['resources'] =
            (response.data && response.data.resources && response.data.resources['boost']) || [];

        return response as ResponseMapped;
    }

    if (response.meta?.multi && response.data?.resources) {
        const resources = response.data.resources;
        const boost = resources['boost'] || [];
        const latestBoost = shuffleArray(resources['latest_boost'] || []);
        const premium = resources['premium'] || [];
        const essential = resources['essential'] || [];

        if (boost) {
            const ignorePositions = FIXED_RESOURCES_IN_RESULTS;

            if (boost.length > 0) {
                for (let i = 0; i < boost.length; i++) {
                    const item = boost[i];
                    item.cacheId = i;
                    item.cacheArr = 'boost';
                    if (fromQuery) {
                        item.url_source += `&from_query=${fromQuery}`;
                    }
                }
            }

            if (latestBoost && latestBoost.length > 0) {
                for (let i = 0; i < latestBoost.length; i++) {
                    const item = latestBoost[i];
                    if (item) {
                        const pos = Math.floor(Math.random() * (boost.length - 1 - ignorePositions) + ignorePositions);
                        item.cacheId = i;
                        item.cacheArr = 'latest_boost';
                        boost.splice(pos, 0, item);
                        if (fromQuery) {
                            item.url_source += `&from_query=${fromQuery}`;
                        }
                    }
                }
            }

            if (premium && premium.length) {
                premium.forEach((item: any, index: number) => {
                    const pos = Math.floor(Math.random() * (boost.length - 1 - ignorePositions) + ignorePositions);
                    item.cacheId = index;
                    item.cacheArr = 'premium';
                    boost.splice(pos, 0, item);
                    if (fromQuery) {
                        item.url_source += `&from_query=${fromQuery}`;
                    }
                });
            }

            if (essential && essential.length) {
                essential.forEach((item: any, index: number) => {
                    const pos = Math.floor(Math.random() * (boost.length - 1 - 0) + 0);
                    item.cacheId = index;
                    item.cacheArr = 'essential';
                    boost.splice(pos, 0, item);
                    if (fromQuery) {
                        item.url_source += `&from_query=${fromQuery}`;
                    }
                });
            }
        }

        response.data.resources = boost;
    }
    return response as ResponseMapped;
};

export interface Response {
    data: {
        resources:
            | Resource[]
            | {
                  boost: Resource[];
                  latest_boost: Resource[];
                  premium: Resource[];
                  essential: Resource[];
              };
        detail?: Resource;
    };
    meta: {
        multi: boolean;
    };
}
export interface ResponseMapped {
    data: {
        resources: Resource[];
    };
    meta: {
        multi: boolean;
    };
}
