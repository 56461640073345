import { Settings } from 'bobjoll/ts/library/settings';

export const getOS = (): string => {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    const unknownOS = 'Unknown';

    const operatingSystems = {
        'Mac OS': macosPlatforms.includes(platform),
        iOS: iosPlatforms.includes(platform),
        Windows: windowsPlatforms.includes(platform),
        Android: /Android/.test(userAgent),
        Linux: /Linux/.test(platform),
        Unknown: true,
    };

    return Object.keys(operatingSystems).find(os => operatingSystems[os]) || unknownOS;
};

export const isMobile = (strict = false) => {
    return eval(`${window.innerWidth}${strict ? '<' : '<='}${Settings.breakpoints.sm}`);
};

export const isTablet = (
    tabletOptions: TabletOptions = {
        checkIOS: false,
        screenWidth: false,
    },
) => {
    const { screenWidth, checkIOS } = tabletOptions;
    const isIOS = checkIOS ? isIOSDevice() : false;
    const widthScreen = screenWidth ? screen.width : window.innerWidth;
    const widthCompare = isIOS ? window.innerWidth : widthScreen;
    return widthCompare < Settings.breakpoints.lg;
};

export const isIOSDevice = () => {
    return /iPad|iPhone/i.test(navigator.userAgent) || (navigator.maxTouchPoints > 1 && isSafariBrowser());
};

export const isAndroidDevice = () => {
    return /Android/i.test(navigator.userAgent);
};

export const getBrowserVersion = (split = ' ') => {
    const ua = window.navigator.userAgent;

    let tem;
    let browser = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(browser[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
    }

    if (browser[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null)
            return tem
                .slice(1)
                .join(' ')
                .replace('OPR', 'Opera');
    }

    browser = browser[2] ? [browser[1], browser[2]] : [navigator.appName, navigator.appVersion, '-?'];

    if ((tem = ua.match(/version\/(\d+)/i)) != null) browser.splice(1, 1, tem[1]);

    return browser.join(split);
};

export const isOptimalBrowser = () => {
    if (window.innerWidth <= Settings.breakpoints.sm) return false;

    const ua = window.navigator.userAgent;
    const browsers = ua.match(/(chrome|safari|firefox(?=\/))\/?\s*(\d+)/i) || [];

    if (!browsers.length) return false;

    const exceptionOpera = ua.match(/\b(OPR|Edge)\/(\d+)/);
    const exceptionSafari = ua.match(/version\/(\d+)/i);

    if ('Chrome' === browsers[1] && exceptionOpera != null) return false;

    const browser = browsers[2] ? [browsers[1], browsers[2]] : [navigator.appName, navigator.appVersion, '-?'];
    const validVersion = {
        chrome: 60,
        firefox: 55,
        safari: 11,
    };

    if (exceptionSafari != null && exceptionSafari[1]) {
        browser.splice(1, 1, exceptionSafari[1]);
    }

    const nameBrowser = browser[0] ? browser[0].toLowerCase() : '';
    const versionBrowser = browser[1] ? parseInt(browser[1]) : 0;

    if (nameBrowser && versionBrowser && validVersion[nameBrowser] && versionBrowser >= validVersion[nameBrowser]) {
        return true;
    }

    return false;
};

export const detectIE = () => {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
        const rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    return false;
};

export const isSafariBrowser = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.includes('safari') && !userAgent.includes('chrome');
};

const eventMobileOrientation = (media: MediaQueryList | MediaQueryListEvent) => {
    const event = new Event('mobile:landscape');
    media.matches && window.dispatchEvent(event);
};

export const mobileOrientationChange = () => {
    const breakpoint = window.matchMedia(`(min-width: ${Settings.breakpoints.sm}px)`);
    if (!breakpoint) return;

    if (typeof breakpoint.addEventListener === 'function') {
        breakpoint.addEventListener('change', eventMobileOrientation);
    } else {
        breakpoint.addListener(eventMobileOrientation);
    }
};

const eventDesktopToTablet = (media: MediaQueryList | MediaQueryListEvent) => {
    const event = new CustomEvent('desktop:change', {
        detail: { isMobile: !media.matches },
    });
    window.dispatchEvent(event);
};

export const changeDesktopToTablet = () => {
    const breakpoint = window.matchMedia(`(min-width: ${Settings.breakpoints.lg}px)`);
    if (!breakpoint) return;

    if (typeof breakpoint.addEventListener === 'function') {
        breakpoint.addEventListener('change', eventDesktopToTablet);
    } else {
        breakpoint.addListener(eventDesktopToTablet);
    }
};

interface TabletOptions {
    checkIOS?: boolean;
    screenWidth?: boolean;
}
