import { q, qq, ww } from 'bobjoll/ts/library/dom';
import { Spinner } from 'Partials/spinner';
import Validation from 'Partials/validation';
import { globals } from 'Project/ts/library/helpers/globals';
import { trackerInterface } from 'Project/ts/library/helpers/tracker';
import { Recaptcha } from 'Project/ts/library/recaptcha';
import Report from 'Project/ts/library/report';

import { DetailApi } from '../api';
import { blurDetailWhileIsOpened } from '../helpers/detail.helpers.event';
import { getDetail, getDetailModalContainer } from '../helpers/detail.helpers.generic';
import { triggerReportTracker } from '../trackers/detail.tracker.report';

const recaptchaReport = new Recaptcha('report');

export const handlerReportClick = async function(this: HTMLButtonElement, event: Event) {
    const spinner = new Spinner(document.body);
    const viewData = DetailApi.getActive();
    const { default: viewTemplate } = await import('Templates/parts/modal-report.hbs');

    event.preventDefault();
    event.stopPropagation();

    if (recaptchaReport.defaultSettings.recaptcha && !recaptchaReport.loaded.report) {
        await recaptchaReport.load();
    }

    triggerReportTracker();

    const modalElement = ww.ModalInstance.print(
        {
            name: 'report',
            html: viewTemplate({ ...globals, ...viewData }),
        },
        true,
    );

    if (modalElement) {
        const modalReportSubmitElement = q('form', modalElement) as HTMLFormElement | undefined;

        modalElement.classList.add('scrollable');

        if (modalReportSubmitElement) {
            recaptchaReport.addEventSubmit(modalReportSubmitElement, handlerReportSubmit);

            const submitButton = q('[type="submit"]', modalReportSubmitElement);
            const submitID = 'report-download-submit';

            recaptchaReport.render(modalReportSubmitElement, submitButton, submitID);
        }

        if (getDetailModalContainer()) {
            q('.modal__close', modalElement)?.addEventListener('click', blurDetailWhileIsOpened);
        }
    }

    spinner.done();
};
const handlerReportSubmit = async function(form: HTMLFormElement, ...args: any[]) {
    if (args[0] && args[0] instanceof Event && args[1] && args[1] instanceof Event) {
        args[0].preventDefault();
        args[1].stopPropagation();
    }

    if (Validation.checkValidity(form)) {
        try {
            const detail = (document.querySelector('#modal-detail.active .detail') ||
                document.querySelector('#main .detail')) as HTMLElement;

            if (detail) {
                const params: { [name: string]: string } = {};
                const authorId = detail.dataset.authorId;
                const authorName = detail.dataset.authorName;
                const resourceId = detail.dataset.id;
                const resourcePremium = detail.dataset.resourcePremium;

                trackerInterface.send('ga', 'send', 'event', 'alerta_foto', authorName, resourceId);

                if (authorId) {
                    params['author_id'] = authorId;
                }

                if (authorName) {
                    params['author_name'] = authorName;
                }

                if (resourceId) {
                    params['resource_id'] = resourceId;
                }

                if (resourcePremium) {
                    params['resource_premium'] = resourcePremium;
                }

                await Report(form, params);
            }
        } catch (err) {
            console.warn(err);
        }
    }

    const submitButton = q('[type="submit"]', form);
    const submitID = 'report-download-submit';
    recaptchaReport.reset(submitButton, submitID);
};

export const initializeReport = function(parentElement?: HTMLElement) {
    const detailElement = getDetail() || document;
    qq('[data-modal="modal-report"]', parentElement || detailElement).forEach(buttonElement => {
        buttonElement.classList.remove('modal__trigger');
        buttonElement.addEventListener('click', handlerReportClick);
    });
};
