import { q, ww } from 'bobjoll/ts/library/dom';
import View from 'BobjollView';

import { modalAddDefaults, modalPrintDefaults } from './modal.config';
import { ModalAddSettings, ModalHideSettings, ModalPrintSettings } from './modal.vm';

ww.ModalsActive = [];
ww.ModalsMultilayer = [];

const add = (options: ModalAddSettings) => {
    const config = { ...modalAddDefaults, ...options };

    let modalElement = document.getElementById(`modal-${config.name}`);

    if (!modalElement) {
        const template = require(`BobjollTemplate/modal-v1.0/element.html.hbs`);

        document.body.insertAdjacentHTML('beforeend', View.render(template, config));

        modalElement = document.getElementById(`modal-${config.name}`);

        if (modalElement && options.lastHbsTemplate) {
            modalElement.setAttribute('data-last-hbs-template', options.lastHbsTemplate);
        }
    }

    if (config.multilayer && ww.ModalsMultilayer.indexOf(`modal-${config.name}`) < 0) {
        ww.ModalsMultilayer.push(`modal-${config.name}`);
    }

    return modalElement;
};

const hide = (options?: ModalHideSettings) => {
    if (ww.ModalsActive.length > 0) {
        const modalSpliceList: number[] = [];
        const modalHideAll = options && options.all ? true : false;
        const modalEvent = new Event('hide');

        [].forEach.call(ww.ModalsActive, (id: string, index: number) => {
            const show = options && typeof options.show !== 'undefined' ? (options.show === id ? true : false) : false;
            const modal = document.getElementById(id);

            if (modal && (modalHideAll || !show)) {
                if (modal.dataset.lastHbsTemplate) {
                    document.body.setAttribute('data-hbs-template', modal.dataset.lastHbsTemplate);
                }

                const multilayer = ww.ModalsMultilayer.indexOf(id) < 0 ? false : true;

                if (modalHideAll || (multilayer && ww.ModalsActive.length == 1) || (!show && !multilayer)) {
                    modal.classList.remove('active');

                    modalSpliceList.push(index);

                    if (typeof options === 'undefined' || typeof options.dispatch === 'undefined' || options.dispatch)
                        modal.dispatchEvent(modalEvent);
                }
            }
        });

        modalSpliceList.sort((a, b) => b - a).forEach(position => ww.ModalsActive.splice(position, 1));
    }

    ww.ModalsActive.length === 0 && preventOverflowScrollbar(true);
};

const print = (options: ModalPrintSettings, showModal = true) => {
    const config = { ...modalPrintDefaults, ...options };

    let modalElement = document.getElementById(`modal-${config.name}`);

    if (!modalElement) {
        const addSettings: ModalAddSettings = {
            name: config.name,
            multilayer: config.multilayer,
        };

        if (config.media) {
            addSettings.media = config.media;
        }

        add(addSettings);

        modalElement = document.getElementById(`modal-${config.name}`);
    }

    if (modalElement) {
        const modalContent = modalElement.querySelector('.content');

        if (modalContent) {
            modalContent.innerHTML = config.html;
        }

        if (showModal) {
            show(`modal-${config.name}`);
        }
    }

    return modalElement;
};

const show = (id: string) => {
    const modalElement = document.getElementById(id);
    const modalEvent = new Event('show');

    if (modalElement) {
        modalElement.classList.add('active');
        preventOverflowScrollbar();

        if (modalElement.dataset['multilayer']) {
            if (ww.ModalsMultilayer.indexOf(id) < 0) {
                ww.ModalsMultilayer.push(id);
            }
        }

        if (ww.ModalsActive.indexOf(id) < 0) {
            ww.ModalsActive.unshift(id);
        }

        modalElement.dispatchEvent(modalEvent);
    }
};

const preventOverflowScrollbar = (reset = false) => {
    const scrollBarWidth = window.innerWidth - document.body.offsetWidth;

    if (scrollBarWidth > 0) {
        const value = !reset ? `calc(100% - ${scrollBarWidth}px)` : '';
        const element = q('body');
        if (element) element.style.width = value;
    }

    document.body.classList[reset ? 'remove' : 'add']('overflow-hidden');
};

export const Modal = {
    add,
    hide,
    print,
    show,
};
