import { getBannerActionEvent, isContentCampaign } from 'Partials/campaign-php/campaign.helpers';

import * as GA4Model from '../ga4.model';

const getManualGA4Event = (options: { [name: string]: any }): GA4Model.GA4ManualEvents => {
    return {
        autopromoAbandonedCart: {
            microfunnel: {
                category: options.category || GA4Model.CATEGORY_BY_DEFAULT,
                label: options.label || 'retargeting',
                action: options.action || 'onload',
                noninteraction: !options.action || options.action === 'onload',
            },
            ga4Options: {
                location: 'popup_right_corner',
                action: 'internal_retargeting',
                interaction_type: options.action || 'onload',
                campaign_name: 'no_campaign',
                product_price: 'discount',
                discount_type: options.discount || 0,
            },
        },
        downloadLimitCoupon: {
            microfunnel: {
                label: `pre-downlimit${options.action === 'click' ? '-gopremium' : ''}`,
                action: options.action || 'onload',
                noninteraction: !options.action || options.action === 'onload',
            },
            ga4Options: {
                location: 'popup_right_corner',
                action: `pre_downlimit_${options.registered ? 'registered' : 'anonymous'}`,
                interaction_type: options.action || 'onload',
                campaign_name: 'no_campaign',
                product_price: 'discount',
                discount_type: options.discount || 0,
            },
            replaceCampaignParams: true,
        },
        autopromoBanner: {
            microfunnel: {
                label: `autopromo${options.action === 'click' ? '-gopremium' : ''}`,
                noninteraction: !options.action || options.action === 'onload',
            },
            ga4Options: {
                event_name: options.event_name || 'microfunnel',
                location: options.location || '',
                action: `autopromo_adblocker_code_${options.ga4Name || ''}`,
                interaction_type: options.action || 'onload',
                ...options.freepikAutopromoOptions,
            },
        },
        detailGoPremiumSidebar: {
            microfunnel: {
                category: options.category || 'premium',
                action: options.action || 'onload',
                label: `detailp${options.action === 'click' ? '-gopremium' : ''}`,
                noninteraction: !options.action || options.action === 'onload',
            },
            ga4Options: {
                location: 'resource_detail_premium',
                action: 'download_premium',
                interaction_type: options.action || 'onload',
                resource_id: options.resource_id || '',
                resource_type: options.resource_type || '',
            },
            replaceCampaignParams: true,
        },
        detailGoPremiumDownloadOptions: {
            microfunnel: {
                category: options.category || 'premium',
                action: options.action || 'onload',
                label: options.label || 'downloadoption',
                noninteraction: !options.action || options.action === 'onload',
            },
            ga4Options: {
                location: `resource_detail_${options.resource_premium ? 'premium' : 'free'}`,
                action: 'modal_download_free_go_premium',
                interaction_type: options.action || 'onload',
                resource_id: options.resource_id || '',
                resource_type: options.resource_type || '',
            },
            replaceCampaignParams: true,
        },
        modalAttributionBannerOnload: {
            microfunnel: {
                label: 'howatrib',
                noninteraction: true,
            },
            ga4Options: {
                location: 'banner_how_attribute',
                action: 'attribution_go_premium',
                interaction_type: 'onload',
            },
            replaceCampaignParams: true,
        },
        modalAttributionBannerAfterDownloadOnload: {
            microfunnel: {
                label: 'attribution',
                noninteraction: true,
            },
            ga4Options: {
                location: 'banner_attribute',
                action: 'attribution_go_premium',
                interaction_type: 'onload',
            },
            replaceCampaignParams: true,
        },
        editWepikButton: {
            microfunnel: {
                label: `edit-button${options.action === 'click' ? '-download' : ''}`,
                noninteraction: !options.action || options.action === 'onload',
            },
            ga4Options: {
                location: 'resource_detail_premium',
                action: 'edit_premium_detail',
                interaction_type: options.action || 'onload',
            },
            replaceCampaignParams: true,
        },
        campaignBanners: {
            ga4Options: {
                event_name: isContentCampaign() ? 'content_banner' : 'microfunnel',
                location: options.location,
                action:
                    options.type ||
                    `${getBannerActionEvent}${
                        options.concatUserType ? (options.registered ? '_registered' : '_anonymous') : ''
                    }`,
                interaction_type: options.action || 'onload',
                ...options.extraInfo,
            },
            replaceCampaignParams: true,
        },
        linkFaqsMonthly: {
            ga4Options: {
                event_name: options.event_name || 'link_faqs',
                location: options.location || 'navbar_tooltip',
                action: options.action || 'click',
                interaction_type: 'click',
            },
        },
        downloadButtonsMonthly: {
            ga4Options: {
                event_name: options.event_name || 'button_download',
                location: options.location || 'resource_detail',
                file_id: options.file_id || '',
                type: options.type || '',
                license: options.license ? 'premium' : 'free',
                interaction_type: 'click',
            },
        },
        downloadButtonsModels: {
            ga4Options: {
                event_name: options.event_name || 'button_download_model',
                location: options.location || 'resource_detail',
                file_id: options.file_id || '',
                type: options.type || '',
                license: options.license ? 'premium' : 'free',
                interaction_type: 'click',
            },
        },
        tagsThumbnails: {
            ga4Options: {
                event_name: 'tags_thumbnails_click',
                location: 'thumbnails',
                file_id: options.file_id || '',
                link_url: options.link_url || '',
                link_text: options.link_text || '',
                interaction_type: 'click',
            },
        },
        dropdownFilter: {
            ga4Options: {
                event_name: 'dropdown_filter',
                filter_name: options.filter_name || '',
            },
            ignoreDefaultParams: true,
        },
        collections: {
            ga4Options: {
                ...options,
            },
            ignoreDefaultParams: true,
        },
        categoryLandingAutopromo: {
            ga4Options: {
                event_name: 'microfunnel',
                location: 'landing_category_bottom',
                interaction_type: options.interaction_type || 'onload',
                action: 'go_premium',
                type: options.type || '',
                campaign_name: 'no_campaign',
                product_price: 'discount',
                discount_type: options.discount || 0,
            },
            replaceCampaignParams: true,
        },
        homeSections: {
            ga4Options: {
                event_name: options.event_name,
                location: options.location,
                interaction_type: 'click',
                category: options.category,
                from_view: options.from_view,
                link_text: options.link_text || '',
                link_url: options.link_url || '',
                ...options.extraInfo,
            },
            replaceCampaignParams: options.replaceCampaignParams ?? false,
        },
        searchLandings: {
            ga4Options: {
                event_name: options.event_name,
                search_term: options.search_term || '',
                search_total_results: options.search_total_results || 0,
                page_location: window.location.href,
                ...options.extraInfo,
            },
            ignoreDefaultParams: true,
        },
        popularSearchesTags: {
            ga4Options: {
                event_name: 'popular_searches',
                search_term: options.search_term || '',
                location: options.location || '',
                interaction_type: 'click',
            },
        },
        sponsor: {
            ga4Options: {
                event_name: 'affiliate',
                location: options.location || '',
                interaction_type: options.action || 'onload',
                action: 'go_adobe',
                ...options.extra,
            },
            ignoreDefaultParams: true,
        },
        homeLoggedBannerMicrofunnel: {
            ga4Options: {
                event_name: 'microfunnel',
                location: 'banner_home',
                action: 'go_premium',
                label: 'new_home_registered',
                interaction_type: options.action || 'onload',
                link_url: options.link_url || '',
                link_text: options.link_text || '',
            },
        },
        homeAnonymousBanner: {
            ga4Options: {
                event_name: 'button_start_now_home',
                location: 'banner_home',
                action: 'start_now_login',
                label: 'new_home_anonymous',
                interaction_type: 'click',
                link_url: options.link_url || '',
                link_text: options.link_text || '',
            },
        },
        tagRemoveAds: {
            ga4Options: {
                event_name: 'microfunnel',
                location: options.location || '',
                interaction_type: options.action || 'onload',
                action: 'remove_ads',
                campaign_name: 'no_campaign',
                product_price: 'discount',
                discount_type: options.discount || 0,
            },
            replaceCampaignParams: true,
        },
        afterLoginEvents: {
            ga4Options: options,
        },
        mockupBakerAlert: {
            ga4Options: {
                event_name: 'plugin_modal',
                interaction_type: options.action || 'onload',
            },
            ignoreDefaultParams: true,
        },
        menuUser: {
            ga4Options: {
                event_name: 'dropdown_menu',
                link_url: options.link_url || '',
                link_text: options.link_text || '',
            },
            ignoreDefaultParams: true,
        },
        followAuthor: {
            ga4Options: {
                event_name: 'follow_author',
                location: options.location || '',
                author_name: options.author_name || '',
            },
            ignoreDefaultParams: true,
        },
        clickAuthorInHome: {
            ga4Options: {
                event_name: 'author_click',
                location: options.location || '',
                position: options.position || '',
                link_url: options.link_url || '',
                link_text: options.link_text || '',
                author_name: options.author_name || '',
            },
            ignoreDefaultParams: true,
        },
        homeCategories: {
            ga4Options: {
                event_name: 'home_categories',
                location: options.location || '',
                label: options.label || '',
                from_view: options.from_view,
                category: options.category,
            },
            ignoreDefaultParams: true,
        },
        popupVideoLaunch: {
            ga4Options: {
                event_name: 'popup_video_launch',
                location: options.location || '',
                interaction_type: options.interaction_type,
            },
            ignoreDefaultParams: true,
        },
        pricingTeamsForm: {
            ga4Options: {
                event_name: 'form_send',
                location: 'pricing',
            },
            ignoreDefaultParams: true,
        },
        pricingTeamsFormSuccess: {
            ga4Options: {
                event_name: 'form_send_success',
                location: 'pricing',
            },
            ignoreDefaultParams: true,
        },
        cancellationForm: {
            ga4Options: {
                process_name: 'standard_cancellation',
                location: 'my_subscription',
                ...options,
            },
            ignoreDefaultParams: true,
        },
        searchImage: {
            ga4Options: {
                event_name: 'search_by_image',
                ...options,
            },
            ignoreDefaultParams: true,
        },
        searchImageModal: {
            ga4Options: {
                event_name: 'search_by_image_modal',
                ...options,
            },
            ignoreDefaultParams: true,
        },
        searchImageSelection: {
            ga4Options: {
                event_name: 'search_by_image_selection',
                ...options,
            },
            ignoreDefaultParams: true,
        },
        searchImageError: {
            ga4Options: {
                event_name: 'search_by_image_error',
                ...options,
            },
            ignoreDefaultParams: true,
        },
        mockupsBannerClick: {
            ga4Options: {
                event_name: 'content_banner',
                ...options,
            },
            ignoreDefaultParams: true,
        },
        advancedSearchModal: {
            ga4Options: {
                event_name: 'advanced_search_modal',
                ...options,
            },
            ignoreDefaultParams: true,
        },
        bannerTemplatesClick: {
            ga4Options: {
                event_name: 'content_banner',
                ...options,
            },
            ignoreDefaultParams: true,
        },
    };
};

export default getManualGA4Event;
