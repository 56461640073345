import { get, getRequestUrl,mapAdobeArgs } from './adobe.api';
import helpers from './adobe.helpers';
export { getCountryLanguage } from './adobe-express.helpers';

export const Adobe = {
    get,
    helpers: {
        ...helpers,
        getRequestUrl,
        mapAdobeArgs,
    },
};
