import fetch from 'isomorphic-fetch';
import Cache from 'Library/cache';
import { cookie } from 'Library/cookie';
import { md5 } from 'Library/helpers/md5';
import debugBarUpdate from 'Partials/debug';
import axios, { Options, Response } from 'redaxios';

const defaultOptions: Options = { withCredentials: true };
const xhrActivePromises: XHRActivePromises = {};
const getXHRAsync = async function(url: string, settings: any, cache?: boolean, cacheCallback?: (body: JSON) => JSON) {
    const response = await axios<
        JSON & {
            csrfToken?: string;
        }
    >(url, {
        ...settings,
        fetch,
    });
    let body = response.data;

    if (settings.validateStatus && !settings.validateStatus(response.status)) {
        if (response.status === 204) {
            throw { status: 204 };
        }

        if (response.status === 429) {
            throw { status: 429 };
        }

        if (response.status !== 200) {
            throw body;
        }
    }

    debugBarUpdate(body);

    if (cache) {
        const time = getMaxAge(response);

        if (cacheCallback) {
            body = cacheCallback(body);
        }

        Cache.set({ key: url, value: body, time });
    }

    if (body.csrfToken) {
        XHR.setCSRF(body.csrfToken);
    }

    return body;
};

const getXHR = async function(url: string, options?: Options, cacheJSON?: boolean, cacheCallback?: (body: any) => any) {
    if (cacheJSON) {
        const cache = Cache.get(url);

        if (cache) {
            return cache;
        }
    }

    if (!options) {
        options = XHR.settings;
    }

    const settings: any = { ...defaultOptions, ...options };

    settings.headers['X-CSRF-TOKEN'] && XHR.updateCSRFWhenSeveralTabsAreOpened();

    let body: JSON;
    let key = url;

    if (settings.body) {
        const params =
            'string' == typeof settings.body
                ? settings.body
                : settings.body.entries
                ? formDataToString(settings.body)
                : '';
        key = `${md5(url + params)}`;
    }

    try {
        if (!xhrActivePromises[key]) {
            xhrActivePromises[key] = getXHRAsync(url, settings, cacheJSON, cacheCallback);
        }

        body = await xhrActivePromises[key];

        delete xhrActivePromises[key];

        return body;
    } catch (e) {
        setTimeout(() => delete xhrActivePromises[key], 30000);

        if (!e.status) {
            throw Error(`Request to "${url}" has failed. Error: ${e}`);
        }

        throw Error(e);
    }
};

export default getXHR;

export function getMaxAge(response: Response<any>) {
    const cache = response.headers['x-accel-expires-debug'];
    const cacheMaxAge = (cache || '600').match(/\d+/);
    return cacheMaxAge ? parseFloat(cacheMaxAge[0]) : 600;
}

export class XHR {
    public static FormData(fields: { [name: string]: any }) {
        const body = new FormData();
        Object.keys(fields).forEach(function(key: string) {
            body.append(key, fields[key]);
        });
        return body;
    }

    public static settings: Options & {
        headers: NonNullable<Options['headers']>;
    } = {
        headers: {
            'X-CSRF-TOKEN': CSRF_TOKEN,
            'X-Requested-With': 'XMLHttpRequest',
        },
    };

    public static updateCSRFWhenSeveralTabsAreOpened() {
        const csrfTokenSaved = cookie.getItem('csrf_freepik');

        if (csrfTokenSaved && csrfTokenSaved != CSRF_TOKEN) {
            XHR.setCSRF(csrfTokenSaved);
        }
    }

    public static setCSRF(token: string) {
        this.settings.headers['X-CSRF-TOKEN'] = token;
        CSRF_TOKEN = token;
    }
}

export function formDataToString(formData: FormData) {
    const obj: { [name: string]: any } = {};
    formData.forEach(function(value, key) {
        obj[key] = value;
    });
    return JSON.stringify(obj);
}

interface XHRActivePromises {
    [name: string]: Promise<JSON>;
}
