import { Arguments } from './arguments';

export const getUUIDSearchTracker = (): string => {
    let uuidFromUrl = '';

    if (window.location.href.includes('#')) {
        const { uuid } = Arguments.stringToObject(window.location.href.split('#')[1]);
        uuidFromUrl = uuid;
    }

    return uuidFromUrl || TRACKING_UUID_SEARCH || '';
};
