export const showCampaignBanner = function(banner?: BannerTypes) {
    let show = typeof GLOBAL_CAMPAIGN !== 'undefined';
    if (show && banner) show = GLOBAL_CAMPAIGN.config.set[banner];

    return show;
};

export const isContentCampaign = (banner?: BannerTypes) =>
    showCampaignBanner(banner) && !!GLOBAL_CAMPAIGN.config.content['bannerUrl'];

export const getBannerActionEvent = isContentCampaign() ? 'go_content' : 'go_premium';

export type BannerTypes =
    | 'home'
    | 'logged'
    | 'category'
    | 'popular'
    | 'keyword'
    | 'collections'
    | 'search'
    | 'attribution'
    | 'limit'
    | 'adblock'
    | 'detail'
    | 'premium'
    | 'download'
    | 'upgrade'
    | 'pricing';
