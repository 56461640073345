import { AdobePopupOrigin } from 'Library/trackerSend/trackerSend.adobestock';
import { InteractionType, ManualEvent } from './ga4.model';
import { q } from 'bobjoll/ts/library/dom';
import triggerManualGA4Events from './ga4.init-manual-events';

export const sponsorGA4Events = (settings: SponsorGA4Params) => {
    const params: ManualEvent = {
        description: 'sponsor',
        options: {
            location: settings.location,
            action: settings.action,
        }
    };

    if (settings.element) params.options.extra = { type: settings.element };

    triggerManualGA4Events(params);
};

export const getSponsorLocation = (viewType: SponsorReferrer) => {
    const type: Record<SponsorReferrer, SponsorLocation> = {
        '404': 'banner_search_result_404',
        'srp': `banner_${q('.spr-bottom') ? 'bottom' : 'top'}_search_result` as SponsorLocation,
        'idp': 'banner_resource_detail',
    };

    return type[viewType] || '';
};

export const getSponsorPopupOrigin = (origin: AdobePopupOrigin) => {
    const type: Record<AdobePopupOrigin, SponsorPopupOrigin> = {
        'page-button-next': 'next_page',
        'page-button-prev': 'prev_pag',
        'page-input': 'input',
        'search': 'search',
    };

    return type[origin] || '';
};

interface SponsorGA4Params {
    action?: InteractionType,
    element?: SponsorElement | SponsorPopupOrigin,
    location: SponsorLocation,
};

type SponsorLocation =
    'banner_bottom_search_result' |
    'banner_pagination_search_result' |
    'banner_resource_detail' |
    'banner_search_result_404' |
    'banner_top_search_result' |
    'pop_up_right_corner' |
    'pop_up';

type SponsorElement = 'item' | 'logo' | 'more' | 'promo';
type SponsorPopupOrigin = 'search' | 'next_page' | 'prev_pag' | 'input';
type SponsorReferrer = 'srp' | 'idp' | '404';
