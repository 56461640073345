import getManualGA4Event from './data/ga4.manual-events';
import { addEvents } from './ga4.functions';
import * as GA4Model from './ga4.model';

const triggerManualGA4Events = (config: GA4Model.ManualEvent) => {
    const { options, description } = config;
    const event = getManualGA4Event(options)[description];

    if (!event) return;

    addEvents(event, event.ga4Options?.interaction_type);
};

export default triggerManualGA4Events;
