import { q } from 'bobjoll/ts/library/dom';
import { SponsorPixel } from 'common/sponsor-common/sponsor.pixel';
import getFilterValue from 'Partials/filters-v2/getFilterValue';
import { qq } from 'Project/ts/library/dom.q';
import { trackerSend } from 'Project/ts/library/trackerSend';

export const adobeAutopromoProbability = 0.4;

export const initializeAdobeBannerAutopromo = (className: string) => {
    const resourceIds = qq('.showcase__item').map(item => item.dataset.id);
    const { query, type } = getFilterValue(['query', 'type']);
    const gaEventBaseUrl = `_ga?sponsor_pixel=adblock&sponsor=${SponsorPixel.ADOBE}`;

    trackerSend(gaEventBaseUrl, {
        id_list: resourceIds.join(','),
        term: query || '',
        type: type || '',
        action: 'imp',
    });

    q(`.banner-a2b.${className}`)?.addEventListener('click', () => {
        trackerSend(gaEventBaseUrl, {
            link: 'promo',
            term: query || '',
            action: 'clk',
        });
    });
};
