export const PIXEL_CUSTOM_URL = 'https://www.freepik.com/_ga?';

export const REDIRECT_URL = '//www.freepik.com/redirect?url=';

export const ACCEPTED_TYPE = {
    photo: 'photo',
    vector: 'vector',
};

export const ACCEPTED_ORIENTATIONS = {
    landscape: 'horizontal',
    portrait: 'vertical',
};

export const ACCEPTED_PEOPLE_GENDER = {
    masculine: 'male',
    feminine: 'female',
};

export const ACCEPTED_PEOPLE_EXCLUDE = {
    exclude: 0,
};
