import { Settings } from 'bobjoll/ts/library/settings';
import { SwiperElement } from 'Project/ts/library/swiper/swiper.controller';

export class Swiper {
    public defaultOptions: SwiperConfig = {
        autoPerPage: true,
        autoBreakPoint: false,
        breakPoint: Settings.breakpoints.lg,
        classesIgnoreSwiper: /menudots-vertical/,
        draggable: true,
        duration: 200,
        easing: 'ease-out',
        elementsIgnoreSwiper: ['TEXTAREA', 'OPTION', 'INPUT', 'SELECT'],
        fullWidth: true,
        ignoreElements: /--fake/,
        loop: false,
        maxWidth: 340,
        minWidth: 340,
        multipleDrag: true,
        navigation: {
            elementPrev: '.swiper__button--prev',
            elementNext: '.swiper__button--next',
            disabledClass: 'disabled',
        },
        perPage: 1,
        selector: '.swiper',
        startIndex: 0,
        swiperElement: undefined,
        threshold: 20,
        onInit: () => {},
        onChange: () => setTimeout(() => Lazyload.run(), 150),
    };

    public addSwiper(config: SwiperConfig) {
        new SwiperElement({ ...this.defaultOptions, ...config });
    }
}

export interface SwiperConfig {
    autoPerPage?: boolean;
    autoBreakPoint?: boolean;
    breakPoint?: number;
    classesIgnoreSwiper?: RegExp;
    draggable?: boolean;
    duration?: number;
    easing?: string;
    elementsIgnoreSwiper?: string[];
    fullWidth?: boolean;
    ignoreElements?: RegExp;
    loop?: boolean;
    maxWidth?: number;
    minWidth?: number;
    multipleDrag?: boolean;
    navigation?: {
        elementPrev: string | null;
        elementNext: string | null;
        disabledClass: string | null;
    };
    perPage?: number;
    selector?: string;
    startIndex?: number;
    swiperElement?: HTMLElement | undefined;
    threshold?: number;
    onInit?: Function;
    onChange?: Function;
    isCollection?: boolean;
}
