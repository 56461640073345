// Declare external variables
declare const VIEW_TEMPLATE:
    | 'generic'
    | 'serie'
    | 'detail'
    | 'keyDates'
    | 'userFollowing'
    | 'fontsView'
    | '3d'
    | 'keyword'
    | 'popularView'
    | 'newest'
    | 'author'
    | 'home';
declare const IS_PREMIUM_USER: boolean;
declare const ADOBE_MODAL_PROBABILITIES: number[];
declare const CATEGORY_LANDING_PROMO: string;
declare const DEVICE_EXPIRATION: number;
declare const DISABLE_ANONYMOUS_DOWNLOADS: boolean;
declare const DOWNLOAD_LIMIT_FREE: number;
declare const DOWNLOAD_LIMIT_FREE_MONTHLY: number;
declare const ESSENTIAL_COUNTRY: string;
declare const ESSENTIAL_USER_TYPE: string;
declare const FEATURE_ABANDONED_CART_DISABLED: boolean;
declare const FEATURE_AD_MANAGER: boolean;
declare const FEATURE_AD_MANAGER_ATTRIBUTE: boolean;
declare const FEATURE_AD_MANAGER_DOWNLOADBOX_BY_COUNTRY: boolean;
declare const FEATURE_ADD_TAG_REMOVE_ADS_BY_COUNTRY: boolean;
declare const FEATURE_MOBILE_ADS_BY_LANGUAGE: boolean;
declare const FEATURE_ADVANCED_SEARCH_BY_LANGUAGE: boolean;
declare const FEATURE_AI_IMAGE_FREEPIK_BY_COUNTRY: boolean;
declare const FEATURE_AI_VERTICAL_BY_LANGUAGE: boolean;
declare const FEATURE_BANNER_ARTIST: boolean;
declare const FEATURE_BANNER_DISNEY_VALENTINES_BY_LANGUAGE: boolean;
declare const FEATURE_BLOCK_DOWNLOAD_TOO_MANY_IPS: boolean;
declare const FEATURE_CHECK_RECAPTCHA_STATUS_BY_COUNTRY: string;
declare const FEATURE_CREDITS_SYSTEM_BY_COUNTRY: boolean;
declare const FEATURE_CUSTOMIZE_TEXT_SEARCHBOX: boolean;
declare const FEATURE_DISABLE_ADOBE_AUTOPROMO_BY_COUNTRY: boolean;
declare const FEATURE_DISABLE_ADS: boolean;
declare const FEATURE_DISABLE_ADOBE_POPUP_BY_COUNTRY: boolean;
declare const FEATURE_DISABLE_AFFILIATE_404_BY_COUNTRY: boolean;
declare const FEATURE_DISABLE_AFFILIATE_BY_COUNTRY: boolean;
declare const FEATURE_DOWNLOAD_FILE_TYPES: boolean;
declare const FEATURE_DOWNLOAD_FROM_THUMBNAIL_BY_COUNTRY: boolean;
declare const FEATURE_ENABLE_GAM_BANNERS: boolean;
declare const FEATURE_ENABLE_SEARCH_IMPRESSIONS: boolean;
declare const FEATURE_ENABLE_SSEO: boolean;
declare const FEATURE_FETCHPRIORITY_HIGH: boolean;
declare const FEATURE_FILTER_AI: boolean;
declare const FEATURE_FILTER_PHOTO_EXCLUDE_AI_BY_LANGUAGE: boolean;
declare const FEATURE_FINGERPRINT: boolean;
declare const FEATURE_HIDE_SPONSOR_VISIBILITY: boolean;
declare const FEATURE_IMAGE_SEARCH_BY_COUNTRY: boolean;
declare const FEATURE_LANDING_CATEGORY_PHOTO: boolean;
declare const FEATURE_LANDINGS_PAID_BY_LANGUAGE: boolean;
declare const FEATURE_MONTHLY_DOWNLOAD_LIMIT_BY_COUNTRY: boolean;
declare const FEATURE_NEW_CANCEL_RENEWAL: boolean;
declare const FEATURE_DISABLE_INFO_RESOURCES: boolean;
declare const FEATURE_NEW_COPIES_COLLECTIONS_BY_COUNTRY: boolean;
declare const FEATURE_NEW_LANDING_PRICING: boolean;
declare const FEATURE_NEW_LANDING_FREEPIK_TEAMS_BY_COUNTRY: boolean;
declare const FEATURE_NEW_LANDING_FREEPIK_BASIC_BY_COUNTRY: boolean;
declare const FEATURE_NEW_LANDING_FREEPIK_PREMIUMT_BY_COUNTRY: boolean;
declare const FEATURE_NEW_LANDING_FREEPIK_MAGNIFIC_BY_COUNTRY: boolean;
declare const FEATURE_MOCKUP_EDITOR_BUTTON: boolean;
declare const FEATURE_OAUTH_ORIGIN_PARAM: boolean;
declare const FEATURE_OBFUSCATE_LINKS_DETAIL_VIEW: boolean;
declare const FEATURE_OBFUSCATE_LINKS: boolean;
declare const FEATURE_OTHERLEVELS_ENABLED: boolean;
declare const FEATURE_OTHERLEVELS_INBOX_TOOLTIP: boolean;
declare const FEATURE_PAUSE_SUBSCRIPTION_V2: boolean;
declare const FEATURE_RECAPTCHA_ENABLED: boolean;
declare const FEATURE_REDUCE_404_RESULTS_REMOVING_LAST_PARAM: boolean;
declare const FEATURE_SHOW_3D_MODELS_BY_LANGUAGE: boolean;
declare const FEATURE_SHOW_NATIVE_ADVERTISING_BY_LANGUAGE: boolean;
declare const FEATURE_SHOW_ADOBE_EXPRESS_STOCKBANNER_BY_COUNTRY: boolean;
declare const FEATURE_SHOW_ADOBE_EXPRESS_APIBANNER_BY_COUNTRY: boolean;
declare const FEATURE_SHOW_LINK_B2B_LANDING: boolean;
declare const FEATURE_SHOW_PAYMENT_UPDATE: boolean;
declare const FEATURE_SPONSOR_SHUTTERSTOCK: string;
declare const FEATURE_SPONSOR_ISTOCK: string;
declare const FEATURE_TOP_BANNER_BY_COUNTRY: boolean;
declare const FEATURE_USER_PERSONA_MODAL_BY_LANGUAGE: boolean;
declare const FEATURE_VERTICAL_GRID_BY_LANGUAGE: boolean;
declare const FEATURE_GA4_EVENTS_FROM_BACKEND: boolean;
declare const FIXED_RESOURCES_IN_RESULTS: number;
declare const FLATICON_URL: string;
declare const FLATICON_AUTHOR_ID: string;
declare const FREEPIK_AUTHOR_ID: string;
declare const TRACKING_SEARCH: string;
declare const GLOBAL_CAMPAIGN: any;
declare const gtag: (...args: (string | IndexSignature)[]) => void;
declare const gtmData: any;
declare const IMG_ZOOM_STRIP: number;
declare const LAZY_LOADING_THRESHOLD_PX: number;
declare const LOGGED_BY_OAUTH: boolean;
declare const MONTHLY_DOWNLOAD_LIMIT_NOTICE_DATE: number;
declare const MY_SUBSCRIPTIONS_URL: string;
declare const pbjsYLHH: pbjsYLHH;
declare const PALETTE_COLORS: string;
declare const PRICING_URL: string;
declare const SHOW_CC_BANNER: boolean;
declare const Showcase: typeof import('Partials/showcase').Showcase;
declare const SPONSOR_SHOW_ADOBESTOCK_PERCENT: number;
declare const SUPPORT_RESOURCES_FAQ_URL: string;
declare const SUPPORT_RESOURCES_FAQ_URL_MONTHLY: string;
declare const USER_COUNTRY_CODE_ISO_3: string;
declare const USER_COUNTRY_CODE: string;
declare const USER_CURRENCY: 'EURO' | 'DOLLAR' | 'INR';
declare const USER_PRICE_MONTHLY: string;
declare const USER_PRICE_YEARLY_BY_MONTH: string;
declare const USER_PRICE_YEARLY: string;
declare const USER_TYPE: 'anonymous' | 'free' | 'premium';
declare const MAX_OCURR_NOTIF_MOCKUP_BAKER_PLUGIN: number;
declare const BASE64_SEARCH_BY_IMAGE: string;
declare const KEYWORD_SEARCH_BY_IMAGE: string;
declare let ACCOUNTS_BASE_URL: string;
declare let advertisement: boolean;
declare let AUTH_TOKEN: string;
declare let bannerAffiliate: any[];
declare let BASE_URL: string;
declare let AUTHOR_URL: string;
declare let POPULAR_URL: string;
declare let COLLECTION_COUNT: number;
declare let COLLECTION_LIMIT: number;
declare let CRITEO_ACCOUNT_ID: string;
declare let CSRF_TOKEN: string;
declare let dataLayer: any[];
declare let debug: any;
declare let DEVICE_FAQS_URL: string;
declare let DOMAIN_FP: string;
declare let FACEBOOK_APP_ID: string;
declare let FACEBOOK_PIXEL_ID: string;
declare let GOOGLE_CLIENT_ID: string;
declare let google_tag_manager: any;
declare let gr: any;
declare let grecaptcha: any;
declare let HISTORY_CACHE_EXPIRES: number;
declare let HISTORY_CACHE_MAX_ITEMS: number;
declare let HOW_ATTRIBUTE_AUTHOR_URL: string;
declare let HOW_EDIT_URL: {
    vector: string;
    psd: string;
    icon: string;
    photo: string;
};
declare let IS_MOBILE: boolean;
declare let TRACKING_UUID_SEARCH: string;
declare let LANGUAGE_NAME: string;
declare let language: { [englishText: string]: string };
declare let LANGUAGE: string;
declare let Lazyload: {
    run: (element?: HTMLElement, preloadImagesWhichAreNotInView?: boolean) => void;
    runScript: (element?: HTMLElement, callback?: Function) => void;
    check: () => void;
    loadVideos: (parent: HTMLElement) => void;
};
declare let GIDP_ONE_TAP_LOGIN_URL: string;
declare let LOGIN_URL: string;
declare let LOGOUT_URL: string;
declare let metas: any;
declare let mixpanel: any;
declare let NUM_RELATED_RESOURCES: number;
declare let NUM_RESOURCES_PREMIUM: string;
declare let NUM_RESOURCES_SELECTION: string;
declare let NUM_RESOURCES: string;
declare let NUM_SPONSOR_RESOURCES: number;
declare let OPTIMAL_BROWSER: boolean;
declare let optimizeTests: any[];
declare let PREMIUM_LICENSE_URL: string;
declare let Raven: any;
declare let RE_CAPTCHA_KEY_INVISIBLE: string;
declare let RecaptchaDownloads: any;
declare let REGISTER_URL: string;
declare let resource_keywords_str: string;
declare let resource_keywords: { [i: number]: { n: string; s: string } };
declare let resource_slugs: string;
declare let THUMBNAIL_SIZE_BIG: number;
declare let THUMBNAIL_SIZE_SMALL: number;
declare let SLIDER_RESOURCES_DEFAULT_LENGTH_MIN: number;
declare let SLIDER_RESOURCES_SERIE_LENGTH_MIN: number;
declare let SPONSOR_BASE_URL: string;
declare let SPONSOR_NAME: string;
declare let SPONSOR_URL_IMPRESS: string;
declare let DETAIL_WIDTHS: any;
declare let STANDARD_LICENSE_URL: string;
declare let stockBannerCallback: Function;
declare let stockBannerCloseFn: Function;
declare let stockBannerExpires: () => Date;
declare let stockBannerImage: string;
declare let stockBannerPixelAffiliate: string;
declare let stockBannerPixelVariation: string;
declare let stockBannerUrl: string;
declare let SUPPORT_URL: string;
declare let TOP_SEARCH_TERMS: string[];
declare let TRACKER_URL: string;
declare let URL_FREEPIK_EDITOR: string;
declare let URL_FREEPIK_STORIES: string;
declare let URL_FREEPIK_WEPIK_ALL_PHOTOS: string;
declare let URL_FREEPIK_MOCKUP_EDITOR: string;
declare let URL_RESOURCE_KEYWORDS: string;
declare let URL_PREMIUM_COLLECTIONS: string;
declare let URL_STATIC_IMG: string;
declare let urlPopupAffiliate: string;
declare let verticalGrid: any;
declare let PLACEHOLDER_SEARCHTEXT: string;
declare let MOCKUP_EDITOR_IDS: number[];
declare let FEATURE_POPUP_REBRANDING: boolean;

if (typeof gr === 'undefined') gr = null;

type pbjsYLHHEventType = 'bidWon';
interface pbjsYLHH {
    onEvent: (eventType: pbjsYLHHEventType, fn: (data: pbjsYLHHOnEventCallbackData) => void) => void;
}

interface pbjsYLHHOnEventCallbackData {
    adUnitCode: string;
}
