import { cookie } from "Library/cookie";
import { isHidden } from "./element";
import { q, ww } from "bobjoll/ts/library/dom";
import { trackerInterface } from "./tracker";

export const isAdBlockEnabled = () => {
    const adElement = q('.myTestAd');
    if (adElement) {
        if (cookie.getItem('adblock_debug')) {
            return true;
        }
        const elementHidden = isHidden(adElement);
        if (elementHidden) {
            trackerInterface.send('ga', 'send', 'event', 'no-display');
        }
        return elementHidden;
    }
    return 'undefined' === typeof ww.advertisement;
};