import { mapResourceComplete } from '../mappers/detail.mappers.resource';

export type Detail = ReturnType<typeof mapResourceComplete>;

let detailActive: Detail['data']['detail'];
let detailMetas: any;
export const setActive = (data: Detail['data']['detail']) => (detailActive = data);
export const getActive = () => detailActive;
export const setActiveMetas = (data: any) => (detailMetas = data);
export const getActiveMetas = () => detailMetas;
