import { getAdobeExpressLinkUrl } from './partnerize';

const languagePerCountry = {
    UK: 'en',
    GB: 'en',
    US: 'en',
    BR: 'br',
    JP: 'jp',
} as const;

export const getCountryLanguage = (): string => languagePerCountry[USER_COUNTRY_CODE] || 'en';

export const getAdobeExpressRedirectUrl = function(destination: string, freepikRedirect = true) {
    const urlTradeDoubler = getAdobeExpressLinkUrl(destination);
    return freepikRedirect ? `//www.freepik.com/redirect?url=` + btoa(urlTradeDoubler) : urlTradeDoubler;
};