import Cache from 'Project/ts/library/cache';
import { xhr,XhrResponse } from 'Service/xhr';

export class Preferences {
    private static response: PreferencesResponse = {
        data: {
            notificationCenterDisabled: false,
            notificationCenterWalkthrough: true,
        },
        success: true,
    };

    public static async get(): Promise<PreferencesResponse> {
        if (Preferences.response) {
            return Preferences.response;
        }

        return xhr('/xhr/user/preferences');
    }

    public static update(preference: keyof PreferencesResponse['data'], value: boolean) {
        Preferences.response.data[preference] = value;
        Cache.Remove('/xhr/user/preferences');
    }
}

interface PreferencesResponse extends XhrResponse {
    data: {
        notificationCenterDisabled: boolean;
        notificationCenterWalkthrough: boolean;
    };
}
