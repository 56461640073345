/* eslint-disable @typescript-eslint/camelcase */
export const data = function() {
    const country = USER_COUNTRY_CODE || 'nn';
    const random = Math.random();
    const args: IndexSignature = {
        country,
        random,
    };
    return args;
};
