import { getAdOptions } from './detail.api.ads';
import { getFollowingStatus, toggleFollow } from './detail.api.follow';
import { getGlobals } from './detail.api.globals';
import { getMetas } from './detail.api.metas';
import { get3DResourceComplete, getResource, getResourceComplete } from './detail.api.resource';
import { getActive, getActiveMetas, setActive, setActiveMetas } from './detail.api.store';

export const DetailApi = {
    getActive,
    getActiveMetas,
    getAdOptions,
    getFollowingStatus,
    getGlobals,
    getMetas,
    getResource,
    getResourceComplete,
    get3DResourceComplete,
    setActive,
    setActiveMetas,
    toggleFollow,
};
