var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing;

  return "    <article class=\"collection collection__item swiper__item\" data-id=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\"\n        data-author-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.user_id : stack1), depth0))
    + "\" data-type=\""
    + alias2(alias1((depth0 != null ? depth0.type : depth0), depth0))
    + "\" data-share-text=\""
    + alias2(alias1((depth0 != null ? depth0.share_text : depth0), depth0))
    + "\"\n        data-share-url=\""
    + alias2(alias1((depth0 != null ? depth0.share_url : depth0), depth0))
    + "\" data-order=\""
    + alias2(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias3,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":4,"column":56},"end":{"line":4,"column":66}}}) : helper)))
    + "\">\n        <div class=\"content\">\n            <a href=\""
    + alias2(alias1((depth0 != null ? depth0.url : depth0), depth0))
    + "\" class=\"collection__link\">\n"
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias4).call(alias3,(helpers.get_length||(depth0 && depth0.get_length)||alias4).call(alias3,(depth0 != null ? depth0.preview_images : depth0),{"name":"get_length","hash":{},"data":data,"loc":{"start":{"line":7,"column":27},"end":{"line":7,"column":59}}}),"==",0,{"name":"compare","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":11,"column":28}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias4).call(alias3,(helpers.get_length||(depth0 && depth0.get_length)||alias4).call(alias3,(depth0 != null ? depth0.preview_images : depth0),{"name":"get_length","hash":{},"data":data,"loc":{"start":{"line":13,"column":27},"end":{"line":13,"column":59}}}),"==",1,{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":15,"column":28}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias4).call(alias3,(helpers.get_length||(depth0 && depth0.get_length)||alias4).call(alias3,(depth0 != null ? depth0.preview_images : depth0),{"name":"get_length","hash":{},"data":data,"loc":{"start":{"line":17,"column":27},"end":{"line":17,"column":59}}}),"==",2,{"name":"compare","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":19,"column":28}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias4).call(alias3,(helpers.get_length||(depth0 && depth0.get_length)||alias4).call(alias3,(depth0 != null ? depth0.preview_images : depth0),{"name":"get_length","hash":{},"data":data,"loc":{"start":{"line":21,"column":27},"end":{"line":21,"column":59}}}),">=",3,{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":23,"column":28}}})) != null ? stack1 : "")
    + "            </a>\n\n            <div class=\"collection__description\">\n                <div class=\"collection__content\">\n                    <div class=\"row row--vertical-center mg-none-i\">\n                        <div class=\"tooltip private hide\">\n                            <i class=\"icon icon--sm icon--lock-solid mg-none-i pd-right-lv1\"></i>\n                            <div class=\"tooltip__container tooltip__container--bottom tooltip__container--pre alignc\">\n                                <div class=\"tooltip__content\">"
    + ((stack1 = (helpers.language||(depth0 && depth0.language)||alias4).call(alias3,"Private collection.",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":32,"column":62},"end":{"line":32,"column":98}}})) != null ? stack1 : "")
    + "</div>\n                            </div>\n                        </div>\n\n"
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias4).call(alias3,(depth0 != null ? depth0.premium : depth0),"==",true,{"name":"compare","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":36,"column":24},"end":{"line":42,"column":36}}})) != null ? stack1 : "")
    + "                    </div>\n\n                    <div class=\"row row--vertical-center mg-none-i font-sm text__scale-gray--7\">\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.total_elements : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":24},"end":{"line":48,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n\n                <div class=\"collections__actions popover popover--top-right\">\n                    <button class=\"popover__trigger link button button--sm button--link button--icon\">\n                        <i class=\"icon--menudots-vertical\"></i>\n                    </button>\n\n                    <div class=\"popover__container\">\n                        <ul>\n"
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias4).call(alias3,false,"==",(depth0 != null ? depth0.current_user_collection : depth0),{"name":"compare","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":28},"end":{"line":72,"column":40}}})) != null ? stack1 : "")
    + "                            <li>\n                                <button class=\"collection-share\">\n                                    <i class=\"icon--share\"></i>\n                                    <span>"
    + alias2((helpers.language||(depth0 && depth0.language)||alias4).call(alias3,"Share",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":76,"column":42},"end":{"line":76,"column":62}}}))
    + "</span>\n                                </button>\n                            </li>\n                        </ul>\n                    </div>\n                </div>\n            </div>\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.meta)) && stack1.collection_view_more_href),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":12},"end":{"line":96,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </article>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <div class=\"no-resources\">\n                        <img src=\""
    + container.escapeExpression(container.lambda(((stack1 = (data && data.root)) && stack1.URL_STATIC_IMG), depth0))
    + "collections/no-resources.svg\" alt=\"\">\n                    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                    " + require("./gallery/single.hbs").apply(null, Array.prototype.slice.call(arguments, arguments.length > 6)) + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                    " + require("./gallery/dual.hbs").apply(null, Array.prototype.slice.call(arguments, arguments.length > 6)) + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                    " + require("./gallery/triple.hbs").apply(null, Array.prototype.slice.call(arguments, arguments.length > 6)) + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                            <span\n                                class=\"collection__title font-md premium premium--text overflow-ellipsis inline-block semibold\"><i\n                                    class=\"icon--sm icon icon--inverted icon--premium\"></i>"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "</span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"collection__title overflow-ellipsis pd-y-none\">"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "</span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "                            <span class=\"count\">"
    + container.escapeExpression((helpers.language||(depth0 && depth0.language)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"%s resources",(depth0 != null ? depth0.total_elements : depth0),{"name":"language","hash":{},"data":data,"loc":{"start":{"line":47,"column":48},"end":{"line":47,"column":95}}}))
    + "</span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                <li>\n                                    <button class=\"collection-follow\">\n"
    + ((stack1 = (helpers.isTrue||(depth0 && depth0.isTrue)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.FEATURE_NEW_COPIES_COLLECTIONS_BY_COUNTRY : depth0),{"name":"isTrue","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":63,"column":40},"end":{"line":69,"column":51}}})) != null ? stack1 : "")
    + "                                    </button>\n                                </li>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "                                            <i class=\"icon--create-folder\"></i>\n                                            <span>"
    + container.escapeExpression((helpers.language||(depth0 && depth0.language)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Save collection",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":65,"column":50},"end":{"line":65,"column":80}}}))
    + "</span>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "                                            <i class=\"icon--follow-folder\"></i>\n                                            <span>"
    + container.escapeExpression((helpers.language||(depth0 && depth0.language)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Follow",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":68,"column":50},"end":{"line":68,"column":71}}}))
    + "</span>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing;

  return "                <div class=\"collection__item__view hide\">\n                    <a href=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.meta)) && stack1.collection_view_more_href), depth0))
    + "\"\n                        class=\"search__link row row--vertical-center row--horizontal-center full-height mg-none\"\n                        data-filter=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.meta)) && stack1.collection_view_more_params), depth0))
    + "\">\n                        <div class=\"pd-lv3 alignc\">\n                            <h3 class=\"first font-h6 heading-inverted line-height-sm mg-none\">"
    + alias2((helpers.language||(depth0 && depth0.language)||alias4).call(alias3,"Collections",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":90,"column":94},"end":{"line":90,"column":120}}}))
    + "\n                            </h3>\n                            <p class=\"font-sm text--inverted mg-none\">"
    + alias2((helpers.language||(depth0 && depth0.language)||alias4).call(alias3,"View more",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":92,"column":70},"end":{"line":92,"column":94}}}))
    + "</p>\n                        </div>\n                    </a>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.collections : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":99,"column":9}}})) != null ? stack1 : "");
},"useData":true});