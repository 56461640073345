import { q, qq } from 'bobjoll/ts/library/dom';
import { setDetailCountDownloads } from 'Partials/detail-v2/helpers/detail.helpers.downloadButton';
import { getDetail } from 'Partials/detail-v2/helpers/detail.helpers.generic';

import { recaptchaDownload } from './download.recaptcha';
import {
    isEditButton,
    isThumbnailDownloadButton,
    setDownloadButtonStyle,
    setEditButtonStyle,
    setThumbnailDownloadButtonStyle,
} from './download.styles';
import {
    setTrackerDownloadDetail,
    setTrackerDownloadFromThumbnail,
    setTrackerDownloadPage,
    setTrackerDownloadUser,
    setTrackerEditButton,
} from './download.tracker';
import { DownloadSource } from './download.vm';
import { winbackNotifications } from 'Partials/notifications/notifications.winback';

export const handlerDownload = async function(this: HTMLLinkElement, event: Event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.classList.contains('disabled')) return;

    const elementEditButton = isEditButton(this);
    const elementThumbnailDownload = isThumbnailDownloadButton(this);

    if (elementEditButton) {
        const detailElement = getDetail();
        detailElement && setEditButtonStyle(detailElement);
    } else if (elementThumbnailDownload) {
        setThumbnailDownloadButtonStyle({ button: this, actionDownloading: true });
    } else {
        setDownloadButtonStyle(this);
    }

    const downloadSource = getDownloadSource(this, elementEditButton);

    if (downloadSource !== DownloadSource.USER_DOWNLOADS) {
        if (gr && gr.user && (await winbackNotifications(true))) return;
    }

    const setTrackerDownload = {
        [DownloadSource.DETAIL]: setTrackerDownloadDetail,
        [DownloadSource.DOWNLOAD_PAGE]: setTrackerDownloadPage,
        [DownloadSource.EDIT_BUTTON]: () => setTrackerEditButton(),
        [DownloadSource.USER_DOWNLOADS]: () => setTrackerDownloadUser(this),
        [DownloadSource.THUMBNAIL]: () => setTrackerDownloadFromThumbnail(this),
    };

    const trackerDownload = setTrackerDownload[downloadSource]();
    recaptchaDownload({ button: this, event, tracker: trackerDownload, downloadSource });
};

export const initializeDownloadButton = (downloadButtonSelector: string) => {
    qq(downloadButtonSelector).forEach(button => {
        button.addEventListener('click', handlerDownload);
    });

    if (FEATURE_MONTHLY_DOWNLOAD_LIMIT_BY_COUNTRY) {
        setDetailCountDownloads();
        gr.addEventListener('gr:update:user', setDetailCountDownloads);
    }
};

const getDownloadSource = (button: HTMLElement, isEditButton = false): DownloadSource => {
    if (isEditButton) return DownloadSource.EDIT_BUTTON;

    let downloadSource = DownloadSource.DOWNLOAD_PAGE;

    if (button.classList.contains('download-button--thumbnail')) {
        downloadSource = DownloadSource.THUMBNAIL;
    } else if (q('section.user--downloads')) {
        downloadSource = DownloadSource.USER_DOWNLOADS;
    } else if (getDetail()) {
        downloadSource = DownloadSource.DETAIL;
    }

    return downloadSource;
};
