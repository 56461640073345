import { q } from 'bobjoll/ts/library/dom';
import { localStorage } from 'bobjoll/ts/library/storage';
import Countdown from 'Partials/countdown';
import triggerManualGA4Events from 'Partials/events/ga4.init-manual-events';
import { cookie } from 'Project/ts/library/cookie';

const monthlyLimitTooltip = {
    launch: () => gr && gr.user && !gr.user.premium && monthlyLimitTooltip.get(),
    closed: () =>
        localStorage.getItem('tooltipFixed', 'monthly-limit-finish') ||
        cookie.getItem('tooltipFixed-monthly-limit-finish'),
    get: async () => {
        if (monthlyLimitTooltip.closed()) {
            if (q('.gr-auth__download-notice')) return;

            monthlyLimitTooltip.info();
            return;
        }

        const [html, TooltipFixed] = await Promise.all([
            monthlyLimitTooltip.template(),
            import('bobjoll/ts/partials/tooltipFixed-v1.0').then(mod => mod.TooltipFixed),
        ]);

        const tooltipElement = TooltipFixed.add({
            appearance: 'light',
            buttonCloseForever: true,
            cookieId: 'monthly-limit-finish',
            elementToInsert: '.gr-auth__notice',
            html,
            insertContainer: true,
            position: 'bottom',
        });

        if (tooltipElement) {
            q('a', tooltipElement)?.addEventListener('click', () => {
                triggerManualGA4Events({
                    description: 'linkFaqsMonthly',
                    options: {},
                });
            });

            q('.notification__close', tooltipElement)?.addEventListener('click', () => {
                triggerManualGA4Events({
                    description: 'linkFaqsMonthly',
                    options: {
                        action: 'close',
                    },
                });

                monthlyLimitTooltip.info();
            });

            q('.gr-auth__popover')?.addEventListener(
                'click',
                () => {
                    if (monthlyLimitTooltip.closed()) return;

                    tooltipElement.classList.remove('tooltipFixed__active');
                    localStorage.setItem('tooltipFixed', 'monthly-limit-finish', '1');
                    monthlyLimitTooltip.info();
                },
                { once: true },
            );
        }
    },
    info: async () => {
        const container = q('.gr-auth__download');

        const notification = document.createElement('div');
        notification.className = 'gr-auth__download-notice';
        notification.innerHTML = await monthlyLimitTooltip.template();

        if (container && container.parentNode) {
            container.parentNode.insertBefore(notification, container.nextSibling);
            q('.gr-auth__info')?.classList.remove('hide');

            q('a', notification)?.addEventListener('click', () => {
                triggerManualGA4Events({
                    description: 'linkFaqsMonthly',
                    options: {
                        location: 'user_menu_download_limit',
                    },
                });
            });
        }
    },
    template: async () => {
        const { default: template } = await import('./views/monthlyLimit.tooltipFixed.hbs');
        const dateObject = new Date(MONTHLY_DOWNLOAD_LIMIT_NOTICE_DATE * 1000);
        const day = parseInt(dateObject.toLocaleString('en-US', { day: 'numeric' }));
        const month = dateObject.toLocaleString('en-US', { month: 'long' });
        const nth = day % 10 == 1 ? 'st' : day % 10 == 2 ? 'nd' : day % 10 == 3 ? 'rd' : 'th';

        return template({
            userLimit: DOWNLOAD_LIMIT_FREE_MONTHLY,
            startDate: `${month} ${day}${nth}`,
            urlFaqs: SUPPORT_RESOURCES_FAQ_URL,
        });
    },
};

const monthlyLimitInfoRenewal = {
    launch: () => gr && gr.user && gr.user.download_limit_renew && monthlyLimitInfoRenewal.get(),
    get: async () => {
        if (q('.gr-auth__download-notice')) return;

        const dateRenewal = new Date(gr.user.download_limit_renew * 1000);
        const date = Countdown.dateLeft(dateRenewal, 0);

        if (date && date.Days) {
            const daysLeft = `${date.Days} ${date.Days === 1 ? 'day' : 'days'} left`;
            const container = q('.gr-auth__download');
            const { default: template } = await import('./views/monthlyLimit.infoRenewal.hbs');
            const notification = document.createElement('div');

            notification.innerHTML = template({
                dateRenewal: dateRenewal.toLocaleDateString(),
                daysLeft,
            });

            if (container && container.parentNode) {
                container.parentNode.insertBefore(notification, container.nextSibling);
            }
            container &&
                q('.tooltip', container)?.addEventListener('click', () => {
                    triggerManualGA4Events({
                        description: 'linkFaqsMonthly',
                        options: {
                            event_name: 'more_info_download_limit',
                            location: 'sider_user_menu',
                        },
                    });
                });
        }
    },
};

export const monthlyLimitComingSoon = () => {
    monthlyLimitTooltip.launch();
    gr.addEventListener('gr:update:user', monthlyLimitTooltip.launch);
};

export const monthlyLimitMenuInfo = () => {
    monthlyLimitInfoRenewal.launch();
    gr.addEventListener('gr:update:user', monthlyLimitInfoRenewal.launch);
};
