import { Settings } from 'bobjoll/ts/library/settings';

import { cookie } from '../library/cookie';

export const getTestVariables = function() {
    const testNames = Settings['test-names'] as string[] | undefined;
    const testVariables: TestVariables = {};

    if (testNames) {
        testNames.forEach(key => {
            const value = cookie.getItem(`test_ab-${key}`);
            if (value) {
                testVariables[key] = value;
            }
        });
    }

    return testVariables;
};

export const setTestClasses = function() {
    const tests = getTestVariables();
    Object.keys(tests).forEach(name => {
        if (tests[name]) {
            document.body.classList.add(`test-ab--${name}-${tests[name]}`);
        }
    });
};

export const getActiveTestVariation = function(name: string) {
    const tests = getTestVariables();
    return tests[name];
};

interface TestVariables {
    [key: string]: string;
}
