import 'User/unloaded';

import { Favorite } from 'User/favorite';

import * as UserHelpersModules from './user.helpers';

export const User: UserComponent = {
    Favorite,
};

export const UserHelpers = {
    ...UserHelpersModules,
};

interface UserComponent {
    [key: string]: {
        initialize: () => void;
        destroy: () => void;
    };
}
