import { localStorage } from 'bobjoll/ts/library/storage';
import { deviceManager } from 'Partials/user/device-manager';
import { xhr } from 'Service/xhr';

import { getDetail } from '../detail-v2/helpers/detail.helpers.generic';
import { getDownloadLimit } from '../notifications';
import { clearDownloadButton, clearEditButton } from './download.styles';
import { DownloadSource, DownloadValidate, TrackerDownload } from './download.vm';

const registerDownloadAPI = `${BASE_URL}xhr/register-download`;
const downloadValidateAPI = `${BASE_URL}xhr/validate`;
export const downloadImageUrlAPI = `${BASE_URL}xhr/download-zoom-url`;

export const validateDownload = async (
    downloadSource: DownloadSource,
    trackerDownload: TrackerDownload,
    id?: string,
): Promise<DownloadValidate> => {
    let downloadValid = true;

    if (downloadSource === DownloadSource.USER_DOWNLOADS) {
        return await validateUserDownload();
    }

    if (gr && gr.user) {
        const resourceType = getResourceType(trackerDownload);
        const typeParam = resourceType ? `?type=${resourceType}` : '';
        await xhr(`${registerDownloadAPI}/${id}${typeParam}`, {
            withCredentials: true,
            headers: {
                'X-CSRF-TOKEN': CSRF_TOKEN,
                'X-Requested-With': 'XMLHttpRequest',
            },
        });
    }

    const {
        isManual: activateCaptcha,
        cypress,
        countDownloads,
        limitDownloads,
        redirectTo429LimitExceeded,
        userSuspected,
    } = await xhr(downloadValidateAPI, {
        withCredentials: true,
        headers: {
            'X-CSRF-TOKEN': CSRF_TOKEN,
            'X-Requested-With': 'XMLHttpRequest',
        },
    });

    if (redirectTo429LimitExceeded) {
        deviceManager.showModal429();
        downloadValid = false;
        setButtonsStyleByDefault();
    }

    if (countDownloads >= 0 && limitDownloads) {
        setStorageDownloadsInfo(countDownloads, limitDownloads);
        getDownloadLimit({ count: countDownloads, limit: limitDownloads });
    }

    return {
        validate: downloadValid,
        activateCaptcha: activateCaptcha || trackerDownload.downloadSource === DownloadSource.DOWNLOAD_PAGE,
        cypress,
        userSuspected,
    };
};

const validateUserDownload = async (): Promise<DownloadValidate> => {
    const request = await xhr(downloadValidateAPI, {
        withCredentials: true,
        headers: {
            'X-CSRF-TOKEN': CSRF_TOKEN,
            'X-Requested-With': 'XMLHttpRequest',
        },
    });

    return {
        validate: true,
        activateCaptcha: request.getPathValue('isManual'),
        userSuspected: request.getPathValue('userSuspected'),
        cypress: request.getPathValue('cypress'),
    };
};

const setButtonsStyleByDefault = (): void => {
    clearDownloadButton(true);
    const detailElement = getDetail();
    detailElement && clearEditButton(detailElement);
};

const setStorageDownloadsInfo = (consumed: number, limit: number) => {
    try {
        localStorage.setItem('downloads', 'consumed', `${consumed + 1}`);
        localStorage.setItem('downloads', 'limit', `${limit}`);
    } catch (err) {}
};

const getResourceType = (trackerDownload: TrackerDownload) => {
    const typeDefault = 'regular';
    const types = {
        fbx: '3d',
        zip: '3d',
        font: 'font',
    };

    const buttonDatasetType = trackerDownload.button?.dataset.type;

    return buttonDatasetType ? types[buttonDatasetType] : typeDefault;
};
