import { Cookie } from 'bobjoll/ts/library/cookie';
import { ga4 } from 'Partials/events/ga4.api';
import { GTMArguments } from 'Partials/tracker-gtm';
import { q } from 'bobjoll/ts/library/dom';

export const topBannerEvents = () => {
    const banner = q('.top-banner');

    if (banner) {
        const cookieExpirationDays = 7;
        const params: GTMArguments<string> = {
            event_name: 'content_banner',
            interaction_type: 'onload',
            location: 'banner_top_menu_home',
            action: 'content_usa_campaign'
        }

        q('.top-banner--close', banner)?.addEventListener('click', () => {
            banner.remove();

            Cookie.setItem(banner.dataset.name || 'topBanner', '1', {
                expires: new Date(new Date().getTime() + cookieExpirationDays * 24 * 60 * 60 * 1000),
            });
        });

        q('.top-banner--button', banner)?.addEventListener('click', () => ga4({
            ...params,
            interaction_type: 'click',
        }));

        ga4(params);
    }
};

