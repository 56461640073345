/* eslint-disable @typescript-eslint/camelcase */
import { Resource } from 'Partials/showcase';

export const mapResourceComplete = function(key: string, resource: Resource, response: any) {
    const keywordsVisible = 6;
    const {
        data: {
            detail: {
                alsolike_logic_applied,
                author: { id, totalResources: total_resources, userId: user_id },
                creation,
                download_file_url,
                file_types,
                is_essential,
                keywords,
                mainKeywords,
                original_filesize,
                original_height,
                original_width,
                ris,
                printType,
                srcset,
                thumbnails,
                plugin_mockup_baker,
                metatags: { ...metas },
            },
            resources,
            related: { series },
            related_author,
            related_collection,
        },
        meta,
        paging,
    } = response;
    return {
        data: {
            detail: {
                ...resource,
                alsolike_logic_applied,
                author: {
                    ...resource.author,
                    id,
                    total_resources,
                    user_id,
                },
                creation_ago: creation.ago,
                creation_date: creation.date,
                download_file_url,
                file_types,
                fileType: '',
                fromView: 'resource_detail',
                is_essential,
                is_essential_and_country: is_essential == '1' && ESSENTIAL_COUNTRY == '1',
                keywords,
                keywordsHidden: keywords.length - keywordsVisible,
                keywordsVisible,
                main_keywords_label: mainKeywords.label,
                main_keywords_sponsor: mainKeywords.sponsor,
                main_keywords: mainKeywords.keywords,
                plugin_mockup_baker,
                original_filesize,
                original_height,
                original_width,
                print_type: printType,
                ris,
                srcset,
                thumbnails: {
                    ...resource.thumbnails,
                    ...thumbnails,
                },
                metatags: { metas },
            },
            resources,
            related: {
                serie: series && series.resources,
                collection: related_collection ?? [],
                author: related_author ?? [],
            },
        },
        key,
        meta,
        paging,
    };
};
