import getFilterValue from 'Partials/filters-v2/getFilterValue';

export const isSearchByImageEnabled = () => {
    if (!FEATURE_IMAGE_SEARCH_BY_COUNTRY) return false;
    const { img } = getFilterValue('img');
    return img === '1';
};

export const getImage = () => {
    const defaultImage = {
        format: '',
        base64: '',
    };

    if (BASE64_SEARCH_BY_IMAGE === '') return defaultImage;

    const regex = /^data:([\w/]+);base64,(.*)/;
    const image = BASE64_SEARCH_BY_IMAGE.match(regex);

    if (!image) return defaultImage;

    return {
        format: image[1],
        base64: image[2],
    };
};
