import 'Library/common';
import 'Library/helpers';
import 'Templates/helpers';
import 'Variables';

import { qq, ww } from 'bobjoll/ts/library/dom';
import extend from 'bobjoll/ts/library/extend';
import { Settings } from 'bobjoll/ts/library/settings';
import { Arguments } from 'Library/arguments';
import { cookie } from 'Library/cookie';
import { Currency } from 'Library/currency';
import { detectIE } from 'Library/helpers/device';
import { loader } from 'Partials/loader';
import { UserHelpers } from 'Partials/user';

import { getConfigAds } from '../common/sponsor-common/sponsor.helpers';
import { isAdBlockEnabled } from '../library/helpers/adblock';
import { initCampaignBanner } from './campaign-php/campaign.events';
import { BannerTypes, showCampaignBanner } from './campaign-php/campaign.helpers';
import { getTestVariables } from './test-ab';

export interface Templates {
    [name: string]: TemplateSettings;
}

export interface LazyTemplate {
    default: HandlebarsTemplateDelegate;
}

export interface TemplateSettings {
    callback?: Function;
    delay: boolean;
    getMetas?: Function;
    getXHR?: Function;
    global?: { [name: string]: any };
    groups?: string[];
    history: boolean;
    insert?: TemplateInsert;
    loader?: boolean;
    lock?: boolean;
    metas?: boolean;
    prePrint?: Function;
    print: boolean;
    push: boolean;
    pushTracker: boolean;
    setMetas?: Function;
    template?:
    | Function
    | LazyTemplate
    | {
        mobile: Function;
        desktop: Function;
    }
    | {
        rule(settings: ViewPrint): boolean;
        hbs: Function;
    }[];
}

export interface TemplateInsert {
    method: TemplateInsertMethods;
    methodArgument?: TemplateInsertMethodArgument | boolean;
    containerHtmlTemplate?: Function;
    element?: string;
    bannerCampaign?: BannerTypes;
}

export interface ViewPrint {
    [name: string]: any;
    delay?: boolean;
    key?: string;
    location?: string;
    lock?: boolean;
    prePrint?: Function;
    print?: boolean;
    push?: boolean;
    pushTracker?: boolean;
    url?: string;
    views: string[];
    xhr?: any;
}

interface ViewOptions {
    name: string;
    template: TemplateSettings;
    groups?: string[];
    override?: boolean;
}

type TemplateInsertMethods = 'innerHTML' | 'insertAdjacentHTML' | 'modal';
type TemplateInsertMethodArgument = 'afterbegin' | 'afterend' | 'beforebegin' | 'beforeend';

const Notification = () => import('BobjollNotifications');

const notification = async function () {
    const notificationObject = await Notification();
    return new notificationObject.default();
};

export class View {
    private defaultConfig = {
        history: true,
        loader: false,
        print: false,
        push: false,
        pushTracker: false,
        delay: false,
        lock: false,
        metas: false,
    };
    main: HTMLElement | null;
    groups: string[] = [];
    timeout: any;
    template = '';
    templates: Templates;
    awaitErrorRetrys: number;
    awaitErrorDelay: number;
    awaitErrorCount: number;
    awaitErrorInterval: any;
    awaitErrorTimeout: any;
    globalVariables: string[];

    constructor() {
        this.main = document.getElementById('main');
        this.template = document.body && document.body.dataset ? document.body.dataset.hbsTemplate || '' : '';
        this.templates = {};
        this.awaitErrorRetrys = 1;
        this.awaitErrorDelay = 60000;
        this.awaitErrorCount = 0;
        this.globalVariables = [
            'ACCOUNTS_BASE_URL',
            'AUTHOR_URL',
            'BASE_URL',
            'ESSENTIAL_COUNTRY',
            'FEATURE_AD_MANAGER',
            'FEATURE_AD_MANAGER_ATTRIBUTE',
            'FEATURE_DISABLE_ADS',
            'FEATURE_DISABLE_AFFILIATE_BY_COUNTRY',
            'FEATURE_DISABLE_INFO_RESOURCES',
            'FEATURE_ENABLE_SSEO',
            'FEATURE_OBFUSCATE_LINKS',
            'HOW_ATTRIBUTE_AUTHOR_URL',
            'LANGUAGE',
            'MY_SUBSCRIPTIONS_URL',
            'NUM_RESOURCES_PREMIUM',
            'NUM_RESOURCES_SELECTION',
            'NUM_RESOURCES',
            'PREMIUM_LICENSE_URL',
            'PRICING_URL',
            'STANDARD_LICENSE_URL',
            'SUPPORT_URL',
            'URL_CATEGORY',
            'URL_RESOURCE_KEYWORDS',
            'URL_STATIC_IMG',
        ];

        window.addEventListener('print', (e: CustomEvent) => {
            const pages = ['search', 'category', 'detail'];

            if (e.detail && pages.indexOf(e.detail) >= 0) {
                const popovers = document.getElementsByClassName('popover');

                [].forEach.call(popovers, (popover: HTMLElement) => {
                    const button = <HTMLElement>popover.querySelector('button');

                    if (button) {
                        button.classList.remove('active');
                    }

                    popover.classList.remove('active');
                });
            }
        });
    }

    public inGroup(view: string, group: string): boolean {
        const template = this.templates[view];

        return template && template.groups && 0 <= template.groups.indexOf(group) ? true : false;
    }

    public getGroup(group: string) {
        return Object.keys(this.templates).filter(view => {
            const template = this.templates[view];

            if (template && template.groups) {
                return template.groups.indexOf(group) >= 0;
            }

            return false;
        });
    }

    public addView(options: ViewOptions) {
        if (!this.templates[options.name] || options.override) {
            if (options.groups) {
                options.template.groups = options.groups;
            }

            this.templates[options.name] = options.template;
        }
    }

    private async print(settings: ViewPrint, view: string) {
        const printEvent = new CustomEvent(`print${view}`, {
            detail: settings,
        });

        if (settings.prePrint && settings.xhr) {
            if ('function' === typeof settings.prePrint) {
                settings.prePrint(settings);
            }
        }

        if (settings.loader) loader.percentage(10);

        if (settings.insert && settings.print && settings.key) {
            const element: HTMLElement | undefined = qq(settings.insert.element).pop();
            const global = await this.getGlobalVariables(settings.key, settings);
            const template = document.createElement('div');

            const bannerCampaign =
                view === 'detail' && settings.xhr.premium ? 'premium' : settings.insert.bannerCampaign;
            const showCampaign = bannerCampaign && showCampaignBanner(bannerCampaign);
            if (showCampaign) {
                global.globalCampaign = GLOBAL_CAMPAIGN;
                global.banner = bannerCampaign;
            }

            const html = await (async function () {
                const options = extend(global, settings.xhr);

                if ('function' === typeof settings.template) {
                    const template = await settings.template(options);
                    if ('object' === typeof template) {
                        const { default: Template } = template;
                        return Template(options);
                    }
                    if ('string' === typeof template) {
                        return template;
                    }
                } else if (Array.isArray(settings.template) && 'object' === typeof settings.template[0]) {
                    for (let i = 0; i < settings.template.length; i++) {
                        const object: any = settings.template[i];

                        if (object.rule(settings)) {
                            return object.hbs(options);
                        }
                    }
                } else if (Array.isArray(settings.template) && 'function' === typeof settings.template[0]) {
                    return settings.template[Math.floor(Math.random() * settings.template.length)](options);
                } else {
                    if (window.innerWidth < Settings['breakpoints']['md']) {
                        return settings.template.mobile(options);
                    } else {
                        return settings.template.desktop(options);
                    }
                }
            })();

            template.innerHTML = html;

            if (settings.loader) {
                const templateImages = qq('img', template);

                if (templateImages) loader.setListener(templateImages);
            }

            if (element && 'innerHTML' === settings.insert.method) {
                element.innerHTML = template.innerHTML;
                if (settings.history) {
                    element.dataset.view = view;
                    element.dataset.key = settings.key;
                }
            }

            if (element && 'insertAdjacentHTML' === settings.insert.method && settings.insert.methodArgument) {
                element.insertAdjacentHTML(settings.insert.methodArgument, template.innerHTML);
            }

            if ('modal' === settings.insert.method) {
                const modalSettings = [
                    {
                        name: view,
                        html: template.innerHTML,
                    },
                ];

                if (settings.insert && typeof settings.insert.methodArgument !== 'undefined') {
                    modalSettings.push(settings.insert.methodArgument);
                }

                // eslint-disable-next-line prefer-spread
                ww.ModalInstance.print.apply(ww.ModalInstance, modalSettings);
            }

            if (element && showCampaign) {
                initCampaignBanner(element, bannerCampaign);
            }
        }

        if (settings.lock && this.main) {
            this.main.classList.remove('disabled');
        }

        if (settings.print) {
            if ('function' === typeof settings.callback) settings.callback(settings, view);
            window.dispatchEvent(printEvent);
            if (settings.groups) {
                settings.groups.forEach((group: string) => {
                    const printGroupEvent = new CustomEvent(`printgroup-${group}`, {
                        detail: settings,
                    });
                    window.dispatchEvent(printGroupEvent);
                });
            }
        }

        if (settings.history) {
            const HistoryEvent = new CustomEvent('pushstate', {
                detail: settings,
            });

            window.dispatchEvent(HistoryEvent);
        }

        if (settings.metas && settings.setMetas && settings.xhr) {
            if ('function' === typeof settings.setMetas) {
                settings.setMetas(settings);
            }
        }
    }

    async newPage(options: ViewPrint) {
        (await notification()).hide('notification-connection');

        if (!options.key) options['key'] = options['url'] || new Date().getTime().toString();

        this.awaitErrorCount = 0;

        clearTimeout(this.awaitErrorTimeout);
        clearInterval(this.awaitErrorInterval);

        options.views.forEach((view: string) => {
            let settings = options;
            const config = this.templates[view] || this.defaultConfig;

            if (config) {
                const printEvent = new CustomEvent('print', {
                    detail: view,
                });

                if (config.history) {
                    document.body.dataset.hbsTemplate = view;
                }

                settings = extend(config, settings);

                if (settings.loader) {
                    loader.initialize(view);
                    loader.percentage(10);
                }

                this.printDelay(
                    !!settings.delay,
                    async () => {
                        if (settings.getXHR && !settings.xhr) {
                            const response = settings.getXHR(settings);

                            response
                                .then((data: XMLHttpRequest) => {
                                    settings.xhr = data;

                                    this.print(settings, view);
                                })
                                .catch((err: XMLHttpRequest) => {
                                    if (('undefined' === typeof debug || !debug.printEvent) && settings.location) {
                                        window.location.href = settings.location;
                                    } else {
                                        throw err;
                                    }
                                });
                        } else {
                            this.print(settings, view);
                        }
                    },
                    50,
                    settings,
                );

                window.dispatchEvent(printEvent);
            }
        });
    }

    private printDelay(delayPrint: boolean, callback: Function, delay: number, settings: ViewPrint) {
        if (delayPrint) {
            clearTimeout(this.timeout);

            if (settings.lock && this.main) {
                this.main.classList.add('disabled');
            }

            this.timeout = setTimeout(() => {
                callback();
            }, delay);
        } else {
            callback();
        }
    }

    private async getGlobalVariables(key: string | number, settings: ViewPrint) {
        const currency = Currency.getCurrency();
        const directDownload = cookie.getItem('direct-download');
        const filters = Arguments.stringToObject(settings.url || settings.location || '');
        const variables: { [name: string]: any } = this.globalVariables.reduce(function (
            acc: { [name: string]: any },
            variable: string,
        ) {
            if ((window as any)[variable]) {
                acc[variable] = (window as any)[variable];
            }

            return acc;
        },
            {});

        if (settings.getMetas && settings.metas) {
            const metas = await settings.getMetas(settings);

            variables.metas = metas.strings;
        }

        variables.key = key;
        variables.mobile = window.innerWidth <= Settings.breakpoints.sm;
        variables.tablet = window.innerWidth > Settings.breakpoints.md;
        variables.format = filters.format || '';
        variables.filters = filters || [];
        variables.controller = settings.views.join(',');
        variables.loggedUser = 'Anonymous' !== UserHelpers.type() ? true : false;
        variables.premiumUser = 'Premium' === UserHelpers.type() ? true : false;
        variables.essentialUser = 'Essential' === UserHelpers.type() ? true : false;
        variables.ie = detectIE() ? true : false;
        variables.CURRENCY = '$';
        variables.CURRENCY_NAME = 'USD';
        variables.ESSENTIAL_COUNTRY = ESSENTIAL_COUNTRY;
        variables.FreepikUser = UserHelpers.isFreepik();
        variables.test_ab = getTestVariables();
        variables.sprClass = FEATURE_DISABLE_ADS ? ' spr--force-hide' : FEATURE_MOBILE_ADS_BY_LANGUAGE ? ' show-mobile-ads' : '';
        variables.sprTestAdsClass = this.getClassContainerTestAds();

        if (settings.views.filter((viewType: string) => viewType.match(/detail-(keywords|related)/)).length) {
            const {
                global: {
                    detail: { type: resourceType },
                },
            } = settings;
            variables.URL_RESOURCE_KEYWORDS_FEATURE = resourceType.match(/photo/i) ? 'fotos' : 'psd';
        }

        if (settings.url) {
            variables.current_url = settings.url;
        }

        if (variables.loggedUser) {
            variables.user = gr.user;
        }

        if ('Premium' !== UserHelpers.type() && 'Essential' !== UserHelpers.type()) {
            variables.ads = true;
        }

        if (directDownload) {
            variables.directDownload = directDownload;
        }

        return { ...variables, ...currency, ...settings.global };
    }

    private getClassContainerTestAds(): string | void {
        if (isAdBlockEnabled() || getConfigAds === 'isDisableAds') return;
        if (getConfigAds === 'showAdsTag') return ' detail__test-ads-tag container-ads-tag';
        if (getConfigAds === 'showRemoveTag') return ' detail__test-ads-tag container-tag-remove-ads';
    }

    public getPages() {
        return Object.keys(this.templates).filter((view: string) => {
            return this.templates[view].history;
        });
    }

    public getView() {
        return document.body.dataset.hbsTemplate || '';
    }

    public getGlobalConstants() {
        const constants: { [name: string]: any } = this.globalVariables.reduce(function (
            acc: { [name: string]: any },
            variable: string,
        ) {
            if ((window as any)[variable]) {
                acc[variable] = (window as any)[variable];
            }

            return acc;
        },
            {});

        constants.mobile = window.innerWidth <= Settings.breakpoints.md;

        if ('Premium' !== UserHelpers.type() && 'Essential' !== UserHelpers.type()) {
            constants.ads = true;
        }

        return constants;
    }
}

export var view = new View();
