import 'Variables';

export class Recaptcha {
    private static api = '//www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit';
    public static grecaptcha: any;
    public defaultSettings = {
        recaptcha: navigator.userAgent.match(/Ghost Inspector/gi) ? false : true,
        recaptchaSitekey: RE_CAPTCHA_KEY_INVISIBLE,
    };
    public nameHandler = '';
    public loaded: RecaptchaActive = {};
    public disable: RecaptchaActive = {};
    public handlers: Handlers = {};
    public widgets: Widgets = {};

    constructor(name: string) {
        this.nameHandler = name;
        this.loaded[name] = false;
        this.handlers[name] = null;
        this.disable[name] = false;
        this.widgets[name] = null;
    }

    public async load(): Promise<{}> {
        const fjs = document.getElementsByTagName('script')[0];
        const js = document.createElement('script') as HTMLScriptElement;
        js.id = 'recaptcha-sdk-' + this.nameHandler;
        js.src = Recaptcha.api;
        if (fjs.parentNode) fjs.parentNode.insertBefore(js, fjs);
        return new Promise((resolve, reject) => {
            (window as any).recaptchaLoaded = resolve;
            this.loaded[this.nameHandler] = true;
            setTimeout(() => reject('Failed loading recaptcha!'), 10000);
        });
    }

    public addEventSubmit(form: HTMLFormElement, callback: Function) {
        this.handlers[this.nameHandler] = (event: Event) => {
            if (event instanceof Event) {
                event.preventDefault();
                event.stopPropagation();
            }

            if (this.disable[this.nameHandler]) {
                this.disable[this.nameHandler] = false;
                return;
            }

            callback(form, event);
        };
    }

    public render(form: HTMLFormElement, button: HTMLElement | null, buttonID: string) {
        if (
            this.defaultSettings.recaptcha &&
            button &&
            grecaptcha &&
            'number' !== typeof this.widgets[this.nameHandler]
        ) {
            button.id = buttonID;

            this.widgets[this.nameHandler] = grecaptcha.render(buttonID, {
                sitekey: this.defaultSettings.recaptchaSitekey,
                callback: this.handlers[this.nameHandler],
            });
        } else {
            form.addEventListener('submit', this.handlers[this.nameHandler] as EventListener);
        }
    }

    public reset(button: HTMLElement | null, buttonID: string) {
        if (this.defaultSettings.recaptcha) {
            const widget = this.widgets[this.nameHandler];

            if (button && 'number' !== typeof widget) {
                button.id = buttonID;

                this.widgets[this.nameHandler] = grecaptcha.render(buttonID, {
                    sitekey: this.defaultSettings.recaptchaSitekey,
                    callback: this.handlers[this.nameHandler],
                });
            } else {
                grecaptcha.reset(widget);
            }
        }
    }
}

interface Handlers {
    [key: string]: null | FormHandlers;
}

interface RecaptchaActive {
    [key: string]: boolean;
}

interface Widgets {
    [key: string]: null | string;
}

type FormHandlers = (this: HTMLElement, event: Event) => void;
