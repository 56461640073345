import { UserHelpers } from 'Partials/user';
import { XHR } from 'Partials/xhr';
import { xhr } from 'Service/xhr';

export default class Follow {
    static api = `${BASE_URL}xhr/user/follow`;

    public static async get(id: string) {
        if (await UserHelpers.isLogged(false)) {
            const response = await xhr(`${Follow.api}?author=${id}`, {
                withCredentials: true,
                headers: {
                    'X-CSRF-TOKEN': CSRF_TOKEN,
                    'X-Requested-With': 'XMLHttpRequest',
                },
            });

            return response;
        }
    }

    public static async toggle(id: string, target?: Element) {
        if (await UserHelpers.isLogged()) {
            const response = await xhr(`${Follow.api}`, {
                method: 'POST',
                data: XHR.FormData({ author: id }),
                withCredentials: true,
                headers: {
                    'X-CSRF-TOKEN': CSRF_TOKEN,
                    'X-Requested-With': 'XMLHttpRequest',
                },
            });

            if (response.cerberusInvalid) {
                document.location.reload();
            }

            if (!response.getPathValue('success') && target) {
                UserHelpers.alert(`notification-${id}`, response.messages, 'error', 'bottom-center');
            }

            return response;
        }
    }
}
