import { q } from 'bobjoll/ts/library/dom';

import triggerManualGA4Events from './ga4.init-manual-events';

export const categoryLandingAutopromoEventsGa4 = () => {
    const clickableElement = q('.category-landing .section-promo .button');

    if (!clickableElement) return;

    const type = clickableElement?.dataset.key;

    clickableElement.addEventListener('click', e => {
        e.stopPropagation();

        triggerManualGA4Events({
            description: 'categoryLandingAutopromo',
            options: {
                type,
                interaction_type: 'click',
            },
        });
    });

    triggerManualGA4Events({
        description: 'categoryLandingAutopromo',
        options: { type },
    });
};
