export function elementInViewport(element: HTMLElement) {
    let top = element.offsetTop;
    let left = element.offsetLeft;
    const width = element.offsetWidth;
    const height = element.offsetHeight;

    while (element.offsetParent) {
        element = element.offsetParent as HTMLElement;
        top += element.offsetTop;
        left += element.offsetLeft;
    }

    return (
        top >= window.pageYOffset &&
        left >= window.pageXOffset &&
        top + height <= window.pageYOffset + window.innerHeight &&
        left + width <= window.pageXOffset + window.innerWidth
    );
}

export const isHidden = (element: HTMLElement) => window.getComputedStyle(element).display === 'none';

export const checkElementOutOfView = (element: HTMLElement, rootMargin = '0px 0px 0%', callback?: Function) => {
    const observer = new IntersectionObserver(
        ([e]) =>
            callback
                ? callback(e.intersectionRatio < 1)
                : e.target.classList.toggle('is-sticky', e.intersectionRatio < 1),
        {
            threshold: [1],
            rootMargin,
        },
    );

    observer.observe(element);
};

export const checkScrollPassedElement = (element: HTMLElement, rootMargin = '0px 0px 0%', callback: Function) => {
    const observer = new IntersectionObserver(([e]) => callback(e.boundingClientRect.y - (e.rootBounds?.y || 0) < 0), {
        threshold: [1],
        rootMargin,
    });

    observer.observe(element);
};

export const checkChildrenHasTheClass = (options: MutationObserverChildrenHasClass) => {
    const { approximateChildrensInView, callback, childrenElements, elementContainer, classNameChanged } = options;
    const totalChildren = childrenElements.length;

    if (totalChildren === 0) return;

    let countChildrenReady = 0;

    childrenElements.forEach((child: HTMLElement) => {
        let classStatePrevious = child.classList.contains(classNameChanged);

        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.attributeName === 'class') {
                    const currentClassState = (mutation.target as HTMLElement).classList.contains(classNameChanged);

                    if (classStatePrevious !== currentClassState) {
                        classStatePrevious = currentClassState;

                        currentClassState && countChildrenReady++;

                        if (countChildrenReady === totalChildren || countChildrenReady >= approximateChildrensInView) {
                            elementContainer.classList.add('visible');
                            callback();
                        }
                    }
                }
            });
        });

        observer.observe(child, {
            attributes: true,
        });
    });

    // fallback if any image doesn't load
    setTimeout(() => {
        if (countChildrenReady !== totalChildren) {
            elementContainer.classList.add('visible');
            callback();
        }
    }, 5000);
};

interface MutationObserverChildrenHasClass {
    approximateChildrensInView: number;
    callback: Function;
    childrenElements: HTMLElement[];
    classNameChanged: string;
    elementContainer: HTMLElement;
}
