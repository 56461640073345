var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing;

  return ((stack1 = (helpers.times||(depth0 && depth0.times)||alias2).call(alias1,(helpers.randomRange||(depth0 && depth0.randomRange)||alias2).call(alias1,1,10,{"name":"randomRange","hash":{},"data":data,"loc":{"start":{"line":2,"column":9},"end":{"line":2,"column":27}}}),{"name":"times","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":50}}})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "<div></div>";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.classes : stack1)) != null ? stack1.sponsor : stack1), depth0));
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    <div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.classes : stack1)) != null ? stack1.wrapper : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.classes : stack1)) != null ? stack1.loadingModifier : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.customClass : stack1), depth0))
    + "\">\n"
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),true,"==",(depth0 != null ? depth0.hideHeaderElement : depth0),{"name":"compare","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":21,"column":20}}})) != null ? stack1 : "")
    + "\n        <section>\n            <div class=\"section-spr--row "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.classes : stack1)) != null ? stack1.row : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.classes : stack1)) != null ? stack1.row : stack1), depth0))
    + "--nowrap overflow-hidden\">\n                " + require("./showcase_shutterstock_loading.hbs").apply(null, Array.prototype.slice.call(arguments, arguments.length > 6)) + "\n            </div>\n        </section>\n    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "        <header>\n            <div class=\"section-spr--row "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.classes : stack1)) != null ? stack1.row : stack1), depth0))
    + "\">\n                <p class=\"loading loading--text\"></p>\n\n                <div class=\"push-right\">\n                    <div class=\"section-spr--row "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.classes : stack1)) != null ? stack1.row : stack1), depth0))
    + "\">\n                        <div class=\"loading loading--text\"></div>\n                    </div>\n                </div>\n            </div>\n        </header>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "    " + require("./showcase_shutterstock.hbs").apply(null, Array.prototype.slice.call(arguments, arguments.length > 6)) + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, alias4=container.escapeExpression;

  return ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias2).call(alias1,true,"==",((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.randomElements : stack1),{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":12}}})) != null ? stack1 : "")
    + "\n<section id=\""
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"section-spr section-spr--grid "
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias2).call(alias1,true,"==",((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.setAdvertisementClasses : stack1),{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":71},"end":{"line":5,"column":174}}})) != null ? stack1 : "")
    + " "
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.classes : stack1)) != null ? stack1.custom : stack1), depth0))
    + "\" data-cs-override-id=\"spn-banner\">\n\n"
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias2).call(alias1,0,"==",(helpers.get_length||(depth0 && depth0.get_length)||alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.data : stack1)) != null ? stack1.resources : stack1),{"name":"get_length","hash":{},"data":data,"loc":{"start":{"line":7,"column":22},"end":{"line":7,"column":62}}}),{"name":"compare","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":31,"column":16}}})) != null ? stack1 : "")
    + "</section>\n";
},"useData":true});