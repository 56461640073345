import { ShutterstockVM } from '@fc/sponsor_front';
import { q } from 'bobjoll/ts/library/dom';

import { createPixel } from './shutterstock.pixel';

export const add404ClickPixelAndRedirection = (sponsorResponse: ShutterstockVM.ShutterstockResponseBanner) => {
    const banner = q('.banner404');

    ['click', 'auxclick'].forEach(eventType => {
        banner &&
            banner.addEventListener(eventType, (e: Event) => {
                const type =
                    e.target instanceof HTMLElement && e.target.classList.contains('shutterstock__logo')
                        ? 'logo'
                        : 'coupon';
                const component = sponsorResponse[type];

                if (component) {
                    createPixel({
                        element: banner,
                        eventType,
                        pixelParams: component.trackerClickCustom,
                    });

                    window.open(component.url, '_blank');
                }
            });
    });
};
