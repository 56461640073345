export class Spinner {
    element: HTMLElement;
    wrapper: HTMLElement;
    constructor(element: HTMLElement) {
        this.wrapper = document.createElement('div');
        this.element = element;
        this.element.classList.add('spinner');
        this.element.appendChild(this.wrapper);
        this.wrapper.classList.add('spinner__wrapper');
        this.element.dataset.disableMouseUp = '';
    }

    done() {
        this.element.classList.remove('spinner');
        this.element.removeChild(this.wrapper);
        this.element.removeAttribute('data-disable-mouse-up');
    }
}