/* eslint-disable @typescript-eslint/no-explicit-any */
import { ww } from 'bobjoll/ts/library/dom';
import abbreviate from 'bobjoll/ts/library/number-abbreviate';
import Handlebars from 'handlebars/runtime';
import {
    bytesToSize,
    capitalize,
    getRandomRange,
    howManyMillions,
    numberFormatCurrency,
    numberFormatHundred,
    numberNormalizer,
    randomAvatar,
    setSignNumber,
} from 'Library/helpers';
import { sprintf } from 'sprintf-js';

import { imageResize } from '../library/helpers/image';
import { ngettext } from '../library/helpers/text';
import { __ } from '../partials/language';

Handlebars.registerHelper('debug', function(optionalValue: any) {
    if (optionalValue) {
        console.log('Value');
        console.log('====================');
        console.log(optionalValue);
        console.log('====================');
    }
});

Handlebars.registerHelper('randomAvatar', function(name: any) {
    return randomAvatar(name);
});

Handlebars.registerHelper('randomRange', function(min: any, max: any) {
    return getRandomRange(min, max);
});

Handlebars.registerHelper('get_length', function(obj: any) {
    if ('object' === typeof obj) {
        return Object.keys(obj).length;
    } else if (Array.isArray(obj)) {
        return obj.length;
    }

    return 0;
});

Handlebars.registerHelper('get_arr_length', function(obj: any) {
    let length = 0;

    if ('object' === typeof obj) {
        length = Object.keys(obj).length;
    } else if (Array.isArray(obj)) {
        length = obj.length;
    }

    return length - 1;
});

Handlebars.registerHelper('getUrlParams', function(url: any) {
    try {
        return url.split('?').pop();
    } catch (e) {
        console.warn(e);

        return null;
    }
});

Handlebars.registerHelper('concat', function(...args: any[]) {
    let outStr = '';

    for (const arg in args) {
        if ('length' !== arg && typeof args[arg] != 'object' && typeof args[arg] != 'function') {
            outStr += args[arg];
        }
    }

    return outStr;
});

Handlebars.registerHelper('compare', function(this: any, lvalue: any, operator: any, rvalue: any, options: any) {
    if (arguments.length < 3) {
        throw new Error("Handlerbars Helper 'compare' needs 2 parameters");
    }

    if (options === undefined) {
        options = rvalue;
        rvalue = operator;
        operator = '===';
    }

    const operators = {
        '==': function(l: any, r: any) {
            return l == r;
        },
        '===': function(l: any, r: any) {
            return l === r;
        },
        '!=': function(l: any, r: any) {
            return l != r;
        },
        '!==': function(l: any, r: any) {
            return l !== r;
        },
        '<': function(l: any, r: any) {
            return l < r;
        },
        '>': function(l: any, r: any) {
            return l > r;
        },
        '<=': function(l: any, r: any) {
            return l <= r;
        },
        '>=': function(l: any, r: any) {
            return l >= r;
        },
        typeof: function(l: any, r: any) {
            return typeof l == r;
        },
    };

    if (!operators[operator]) {
        throw new Error("Handlerbars Helper 'compare' doesn't know the operator " + operator);
    }

    const result = operators[operator](lvalue, rvalue);

    if (result) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
});

Handlebars.registerHelper('limit', function(arr, limit) {
    return arr.slice(0, limit);
});

Handlebars.registerHelper('language', function(...args: any[]): string {
    // eslint-disable-next-line prefer-spread
    return __.apply(null, args);
});

Handlebars.registerHelper('sprintf', function(...args: Parameters<typeof sprintf>) {
    return sprintf(...args);
});

Handlebars.registerHelper('imageLoaderSize', function(...args: any[]) {
    return args.reduce(function(acc, number) {
        if (!acc && number && (typeof number).match(/string|number/)) {
            acc = number;
        }
        return acc;
    }, 0);
});

Handlebars.registerHelper('numberFormat', function(number, decimals) {
    if ('undefined' !== typeof number) {
        if ('number' !== typeof number) {
            try {
                number = numberNormalizer(number);
            } catch (err) {
                return number;
            }
        }

        if ('number' === typeof decimals) {
            number = number.toFixed(decimals);
        }

        number = number.toLocaleString(LANGUAGE);
    }

    return number;
});

Handlebars.registerHelper('numberFormatAbbr', function(number) {
    if (number) {
        if ('number' !== typeof number) {
            try {
                number = numberNormalizer(number);
            } catch (err) {}
        }

        return abbreviate(number, 0, true);
    }

    return number;
});

Handlebars.registerHelper('base64Encode', function(string = '') {
    return window.btoa(string);
});

Handlebars.registerHelper('numberFormatCurrency', function(string, decimal) {
    return numberFormatCurrency(string, decimal);
});

Handlebars.registerHelper('numberFormatHundred', function(n: number | string, applySign = true, abbr = false) {
    return numberFormatHundred(n, applySign, abbr);
});

Handlebars.registerHelper('setSignNumber', function(n: number | string) {
    return setSignNumber(n);
});

Handlebars.registerHelper('capitalize', function(str: string) {
    return capitalize(str);
});

Handlebars.registerHelper('times', function(n, block) {
    let accum = '';

    if (!n) {
        n = 20;
    }

    for (let i = 0; i < n; ++i) {
        accum += block.fn(i);
    }

    return accum;
});

Handlebars.registerHelper('contains', function(this: any, arr, value, options) {
    if (arr.indexOf(value) >= 0) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
});

Handlebars.registerHelper('howManyMillions', function(number: number | string) {
    if (number) {
        if ('number' !== typeof number) {
            try {
                number = numberNormalizer(number);
            } catch (err) {}
        }

        return howManyMillions(number);
    }

    return number;
});

Handlebars.registerHelper('randomArr', function(this: any, arr: any, options: any) {
    const index = Math.floor(Math.random() * arr.length);
    const data = {
        ...this,
        ...arr[index],
    };
    return options.fn({
        index,
        ...data,
    });
});

Handlebars.registerHelper('ngettext', function(str1: string, str2: string, n: number) {
    return ngettext(str1, str2, n);
});

Handlebars.registerHelper('timeAgo', async function(timestamp: number) {
    const date = new Date(timestamp * 1000);
    const { formatDistanceStrict } = await import('Library/date-fns');
    return formatDistanceStrict(date, new Date(), {
        addSuffix: true,
    });
});

Handlebars.registerHelper('window', function(key: string) {
    const variable = ww.getPathValue(key);
    if (variable && (typeof variable).match(/string|number/i)) {
        return variable;
    }
});

Handlebars.registerHelper('encodeURIComponent', (str: string) => encodeURIComponent(str));

Handlebars.registerHelper('ternary', function(...args: string[]): string | void {
    try {
        const [value1, operator, value2, , valueTrue, , valueFalse] = args;
        return {
            '==': (l: string, r: string) => l == r,
            '===': (l: string, r: string) => l === r,
            '!=': (l: string, r: string) => l != r,
            '!==': (l: string, r: string) => l !== r,
            '<': (l: string, r: string) => l < r,
            '>': (l: string, r: string) => l > r,
            '<=': (l: string, r: string) => l <= r,
            '>=': (l: string, r: string) => l >= r,
            typeof: (l: string, r: string) => typeof l == r,
        }[operator](value1, value2)
            ? valueTrue
            : valueFalse;
    } catch (err) {
        console.error(err);
    }
} as any);

Handlebars.registerHelper('isTrue', function(this: any, value: any, options: any) {
    if (value) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
});

Handlebars.registerHelper('isTrueCondition', function(
    this: any,
    value: any,
    condition: any,
    value2: any,
    value3: any,
    options: any,
) {
    const conditions = {
        '||': value || value2 || value3,
        '&&': value && value2 && value3,
    };
    if (conditions[condition]) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
});

Handlebars.registerHelper('isFalse', function(this: any, value: any, options: any) {
    if (!value) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
});

Handlebars.registerHelper('isFalseCondition', function(
    this: any,
    value: any,
    condition: any,
    value2: any,
    options: any,
) {
    const conditions = {
        '||': !value || !value2,
        '&&': !value && !value2,
    };
    if (conditions[condition]) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
});

Handlebars.registerHelper('context', function(this: any, context: any, options: any) {
    return options.fn({ ...context });
});

Handlebars.registerHelper('fallback', function(...args: string[]) {
    const str = args.reduce((acc, str) => {
        if ('string' === typeof str && !acc) {
            acc = str;
        }
        return acc;
    }, null as null | string);
    return str || '';
} as any);

Handlebars.registerHelper('imageResizeStyle', function(width, height, newWidth, newHeight) {
    const size = imageResize({
        width,
        height,
        newWidth,
        newHeight,
    });
    return `style="width: ${size.width}px; height: ${size.height}px;"`;
});

Handlebars.registerHelper('match', function(this: any, str: string | number, regExpStr: string, options: any) {
    const regExp = new RegExp(regExpStr, 'gi');
    try {
        if ('number' === typeof str) {
            str = str.toString();
        }

        if (str.match(regExp)) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }
    } catch (error) {
        return options.inverse(this);
    }
});

Handlebars.registerHelper('setVar', function(varName, varValue, options) {
    options.data.root[varName] = varValue;
});

Handlebars.registerHelper('counter', function(index) {
    return index + 1;
});

Handlebars.registerHelper('relativeHeight', function(width, height, options) {
    if (width && height) {
        const relativeHeight = (height / width) * 100;
        options.data.root['relativeHeight'] = relativeHeight.toFixed(2);
    }
});

Handlebars.registerHelper('bytesToSize', function(bytes) {
    return bytesToSize(parseInt(bytes));
});
