import { ViewPrint } from 'Partials/view';
import { Arguments } from 'Project/ts/library/arguments';
import Cache from 'Project/ts/library/cache';
import { mapBoosterResources } from 'Project/ts/library/helpers/resources';
import xhr from 'Project/ts/service/xhr';

import { errorHandler } from '../helpers/detail.helpers.error';
import { mapResourceComplete } from '../mappers/detail.mappers.resource';
import { mapModelResourceComplete } from '../mappers/detail.mappers.resource_3d';
import { enableDownloadSelector } from '../partials/detail.partials.downloadSelector';
import { enableDownloadSizes } from '../partials/detail.partials.downloadSize';
import { getCacheResource } from './detail.api.cache';
import { getGlobals } from './detail.api.globals';

export const getResource = function(options: ViewPrint) {
    const {
        url,
        dependency: { cacheId, cacheKey, id },
    } = options;
    return new Promise(async resolve => {
        const args = [];
        const filters = Arguments.stringToObject(cacheKey);
        if ('undefined' !== typeof cacheId) {
            args.push(`position=${cacheId}`);
        }
        if ('undefined' !== typeof filters.page) {
            args.push(`page=${filters.page}`);
        }
        if ('undefined' !== typeof filters.query) {
            args.push(`query=${filters.query}`);
        }
        try {
            const { download, resource, navigation } = await getCacheResource({
                cacheId,
                cacheKey,
                id,
            });
            if (!resource) throw Error('Resource not found');
            const globals = getGlobals(resource);

            resolve({
                ...resource,
                ...download,
                ...globals,
                navigation,
                downloadSelector: enableDownloadSelector(resource),
                enableDownloadSizes: enableDownloadSizes(resource),
            });
        } catch (error) {
            errorHandler(`${url}${args.length ? '#' + args.join('&') : ''}`, error);
        }
    });
};

export const getResourceComplete = function(options: ViewPrint): Promise<ReturnType<typeof mapResourceComplete>> {
    const { id, type } = options.xhr || {};
    return new Promise((resolve, reject) => {
        try {
            let queryParam = '';
            let fromQuery: string;
            if (options.key) {
                const { query: queryKey, from_query: fromQueryKey } = Arguments.stringToObject(options.key);
                const { query: queryUrl, from_query: fromQueryUrl } = Arguments.stringToObject(window.location.href);
                const query = queryUrl || queryKey;
                fromQuery = fromQueryUrl || fromQueryKey;
                if (query) {
                    queryParam = `&query=${query}`;
                }
            }

            const cacheKey = `/xhr/detail/${id}?type=${type}${queryParam}`;
            const cache = Cache.get(cacheKey);

            if (cache) {
                resolve(cache);
            }

            xhr.get(cacheKey, {
                headers: {
                    'X-CSRF-TOKEN': CSRF_TOKEN,
                    'X-Requested-With': 'XMLHttpRequest',
                },
            }).then(response => {
                const data = mapResourceComplete(cacheKey, options.xhr, mapBoosterResources(response, fromQuery));

                Cache.set({ key: cacheKey, value: data, time: 1800 });
                resolve(data);
            });
        } catch (error) {
            reject(error);
        }
    });
};

export const get3DResourceComplete = function(
    options: ViewPrint,
): Promise<ReturnType<typeof mapModelResourceComplete>> {
    const { id, type } = options.xhr || {};
    return new Promise((resolve, reject) => {
        try {
            let queryParam = '';
            let fromQuery: string;
            if (options.key) {
                const { query: queryKey, from_query: fromQueryKey } = Arguments.stringToObject(options.key);
                const { query: queryUrl, from_query: fromQueryUrl } = Arguments.stringToObject(window.location.href);
                const query = queryUrl || queryKey;
                fromQuery = fromQueryUrl || fromQueryKey;
                if (query) {
                    queryParam = `&query=${query}`;
                }
            }

            const cacheKey = `/xhr/3d-models/detail/${id}?type=${type}${queryParam}`;
            const cache = Cache.get(cacheKey);

            if (cache) {
                resolve(cache);
            }

            xhr.get(cacheKey, {
                headers: {
                    'X-CSRF-TOKEN': CSRF_TOKEN,
                    'X-Requested-With': 'XMLHttpRequest',
                },
            }).then(response => {
                let data = mapModelResourceComplete(cacheKey, options.xhr, mapBoosterResources(response, fromQuery));

                Cache.set({ key: cacheKey, value: data, time: 1800 });
                resolve(data);
            });
        } catch (error) {
            reject(error);
        }
    });
};
