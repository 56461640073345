import { Cookie } from 'bobjoll/ts/library/cookie';
import { GTMArguments } from 'Partials/tracker-gtm';
import { ww } from 'bobjoll/ts/library/dom';

export const ga4 = (options?: GTMArguments<string | Function>) => {
    if ('undefined' === typeof ww.dataLayer) {
        ww.dataLayer = ww.dataLayer || [];
    }

    if (Cookie.getItem('ga4_events_debug')) {
        console.log('ga4 event', options);
    }

    const data = {
        event: 'ga4event',
        ...options,
    };

    ww.dataLayer.push(data);
};
