import { Settings } from 'bobjoll/ts/library/settings';
import { cookie } from 'Library/cookie';
import { Currency } from 'Library/currency';
import { detectIE } from 'Library/helpers/device';
import { globals } from 'Library/helpers/globals';
import { __ } from 'Partials/language';
import { UserHelpers } from 'Partials/user';
import { isFlaticonAuthor } from 'Partials/user/user.helpers';

export const getGlobals = (resource: any = {}) => {
    const fileType = resource && resource.type ? resource.type : '';
    const pathVectors = {
        en: 'vectors',
        nl: 'vectoren',
        fr: 'vecteurs',
        de: 'vektoren',
        it: 'vettori',
        pl: 'wektory',
        pt: 'vetores',
        ru: 'vectors',
        es: 'vectores',
        jp: 'vectors',
        ko: 'vectors',
    };
    const newFeatureVector = fileType.match(/vector/i); // fixme Revisar un par de veces

    let URL_RESOURCE_KEYWORDS_FEATURE = URL_RESOURCE_KEYWORDS.split('-')[0];
    if (fileType.match(/psd|photo/i)) {
        URL_RESOURCE_KEYWORDS_FEATURE = fileType.match(/psd/i)
            ? 'psd'
            : LANGUAGE.match(/en|jp|ko|ru/i)
            ? 'photos'
            : LANGUAGE.match(/nl/i)
            ? 'fotos'
            : LANGUAGE.match(/pl/i)
            ? 'zdjecia'
            : URL_RESOURCE_KEYWORDS.split('-')[0];
    } else if (newFeatureVector) {
        URL_RESOURCE_KEYWORDS_FEATURE = pathVectors[LANGUAGE];
    }

    const currency = Currency.getCurrency();
    const essentialUser = 'Essential' === UserHelpers.type();
    const premiumUser = 'Premium' === UserHelpers.type();
    const showFreeDownloadButton = !resource.premium && resource.selection !== '0';
    const disableForAnonymous = DISABLE_ANONYMOUS_DOWNLOADS && USER_TYPE === 'anonymous' && showFreeDownloadButton;
    const limitReached =
        FEATURE_MONTHLY_DOWNLOAD_LIMIT_BY_COUNTRY &&
        USER_TYPE === 'free' &&
        showFreeDownloadButton &&
        UserHelpers.isUserLimitReached();
    const isAuthorFlaticon = isFlaticonAuthor(resource.author?.id || '');
    const isAuthorFlaticonAndPackIcon = isAuthorFlaticon && resource.selection === '1';
    const fileFormatTextForFlaticon = isAuthorFlaticonAndPackIcon
        ? __('Icon pack in multiple formats')
        : isAuthorFlaticon
        ? __('File type: %s', '<span class="uppercase">PNG, SVG</span>')
        : false;

    const isEnabledViewSimilar = FEATURE_IMAGE_SEARCH_BY_COUNTRY && fileType.match(/psd|vector/i);

    const global = {
        ...globals,
        ...currency,
        essentialUser,
        FEATURE_AD_MANAGER,
        FEATURE_AD_MANAGER_ATTRIBUTE,
        FEATURE_DISABLE_INFO_RESOURCES,
        FEATURE_MONTHLY_DOWNLOAD_LIMIT_BY_COUNTRY,
        FEATURE_NEW_COPIES_COLLECTIONS_BY_COUNTRY,
        FEATURE_OBFUSCATE_LINKS_DETAIL_VIEW,
        SLIDER_RESOURCES_DEFAULT_LENGTH_MIN,
        SLIDER_RESOURCES_SERIE_LENGTH_MIN,
        ie: detectIE() ? true : false,
        isEnabledViewSimilar,
        mobile: window.innerWidth <= Settings.breakpoints.sm,
        premiumUser,
        tablet: window.innerWidth > Settings.breakpoints.md,
        testBlueEditButton: cookie.getItem('gr_b43xacW3bwTpvG_testAB_edit_button') ? true : false,
        URL_RESOURCE_KEYWORDS_FEATURE,
        user: gr.user,
        disableForAnonymous,
        limitReached,
        fileFormatTextForFlaticon,
        trackerHowToEditFileType: isAuthorFlaticonAndPackIcon
            ? 'click_how_edit_icon-pack'
            : `click_how_edit_${resource.type}`,
    };

    if (fileType && HOW_EDIT_URL[fileType]) {
        global['howToEditUrl'] = HOW_EDIT_URL[fileType];
    }

    return global;
};
