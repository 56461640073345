import { q } from 'bobjoll/ts/library/dom';
import { InsertSettings } from 'bobjoll/ts/partials/alert-v2.0';
import { handlerReportClick } from 'Partials/detail-v2/partials/detail.partials.report';
import { __ } from 'Partials/language';

const alertV2 = () => import('bobjoll/ts/partials/alert-v2.0');
const buttonClass = 'notification-report-link';

const insertAlert: InsertSettings = {
    content: __(
        'The file you are trying to download does not exist, or the link is broken. Please report the problem so it can be fixed.',
    ),
    title: __('Download failed'),
    type: 'error',
    link: {
        url: '#',
        target: '_self',
        text: __('Report problem'),
        customClass: buttonClass,
        clickAndClose: true,
        arrow: true,
    },
};

export const showAlertDownloadError = async () => {
    const alert = await alertV2();
    new alert.default().new(insertAlert);

    const linkReport = q(`.notification .${buttonClass}`);
    linkReport?.addEventListener('click', (e: Event) => {
        e.preventDefault();
        handlerReportClick.bind(linkReport, e);
    });
};
