import { q, ww } from 'bobjoll/ts/library/dom';
import getFilterValue from 'Partials/filters-v2/getFilterValue';
import { isSearchByImageEnabled } from 'Partials/search-by-image/image/searchimage.get-image';

import { Params } from './istock.vm';
import { getQueryFromHashParameters } from 'Project/ts/library/helpers/url';

export const getParamsToAPI = (query?: string): Params => {
    const filters = getFilterValue(Object.keys(ww.FiltersEnabled));
    const searchTerm = query || filters.query;
    const firstKeyword = q('.detail__keywords .search__link')?.innerText;
    const words = [searchTerm, firstKeyword !== searchTerm ? firstKeyword : ''].filter(Boolean).join(' ');

    const params = {
        phrase: (isSearchByImageEnabled() && KEYWORD_SEARCH_BY_IMAGE) || words || getQueryFromHashParameters() || '',
        page: filters.page || 1,
    };

    return params;
};
