import { q } from 'bobjoll/ts/library/gr/dom.q';

export default class Countdown {
    private static readonly getOuter = async () =>
        import('Project/ts/templates/parts/countdown.hbs').then(mod => mod.default);
    private static readonly getInner = () =>
        import('Project/ts/templates/parts/countdown-inner.hbs').then(mod => mod.default);

    private element: HTMLElement;
    private settings: CountdownSettings;
    private interval: any;

    constructor(settings: CountdownSettings) {
        this.settings = settings;

        const initialize = async () => {
            if (this.settings.container.classList.contains('countdown')) {
                this.element = this.settings.container;
            } else {
                const template = await Countdown.getOuter();
                this.settings.container.insertAdjacentHTML('beforeend', template());

                this.element = q('.countdown', this.settings.container) || document.createElement('div');
            }

            this.setup();
        };

        initialize();
    }

    public static dateLeft(date: Date, depth: number) {
        const settings: CountdownSettings = {
            container: document.body,
            dateEnd: date,
        };

        return Countdown.count(settings, depth);
    }

    private static normalize(number: number): string {
        return `${number < 10 ? '0' : ''}${number}`;
    }

    private static calculate(distance: number, divider: number, time: number) {
        return Countdown.normalize(Math.floor((distance % divider) / time));
    }

    private static count(options: CountdownSettings, depth = 3) {
        const _distance = options.dateEnd.getTime() - new Date().getTime();
        if (_distance < 0) return;

        const _second = 1000;
        const _minute = _second * 60;
        const _hour = _minute * 60;
        const _day = _hour * 24;

        const date: {
            Days?: number;
            Hours?: string;
            Mins?: string;
            Secs?: string;
        } = {};

        date.Days = Math[depth < 1 ? 'ceil' : 'floor'](_distance / _day);
        if (depth > 0) date.Hours = Countdown.calculate(_distance, _day, _hour);
        if (depth > 1) date.Mins = Countdown.calculate(_distance, _hour, _minute);
        if (depth > 2) date.Secs = Countdown.calculate(_distance, _minute, _second);

        return date;
    }

    private static countLeft(options: CountdownSettings) {
        if (!options.dateStart || !options.total) return;

        const dateCurrent = new Date();
        const timeTotal = options.dateEnd.getTime() - options.dateStart.getTime();
        const timeLeft = options.dateEnd.getTime() - dateCurrent.getTime();
        const total = Math.round(Math.abs((timeLeft / timeTotal) * options.total))
            .toString()
            .split('');

        if (timeLeft <= 0 || !(dateCurrent >= options.dateStart && dateCurrent <= options.dateEnd)) {
            return '0000'.toString().split('');
        }

        if (total.length < options.total.toString().length) {
            const loop = options.total.toString().length - total.length;
            for (let i = 0; i < loop; i++) {
                total.unshift('0');
            }
        }

        return total;
    }

    private setup() {
        let method: Function = Countdown.count;

        if (this.settings.dateStart && this.settings.total) {
            method = Countdown.countLeft;
        }

        const setElements = async () => {
            const template = await Countdown.getInner();

            this.element.innerHTML = template({
                date: method(this.settings),
            });

            this.interval = setInterval(() => {
                const date = method(this.settings);

                const data: any = {};

                if (date) {
                    data['date'] = date;
                }

                this.element.innerHTML = template(data);

                if (new Date().getTime() >= this.settings.dateEnd.getTime()) {
                    clearInterval(this.interval);
                }
            }, 1000);
        };

        setElements();
    }
}

export interface CountdownSettings {
    container: HTMLElement;
    dateStart?: Date;
    dateEnd: Date;
    total?: number;
}
