import { q } from 'bobjoll/ts/library/dom';
import { getBannerActionEvent } from 'Partials/campaign-php/campaign.helpers';

const pageType = q('body.page-new') ? 'new' : q('body.page-fpchoice') ? 'fpchoice' : 'popular';
const getTypeOfSearch = q('.filter-sidebar--applied__content span i[data-key="type"]')?.dataset.option || 'all';
const searchElement = q('#search-value') as HTMLInputElement | undefined;
const nextSearchElement = q('#next-search') as HTMLInputElement | undefined;
const searchValue = (nextSearchElement && nextSearchElement.value !== '' ? nextSearchElement.value : searchElement?.value.trim()) || '';
const pages = parseInt((q('#pagination-pages') as HTMLInputElement)?.textContent || '1');

export const campaignGA4Events: BannersGA4 = {
    home: {
        location: 'banner_home',
        type: `${getBannerActionEvent}_anonymous`,
    },
    logged: {
        location: 'banner_home',
        type: `${getBannerActionEvent}_registered`,
    },
    category: { // /popular, /new
        location: 'banner_category',
        type: pageType,
    },
    popular: { // /popular-photos, /popular-vectors, /popular-psd
        location: 'banner_popular',
        type: getTypeOfSearch,
    },
    keyword: { // /free-photos-vectors/background, /photos/background
        location: 'banner_tag',
        searchTerm: searchValue,
    },
    collections: {
        location: 'banner_collection',
        type: q('body.category--landing') ? 'all' : q('main')?.dataset.collectionType,
    },
    limit: {
        location: 'landing_download_limit',
        concatUserType: true,
    },
    upgrade: {
        location: 'banner_top_home',
        type: 'go_upgrade_annual'
    },
    search: {
        location: `banner_pagination_${pages > 5 ? '>5' : pages}`,
        searchTerm: searchValue,
    },
    attribution: {
        location: 'banner_attribute',
        type: `attribution_${getBannerActionEvent}`
    },
};

type BannersGA4 = {
    [name: string]: {
        concatUserType?: boolean;
        location?: string;
        searchTerm?: string;
        type?: string;
    };
};
