var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<i class=\"icon--premium hover\"></i>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda;

  return "<figcaption>\n    <div class=\"title-container\">\n        "
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias2).call(alias1,(depth0 != null ? depth0.premium : depth0),"==","1",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":84}}})) != null ? stack1 : "")
    + "\n        <p class=\"title\">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":4,"column":25},"end":{"line":4,"column":34}}}) : helper)))
    + "</p>\n    </div>\n    <div class=\"author-container\">\n        <span class=\"avatar\"><img class=\"avatar-img\"\n            src=\""
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.author : depth0)) != null ? stack1.avatar : stack1), depth0))
    + "\"\n            alt=\""
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.author : depth0)) != null ? stack1.name : stack1), depth0))
    + "\"\n            loading=\"lazy\"\n            onerror=\"this.src='"
    + alias3((helpers.randomAvatar||(depth0 && depth0.randomAvatar)||alias2).call(alias1,((stack1 = (depth0 != null ? depth0.author : depth0)) != null ? stack1.name : stack1),{"name":"randomAvatar","hash":{},"data":data,"loc":{"start":{"line":11,"column":31},"end":{"line":11,"column":59}}}))
    + "'\">\n        </span>\n        <span class=\"name\" data-author-slug=\""
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.author : depth0)) != null ? stack1.slug : stack1), depth0))
    + "\">"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.author : depth0)) != null ? stack1.name : stack1), depth0))
    + "</span>\n    </div>\n</figcaption>\n";
},"useData":true});