import { InsertSettings, Position } from 'BobjollNotifications';
import { cookie } from 'Project/ts/library/cookie';
import { getDomainName, getMultiDomainName } from 'Project/ts/library/helpers';

export const interestedCookieName = 'interested_adb';

const Notification = () => import('BobjollNotifications');

const notification = async function () {
    const notificationObject = await Notification();
    const { default: template } = await import('bobjoll/ts/views/hbs/alert-v1.0/element.html.hbs');
    return new notificationObject.default({
        fixed: true,
        recurrent: false,
        template,
    });
};

export const alert = async (
    id: string,
    html: string,
    type: 'warning' | 'error' | 'success',
    position: keyof Position,
    timeout?: number
) => {
    const settings: InsertSettings = {
        id: id,
        class: `notification--${type}`,
        html: html,
        position: position,
    };

    if (timeout) {
        settings.timeout = timeout;
        settings.fixed = false;
    }

    (await notification()).insert(settings);
};

export const isLibraryLoaded = () => 'undefined' !== typeof gr && null !== gr;

export const isLogged = async (showAuth = true, fn?: Function, url?: string, origin = '') => {
    let status = isLibraryLoaded() && gr.isLogged();
    if (!status && showAuth) {
        setGrLgUri(url);
        const originParam = (FEATURE_OAUTH_ORIGIN_PARAM && origin) ? `&origin=${origin}` : '';
        window.location.href = `${LOGIN_URL}${originParam}`;
    }
    if (fn) fn(status);
    return status;
};

export const isFreepik = () => isLibraryLoaded() && ((gr.user && gr.user.email) || '').match(/\@freepik.com/);

export const isPremium = () => (isLibraryLoaded() && gr.isPremium()) || false;

export const isEssential = () => '1' === ESSENTIAL_USER_TYPE;

export const isPremiumFreepik = () =>
    true === gr.getPathValue('user.premium_freepik') || '1' == gr.getPathValue('user.premium_freepik');

export const isPremiumFlaticon = () =>
    true === gr.getPathValue('user.premium_flaticon') || '1' == gr.getPathValue('user.premium_flaticon');

export const isPremiumFreepikAnnual = () => 'year' == gr.getPathValue('user.pr_freq_fp');

export const isPremiumFreepikMonthly = () => 'month' == gr.getPathValue('user.pr_freq_fp');

export const isPremiumFlaticonAnnual = () => 'year' == gr.getPathValue('user.pr_freq_fi');

export const isPremiumFlaticonMonthly = () => 'month' == gr.getPathValue('user.pr_freq_fi');

export const isRenewalCanceledFreepik = () => '1' == gr.getPathValue('user.renewal_canceled_freepik');

export const isRenewalCanceledFlaticon = () => '1' == gr.getPathValue('user.renewal_canceled_flaticon');

export const isRenewalCanceledPlus = () => '1' == gr.getPathValue('user.renewal_canceled_plus');

export const isInterestedInSponsor = () => (Object.keys(getUserSponsorInterested()).length ? true : false);

export const isFlaticonAuthor = (id: string) => id == FLATICON_AUTHOR_ID;

export const isFreepikAuthor = (id: string) => id == FREEPIK_AUTHOR_ID;

export const id = () => (isLibraryLoaded() ? (gr.isLogged() && gr.user ? gr.user.id : 0) : 0);

export const getClientId = (): string => cookie.getItem('_ga') || 'nn';

export const type = () =>
    isLibraryLoaded()
        ? gr.isLogged()
            ? gr.isPremium()
                ? 'Premium'
                : gr.isEssential()
                    ? 'Essential'
                    : 'Free'
            : 'Anonymous'
        : 'Anonymous';

export const getData = () => (isLogged(false) ? gr.user : null);

export const getUserSponsorInterested = () => {
    const cookieInterested = cookie.getItem(interestedCookieName);
    return cookieInterested ? JSON.parse(cookieInterested) : {};
};

export const setCbUrl = () => {
    cookie.setItem('CB_URL', window.location.href, {
        path: '/',
        domain: getDomainName(document.domain) || document.domain,
        expires: new Date(new Date().getTime() + 10 * 60 * 1000),
    });
};

export const setCbPurchaseUrl = () => {
    cookie.setItem('CB_PURCHASE_URL', window.location.href + '#modal=modal-premium-complete', {
        path: '/',
        domain: getDomainName(document.domain) || document.domain,
        expires: new Date(new Date().getTime() + 10 * 60 * 1000),
    });
};

const clearUrl = (url: string) => {
    let newUrl = url;
    const hashIndex = url.indexOf('#');
    if (hashIndex !== -1) {
      const hashPart = url.substring(hashIndex + 1);
  
      const dotIndex = hashPart.indexOf('.');
      const slashIndex = hashPart.indexOf('/');
  
      if (dotIndex !== -1 || slashIndex !== -1) {
        newUrl = url.substring(0, hashIndex);
      }
    }
    return newUrl;
  };

export const setGrLgUri = (url: string = window.location.href) => {
    cookie.setItem('GR_LGURI', clearUrl(url), {
        domain: getMultiDomainName()
    });
}

export const issetUserInfoDownloads = () => gr && gr.user
    && typeof gr.user.downloads !== 'undefined'
    && typeof gr.user.limit_downloads !== 'undefined';

export const isUserLimitReached = () => {
    if (issetUserInfoDownloads()) {
        return gr.user.downloads == gr.user.limit_downloads;
    }

    return false;
}
