export const imageResize = function(options: ImageResizeOptions) {
    const { width, height } = options;
    const { newWidth = width, newHeight = height } = options;
    const ratio = width > newWidth ? newWidth / width : height > newHeight ? newHeight / height : 1;
    return {
        width: width * ratio,
        height: height * ratio,
    };
};

interface ImageResizeOptions {
    width: number;
    height: number;
    newWidth?: number;
    newHeight?: number;
}
