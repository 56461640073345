import { q } from 'bobjoll/ts/library/dom';

const menuCollapseBreakpoint = 1160;
const elementPositionExceptions: ElementExceptionToSetPosition = {
    header: {
        conditionBreakpoint: menuCollapseBreakpoint,
        newValue: 'calc(50px + {BANNER_HEIGHT})',
        newValueByCondition: '{BANNER_HEIGHT}',
        property: 'top',
        element: q('#header'),
    },
    navigation: {
        newValue: '{BANNER_HEIGHT}',
        property: 'top',
        element: q('#navigation'),
    },
    main: {
        newValue: '{BANNER_HEIGHT}',
        property: 'padding-top',
        element: q('#main'),
    },
    calendar: {
        newValue: '{BANNER_HEIGHT}',
        property: 'top',
        element: q('.subhome-calendar .section-month-selector'),
    },
};

export const setPositionBannerTop = (notification: HTMLElement) => {
    const elementHeight = `${notification.clientHeight}px`;
    getAllElementsToSetPosition(elementHeight);
};

const getAllElementsToSetPosition = (elementHeight: string) => {
    Object.keys(elementPositionExceptions).forEach((elementName: string) => {
        const elementProperties: ElementProperties = elementPositionExceptions[elementName];
        setNewValueToAddaptElementsToNotification(elementProperties, elementHeight);
    });
};

const setNewValueToAddaptElementsToNotification = (elementProperties: ElementProperties, elementHeight: string) => {
    const { element, conditionBreakpoint, newValue, newValueByCondition, property } = elementProperties;

    if (element) {
        const newValueProperty =
            conditionBreakpoint && newValueByCondition && window.innerWidth >= conditionBreakpoint
                ? newValueByCondition
                : newValue;
        element.style[property] = newValueProperty.replace('{BANNER_HEIGHT}', elementHeight);
    }
};

export const setPositionBannerTopByResize = (id: string) => {
    const notification = q(`#${id}`) as HTMLElement;
    if (!notification) return;

    setPositionBannerTop(notification);
    window.addEventListener('resize', () => setPositionBannerTop(notification));

    const elementHeightIfNotExists = '0px';
    q('.notification__close', notification)?.addEventListener('click', () =>
        getAllElementsToSetPosition(elementHeightIfNotExists),
    );
};

interface ElementProperties {
    conditionBreakpoint?: number;
    element: HTMLElement | null;
    newValue: string;
    newValueByCondition?: string;
    property: string;
}

interface ElementExceptionToSetPosition {
    [name: string]: ElementProperties;
}
