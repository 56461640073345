var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "?bv="
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.banner : depth0)) != null ? stack1.bestValue : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.banner : depth0)) != null ? stack1.coupon : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":85},"end":{"line":1,"column":141}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "?coupon="
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.banner : depth0)) != null ? stack1.coupon : stack1), depth0));
},"6":function(container,depth0,helpers,partials,data) {
    return " banner-small";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.banner : depth0)) != null ? stack1.microfunnel : stack1), depth0));
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.banner : depth0)) != null ? stack1["class"] : stack1), depth0));
},"12":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <img class=\"image-logo\" src=\""
    + container.escapeExpression(((helper = (helper = helpers.URL_STATIC_IMG || (depth0 != null ? depth0.URL_STATIC_IMG : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"URL_STATIC_IMG","hash":{},"data":data,"loc":{"start":{"line":6,"column":41},"end":{"line":6,"column":61}}}) : helper)))
    + "logos/freepik-inverted.svg\" alt=\"Freepik Logo\" />\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <p class=\"title\">"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.banner : depth0)) != null ? stack1.title : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.banner : depth0)) != null ? stack1.text : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing;

  return "                    "
    + ((stack1 = (helpers.language||(depth0 && depth0.language)||alias2).call(alias1,"%sUnlimited downloads%s for your limitless ideas","<strong>","</strong></br>",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":15,"column":115}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = (helpers.language||(depth0 && depth0.language)||alias2).call(alias1,"%sNow with Flaticon exclusive content.%s","<p class=\"text-flaticon mg-top-lv2\">","</p>",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":16,"column":125}}})) != null ? stack1 : "")
    + "\n                ";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.banner : depth0)) != null ? stack1.buttonSize : stack1), depth0));
},"22":function(container,depth0,helpers,partials,data) {
    return "sm";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.banner : depth0)) != null ? stack1.button : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = (helpers.language||(depth0 && depth0.language)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Go Premium",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":23,"column":45}}})) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<a href=\""
    + alias4(((helper = (helper = helpers.PRICING_URL || (depth0 != null ? depth0.PRICING_URL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PRICING_URL","hash":{},"data":data,"loc":{"start":{"line":1,"column":9},"end":{"line":1,"column":26}}}) : helper)))
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.banner : depth0)) != null ? stack1.bestValue : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":26},"end":{"line":1,"column":148}}})) != null ? stack1 : "")
    + "\"\n    class=\"banner-microfunnel banner-"
    + alias4(container.lambda(((stack1 = (depth0 != null ? depth0.banner : depth0)) != null ? stack1["class"] : stack1), depth0))
    + ((stack1 = (helpers.isTrue||(depth0 && depth0.isTrue)||alias2).call(alias1,((stack1 = (depth0 != null ? depth0.banner : depth0)) != null ? stack1.small : stack1),{"name":"isTrue","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":55},"end":{"line":2,"column":104}}})) != null ? stack1 : "")
    + "\"\n    data-microfunnel=\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.banner : depth0)) != null ? stack1.microfunnel : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":3,"column":22},"end":{"line":3,"column":106}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"content\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.banner : depth0)) != null ? stack1.logo : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.banner : depth0)) != null ? stack1.title : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":10,"column":15}}})) != null ? stack1 : "")
    + "        <p class=\"text\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.banner : depth0)) != null ? stack1.text : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":17,"column":23}}})) != null ? stack1 : "")
    + "</p>\n        <button\n            class=\"button button--"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.banner : depth0)) != null ? stack1.buttonSize : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":19,"column":34},"end":{"line":19,"column":100}}})) != null ? stack1 : "")
    + " button-microfunnel\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.banner : depth0)) != null ? stack1.button : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + "        </button>\n    </div>\n    <img class=\"image-xs\" src=\""
    + alias4(((helper = (helper = helpers.URL_STATIC_IMG || (depth0 != null ? depth0.URL_STATIC_IMG : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"URL_STATIC_IMG","hash":{},"data":data,"loc":{"start":{"line":27,"column":31},"end":{"line":27,"column":51}}}) : helper)))
    + "banner/microfunnel-xs.jpg\" alt=\"Freepik Microfunnel Detail\" />\n    <picture class=\"image\">\n        <source\n            srcset=\""
    + alias4(((helper = (helper = helpers.URL_STATIC_IMG || (depth0 != null ? depth0.URL_STATIC_IMG : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"URL_STATIC_IMG","hash":{},"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":30,"column":40}}}) : helper)))
    + "banner/microfunnel-flaticon.webp\"\n            type=\"image/webp\">\n        <source\n            srcset=\""
    + alias4(((helper = (helper = helpers.URL_STATIC_IMG || (depth0 != null ? depth0.URL_STATIC_IMG : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"URL_STATIC_IMG","hash":{},"data":data,"loc":{"start":{"line":33,"column":20},"end":{"line":33,"column":40}}}) : helper)))
    + "banner/microfunnel-flaticon.png\"\n            type=\"image/png\">\n        <img src=\""
    + alias4(((helper = (helper = helpers.URL_STATIC_IMG || (depth0 != null ? depth0.URL_STATIC_IMG : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"URL_STATIC_IMG","hash":{},"data":data,"loc":{"start":{"line":35,"column":18},"end":{"line":35,"column":38}}}) : helper)))
    + "banner/microfunnel-flaticon.png\"\n            alt=\"Freepik Microfunnel\" />\n    </picture>\n</a>\n";
},"useData":true});