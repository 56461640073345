import { q } from 'bobjoll/ts/library/dom';
import { waitToBeLoaded } from 'Library/helpers';
import { isAdBlockEnabled } from 'Library/helpers/adblock';
import { globals } from 'Library/helpers/globals';
import { categoryLandingAutopromoEventsGa4 } from 'Partials/events/ga4.category-landing';
import { homeFpcBannerEventsGA4, homeMiddleBannerEventsGA4 } from 'Partials/events/ga4.home';
import { __ } from 'Partials/language';
import { cookie } from 'Project/ts/library/cookie';

export const initContentBanner = async (slotList: ContentBannerSlots[]) => {
    await waitToBeLoaded('language');

    const isFreeUser = USER_TYPE === 'free';
    const textFlaticon = __('%sNow with Flaticon exclusive content.%s', '<p class="text">', '</p>');
    const textBanner = __('Join our Premium family, outsmart deadlines, and live a hassle-free life');
    const autopromo = LANGUAGE === 'en' ? 'calendar' : 'wepik';

    const freeText = __('%sUnlimited downloads%s for your limitless ideas', '<strong>', '</strong>');
    const freeTextButton = __('Go Premium');

    const contentBanners: ContentBanners = {
        HOME_HEADER: {
            id: 'middlebanner_home',
            listener: 'content:middlebanner_home',
            template: 'content-home',
            banner: {
                userType: USER_TYPE,
                class: isFreeUser ? ' button--yellow' : '',
                text: isFreeUser ? freeText : __('Create an account to enjoy more free downloads'),
                button: isFreeUser ? freeTextButton : __('Sign up for free'),
                urlButton: isFreeUser ? `${PRICING_URL}?origin=freepik_web` : REGISTER_URL,
            },
            callback: homeMiddleBannerEventsGA4,
        },
        HOME_BODY: {
            id: 'content_banner_home_fpc',
            listener: 'content:content_banner_home_fpc',
            template: autopromo,
            callback: () => homeFpcBannerEventsGA4(`.section--${autopromo} a`, autopromo),
        },
        LANDING_CATEGORY_BOTTOM: {
            id: 'landing_category_bottom',
            listener: 'content:landing_category_bottom',
            template: 'landing_category_bottom',
            banner: JSON.parse(CATEGORY_LANDING_PROMO || '{}'),
            callback: categoryLandingAutopromoEventsGa4,
        },
        BANNER_ATTRIBUTE: {
            id: 'banner_attribute',
            listener: 'content:banner_attribute',
            banner: {
                class: 'attribution',
                small: true,
                title: __('Forget about attribution'),
                text: `${textBanner} ${textFlaticon}`,
                button: __('Go Premium'),
            },
        },
    };

    slotList.forEach(slot => {
        const banner = contentBanners[slot];
        const { listener } = banner;

        window.addEventListener(listener, () => {
            renderContentBanner(banner);
        });

        if (!cookie.getItem('OptanonConsent') || isAdBlockEnabled() || IS_MOBILE || FEATURE_DISABLE_ADS) {
            renderContentBanner(banner);
        }
    });
};

const renderContentBanner = async (content: ContentBanner) => {
    const { id, template, banner } = content;
    const contentBanner = q(`#${id}`);

    if (contentBanner) {
        const { default: render } = template
            ? await import(`Partials/adManager/banners/${template}.hbs`)
            : await import(`Partials/microfunnels/banner.hbs`);
        contentBanner.innerHTML = render({ ...globals, banner });

        if (content.callback) content.callback();
    }
};

interface ContentBanner {
    banner?: DataBanner;
    id: string;
    listener: string;
    template?: string;
    callback?: Function;
}

interface DataBanner {
    button?: string;
    class?: string;
    dataKey?: string;
    imageName?: string;
    small?: boolean;
    text?: string;
    title?: string;
    urlButton?: string;
    image?: string;
    description?: string;
    userType?: 'anonymous' | 'free' | 'premium';
}

type ContentBannerSlots = 'HOME_HEADER' | 'HOME_BODY' | 'LANDING_CATEGORY_BOTTOM' | 'BANNER_ATTRIBUTE';

type ContentBanners = {
    [key in ContentBannerSlots]: ContentBanner;
};
