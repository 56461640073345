import { q } from 'bobjoll/ts/library/dom';
import { Settings } from 'bobjoll/ts/library/settings';
import { Resource } from 'Partials/showcase';

export const enableDownloadSelector = (resource: Resource): DownloadSelector =>
    FEATURE_DOWNLOAD_FILE_TYPES && resource?.type?.match(/vector|psd/i) ? 'types' : false;

export const initializeDownloadSelector = (selector: DownloadSelector, callback: Function) => {
    const popoverDownload = q('.popover__download');

    popoverDownload &&
        popoverDownload.addEventListener('click', () => popoverDownload.dataset.selector === selector && callback());
};

export const changePopoverPosition = () => {
    if (window.innerWidth >= Settings.breakpoints.sm) {
        q('.download-resource-container .popover')?.classList.replace('popover--top-right', 'popover--bottom-right');
    }
};

type DownloadSelector = 'types' | false;
