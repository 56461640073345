import 'Variables';

import { sessionStorage as storage } from 'bobjoll/ts/library/storage';
import { checkDebugEnviroment } from 'Library/helpers';
import { sprintf } from 'sprintf-js';

import { unescapeQuotes } from '../library/helpers/text';

declare module 'bobjoll/ts/library/storage' {
    interface ClientStorage {
        get(namespace: 'language', key: string): string[];
        set(namespace: 'language', key: string, value: string[]): void;
    }
}

const STORAGE_LANGUAGE_NS = 'language';

export const __ = function(str: string, ...args: (string | number)[]): string {
    try {
        const { string, status } = getLanguageString(str);
        setLanguageDebug(string, status);
        if (args.length) {
            const settings = args.reduce((acc, item) => (acc.push(item), acc), [string] as Parameters<typeof sprintf>);
            return sprintf(...settings);
        }
        return string;
    } catch (error) {
        console.error('Error in Language:', str, args, error);
        return str;
    }
};

const getLanguageString = (str: string) => {
    if ('undefined' === typeof language) return { string: str, status: false };
    return {
        string: unescapeQuotes(language[str] || str),
        status: language[str] ? true : false,
    };
};

const setLanguageDebug = (string: string, status: boolean) => {
    if (checkDebugEnviroment() && !status) {
        const languageStrings = storage.get(STORAGE_LANGUAGE_NS, 'languageStrings') || [];
        if (languageStrings.indexOf(string) < 0) {
            languageStrings.push(string);
        }
        storage.set(STORAGE_LANGUAGE_NS, 'languageStrings', languageStrings);
    }
};
