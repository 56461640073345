import { delegate, q } from 'bobjoll/ts/library/dom';
import { ga4 } from './ga4.api';
import { replaceCampaignParams } from './ga4.get-campaign';
import * as GA4Model from './ga4.model';
import Tracker from 'Library/tracker';

const replaceGA4ParamsByDefault = (event: GA4Model.EventConfig, action: GA4Model.InteractionType) => {
    if (event.ga4Options) {
        event.ga4Options = {
            ...event.ga4Options,
            event_name: event.ga4Options.event_name || GA4Model.EVENT_NAME_BY_DEFAULT,
            interaction_type: action,
        }
    }

    return event;
};

export const mapEvents = (eventList: GA4Model.GA4ManualEvents | GA4Model.GA4AutomaticEvents) => {
    Object.keys(eventList).forEach((description: string) => {
        const event = eventList[description];

        if (!event.element) return;

        if (event.applyOnloadEvent) {
            if (q(event.element)) {
                addEvents(event, GA4Model.ACTIONS.onload);
            }
        }

        if (event.applyClickEvent) {
            delegate(event.element, GA4Model.ACTIONS.click, () => addEvents(event, GA4Model.ACTIONS.click));
        }
    });
};

export const addEvents = (event: GA4Model.EventConfig, actionDefault: GA4Model.InteractionType = GA4Model.ACTIONS.onload) => {
    if (!event.ignoreDefaultParams) event = replaceGA4ParamsByDefault(event, actionDefault);
    event = replaceCampaignParams(event);

    const { microfunnel, ga4Options } = event;

    ga4(ga4Options);

    if (microfunnel) {
        const { category, action, label, noninteraction } = microfunnel;
        const microfunnelCategory = category || GA4Model.CATEGORY_BY_DEFAULT;
        const microfunnelAction = actionDefault ?? action;
        const microfunnelNonInteraction = noninteraction || (microfunnelAction === GA4Model.ACTIONS.onload ? true : false);
        const microfunnelLabel = `${label}${microfunnelAction === GA4Model.ACTIONS.click ? '-gopremium' : ''}`;

        Tracker.trackMicroFunnelEvent({
            category: microfunnelCategory,
            action: microfunnelAction,
            label: microfunnelLabel,
            noninteraction: microfunnelNonInteraction,
        });
    }
};
