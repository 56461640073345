import { delegate, q, qq } from 'bobjoll/ts/library/dom';
import { getQueryStringFromURL } from 'Library/helpers/url';
import Tracker from 'Library/tracker';
import { collectionClickGA4Events } from 'Partials/events/ga4.collections';
import { Search } from 'Partials/search/controller';

const trackPrintCollections = function () {
    const collectionIds = (qq('.collection__item') as HTMLElement[]).map(collectionItem => collectionItem.dataset.id);

    if (collectionIds && collectionIds.length > 0) {
        const args = ['collections', 'print', collectionIds.join(',')];
        Tracker.trackGAEventNoCache(args);
    }
}

const trackSearchCollectionClick = function () {
    delegate('article.collection__item a.collection__link, .section--collections .flex-section--carousel .caption-list--item',
        'click', function (this: HTMLLinkElement) {
            const parentArticle = this.parent('.collection__item') as HTMLElement;
            const order = parentArticle.dataset.order;

            if (order) {
                const id = parentArticle.dataset.id;
                const args = ['collections', 'search-click-related', Search.getValue(), id, +order + 1];
                Tracker.trackGAEventNoCache(args);
                collectionClickGA4Events(parentArticle);
            }
        });
}

const initializeTrackCollectionResourceDownload = function () {
    const searchValue = Search.getValue();

    searchValue && (qq('.collection__item .collection__link') as HTMLLinkElement[]).forEach(link => {
        link.href = `${link.href}?query=${searchValue}`;
    });

    const query = getQueryStringFromURL('query');
    const collectionId = q('div.collection')?.dataset.id;

    if (query && query !== '' && collectionId && collectionId !== '') {

        (qq('.showcase__link') as HTMLLinkElement[])
            .filter(link => link.href.indexOf('?query') === -1)
            .forEach(link => {
                link.href = `${link.href}?query=${query}&collectionId=${collectionId}`;
            });
    }
}

const initializeCollectionClickElements = function () {

    delegate('article.collection__item .collection__title, article.collection__item .count', 'click', function (this: HTMLElement) {
        const collection = this.closest('article.collection__item') as HTMLElement;
        const collectionAnchor = q('.collection__link', collection) as HTMLLinkElement;

        collectionAnchor && collectionAnchor.click();
    });
}

export const trackCollectionResourceDownload = function () {
    const query = getQueryStringFromURL('query');
    const collectionId = getQueryStringFromURL('collectionId');
    const resourceId = q('.detail')?.dataset.id;

    if (query && collectionId && resourceId) {
        const args = ['collections', 'search-click', query, collectionId, resourceId];
        Tracker.trackGAEventNoCache(args);
    }
}

export const initializeCollectionEvents = function () {
    trackPrintCollections();
    trackSearchCollectionClick();
    initializeTrackCollectionResourceDownload();
    initializeCollectionClickElements();
}
