/* eslint-disable @typescript-eslint/no-explicit-any */
import { sessionStorage as storage } from 'bobjoll/ts/library/storage';

const CACHE_NAMESPACE_JSON = 'cache-json';
const CACHE_NAMESPACE_EXPIRY = 'cache-expiry';

declare module 'bobjoll/ts/library/storage' {
    interface ClientStorage {
        get(namespace: 'cache-json', key: string): boolean;
        get(namespace: 'cache-expiry', key: string): boolean;
        set(namespace: 'cache-json', key: string, value: boolean, time: number): void;
        set(namespace: 'cache-expiry', key: string, value: boolean, time: number): void;
    }
}

export default class Cache {
    public static set(options: CacheOptions) {
        const { key } = options;
        let { value, time } = options;

        let expiry: Date | number = new Date();

        if (time < 1200) {
            time = 1200;
        }

        expiry.setSeconds(expiry.getSeconds() + time);

        expiry = expiry.getTime();

        try {
            Object.keys(value).forEach(key => {
                if (key.match(/^__/gi)) {
                    delete value[key];
                }
            });
            value = JSON.stringify(value);
        } catch (e) {
            console.log('Cache Error, JSON.stringify: ', e);
            return;
        }

        try {
            storage.set(CACHE_NAMESPACE_JSON, key, value);
            storage.set(CACHE_NAMESPACE_EXPIRY, key, expiry);
        } catch (e) {
            if (Cache.QuotaExceeded(e)) {
                const stored: { key: string; size: number; expiration: number }[] = [];
                this.Each(function(key: string) {
                    const cacheJson: any = storage.get(CACHE_NAMESPACE_JSON, key);
                    const cacheExpiry: any = storage.get(CACHE_NAMESPACE_EXPIRY, key);

                    if (cacheJson && cacheExpiry) {
                        stored.push({
                            key,
                            size: cacheJson.length,
                            expiration: cacheExpiry,
                        });
                    }
                });

                stored
                    .sort((a, b) => b.expiration - a.expiration)
                    .slice(0, 19)
                    .forEach(cache => Cache.Remove(cache.key));

                try {
                    storage.set(CACHE_NAMESPACE_JSON, key, value);
                    storage.set(CACHE_NAMESPACE_EXPIRY, key, expiry);
                } catch (e) {
                    stored.forEach(cache => Cache.Remove(cache.key));
                    storage.set(CACHE_NAMESPACE_JSON, key, value);
                    storage.set(CACHE_NAMESPACE_EXPIRY, key, expiry);
                }
            }
        }
    }

    public static get(key: string) {
        Cache.FlushExpiredItem(key);

        const value: any = storage.get(CACHE_NAMESPACE_JSON, key);

        try {
            return JSON.parse(value);
        } catch (e) {
            return value;
        }
    }

    public static FlushExpiredItem(key: string) {
        const current = new Date().getTime();
        const expiration = parseInt(storage.get(CACHE_NAMESPACE_EXPIRY, key) as any);

        if (current >= expiration) {
            Cache.Remove(key);
        }
    }

    public static Remove(key: string) {
        storage.remove(CACHE_NAMESPACE_JSON, key);
        storage.remove(CACHE_NAMESPACE_EXPIRY, key);
    }

    public static Each(callback: Function) {
        const keys = storage.keys(CACHE_NAMESPACE_EXPIRY);

        keys.forEach((key: string) => {
            callback(key.replace(`${CACHE_NAMESPACE_EXPIRY}/`, ''));
        });
    }

    private static QuotaExceeded(e: Error) {
        return e.name.match(/QUOTA/i);
    }
}

export interface CacheOptions {
    key: string;
    value: any;
    time: number;
}
