import { q } from 'bobjoll/ts/library/dom';
import { downloadResource, downloadResourceWithLink } from 'Partials/download/download.recaptcha';
import { downloadImageUrlAPI } from 'Partials/download/download.validations';
import { DownloadResourceErrorCodes } from 'Partials/download/download.vm';
import { showCopyAlert } from 'Partials/notifications/notifications.copy';
import { Resource } from 'Partials/showcase';
import { clearDownloadButton } from 'Project/ts/partials/download/download.styles';
import { xhr } from 'Service/xhr';

import { __ } from '../../language';

export const enableDownloadSizes = (resource: Resource) =>
    resource.type.match(/photo/i) &&
    resource.selection.toString().match(/1|2/) &&
    !(resource.selection.toString().match(/2/) && !resource.premium);

export const getImageLargeSize = async (resourceId: string, token?: string): Promise<ImageLargeSizeResponse> => {
    const urlAPI = `${downloadImageUrlAPI}/${resourceId}${token ? `?token=${token}` : ''}`;

    const { success, url, filename, message, code, todayDownloads } = await xhr(urlAPI, {
        withCredentials: true,
        headers: {
            'X-CSRF-TOKEN': CSRF_TOKEN,
            'X-Requested-With': 'XMLHttpRequest',
        },
        validateStatus: (status) => status >= 200 && status < 500,
    });

    if (todayDownloads && gr && gr.isOAuthLogged()) {
        gr.updateTodayDownloads(todayDownloads);
    }

    if (success) {
        return Promise.resolve({ url, filename });
    } else {
        return Promise.reject({ message, code, url });
    }
};

export const downloadJPGSpecificSize = (
    resourceId: string,
    width: number,
    height: number,
    detailElement: HTMLElement | Document,
    token?: string
) => {
    const originalImage = q('.detail__gallery img') as HTMLImageElement;
    const canvas = q('.img-size-canvas', detailElement) as HTMLCanvasElement;

    if (originalImage && width && height && canvas) {
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
            getImageLargeSize(resourceId, token)
                .then(imageInfo => {
                    const canvasImage = new Image();
                    canvasImage.src = imageInfo.url;
                    canvasImage.crossOrigin = 'Anonymous';

                    canvasImage.onload = async function () {
                        ctx.drawImage(canvasImage, 0, 0, +width, +height);
                        canvas.toBlob(
                            blob => {
                                const canvasImageUrl = window.URL.createObjectURL(blob);
                                downloadResource(canvasImageUrl, imageInfo.filename);
                                window.URL.revokeObjectURL(canvasImageUrl);
                            },
                            'image/jpeg',
                            1
                        );
                    };
                })
                .catch(({ message, code, url }) => {
                    if (url) {
                        window.location.href = url;
                    } else if (DownloadResourceErrorCodes.DOWNLOAD_LIMIT_EXCEEDED === code) {
                        downloadResourceWithLink(token);
                    } else {
                        showCopyAlert(__(message), __('Download resource'), true);
                    }
                })
                .finally(() => clearDownloadButton(true));
        }
    }
};

export const getDownloadSettings = (detail: HTMLElement | null): DownloadSettings => {
    const resourceId = detail?.dataset.id;
    let copyToClipboardActionTriggered = false;
    let clickEditButtonTriggered = false;
    let selectedSize = 'o';
    let selectedSizeElement = null;
    let downloadType = DownloadType.ORIGINAL_DOWNLOAD;
    let width = 0;
    let height = 0;
    let selectedFileType = '';

    if (detail) {
        clickEditButtonTriggered = q('.button-freepik-edit.active', detail) ? true : false;
        selectedSizeElement = q('.size-selector .size.active span', detail) as HTMLSpanElement;
        copyToClipboardActionTriggered = q('.copy_size__trigger', detail)?.dataset.copyAction === "true";
        selectedFileType = q('.download-selector .item.active', detail)?.dataset.id || '';

        if (selectedSizeElement && !copyToClipboardActionTriggered) {
            selectedSizeElement.closest('.size.active')?.classList.remove('active');
            selectedSize = selectedSizeElement.dataset.size ?? selectedSize;

            if (selectedSizeElement.dataset.width && selectedSizeElement.dataset.height) {
                width = +selectedSizeElement.dataset.width;
                height = +selectedSizeElement.dataset.height;
            }
        }

        if (selectedFileType !== '') {
            q('.download-selector .item.active', detail)?.classList.remove('active');
        }
    }

    if (copyToClipboardActionTriggered && resourceId) {
        downloadType = DownloadType.COPY_IMAGE;
    } else if (selectedSizeElement && resourceId && selectedSize !== 'o') {
        downloadType = DownloadType.SIZE_DOWNLOAD;
    } else if (clickEditButtonTriggered) {
        downloadType = DownloadType.EDIT_BUTTON;
    } else if (selectedFileType !== '') {
        downloadType = DownloadType.FILE_TYPES_DOWNLOAD;
    }

    return {
        downloadType,
        resourceId,
        width,
        height,
        selectedFileType,
    };
}

interface ImageLargeSizeResponse {
    filename: string;
    url: string;
}

interface DownloadSettings {
    downloadType: DownloadType;
    height?: number;
    resourceId?: string;
    selectedFileType?: string;
    size?: string;
    width?: number;
}

export enum DownloadType {
    COPY_IMAGE,
    EDIT_BUTTON,
    ORIGINAL_DOWNLOAD,
    SIZE_DOWNLOAD,
    FILE_TYPES_DOWNLOAD,
}
