import { Arguments } from '../arguments';
import { data } from './trackerSend.mappers';

export const trackerSend = (url: string, options?: TrackerOptions) => {
    let tracker: HTMLImageElement | null = new Image();
    const trackerData = { ...data(), ...options };
    const status = new Promise(function(resolve, reject) {
        if (tracker) {
            tracker.onload = () => resolve(true);
            tracker.onerror = () => reject(false);
        }
    }) as Promise<boolean>;
    if (url.indexOf('?') >= 0) {
        url += '&' + Arguments.objectToString(trackerData);
    } else {
        url += '?' + Arguments.objectToString(trackerData);
    }
    tracker.src = TRACKER_URL + url;
    status
        .then(success => {
            if ('undefined' !== typeof debug && debug.printEvent && tracker) {
                debug.printEvent({
                    driver: 'fr',
                    settings: [tracker.src],
                    status: success ? 'success' : 'error',
                    url: window.location.href,
                });
            }
            tracker = null;
        })
        .catch(() => {});
};

export interface TrackerOptions {
    [name: string]: string | number | undefined;
}
