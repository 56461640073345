export const snakeToCamel = (string: string) => {
    return string.replace(/(_\w)/g, function(m) {
        return m[1].toUpperCase();
    });
};
export const camelToSnake = (string: string) => {
    return string
        .replace(/[\w]([A-Z])/g, function(m) {
            return m[0] + '_' + m[1];
        })
        .toLowerCase();
};
