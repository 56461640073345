import { qq } from 'bobjoll/ts/library/dom';
import { ga4EventsMonthlyDownloadPage, ga4EventsMonthlyLimitReached } from 'Partials/events/ga4.monthlyLimit';
import { issetUserInfoDownloads, isUserLimitReached } from 'Partials/user/user.helpers';

export const setDetailCountDownloads = () => {
    const detailDownloadCounter = qq('.detail-downloaded-counters');
    const isLimit = isUserLimitReached();

    ga4EventsMonthlyDownloadPage(isLimit);

    if (detailDownloadCounter.length > 0 && issetUserInfoDownloads()) {
        const consumed = gr.user.downloads;
        const limit = gr.user.limit_downloads;

        detailDownloadCounter.forEach(element => {
            element.innerHTML = consumed > 0 ? `${consumed}/${limit}` : `${limit}`;
            checkLimitAndClearButton(element.closest('.button') as HTMLElement, isLimit);
        });
    }
};

const checkLimitAndClearButton = async (button: HTMLElement, isLimit: boolean) => {
    if (isLimit) {
        const container = button.parentElement;

        if (container) {
            const template = await import(`Project/ts/templates/detail/downloads/limit-reached.hbs`).then(
                mod => mod.default,
            );
            container.innerHTML = template({ BASE_URL });
            ga4EventsMonthlyLimitReached();
            return;
        }
    }

    button.classList.remove('button--loading', 'disabled');
};
