var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing;

  return "    <div class=\"banner-download-box-container\">\n        <div id=\"banner_downloadbox\" class=\"banner-download-box\"></div>\n        <div class=\"banner-download-box-success\">\n            <p class=\"bold\"><i class=\"icon icon--check\"></i> "
    + container.escapeExpression((helpers.language||(depth0 && depth0.language)||alias2).call(alias1,"Download completed",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":5,"column":61},"end":{"line":5,"column":96}}}))
    + "</p>\n            <p>"
    + ((stack1 = (helpers.language||(depth0 && depth0.language)||alias2).call(alias1,"Download the image license anytime from %sProfile > Downloads.%s","<a href=\"/user/downloads\" class=\"link--inverted regular underline\" target=\"_blank\" rel=\"nofollow noopener noreferrer\">","</a>",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":6,"column":15},"end":{"line":8,"column":22}}})) != null ? stack1 : "")
    + "</p>\n        </div>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression;

  return "    <div class=\"attribution\">\n        <span class=\"bold full-width\">\n            "
    + alias3((helpers.language||(depth0 && depth0.language)||alias2).call(alias1,"Attribute this image",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":14,"column":49}}}))
    + "\n        </span>\n\n        <p>\n            "
    + alias3((helpers.language||(depth0 && depth0.language)||alias2).call(alias1,"Remember to mention the author and the source when using this image. Copy the attribution details below and include them on your project or website.",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":18,"column":177}}}))
    + "\n        </p>\n\n        <div class=\"flex-break\"></div>\n\n        <div class=\"copy\">\n            <button class=\"copy__trigger track button push-center gtm-copy-to-atribute-button\"\n                data-track-arguments=\"event, attribution-copy, post-download-copy\" data-copy='"
    + ((stack1 = ((helper = (helper = helpers.copyButtonText || (depth0 != null ? depth0.copyButtonText : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"copyButtonText","hash":{},"data":data,"loc":{"start":{"line":25,"column":94},"end":{"line":25,"column":114}}}) : helper))) != null ? stack1 : "")
    + "'>\n                <span class=\"overflow-lines overflow-lines--1\">\n"
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias2).call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.author)) && stack1.user_id),"==",23,{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":31,"column":32}}})) != null ? stack1 : "")
    + "</span>\n                <i class=\"icon--duplicate\"></i>\n            </button>\n        </div>\n\n        <div class=\"flex-break\"></div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.user),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":43,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"container-button-download-broken\">\n        <button class=\"modal__trigger button button--sm button--link-inverted gtm-problems-downloading-button\"\n            data-modal=\"modal-report\">\n            "
    + ((stack1 = (helpers.language||(depth0 && depth0.language)||alias2).call(alias1,"Problems with the download? %sLet us know%s","<span class=\"underline\">","</span>",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":49,"column":12},"end":{"line":49,"column":111}}})) != null ? stack1 : "")
    + "\n        </button>\n    </div>\n\n"
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias2).call(alias1,(depth0 != null ? depth0.mobile : depth0),"==",false,{"name":"compare","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":4},"end":{"line":63,"column":16}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + ((stack1 = (helpers.language||(depth0 && depth0.language)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Image by %s","Freepik",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":28,"column":24},"end":{"line":28,"column":64}}})) != null ? stack1 : "")
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + ((stack1 = (helpers.language||(depth0 && depth0.language)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"%sImage by %s%s on %s","",((stack1 = ((stack1 = (data && data.root)) && stack1.author)) && stack1.name),"","Freepik",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":30,"column":24},"end":{"line":30,"column":97}}})) != null ? stack1 : "")
    + "\n                    ";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <label class=\"font-sm\">"
    + ((stack1 = (helpers.language||(depth0 && depth0.language)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Download the image license anytime from %sProfile > Downloads.%s","<a href=\"/user/downloads\" class=\"underline link--inverted regular gtm-license-profile-link\" target=\"_blank\" rel=\"nofollow noopener noreferrer\">","</a>",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":39,"column":35},"end":{"line":42,"column":26}}})) != null ? stack1 : "")
    + "</label>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.globalCampaign : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":54,"column":8},"end":{"line":62,"column":15}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "            " + require("Partials/campaign-php/views/banner.hbs").apply(null, Array.prototype.slice.call(arguments, arguments.length > 6)) + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.isTrue||(depth0 && depth0.isTrue)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.FEATURE_AD_MANAGER_ATTRIBUTE),{"name":"isTrue","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":57,"column":12},"end":{"line":61,"column":23}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    return "                <div id=\"banner_attribute\" class=\"section--banner_attribute ad-manager-height\"></div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "            " + require("Partials/microfunnels/banner.hbs").apply(null, Array.prototype.slice.call(arguments, arguments.length > 6)) + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.showDownloadBoxBanner : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":64,"column":7}}})) != null ? stack1 : "");
},"useData":true});