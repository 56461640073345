import { q, qq } from 'bobjoll/ts/library/dom';
import { __ } from 'Partials/language';
import { xhr } from 'Service/xhr';

import { DownloadFileTypesResponse } from './detail.partials.downloadFileTypesModel';
import {
    getLocalValue,
    psdMockupBakerException,
    setLocalValue,
    sortList,
} from './detail.partials.downloadPSDMockupBaker';
import { changePopoverPosition } from './detail.partials.downloadSelector';

export const getResourceFiles = async (resourceId: string): Promise<DownloadFileTypesResponse[]> => {
    const urlAPI = `${BASE_URL}xhr/resources/${resourceId}/files`;

    const { data } = await xhr(urlAPI, {
        withCredentials: true,
        headers: {
            'X-CSRF-TOKEN': CSRF_TOKEN,
            'X-Requested-With': 'XMLHttpRequest',
        },
        validateStatus: status => status >= 200 && status < 500,
    });

    return data;
};

export const initializeDownloadFileTypes = async (resourceId: string, container: HTMLElement) => {
    if (q('.download-selector', container)) return;

    const downloadButton = q('.download-button', container);
    const popoverContainer = q('.download-resource-container .popover', container);

    if (resourceId && downloadButton && popoverContainer) {
        downloadButton.classList.add('button--loading');
        const zipSize = container.dataset.originalFilesize || 0;

        changePopoverPosition();

        await getResourceFiles(resourceId)
            .then(async enableTypes => {
                enableTypes.forEach(
                    (item: DownloadFileTypesResponse) => (item.colorspace = formatColorSpace(item.colorspace)),
                );

                enableTypes = sortList(enableTypes);

                const PSDVersion23ID = psdMockupBakerException(enableTypes);

                const { default: template } = await import('Templates/detail/downloads/download-type-menu.hbs');
                const selectorElement = document.createElement('div');

                selectorElement.innerHTML = template({
                    enableTypes,
                    zipSize,
                });
                popoverContainer.append(selectorElement);

                const fileTypes = qq('.download-selector .item', container);
                fileTypes.forEach(item => {
                    item.addEventListener('click', function() {
                        fileTypes.forEach(item => item.classList.remove('active'));
                        this.dataset.id && this.classList.add('active');

                        q('.popover__download', container)?.click();
                        downloadButton.dataset.type = this.dataset.filetype || '';
                        downloadButton.click();

                        const newPSD23isClicked = this.innerHTML.includes('PS v23');
                        const dataSaved = getLocalValue();

                        if (parseInt(dataSaved) <= MAX_OCURR_NOTIF_MOCKUP_BAKER_PLUGIN) {
                            if (PSDVersion23ID > 0 && newPSD23isClicked) {
                                (async () => {
                                    const { showMockupBakerAlert } = await import(
                                        'Partials/notifications/notifications.psdMockupBaker'
                                    );
                                    showMockupBakerAlert();
                                })();
                            }

                            const sum = parseInt(dataSaved) + 1;
                            setLocalValue(sum.toString());
                        }
                    });
                });
            })
            .finally(() => downloadButton.classList.remove('button--loading'));
    }
};

const formatColorSpace = (color: string) => {
    const format = {
        RGB: __('RGB - Web'),
        CMYK: __('CMYK - Print'),
    };

    return format[color] ? `(${format[color]})` : '';
};
