var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"cancellation__steps\">\n    <ul data-pepinen=\"test\">\n        <li "
    + ((stack1 = (helpers.getActiveClass||(depth0 && depth0.getActiveClass)||alias2).call(alias1,((stack1 = (depth0 != null ? depth0.stepsActive : depth0)) != null ? stack1["0"] : stack1),{"name":"getActiveClass","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":48}}})) != null ? stack1 : "")
    + "><span>"
    + alias3((helpers.language||(depth0 && depth0.language)||alias2).call(alias1,"Select",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":3,"column":55},"end":{"line":3,"column":78}}}))
    + "</span></li>\n        <li "
    + ((stack1 = (helpers.getActiveClass||(depth0 && depth0.getActiveClass)||alias2).call(alias1,((stack1 = (depth0 != null ? depth0.stepsActive : depth0)) != null ? stack1["1"] : stack1),{"name":"getActiveClass","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":48}}})) != null ? stack1 : "")
    + "><span>"
    + alias3((helpers.language||(depth0 && depth0.language)||alias2).call(alias1,"Confirm",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":4,"column":55},"end":{"line":4,"column":79}}}))
    + "</span></li>\n        <li "
    + ((stack1 = (helpers.getActiveClass||(depth0 && depth0.getActiveClass)||alias2).call(alias1,((stack1 = (depth0 != null ? depth0.stepsActive : depth0)) != null ? stack1["2"] : stack1),{"name":"getActiveClass","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":48}}})) != null ? stack1 : "")
    + "><span>"
    + alias3((helpers.language||(depth0 && depth0.language)||alias2).call(alias1,"Finish",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":5,"column":55},"end":{"line":5,"column":78}}}))
    + "</span></li>\n    </ul>\n    <div class=\"progress\">\n        <div class=\"progress__bar\" style=\"width: "
    + alias3(((helper = (helper = helpers.completed || (depth0 != null ? depth0.completed : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"completed","hash":{},"data":data,"loc":{"start":{"line":8,"column":49},"end":{"line":8,"column":64}}}) : helper)))
    + "%;\"></div>\n    </div>\n</div>";
},"useData":true});