export class Arguments {
    public static stringToObject(str: string) {
        const filters: { [name: string]: string } = {};

        try {
            const string = str.substr(str.indexOf('?') + 1);

            string.split('&').forEach(function(group: string) {
                const filter = group.split('=');

                filters[decodeURIComponent(filter[0])] = decodeURIComponent(filter[1]);
            });
        } catch (err) {}

        return filters;
    }

    public static objectToString(obj: { [name: string]: any }, spaceAsPlus = false, avoidUndefinedValues = false) {
        const keys = Object.keys(obj);
        const filter: string[] = [];

        keys.sort((a, b) => {
            if (a < b) {
                return -1;
            }
            return 1;
        })
            .filter(key => key !== '')
            .forEach(key => {
                if (avoidUndefinedValues && obj[key] === undefined) {
                    return;
                }
                const s = encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
                filter.push(spaceAsPlus ? s.replace(/%20|%2B/g, '+') : s);
            });

        return filter.join('&');
    }
}

export interface ArgumentsDictionary extends Object {
    [name: string]: any;
}
