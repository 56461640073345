import { q } from 'bobjoll/ts/library/dom';
import getFilterValue from 'Partials/filters-v2/getFilterValue';
import { UserHelpers } from 'Partials/user';
import { Arguments } from 'Project/ts/library/arguments';
import { numberNormalizer } from 'Project/ts/library/helpers';

import { isTruthy } from '../helpers/detail.helpers.generic';

export const mapFreepikTracker = function(resource: any, fileTypeExtension?: string): string[] {
    const resourceType = ['psd', 'photo', 'vector'];
    const trackerArgs = [];
    const detailOrigin = getDetailOrigin();
    const userId = UserHelpers.id();
    const userIsPremium = UserHelpers.isPremium();
    const {
        dependency: { cacheId, referrerArgs },
        id,
        premium = 0,
        selection = 0,
        author,
        type,
        fromView,
    } = resource;
    let { from_view: fromViewURL, fromView: fromViewNewStackURL, track, regularType, uuid } = Arguments.stringToObject(
        window.location.href.replace('#', '?'),
    );

    const urlRegularType = resourceType.includes(regularType) ? regularType : '';
    const format = fromViewURL || fromView || 'search';
    const fromViewType = fromView || fromViewURL || fromViewNewStackURL || 'detail';
    const { type: typeFilter } = getFilterValue('type');
    const isAiResource = typeFilter && typeFilter === 'ai';

    if (!track && TRACKING_SEARCH != '') {
        track = TRACKING_SEARCH;
    }

    trackerArgs.push(`id=${id}`);
    trackerArgs.push(`userPremium=${userIsPremium ? 1 : 0}`);

    if (cacheId) {
        trackerArgs.push(`position=${cacheId}`);
    }

    if (selection) {
        trackerArgs.push(`exclusive=${isTruthy(selection) ? 1 : 0}`);
    }

    if (premium) {
        trackerArgs.push(`resourcePremium=${isTruthy(premium) ? 1 : 0}`);
    }

    if (userId) {
        trackerArgs.push(`userid=${userId}`);
    } else {
        trackerArgs.push(`userid=0`);
    }

    if (track) {
        trackerArgs.push(`track=${track}`);
    }

    if (uuid) {
        trackerArgs.push(`uuid=${uuid}`);
    }

    if (detailOrigin) {
        const { page, query, position, from_query } = detailOrigin;
        trackerArgs.push(`language=${LANGUAGE_NAME}`);
        page && trackerArgs.push(`page=${page}`);
        trackerArgs.push(`pg=detalle-${LANGUAGE_NAME}`);
        position && trackerArgs.push(`position=${position}`);
        trackerArgs.push(`term=${query}`);
        trackerArgs.push(`original_term=${from_query || query}`);
    }

    if (referrerArgs) {
        trackerArgs.push(Arguments.objectToString(referrerArgs, false, true));
    }

    const tracker = {
        popular: 'category',
        static: q('main.home') ? 'home' : 'collections',
        showcase: 'home',
        'search-banner': 'collections',
        thumbnail:
            VIEW_TEMPLATE === 'keyword'
                ? 'thumbnail_keyword'
                : VIEW_TEMPLATE.match(/popularView|newest/)
                ? 'thumbnail_category'
                : 'thumbnail_search',
    };

    trackerArgs.push(`format=${tracker[format] || format}`);
    trackerArgs.push(`fromView=${tracker[fromViewType] || fromViewType}`);

    if (q('.author-info') || format === 'author') {
        trackerArgs.push(`author=${author.user_id}`);
    }

    trackerArgs.push(`resourceType=${type === 'model' ? '3d' : isAiResource ? 'ai' : urlRegularType || 'regular'}`);

    if (fileTypeExtension) trackerArgs.push(`extension=${fileTypeExtension}`);

    return trackerArgs;
};

const getDetailOrigin = function(): DetailOrigin | void {
    const params = window.location.hash || window.location.search;
    const { page, query, position, from_query, term } = Arguments.stringToObject(
        params.replace('#', '').replace('?', ''),
    );
    if (query || term) {
        return {
            page: page ? numberNormalizer(page) : undefined,
            position: position ? numberNormalizer(position) : undefined,
            query: query || term,
            from_query,
        };
    }
};

interface DetailOrigin {
    from_query?: string;
    page?: number;
    position?: number;
    query: string;
}
