import 'Variables';

import { q, qq } from 'bobjoll/ts/library/dom';
import template from 'Project/ts/templates/parts/metas.hbs';

export default class Metas {
    private static data: any;
    private static ignore = ['viewport'];

    private static setStatic(path: string) {
        if ('' === path) return;

        const paths = path.split('.');
        const h1Element = q('h1');
        const h2Element = h1Element && h1Element.nextElementSibling ? h1Element.nextElementSibling : undefined;
        const data = {
            background_image: '%s',
            h1: h1Element ? h1Element.innerHTML : '',
            h2: h2Element ? h2Element.innerHTML : '',
            metas: [].slice
                .call(document.head!.children)
                .filter(
                    (node: any) =>
                        'META' === node.nodeName && (node.getAttribute('name') || node.getAttribute('property')),
                )
                .reduce((metas: any[], metaElement: HTMLMetaElement) => {
                    const metaAttributes = metaElement.getAttributeNames() || [];

                    if (metaAttributes.length) {
                        const meta = metaAttributes.reduce((acc, attribute) => {
                            acc[attribute] = metaElement.getAttribute(attribute);
                            return acc;
                        }, <any>{});

                        if (meta) {
                            metas.push(meta);
                        }
                    }

                    return metas;
                }, []),
            title: document.title,
            total_resources: '%s',
            raw: true,
        };

        paths.reduce((metas, path, index, arr) => {
            if (!metas[path]) {
                return (metas[path] = index + 1 === arr.length ? data : {});
            }

            return metas[path];
        }, Metas.data);
    }

    static clear() {
        qq('meta', document.head || document.body).forEach((meta: HTMLMetaElement) => {
            const name = meta.name || (meta as any).property;
            if (
                Metas.ignore.indexOf(name) < 0 &&
                ('undefined' !== typeof meta.name || 'undefined' !== typeof (meta as any).property) &&
                document.head &&
                meta
            ) {
                document.head.removeChild(meta);
            }
        });
    }

    static modify(newMeta: MetasStrings) {
        if (!newMeta || (!newMeta.property && !newMeta.name)) return;

        newMeta.property
            ? q(`meta[property="${newMeta.property}"]`)?.setAttribute('content', newMeta.content)
            : q(`meta[name="${newMeta.name}"]`)?.setAttribute('content', newMeta.content);
    }

    static add(settings: { name?: string; content?: string; property?: string; rel?: string; href?: string }) {
        if (document.head) {
            document.head.insertAdjacentHTML('beforeend', template(settings));
        }
    }

    static get(path: string) {
        if (!Metas.data && window.hasOwnProperty('metas')) {
            Metas.data = metas;
        }

        if (Metas.data && !Metas.data.getPathValue(path)) {
            Metas.setStatic(path);
        }

        return Metas.data && Metas.data.getPathValue(path);
    }
}

export type MetasStrings = { name?: string; property?: string; content: string };
