import { q, ww } from 'bobjoll/ts/library/dom';
import getFilterValue from 'Partials/filters-v2/getFilterValue';
import { isSearchByImageEnabled } from 'Partials/search-by-image/image/searchimage.get-image';

import {
    ACCEPTED_ORIENTATIONS,
    ACCEPTED_PEOPLE_EXCLUDE,
    ACCEPTED_PEOPLE_GENDER,
    ACCEPTED_TYPE,
} from './shutterstock.constants';
import { Params, ParamsToAPI } from './shutterstock.vm';
import { getQueryFromHashParameters } from 'Project/ts/library/helpers/url';

const mapFiltersToApi = (options: ParamsToAPI) => {
    const { filters, params, filterKey, acceptedParam, apiParam } = options;
    const filter = filters[filterKey];

    if (!filter) return;

    if (filter && Object.keys(acceptedParam).includes(filter)) {
        params[apiParam] = acceptedParam[filter];
    }
};

export const getParamsToAPI = (query?: string): Params => {
    const filters = getFilterValue(Object.keys(ww.FiltersEnabled));
    const searchTerm = query || filters.query;
    const firstKeyword = q('.detail__keywords .search__link')?.innerText;
    const words = [searchTerm, firstKeyword !== searchTerm ? firstKeyword : ''].filter(Boolean).join(' ');
    const language = LANGUAGE === 'jp' ? 'ja' : LANGUAGE;

    const params = {
        language,
        query:
            (isSearchByImageEnabled() && KEYWORD_SEARCH_BY_IMAGE) ||
            words ||
            getQueryFromHashParameters() ||
            '',
        page: 1,
        perPage: 20,
    };
    const filterConfig = [
        { filterKey: 'type', acceptedParam: ACCEPTED_TYPE, apiParam: 'image_type' },
        { filterKey: 'orientation', acceptedParam: ACCEPTED_ORIENTATIONS, apiParam: 'orientation' },
        { filterKey: 'demographic', acceptedParam: ACCEPTED_PEOPLE_GENDER, apiParam: 'people_gender' },
        { filterKey: 'people', acceptedParam: ACCEPTED_PEOPLE_EXCLUDE, apiParam: 'people_number' },
    ];

    filterConfig.forEach(config => mapFiltersToApi({ filters, params, ...config }));

    return params;
};
