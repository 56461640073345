import { ModalAddSettings, ModalPrintSettings } from './modal.vm';

export const modalAddDefaults: ModalAddSettings = {
    name: '',
    multilayer: false,
};

export const modalPrintDefaults: ModalPrintSettings = {
    name: '',
    html: '',
    multilayer: false,
};
