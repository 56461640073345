import { LoadScript } from 'Library/load-scripts';

class PixelScriptPinterest extends LoadScript {
    private pinterestID = '2613079744398';

    constructor() {
        super();
    }

    public addNoScript(page: string, extra?: { [name: string]: any }) {
        const pixel = 'pinterestNoScript';
        const argsExtra = extra
            ? Object.keys(extra)
                  .map(key => `[${key}]=${extra[key]}`)
                  .join('&ed')
            : '';
        const params = argsExtra ? `&ed${argsExtra}` : '';

        this.loaded[pixel] = false;

        this.Scripts = {
            [pixel]: {
                name: 'pinterest-noscript',
                noScript: `<img height="1" width="1" style="display:none;" alt=""
                                src="https://ct.pinterest.com/v3/?tid=${this.pinterestID}&event=${page}${params}&noscript=1" />`,
                page: page,
            },
        };

        this.load(pixel);
    }

    public addEvent(page: string, termEvent?: { [name: string]: any }) {
        if (typeof (window as any).pintrk === 'function') {
            (window as any).pintrk('track', page, termEvent);
            this.addNoScript(page, termEvent);
        }
    }
}

export const pixelScriptPinterest = new PixelScriptPinterest();
