/// <reference types="google.analytics"/>
import 'Variables';

import { GTMArguments } from './tracker-gtm';

const ga2: any = (...args: any[]): void => {
    let params: GTMArguments<string> = {
        command: args[0],
        event: 'old_' + args[1],
        category: args[2],
        action: args[3],
        label: args[4],
    };

    if (args[5]) {
        params = { ...params, noninteraction: 'true' };
        args.splice(5, 1);
    }

    dataLayer.push(params);

    // ping nginx on events to get a faster and more flexible way to check analytics on events.
    if (args.length > 1 && args[0] == 'send' && args[1] == 'event') {
        const argUri = args.map(arg => encodeURIComponent(arg)).join('&');
        new Image().src = `/_ga?${argUri}`;
    }
}

export default ga2 as typeof ga;
