import { q } from 'bobjoll/ts/library/dom';

export const forceCloseNotification = (position: string) => {
    const container = ('top' === position)
        ? '.notify-wrapper--top'
        : ('bottom' === position)
            ? '.notify-wrapper--bottom'
            : `#notifications .notifications__${position}`;
    q(`${container} .notification__close`)?.click();
};

export const overlayNotifications = (active: boolean) => {
    const notificationsContainer = q('#notifications');
    if (notificationsContainer) {
        notificationsContainer.classList[active ? 'add' : 'remove']('overlay');
    }
}
