/* eslint-disable @typescript-eslint/camelcase */
import { Resource } from 'Partials/showcase';

export const mapModelResourceComplete = function(key: string, resource: any, response: any) {
    const keywordsVisible = 6;
    const {
        data: {
            detail: {
                author: { id, totalResources: total_resources, userId: user_id },
                download_file_url,
                downloads,
                file_types,
                is_essential,
                keywords,
                original_filesize,
                original_height,
                original_width,
                ris,
                printType,
                srcset,
                thumbnails,
                properties,
                metatags: { ...metas },
            },
            resources,
            // related: { series },
            related_author,
            related_collection,
        },
        meta,
        paging,
    } = response;
    return {
        data: {
            detail: {
                ...resource,
                author: {
                    ...resource.author,
                    id,
                    total_resources,
                    user_id,
                },
                download_file_url,
                downloads_formats: {
                    zip: downloads.zip,
                    fbx: downloads.fbx,
                },
                file_types,
                is_essential,
                is_essential_and_country: is_essential == '1' && ESSENTIAL_COUNTRY == '1',
                keywords,
                keywordsHidden: keywords.length - keywordsVisible,
                keywordsVisible,
                original_filesize,
                original_height,
                original_width,
                print_type: printType,
                ris,
                srcset,
                thumbnails: {
                    ...resource.thumbnails,
                    ...thumbnails,
                },
                metatags: { metas },
                properties: properties,
            },
            resources:
                resources?.map((resource: Resource) => ({
                    ...resource,
                    author: {
                        ...resource.author,
                        name: resource.author['author_name'] ?? (resource.author.name || ''),
                        avatar: resource.author['author_user_avatar'] ?? (resource.author.avatar || ''),
                        slug: resource.author['author_slug'] ?? (resource.author['slug'] || ''),
                    },
                })) || [],
            related: {
                // serie: series && series.resources,
                collection: related_collection ?? [],
                author: related_author ?? [],
            },
        },
        key,
        meta,
        paging,
    };
};
