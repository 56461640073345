import { q } from 'bobjoll/ts/library/dom';
import { showCampaignBanner } from 'Partials/campaign-php/campaign.helpers';
import triggerManualGA4Events from 'Partials/events/ga4.init-manual-events';
import { __ } from 'Partials/language';
import notify from 'Partials/notify';
import { UserHelpers } from 'Partials/user';
import { cookie } from 'Project/ts/library/cookie';

import { forceCloseNotification } from './notifications.helpers';

const showCoupon = function(values: DownloadLimit) {
    if (cookie.getItem('debug_download_limit_coupon')) return true;
    if (showCampaignBanner()) return false;

    // FIXME: el cupon autopromo no aparece para los usuarios de India mientras ChargeBee no tenga la implementación de cupones en prod
    return UserHelpers.isPremium() ||
        UserHelpers.isEssential() ||
        !showLimitDownload(values) ||
        USER_COUNTRY_CODE === 'IN'
        ? false
        : true;
};

export const getDownloadLimit = async function(values: DownloadLimit) {
    if (!showCoupon(values)) return;

    const { default: template } = await import(`Partials/microfunnels/banner.hbs`);
    const isLogged = gr && gr.user;
    const code = 'DLFP10OFF';
    const discount = 10;
    const textBanner = __('Go limitless with Premium and %s save %s %s', '<strong>', `${discount}%`, '</strong>');
    const textFlaticon = __('%sNow with Flaticon exclusive content.%s', '<p class="text">', '</p>');

    const title = __('You\'re about to reach your download limit');
    const text = `${textBanner} ${textFlaticon}`;
    const button = __('Get Discount');
    const buttonSize = 'sm';

    const banner = {
        class: 'coupon banner-coupon-limit',
        title,
        text,
        button,
        buttonSize,
        coupon: code,
    };

    forceCloseNotification('bottom-right');

    notify.addNotification({
        class: 'notification--microfunnel',
        expires: () => new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        html: template({
            PRICING_URL,
            URL_STATIC_IMG,
            banner,
        }),
        id: `coupon-download-limit-80${gr && gr.user ? `-${gr.user.id}` : ''}`,
        position: 'bottom-right',
        priority: 1,
        callback: (coupon: HTMLElement) => {
            if (coupon) {
                coupon.addEventListener('click', (event: Event) => {
                    if (notify.closed(event)) return;

                    q('.notification__close', coupon)?.click();

                    triggerManualGA4Events({
                        description: 'downloadLimitCoupon',
                        options: {
                            action: 'click',
                            discount,
                            registered: isLogged,
                        },
                    });
                });

                triggerManualGA4Events({
                    description: 'downloadLimitCoupon',
                    options: {
                        action: 'onload',
                        discount,
                        registered: isLogged,
                    },
                });
            }

            setTimeout(() => Lazyload.run(), 100);
        },
    });

    notify.printQueue();
};

const showLimitDownload = function(values: DownloadLimit) {
    const percentageLimit = 80;

    if (values.count && values.limit) {
        const checkLimit = (values.limit * percentageLimit) / 100;
        if (Math.trunc(checkLimit) <= values.count + 1) return true;
    }

    return false;
};

interface DownloadLimit {
    count: number;
    limit: number;
}
