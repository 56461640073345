import { get } from './optimize.api';

let experimentId: string | null = null;
export const getDetailExperiment = () => {
    const optimizeId = 'bJW5dvVOSTerbUOdvvs9qw';
    const id = get(optimizeId);
    if (id) {
        experimentId = id;
    }
    return {
        id: optimizeId,
        value: experimentId,
    };
};
