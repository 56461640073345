var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing;

  return "<div class=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.classes : stack1)) != null ? stack1.wrapper : stack1), depth0))
    + "\">\n    <section class=\"showcase-spr\"\n        data-layout=\"pixabay\"\n        data-layout-editable=\"false\"\n"
    + ((stack1 = (helpers.isTrue||(depth0 && depth0.isTrue)||alias4).call(alias3,(depth0 != null ? depth0.FEATURE_HIDE_SPONSOR_VISIBILITY : depth0),{"name":"isTrue","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":8,"column":19}}})) != null ? stack1 : "")
    + ">\n\n        <div class=\"section-spr--row "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.classes : stack1)) != null ? stack1.row : stack1), depth0))
    + "\">\n            " + require("./showcase_shutterstock_item.hbs").apply(null, Array.prototype.slice.call(arguments, arguments.length > 6)) + "\n        </div>\n\n            <footer class=\"footer-spr\">\n                <div class=\"wrapper-title\">\n                    " + require("./showcase_shutterstock_title.hbs").apply(null, Array.prototype.slice.call(arguments, arguments.length > 6)) + "\n                </div>\n                <a id=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.shutterstock)) && stack1.ids)) && stack1.more), depth0))
    + "\"\n                    href=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.shutterstock)) && stack1.data)) && stack1.show_more_url), depth0))
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.user)) && stack1.clientId),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":67},"end":{"line":19,"column":135}}})) != null ? stack1 : "")
    + "\"\n                    class=\"button--arrow "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.classes : stack1)) != null ? stack1.more : stack1), depth0))
    + "\"\n                    target=\"_blank\"\n                    rel=\"nofollow noopener noreferrer"
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias4).call(alias3,"iStockPhoto","===",((stack1 = (data && data.root)) && stack1.SPONSOR),{"name":"compare","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":53},"end":{"line":22,"column":121}}})) != null ? stack1 : "")
    + "\"\n                    data-cs-override-id=\"spn-more\">\n                        <span>"
    + alias2((helpers.language||(depth0 && depth0.language)||alias4).call(alias3,"Show more",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":24,"column":30},"end":{"line":24,"column":54}}}))
    + " <i class=\"icon icon--lg icon--right-small mg-left-lv2\"></i></span>\n                </a>\n            </footer>\n\n    </section>\n\n"
    + ((stack1 = (helpers.compare||(depth0 && depth0.compare)||alias4).call(alias3,"Shutterstock","===",(depth0 != null ? depth0.SPONSOR : depth0),{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":37,"column":16}}})) != null ? stack1 : "")
    + "</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "        style=\"visibility:hidden\"\n        ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "&client_id="
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.user)) && stack1.clientId), depth0));
},"6":function(container,depth0,helpers,partials,data) {
    return " sponsored";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.meta : stack1)) != null ? stack1.pixel_url : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":4},"end":{"line":36,"column":11}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "    <div style=\"visibility: hidden; position: absolute; pointer-events: none;\">\n        <img src=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.meta : stack1)) != null ? stack1.pixel_url : stack1), depth0))
    + "\" />\n        <img src=\"https://www.freepik.com/_ga?sponsor_pixel="
    + alias1((helpers.encodeURIComponent||(depth0 && depth0.encodeURIComponent)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.meta : stack1)) != null ? stack1.pixel_url : stack1),{"name":"encodeURIComponent","hash":{},"data":data,"loc":{"start":{"line":34,"column":60},"end":{"line":34,"column":111}}}))
    + "\" />\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.shutterstock : depth0)) != null ? stack1.data : stack1)) != null ? stack1.resources : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":39,"column":7}}})) != null ? stack1 : "");
},"useData":true});