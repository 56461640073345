/* eslint-disable @typescript-eslint/camelcase */
import Cache from 'Library/cache';
import { __ } from 'Partials/language';
import { Resource } from 'Partials/showcase';
import { UserHelpers } from 'Partials/user';

const getRelatedResourceFromDetail = (options: DetailCacheOptions) => {
    const { cacheKey, cacheId, id } = options;
    const cache = Cache.get(cacheKey);

    if (cache) {
        const relatedSeries = cache.getPathValue('data.related.serie') || [];
        const relatedAuthor = cache.getPathValue('data.related.author.resources') || [];
        const relatedCollection = cache.getPathValue('data.related.collection.resources') || [];
        const relatedAlsoLike = cache.getPathValue('data.resources') || [];

        const relatedResourcesOrdered = [...relatedSeries, ...relatedAuthor, ...relatedCollection, ...relatedAlsoLike];

        const resourceData = relatedResourcesOrdered.find(({ id: resourceId }: Resource) => id == resourceId);

        return {
            resource: resourceData || {},
            navigation: getCacheResourceNavigation(Number(cacheId), relatedResourcesOrdered),
        };
    }

    return {
        resource: {},
    };
};

export const getCacheResource = (options: DetailCacheOptions): Promise<DetailCacheResponse> => {
    return new Promise((resolve, reject) => {
        try {
            const { id } = options;
            const { resource, navigation } = getRelatedResourceFromDetail(options);

            if (resource.length === 0 || id != resource.id) {
                throw Error('Resource was not found (cache from detail related)');
            }

            const download = getCacheResourceDownloadUrl(Number(id));

            resource.show_mockup_editor_button = showMockupEditorButton(resource);
            resource.show_editor_button = showEditStoriesButton(resource);
            resource.show_editor_tooltip = showEditStoriesTooltip(resource);

            resolve({
                download,
                navigation,
                resource,
                success: true,
            });
        } catch (error) {
            reject({
                error,
                success: false,
            });
        }
    });
};

const getCacheResourceNavigation = (id: number, resources: Resource[]): DetailCacheNavigation | void => {
    const prevId = id - 1 < 0 ? resources.length - 1 : id - 1;
    const nextId = id + 1 >= resources.length ? 0 : id + 1;
    if (resources[prevId] && resources[nextId]) {
        return {
            prev: {
                id: resources[prevId].id,
                index: prevId,
                url_source: resources[prevId].url_source,
            },
            next: {
                id: resources[nextId].id,
                index: nextId,
                url_source: resources[nextId].url_source,
            },
        };
    }
};

const getCacheResourceDownloadUrl = (id: number) => {
    return {
        download_file_url: `${BASE_URL}download-file/${id}`,
        download_landing_url: `${BASE_URL}download/${id}`,
        download_license_url: `${BASE_URL}profile/license/pdf/${id}?lang=${LANGUAGE}`,
    };
};

const showMockupEditorButton = (resource: Resource) => resource.freepik_editor === '2';

const showEditStoriesButton = (resource: Resource) => {
    if (!OPTIMAL_BROWSER && resource.freepik_stories == '1') return false;
    return resource.freepik_editor == '1' || resource.freepik_stories || resource.type.match(/photo/);
};

const showEditStoriesTooltip = (resource: Resource) =>
    resource.type.match(/photo/) &&
    (UserHelpers.isPremium() || (UserHelpers.isEssential() && resource.is_essential) || !resource.premium);

export interface DetailCacheOptions {
    cacheId: string;
    cacheKey: string;
    id: string;
}

interface DetailCacheResponse {
    success: boolean;
    resource: Resource;
    download: DetailCacheDownload;
    navigation: ReturnType<typeof getCacheResourceNavigation>;
}

interface DetailCacheNavigation {
    prev: DetailCacheNavigationObj;
    next: DetailCacheNavigationObj;
}

interface DetailCacheNavigationObj {
    id: string;
    index: number;
    url_source: string;
}

interface DetailCacheDownload {
    download_file_url: string;
    download_landing_url: string;
    download_license_url: string;
}
