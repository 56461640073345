import { BannerTypes, isContentCampaign } from 'Partials/campaign-php/campaign.helpers';
import triggerManualGA4Events from 'Partials/events/ga4.init-manual-events';
import { ManualEvent } from 'Partials/events/ga4.model';
import { q } from 'Project/ts/library/dom.q';

import { getCampaignParams } from './ga4.get-campaign';

export const sendAutopromoGA4events = (slot: string, id: string, ga4Name: string) => {
    const bannerElement = q(`div[data-id='${id}']`);
    const isFreepikAutopromo = ga4Name === 'freepik';
    const searchBannerPosition = isFreepikAutopromo ? '1' : '2';
    const bannerType: BannerTypes = slot.indexOf('SIDEBAR') !== -1 ? 'detail' : 'adblock';
    const location = bannerType === 'detail' ? 'resource_detail' : `search_results_list_${searchBannerPosition}`;
    const autopromoFreepikParams = getCampaignParams(isContentCampaign(bannerType));
    const campaignType = isContentCampaign(bannerType) ? 'content_banner' : 'microfunnel';
    const optionsEvent = {
        action: 'onload',
        event_name: isFreepikAutopromo ? campaignType : 'autopromo',
        location,
        ga4Name,
        freepikAutopromoOptions: isFreepikAutopromo ? autopromoFreepikParams : {},
    };
    const autopromoDefaultParams: ManualEvent = {
        description: 'autopromoBanner',
        options: optionsEvent,
    };

    triggerManualGA4Events(autopromoDefaultParams);

    bannerElement &&
        bannerElement.addEventListener('click', () =>
            triggerManualGA4Events({
                ...autopromoDefaultParams,
                options: {
                    ...optionsEvent,
                    action: 'click',
                },
            }),
        );
};
