import { q, qq } from 'bobjoll/ts/library/dom';
import abbreviate from 'bobjoll/ts/library/number-abbreviate';
import { __ } from 'Partials/language';
import { UserHelpers } from 'Partials/user';
import { numberNormalizer } from 'Project/ts/library/helpers';
import { UserService } from 'Project/ts/service/user';
import { ToggleResponse } from 'Project/ts/service/user/favorite';
import { FavoriteEvents } from 'User/favorite/events';

import { FavoriteJobs } from './events-unloaded';

export class Favorite {
    public static initialize() {
        Favorite.updateState();
        FavoriteEvents.addEventListener();
    }

    public static destroy() {
        Favorite.resetState();
        FavoriteEvents.removeEventListener();
    }

    public static resetState() {
        qq('.button--favorite').forEach(buttonElement => buttonElement.classList.remove('active'));
    }

    public static async updateState(forceRequest?: boolean, onlyDetail = false) {
        if (!(await UserHelpers.isLogged(false))) return;

        UserService.Favorite.get(Favorite.getShowedResourcesIds(onlyDetail), forceRequest).then(response => {
            const jobs: Promise<ToggleResponse>[] = FavoriteJobs.get().map(job => UserService.Favorite.toggle(job));
            FavoriteJobs.clear();
            Promise.all(jobs).then(() => Favorite.updateState);
            if (!jobs.length) {
                UserService.Favorite.toggleLog()
                    .filter((entry, index, array) => array.indexOf(entry) === index)
                    .forEach(entry => Favorite.updateCounter(entry.id));
                qq('.button--favorite').forEach(buttonElement => {
                    const checkFavorite = response.data.resources.some(
                        id => id.toString() === buttonElement.dataset.rid || '',
                    );

                    checkFavorite && buttonElement.classList.add('active');

                    Favorite.setButtonFavouriteState(buttonElement as HTMLButtonElement, checkFavorite);
                });
            }
        });
    }

    public static updateToggle(resourceID: string) {
        UserService.Favorite.get(Favorite.getShowedResourcesIds()).then(response => {
            qq(`.button--favorite[data-rid="${resourceID}"]`).forEach(buttonElement => {
                const checked = response.data.resources.indexOf(resourceID) >= 0;

                buttonElement.classList[checked ? 'add' : 'remove']('active');

                Favorite.setButtonFavouriteState(buttonElement as HTMLButtonElement, checked);
            });
        });
    }

    public static updateCounter(resourceID: string) {
        const toggleCount = UserService.Favorite.toggleCount(resourceID);
        qq(`.badge--favorite[data-rid="${resourceID}"]`).forEach(resourceCounter => {
            const total = toggleCount + numberNormalizer(resourceCounter.dataset.rl || resourceCounter.innerText);
            if (resourceCounter.dataset.rl && total < 1000) {
                resourceCounter.innerText = abbreviate(total, 0, true);
            }
        });
    }

    public static setButtonFavouriteState(button: HTMLButtonElement, active: boolean) {
        const textStatus = active ? __('Liked') : __('Like');

        if (!button.classList.contains('button--favorite-detail')) {
            button.classList[active ? 'add' : 'remove']('icon--heart-filled');
            button.classList[!active ? 'add' : 'remove']('icon--heart');
            const componentToChangeText: HTMLElement | null = q('.tooltip__content', button) || button;

            if (componentToChangeText) {
                componentToChangeText.textContent = textStatus;
            }
        } else {
            const text: HTMLElement | null = q('span', button);

            if (text) {
                text.textContent = textStatus;
            }
        }
    }

    private static getShowedResourcesIds(onlyDetail = false) {
        return (
            (qq(
                onlyDetail ? '.detail__related .showcase__item, .detail' : 'figure.showcase__item, #main .detail',
            ) as HTMLElement[]) || []
        )
            .map(res => res.dataset.id)
            .join(',');
    }
}
