import { PIXEL_CUSTOM_URL } from './shutterstock.constants';
import { Tracker } from './shutterstock.vm';

export const createPixel = (config: Tracker) => {
    const { pixelParams } = config;
    const tracker = new Image();
    const pixelUrl = `${PIXEL_CUSTOM_URL}${pixelParams}&r=${new Date().getTime()}`;

    tracker.src = `${pixelUrl}${config.listItemIds ? `&id_list=${encodeURIComponent(config.listItemIds)}` : ''}`;
};

export const createSponsorPixel = (trackerImpSponsor: string) => {
    const tracker = new Image();
    tracker.src = trackerImpSponsor;
};
