import { getRangeFriendlyValue, Range } from './range';

const ageRange: Range[] = [
    {
        range: {
            min: 0,
            max: 7,
        },
        friendlyValue: '0-7',
    },
    {
        range: {
            min: 8,
            max: 14,
        },
        friendlyValue: '8-14',
    },
    {
        range: {
            min: 15,
            max: 30,
        },
        friendlyValue: '15-30',
    },
    {
        range: {
            min: 31,
            max: 60,
        },
        friendlyValue: '31-60',
    },
    {
        range: {
            min: 61,
            max: 90,
        },
        friendlyValue: '61-90',
    },
    {
        range: {
            min: 91,
            max: 180,
        },
        friendlyValue: '91-180',
    },
    {
        range: {
            min: 181,
            max: 365,
        },
        friendlyValue: '181-365',
    },
    {
        range: {
            min: 366,
            max: 530,
        },
        friendlyValue: '366-530',
    },
    {
        range: {
            min: 531,
            max: 895,
        },
        friendlyValue: '531-895',
    },
    {
        range: {
            min: 896,
            max: 1260,
        },
        friendlyValue: '896-1260',
    },
    {
        range: {
            min: 1261,
            max: 1625,
        },
        friendlyValue: '1261-1625',
    },
    {
        range: {
            min: 1625,
            max: 1990,
        },
        friendlyValue: '1625-1990',
    },
    {
        range: {
            min: 1991,
        },
        friendlyValue: ': 1991+',
    },
];

export const getResourceAge = function(creationDate: Date) {
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate.getTime() - creationDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const friendlyValue = getRangeFriendlyValue(diffDays, ageRange);

    return friendlyValue || diffDays;
};
