import { cookie } from 'Library/cookie';
import Tracker from 'Library/tracker';
import { TrackerOptions, trackerSend } from 'Library/trackerSend';
import { getSponsorLocation, getSponsorPopupOrigin, sponsorGA4Events } from 'Partials/events/ga4.sponsor';
import getFilterValue from 'Partials/filters-v2/getFilterValue';
import { UserHelpers } from 'Partials/user';
import { SponsorPixel } from 'Project/ts/common/sponsor-common/sponsor.pixel';
import { trackerInterface } from 'Project/ts/library/helpers/tracker';

import { getTrackerData, getViewType } from './trackerSend.helpers';

function markUserAsInterested(value: string) {
    const interested = UserHelpers.getUserSponsorInterested();
    if (Object.keys(interested).length) {
        interested[value] = interested[value] ? interested[value] + 1 : 1;
    } else {
        interested[value] = 1;
    }
    cookie.setItem(UserHelpers.interestedCookieName, JSON.stringify(interested), {
        expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
    });
}

export const adobeRender = (data: AdobeRenderOptions) => {
    const viewType = getViewType();
    const trackerData = getTrackerData({ ...data, action: 'imp' }, viewType);

    trackerSend(`_ga?sponsor_pixel=${viewType}&sponsor=${SponsorPixel.ADOBE}`, trackerData);

    viewType !== 'nn' &&
        sponsorGA4Events({
            location: getSponsorLocation(viewType),
        });
};

export const adobeRenderCoupon = (data: AdobeCouponOptions) => {
    const { query } = getFilterValue('query');
    data.action = 'imp';
    if (query) data.term = query;
    const isAdobeExpress = FEATURE_SHOW_ADOBE_EXPRESS_STOCKBANNER_BY_COUNTRY;

    trackerSend(
        `_ga?sponsor_pixel=stockbanner&sponsor=${isAdobeExpress ? SponsorPixel.ADOBE_EXPRESS : SponsorPixel.ADOBE}`,
        data,
    );

    if (data.pixelAffiliate && '' !== data.pixelAffiliate) Tracker.stockbannerAffiliate(data.pixelAffiliate);

    sponsorGA4Events({ location: 'pop_up_right_corner' });
};

export const adobeClickCoupon = (data: AdobeCouponOptions) => {
    const { query } = getFilterValue('query');
    const isAdobeExpress = FEATURE_SHOW_ADOBE_EXPRESS_STOCKBANNER_BY_COUNTRY;
    const viewType = 'stockbanner';
    data.action = 'clk';
    if (query) data.term = query;

    trackerSend(
        `_ga?sponsor_pixel=${viewType}&sponsor=${isAdobeExpress ? SponsorPixel.ADOBE_EXPRESS : SponsorPixel.ADOBE}`,
        data,
    );
    trackerInterface.send(
        'ga',
        'send',
        'event',
        `affiliate-coupon-click-${isAdobeExpress ? 'express-1' : data.variation}`,
        'click',
    );

    markUserAsInterested(viewType);

    sponsorGA4Events({
        location: 'pop_up_right_corner',
        action: 'click',
    });
};

export const adobeItemClick = (data: AdobeClickOptions) => {
    const viewType = getViewType();
    let trackerData = getTrackerData({ ...data, action: 'clk' }, viewType);

    trackerSend(`_ga?sponsor_pixel=${viewType}&sponsor=${SponsorPixel.ADOBE}`, trackerData);
    trackerInterface.send('ga', 'send', 'event', 'affiliate-banner', 'click', 'item');
    markUserAsInterested(viewType);

    viewType !== 'nn' &&
        sponsorGA4Events({
            location: getSponsorLocation(viewType),
            action: 'click',
            element: 'item',
        });
};

export const adobePopup = (data: AdobePopupOptions, origin: AdobePopupOrigin, page?: number) => {
    const eventLabelSufix = origin === 'search' ? 'random' : `NextPage${page || ''}`;
    const cookieTestAdobeModal = cookie.getItem('gr_fABy3v_test_adobe_modal');
    const adobeModalTest = cookieTestAdobeModal && cookieTestAdobeModal === 'b';
    const trackerData = getTrackerData({ ...data, action: data.action || 'clk' }, 'popup');

    if (origin === 'page-button-next' || origin === 'page-button-prev') {
        trackerData.origin = 'page-button';
    }

    trackerSend(`_ga?sponsor_pixel=popup&sponsor=${SponsorPixel.ADOBE}`, trackerData);
    trackerInterface.send(
        'ga',
        'send',
        'event',
        'sponsor',
        'click',
        `go-adobe-${adobeModalTest ? 'modal' : 'popup'}-${eventLabelSufix}`,
    );

    sponsorGA4Events({
        location: 'pop_up',
        element: getSponsorPopupOrigin(origin),
    });
};

export const adobeEventClick = (data: AdobeEventClickOptions) => {
    const viewType = getViewType();
    let trackerData = getTrackerData({ ...data, action: 'clk' }, viewType);

    trackerSend(`_ga?sponsor_pixel=${viewType}&sponsor=${SponsorPixel.ADOBE}`, trackerData);
    trackerInterface.send('ga', 'send', 'event', 'affiliate-banner', 'click', data.link);
    markUserAsInterested(viewType);

    viewType !== 'nn' &&
        sponsorGA4Events({
            location: getSponsorLocation(viewType),
            action: 'click',
            element: data.link,
        });
};

export const adobeMktCopyClick = (data: AdobeEventClickOptions) => {
    const viewType = 'srp';
    const trackerData = getTrackerData({ ...data, action: 'clk' }, viewType);
    trackerSend(`_ga?sponsor_pixel=${viewType}&sponsor=${SponsorPixel.ADOBE}`, trackerData);

    sponsorGA4Events({
        location: 'banner_pagination_search_result',
        action: 'click',
        element: data.link,
    });
};

export interface AdobeRenderOptions extends TrackerOptions {
    id_list: string;
    term: string;
}

export interface AdobeCouponOptions extends TrackerOptions {
    pixelAffiliate?: string;
    variation: string;
}

export interface AdobeClickOptions extends TrackerOptions {
    term: string;
}

export type AdobePopupOrigin = 'page-button-next' | 'page-button-prev' | 'page-input' | 'search';

export interface AdobePopupOptions extends TrackerOptions {
    term: string;
    origin: AdobePopupOrigin;
    epi2?: string;
    interstitial?: number;
}

export interface AdobeEventClickOptions extends TrackerOptions {
    term: string;
    link: 'more' | 'logo' | 'promo';
}
