/* eslint-disable @typescript-eslint/no-explicit-any */
import { ww } from 'bobjoll/ts/library/dom';
import { CacheOptions } from 'Project/ts/library/cache';
import { Options } from 'redaxios';
import xhr from 'Service/xhr';

import { AdobeResponseStatus, mapResponse } from './adobe.mappers';
import { Placements } from './partnerize/types';

const SECONDS_IN_24_HOURS = 86400;

const getLocale = () => {
    if ('es' === LANGUAGE) return 'es_ES';
    if ('en' === LANGUAGE) return 'en_US';
    if ('fr' === LANGUAGE) return 'fr_FR';
    if ('nl' === LANGUAGE) return 'nl_NL';
    if ('de' === LANGUAGE) return 'de_DE';
    if ('pl' === LANGUAGE) return 'pl_PL';
    if ('jp' === LANGUAGE) return 'ja_JP';
    if ('it' === LANGUAGE) return 'it_IT';
    if ('ru' === LANGUAGE) return 'ru_RU';
    if ('pt' === LANGUAGE) return 'pt_BR';
    if ('ko' === LANGUAGE) return 'ko_KR';

    return LANGUAGE;
};

const defaultArguments: AdobeOptions = {
    limit: 80,
    locales: getLocale(),
    page: 1,
    product: 'freepik-js-1',
    type: ['vector', 'photo'],
    words: '',
};

const xhrOptions: Options = {
    withCredentials: false,
    headers: {
        'X-Product': 'SearchAppFP/1.0',
    },
};

export const getImpl = async (placement: Placements, options: AdobeOptions) => {
    let status: AdobeResponseStatus = 'success';
    const minimumFilesToShow = 32;
    let response: any;

    try {
        response = await getAdobeData(options);

        if (!response.files.length || response.files.length < minimumFilesToShow) {
            throw 'empty';
        }
    } catch (err) {
        let { page = 1 } = options;
        status = 'empty' === err ? err : 'failed';

        if (page > 10) page = 10;

        response = await getAdobeDataForEmptyResults(page);
    }

    return mapResponse(placement, options, response, status);
};

const getAdobeDataForEmptyResults = async (page: number) => {
    const options = {
        limit: 80,
        locales: getLocale(),
        order: 'relevance',
        page,
        type: [],
        words: '',
    };

    const cache = {
        key: '',
        time: SECONDS_IN_24_HOURS,
        value: '',
    };

    return await getAdobeData(options, cache);
};

const getAdobeData = async (options: AdobeOptions, cacheOptions?: CacheOptions) => {
    const requestUrl = getRequestUrl(options);
    const cache = cacheOptions ? cacheOptions : true;
    return await xhr.get(requestUrl, xhrOptions, cache);
};

export const getRequestUrlImpl = (options: AdobeOptions) => {
    const args = getArguments(options);
    return `https://sp.cdnpk.net/Rest/Media/1/ExpressSearch/Files?${args}`;
};

export const getArgumentsImpl = (options: AdobeOptions) => {
    const args = { ...defaultArguments, ...options };
    args.page = (args.page || 1) - 1;
    if (args.words) {
        args.words = encodeURI(args.words);
    }
    return Object.keys(args)
        .reduce((acc, key) => {
            const value = args[key];
            if (Array.isArray(value) ? value.length : true) {
                acc.push(getArgumentMethodValue(key, args[key]));
            }
            return acc;
        }, [] as string[])
        .join('&');
};

const getArgumentMethodValue = (key: string, value: string): string => {
    const method = argumentMethods[key];
    return method ? method(value) : `${key}=${value}`;
};

const argumentMethods: AdobeArgumentMethod = {
    limit: limit => `search_parameters[limit]=${limit}`,
    locales: locale => `locale=${locale}`,
    order: order => `search_parameters[order]=${order}`,
    orientation: orientation => `search_parameters[filters][orientation]=${orientation}`,
    page: page => `page=${page}`,
    type: types => types.map(t => `search_parameters[filters][content_type:${t}]=1`).join('&'),
    words: words => `search_parameters[words]=${words}`,
};

(ww.AdobeMiddlewares as Partial<AdobeMiddleware>[]) = [...(ww.AdobeMiddlewares || [])];

export const addMiddleware = (m: AdobeMiddleware) => {
    ww.AdobeMiddlewares.push(m);
};

export const mapAdobeArgs = (requestArgs: IndexSignature) => {
    const filters = [
        {
            name: 'image_type',
            equivalent: 'type',
        },
        {
            name: 'page',
            equivalent: 'page',
        },
        {
            name: 'region',
            equivalent: 'locales',
        },
        {
            name: 'query',
            equivalent: 'words',
        },
        {
            name: 'orientation',
            equivalent: 'orientation',
        },
        {
            name: 'limit',
            equivalent: 'limit',
        },
    ];
    const args = filters.reduce((acc, { name, equivalent }) => {
        const value = requestArgs[name];
        if (value && equivalent !== 'locales') {
            acc[equivalent] = value.indexOf(',') >= 0 || 'image_type' === name ? value.split(',') : value;
        }
        return acc;
    }, {} as IndexSignature);
    if (args.orientation) {
        if (args.orientation == 'landscape') {
            args.orientation = 'horizontal';
        }
        if (args.orientation == 'portrait') {
            args.orientation = 'vertical';
        }
        if (args.orientation == 'panoramic') {
            delete args.orientation;
        }
    }
    if (!args.words) {
        delete args.orientation;
    }
    return args as AdobeOptions;
};

const globalMiddleware: AdobeMiddleware = {
    preGet: (referrer, options) => {
        let r = referrer,
            o = options;
        for (const m of ww.AdobeMiddlewares) {
            if (m.preGet) {
                [r, o] = m.preGet(r, o);
            }
        }
        return [r, o];
    },
    postGet: retval => {
        let r = retval;
        for (const m of ww.AdobeMiddlewares) {
            if (m.postGet) {
                r = m.postGet(r);
            }
        }
        return r;
    },
    preGetRequestUrl: options => {
        let o = options;
        for (const m of ww.AdobeMiddlewares) {
            if (m.preGetRequestUrl) {
                o = m.preGetRequestUrl(o);
            }
        }
        return o;
    },
    postGetRequestUrl: retval => {
        let r = retval;
        for (const m of ww.AdobeMiddlewares) {
            if (m.postGetRequestUrl) {
                r = m.postGetRequestUrl(r);
            }
        }
        return r;
    },
    preGetArguments: options => {
        let o = options;
        for (const m of ww.AdobeMiddlewares) {
            if (m.preGetArguments) {
                o = m.preGetArguments(o);
            }
        }
        return o;
    },
    postGetArguments: retval => {
        let r = retval;
        for (const m of ww.AdobeMiddlewares) {
            if (m.postGetArguments) {
                r = m.postGetArguments(r);
            }
        }
        return r;
    },
};

export const get = async (placement: Placements, options: AdobeOptions) =>
    globalMiddleware.postGet(await getImpl(...globalMiddleware.preGet(placement, options)));

export const getRequestUrl = (options: AdobeOptions) =>
    globalMiddleware.postGetRequestUrl(getRequestUrlImpl(globalMiddleware.preGetRequestUrl(options)));

export const getArguments = (options: AdobeOptions) =>
    globalMiddleware.postGetArguments(getArgumentsImpl(globalMiddleware.preGetArguments(options)));

type AdobeArgumentMethod = { [K in keyof AdobeMethods]: (option: AdobeMethods[K]) => string };
type AdobeOrientations = 'horizontal' | 'vertical' | 'square';
type AdobeTypes = 'vector' | 'photo' | 'illustration';

export interface AdobeURLOptions {
    args?: IndexSignature;
    freepikRedirect?: boolean;
    options: AdobeOptions;
    placement: Placements;
    url?: string;
}

export interface AdobeOptions {
    limit?: number;
    locales?: string;
    order?: string;
    orientation?: AdobeOrientations;
    page?: number;
    product?: string;
    type?: AdobeTypes[];
    words: string;
}

export interface AdobeMethods {
    limit: number;
    locales: string;
    order: string;
    orientation: AdobeOrientations;
    page: number;
    type: AdobeTypes[];
    words: string;
}

interface AdobeCategory {
    id: number;
    name: string;
}

interface AdobeFile {
    category: AdobeCategory;
    content_type: string;
    creator_id: number;
    creator_name: string;
    details_url: string;
    height: number;
    id: number;
    premium_level_id: number;
    thumbnail_240_height: number;
    thumbnail_240_url: string;
    thumbnail_240_width: number;
    thumbnail_500_height: number;
    thumbnail_500_url: string;
    thumbnail_500_width: number;
    title: string;
    vector_type: string;
    width: number;
}

export interface AdobeResponse {
    nb_results: number;
    files: AdobeFile[];
}

interface AdobeMiddleware {
    preGet: (placement: Placements, options: AdobeOptions) => [Placements, AdobeOptions];
    postGet: (retval: ThenArg<typeof getImpl>) => ThenArg<typeof getImpl>;

    preGetRequestUrl: (options: AdobeOptions) => AdobeOptions;
    postGetRequestUrl: (retval: ReturnType<typeof getRequestUrlImpl>) => ReturnType<typeof getRequestUrlImpl>;

    preGetArguments: (options: AdobeOptions) => AdobeOptions;
    postGetArguments: (retval: ReturnType<typeof getArgumentsImpl>) => ReturnType<typeof getArgumentsImpl>;
}

type ThenArg<T> = T extends Promise<infer U> ? U : T extends (...args: any[]) => Promise<infer V> ? V : T;
